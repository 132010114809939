import { format, isWeekend } from 'date-fns';
import { timeStrToLocal } from './timeConvert';

const isWorking = (user) => {
  if (!user.work_start_time || isWeekend(new Date())) {
    return false;
  }
  const userTime = format(new Date(), 'HH:mm');
  const workStartTime = timeStrToLocal(user.work_start_time, user.timezone);
  const workEndTime = timeStrToLocal(user.work_end_time, user.timezone);
  return userTime >= workStartTime && userTime <= workEndTime;
};

export default isWorking;
