import { combineEpics } from 'redux-observable';
import usersEpic from './users.epic';
import authEpic from './auth.epic';
import clientsEpic from './clients.epic';
import projectsEpic from 'epic/projects.epic';
import timesEpic from 'epic/times.epic';
import meEpic from 'epic/me.epic';
import accessEpic from 'epic/access.epic';
import rolesEpic from 'epic/roles.epic';
import locationsEpic from 'epic/locations.epic';
import officeIPsEpic from './officeIPs.epic';
import domainsEpic from './domains.epic';
import groupsEpic from './groups.epic';
import presencesEpic from './presences.epic';
import raportEpic from './raport.epic';
import teamsEpic from './teams.epic';
import permissionsEpic from './permissions.epic';
import holidaysEpic from './holidays.epic';

const rootEpic = combineEpics(
  usersEpic,
  authEpic,
  clientsEpic,
  projectsEpic,
  timesEpic,
  meEpic,
  accessEpic,
  rolesEpic,
  locationsEpic,
  officeIPsEpic,
  domainsEpic,
  groupsEpic,
  presencesEpic,
  raportEpic,
  teamsEpic,
  permissionsEpic,
  holidaysEpic
);

export default rootEpic;
