import { Observable } from 'rxjs';
import queryString from 'query-string';
import domainsConstants from 'constants/domains.constants';
import domainsActions from 'actions/domains.actions';
import { get, post, remove } from 'helpers/ajax';

const domainsEpic = (action$, store) =>
  Observable.merge(
    action$.ofType(domainsConstants.FETCH_DOMAINS_REQUEST).mergeMap((action) =>
      get(
        `/api/v1/domainaccess?${queryString.stringify(action.params)}`,
        {},
        true
      )
        .map((response) =>
          domainsActions.fetchDomainsSuccess(response.response)
        )
        .catch((error) =>
          Observable.of(domainsActions.fetchDomainsFailure(error))
        )
    ),
    action$.ofType(domainsConstants.CREATE_DOMAIN_REQUEST).mergeMap((action) =>
      post(
        '/api/v1/domainaccess',
        action.data,
        { 'Content-Type': 'application/json' },
        true
      )
        .map((response) =>
          domainsActions.createDomainSuccess(response.response)
        )
        .catch((error) =>
          Observable.of(domainsActions.createDomainFailure(error))
        )
    ),
    action$
      .ofType(domainsConstants.CREATE_DOMAIN_SUCCESS)
      .map(() =>
        domainsActions.fetchDomainsRequest(store.getState().domains.params)
      ),
    action$.ofType(domainsConstants.DELETE_DOMAIN_REQUEST).mergeMap((action) =>
      remove(`/api/v1/domainaccess/${action.id}`, {}, true)
        .map(() => domainsActions.deleteDomainSuccess(action.id))
        .catch((error) =>
          Observable.of(domainsActions.deleteDomainFailure(error))
        )
    ),
    action$
      .ofType(domainsConstants.DELETE_DOMAIN_SUCCESS)
      .map(() =>
        domainsActions.fetchDomainsRequest(store.getState().domains.params)
      )
  );

export default domainsEpic;
