import rolesConstants from 'constants/roles.constants';

function fetchRolesRequest(params) {
  return {
    type: rolesConstants.FETCH_ROLES_REQUEST,
    params,
  };
}

function fetchRolesSuccess(roles) {
  return {
    type: rolesConstants.FETCH_ROLES_SUCCESS,
    roles,
  };
}

function fetchRolesFailure(error) {
  return {
    type: rolesConstants.FETCH_ROLES_FAILURE,
    error,
  };
}

function createRoleRequest(data) {
  return {
    type: rolesConstants.CREATE_ROLE_REQUEST,
    data,
  };
}

function createRoleSuccess(data) {
  return {
    type: rolesConstants.CREATE_ROLE_SUCCESS,
    data,
  };
}

function createRoleFailure(error) {
  return {
    type: rolesConstants.CREATE_ROLE_FAILURE,
    error,
  };
}

function deleteRoleRequest(id) {
  return {
    type: rolesConstants.DELETE_ROLE_REQUEST,
    id,
  };
}

function deleteRoleSuccess(id) {
  return {
    type: rolesConstants.DELETE_ROLE_SUCCESS,
    id,
  };
}

function deleteRoleFailure(error) {
  return {
    type: rolesConstants.DELETE_ROLE_FAILURE,
    error,
  };
}

const rolesActions = {
  fetchRolesRequest,
  fetchRolesSuccess,
  fetchRolesFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFailure,
};

export default rolesActions;
