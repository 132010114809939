import { Observable } from 'rxjs';
import queryString from 'query-string';
import groupsConstants from 'constants/groups.constants';
import groupsActions from 'actions/groups.actions';
import { get } from 'helpers/ajax';

const groupsEpic = (action$) =>
  Observable.merge(
    action$.ofType(groupsConstants.FETCH_GROUPS_REQUEST).mergeMap((action) =>
      get(`/api/v1/groups?${queryString.stringify(action.params)}`, {}, true)
        .map((response) => groupsActions.fetchGroupsSuccess(response.response))
        .catch((error) =>
          Observable.of(groupsActions.fetchGroupsFailure(error))
        )
    )
  );

export default groupsEpic;
