import { Spin } from 'antd';
import styles from './Loader.less';

const Cat = () => {
  const colors = [
    '#af0404',
    '#8a8300',
    '#4a8a00',
    '#007b5d',
    '#004c7b',
    'white',
    '#004fe3',
    '#6834b5',
    '#b534af',
    '#b5344c',
  ];
  document.documentElement.style.cssText = `--cat-color1: ${
    colors[Math.floor(Math.random() * (colors.length - 1))]
  }`;
  const segments = 30;
  const renderSegments = () => {
    const segmentArray = [];
    // eslint-disable-next-line
    for (let i = 0; i < segments; i++) {
      segmentArray.push(<div key={i} className={styles.cat__segment} />);
    }
    return segmentArray;
  };

  return <div className={styles.cat}>{renderSegments()}</div>;
};

const Loader = ({ children, loading }) => {
  const loaderTheme = JSON.parse(localStorage.getItem('loader'));

  return (
    <Spin
      indicator={loaderTheme === 'cat' && <Cat />}
      wrapperClassName={styles.loader}
      size="large"
      spinning={loading}
    >
      {children || <div />}
    </Spin>
  );
};

export default Loader;
