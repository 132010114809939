import { Row, Tooltip } from 'antd';
import clsx from 'clsx';
import isWorking from 'helpers/isWorking';
import { timeStrToLocal } from 'helpers/timeConvert';
import userTheme from 'helpers/userTheme';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectUserLeader,
  selectUserPrivateTeams,
  selectUserPublicTeams,
} from 'selectors/teams.selectors';
import UserGravatar from '../UserGravatar/UserGravatar';
import styles from './UserTooltip.less';

const UserTooltip = ({ user, children }) => {
  const userLeader = useSelector((state) => selectUserLeader(state, user));
  const privateTeams = useSelector((state) =>
    selectUserPrivateTeams(state, user)
  );
  const publicTeams = useSelector((state) =>
    selectUserPublicTeams(state, user)
  );
  const me = useSelector((state) => state.me);

  const darkMode = userTheme(me.data.theme) === 'dark_mode';

  const createTeam = (teams) =>
    teams?.map((team) => (
      <Link
        to={`/monthly?team=${team.name.replace(' ', '_')}`}
        className={styles.team}
        key={`${user.id}${team.group_id}`}
        style={{
          border: `2px solid ${team.color1}80`,
        }}
      >
        {team.name}
      </Link>
    ));

  /* eslint-disable */
  const tooltipElement = (
    <>
      <Row className={styles.userHeader} type="flex" align="middle">
        <UserGravatar user={user} email={user.email} size="large" />
        <div>
          <h4>{user.name}</h4>
          <p className={styles.position}>{user.position}</p>
        </div>
        {user.user_state?.currently_has_days_off ? (
          <span style={{ fontSize: '24px', margin: 'auto 0 auto auto' }}>
            🌴
          </span>
        ) : user.user_state?.currently_working_remotely ? (
          <span style={{ fontSize: '24px', margin: 'auto 0 auto auto' }}>
            🏡
          </span>
        ) : (
          ''
        )}
      </Row>
      <Row className={styles.privateTeams} type="flex">
        {createTeam(privateTeams)}
      </Row>
      <div className={styles.userInfo}>
        <p className={styles.userStatus}>
          Status:{' '}
          <span
            className={clsx(
              styles.userDot,
              isWorking(user) && !user.user_state?.currently_has_days_off
                ? styles.active
                : styles.inactive
            )}
          />{' '}
          (
          {user.user_state?.currently_has_days_off
            ? 'vacationing'
            : user.user_state?.currently_working_remotely && isWorking(user)
            ? 'working remotely'
            : isWorking(user)
            ? 'working'
            : 'outside of office hours'}
          )
        </p>
        {user.work_start_time && (
          <p>
            {`Usually working: ${timeStrToLocal(
              user.work_start_time,
              user.timezone
            )} - ${timeStrToLocal(user.work_end_time, user.timezone)}`}
          </p>
        )}
        <p>{user.email}</p>
        {user.current_job_tenure && (
          <p>
            {`Job tenure: ${
              user.current_job_tenure.years
                ? `${user.current_job_tenure.years} ${
                    user.current_job_tenure.years === 1 ? 'year' : 'years'
                  }`
                : ''
            } ${
              user.current_job_tenure.months
                ? `${user.current_job_tenure.months} ${
                    user.current_job_tenure.months === 1 ? 'month' : 'months'
                  }`
                : ''
            }`}
          </p>
        )}
        <p>{user.phone_number}</p>
        {userLeader && (
          <p>
            Leader:{' '}
            <Link className={styles.userLink} to={`/users/${userLeader?.id}`}>
              {userLeader?.name}
            </Link>
          </p>
        )}
      </div>
      {publicTeams?.[0] && (
        <div className={styles.publicTeams}>
          <h5>Teams</h5>
          <div className={styles.groupsContainer}>
            {createTeam(publicTeams)}
          </div>
        </div>
      )}
    </>
  );

  return (
    <Tooltip
      placement="right"
      showArrow={false}
      overlayClassName={clsx(styles.tooltip, darkMode && styles.darkMode)}
      title={() => tooltipElement}
    >
      {children}
    </Tooltip>
  );
};

export default UserTooltip;
