import meConstants from 'constants/me.constants';

function retrieveMeRequest() {
  return {
    type: meConstants.RETRIEVE_ME_REQUEST,
  };
}

function retrieveMeSuccess(data) {
  return {
    type: meConstants.RETRIEVE_ME_SUCCESS,
    data,
  };
}

function retrieveMeFailure(error) {
  return {
    type: meConstants.RETRIEVE_ME_FAILURE,
    error,
  };
}

const meActions = {
  retrieveMeRequest,
  retrieveMeSuccess,
  retrieveMeFailure,
};

export default meActions;
