import dayjs from 'dayjs';
import clientsConstants from 'constants/clients.constants';

/* eslint-disable prefer-object-spread */
export default function clients(state = {}, action) {
  switch (action.type) {
    case clientsConstants.FETCH_CLIENTS_REQUEST: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        params: action.params,
        fetching: true,
        fetched: false,
      };
      return Object.assign({}, state, newState);
    }

    case clientsConstants.FETCH_CLIENTS_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        clients: action.clients,
        fetching: false,
        fetched: true,
        fetchedAt: dayjs(),
      };
      return Object.assign({}, state, newState);
    }

    case clientsConstants.FETCH_CLIENTS_FAILURE: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        clients: {},
        fetched: false,
        fetching: false,
      };
      return Object.assign({}, state, newState);
    }

    case clientsConstants.DELETE_CLIENT_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      });

    case clientsConstants.DELETE_CLIENT_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      });

    case clientsConstants.DELETE_CLIENT_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
      });

    case clientsConstants.PATCH_CLIENT_REQUEST:
      return Object.assign({}, state, {
        patching: true,
      });

    case clientsConstants.PATCH_CLIENT_SUCCESS:
      return Object.assign({}, state, {
        patching: false,
      });

    case clientsConstants.PATCH_CLIENT_FAILURE:
      return Object.assign({}, state, {
        patching: false,
      });

    case clientsConstants.CREATE_CLIENT_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      });

    case clientsConstants.CREATE_CLIENT_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
      });

    case clientsConstants.CREATE_CLIENT_FAILURE:
      return Object.assign({}, state, {
        creating: false,
      });

    default:
      return state;
  }
}
