const daysOffConstants = {
  FETCH_NEXT_DAYS_OFF_REQUEST: 'DAYS_OFF_FETCH_NEXT_DAYS_OFF_REQUEST',
  FETCH_NEXT_DAYS_OFF_SUCCESS: 'DAYS_OFF_FETCH_NEXT_DAYS_OFF_SUCCESS',
  FETCH_DAYS_OFF_REQUEST: 'DAYS_OFF_FETCH_DAYS_OFF_REQUEST',
  FETCH_DAYS_OFF_SUCCESS: 'DAYS_OFF_FETCH_DAYS_OFF_SUCCESS',
  FETCH_DAYS_OFF_FAILURE: 'DAYS_OFF_FETCH_DAYS_OFF_FAILURE',

  CREATE_DAYS_OFF_REQUEST: 'DAYS_OFF_CREATE_DAYS_OFF_REQUEST',
  CREATE_DAYS_OFF_SUCCESS: 'DAYS_OFF_CREATE_DAYS_OFF_SUCCESS',
  CREATE_DAYS_OFF_FAILURE: 'DAYS_OFF_CREATE_DAYS_OFF_FAILURE',

  DELETE_DAYS_OFF_REQUEST: 'DAYS_OFF_DELETE_DAYS_OFF_REQUEST',
  DELETE_DAYS_OFF_FAILURE: 'DAYS_OFF_DELETE_DAYS_OFF_FAILURE',

  FETCH_HOLIDAYS_REQUEST: 'DAYS_OFF_FETCH_HOLIDAYS_REQUEST',
  FETCH_HOLIDAYS_SUCCESS: 'DAYS_OFF_FETCH_HOLIDAYS_SUCCESS',
  FETCH_HOLIDAYS_ERROR: 'DAYS_OFF_FETCH_HOLIDAYS_ERROR',
  CHECK_AND_CREATE_DAYS_OFF: 'CHECK_AND_CREATE_DAYS_OFF',
};

export default daysOffConstants;
