const presencesConstants = {
  FETCH_PRESENCES_REQUEST: 'PRESENCES_FETCH_PRESENCES_REQUEST',
  FETCH_PRESENCES_SUCCESS: 'PRESENCES_FETCH_PRESENCES_SUCCESS',
  FETCH_PRESENCES_FAILURE: 'PRESENCES_FETCH_PRESENCES_FAILURE',
  SEND_PRESENCE_TICK: 'PRESENCES_SEND_PRESENCE_TICK_REQUEST',
  SEND_PRESENCE_TICK_SUCCESS: 'PRESENCES_SEND_PRESENCE_TICK_SUCCESS',
  SEND_PRESENCE_TICK_FAILURE: 'PRESENCES_SEND_PRESENCE_TICK_FAILURE',
};

export default presencesConstants;
