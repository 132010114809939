import raportConstants from 'constants/raport.constants';

function fetchRaportRequest(params, storeId) {
  return {
    type: raportConstants.FETCH_RAPORT_REQUEST,
    params,
    storeId,
  };
}

function fetchRaportSuccess(raport, storeId) {
  return {
    type: raportConstants.FETCH_RAPORT_SUCCESS,
    raport,
    storeId,
  };
}

function fetchRaportFailure(error, storeId) {
  return {
    type: raportConstants.FETCH_RAPORT_FAILURE,
    error,
    storeId,
  };
}

const raportActions = {
  fetchRaportRequest,
  fetchRaportSuccess,
  fetchRaportFailure,
};

export default raportActions;
