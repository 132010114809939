import {
  CarOutlined,
  MinusOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Row, Select, Switch, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import raportActions from 'actions/raport.actions';
import { clsx } from 'clsx';
import { formatDate, formatTime } from 'helpers/dateTime';
import without from 'lodash/without';
import './Monthly.css';
import styles from './Monthly.less';

import MonthPicker from 'components/QuickMonthPicker';

import Loader from 'components/Loader/Loader';
import Sprite from 'components/Sprite/Sprite';
import UserBadge from 'components/UserBadge/UserBadge';
import UserTooltip from 'components/UserTooltip/UserTooltip';
import compareAlphabetically from 'helpers/compareAlphabetically';
import sortByCurrentUser from 'helpers/sortByCurrentUser';
import { getIsAdmin } from 'selectors/permissions.selectors';

const toggleElement = (array, elem) => {
  const arrayWithoutElem = without(array, elem);
  // element was not present in the array, so return array with it.
  if (array.length === arrayWithoutElem.length) {
    return [...array, elem];
  }
  // element was present in the array, so return array without it.
  return arrayWithoutElem;
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const loadFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

class MonthlyReport extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const currentDate = dayjs(params.get('month') || dayjs());
    this.displayName = 'MonthlyReport';
    const teamParam = params.get('team');

    const teamValue = teamParam
      ? teamParam.split('_').join(' ')
      : 'Select Team';

    this.state = {
      currentDate,
      columns: [],
      // eslint-disable-next-line
      columnsTeam: [],
      times: [],
      showTotal: true,
      showGrouped: true,
      teams: [],
      selectedTeam: teamValue,
      expandedKeys: [],
      meOnTop: false,
    };

    this.toggleTeam = this.toggleTeam.bind(this);

    this.handleDate = this.handleDate.bind(this);
  }

  componentDidMount() {
    if (this.state.selectedTeam !== 'Select Team') {
      saveToLocalStorage('showGrouped', true);
    }

    this.setState({
      expandedKeys: loadFromLocalStorage('expandedKeys'),
      showGrouped: loadFromLocalStorage('showGrouped'),
      meOnTop: loadFromLocalStorage('meOnTop'),
    });

    this.updateRaport(this.state.currentDate);
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps, prevState) {
    const { raport, me, teams, permissions } = this.props;
    const { currentDate } = this.state;

    if (this.state.expandedKeys !== prevState.expandedKeys) {
      saveToLocalStorage('expandedKeys', this.state.expandedKeys);
    }

    if (this.state.showGrouped !== prevState.showGrouped) {
      saveToLocalStorage('showGrouped', this.state.showGrouped);
    }

    if (this.state.meOnTop !== prevState.meOnTop) {
      saveToLocalStorage('meOnTop', this.state.meOnTop);
    }

    const expandedKeysStorage = loadFromLocalStorage('expandedKeys');
    const showGroupedStorage =
      loadFromLocalStorage('showGrouped') !== null || undefined;

    // check if url changed and update is needed
    const queryString = this.props.location.search;
    const paramsChanged = prevProps.location.search !== queryString;
    if (paramsChanged) {
      const queryParams = new URLSearchParams(queryString);
      const newDate = dayjs(queryParams.get('month') || dayjs());
      this.updateRaport(newDate);
      const teamParam = queryParams.get('team');

      this.setState({
        selectedTeam: teamParam
          ? teamParam.split('_').join(' ')
          : 'Select Team',
      });
    }

    if (
      !raport ||
      !raport.raportPage.fetched ||
      !me.fetched ||
      !teams.fetched ||
      !permissions.fetched
    ) {
      return;
    }
    raport.raportPage.fetched = false;
    const newRaport = raport.raportPage.raport;
    const times = newRaport.times;
    const days = newRaport.days;

    if (days && times && times.length) {
      // create columns from days
      const { columns, columnsTeam } = this.createColumns(days, currentDate);

      // find my times
      times.sort(
        // sort rest of the table
        (a, b) => a.user.name.localeCompare(b.user.name)
      );
      const meTimes = times.find((x) => x.user.id === me.data.id);
      if (!meTimes) {
        this.setState(() => ({
          times,
          columns,
          /* eslint-disable react/no-unused-state */
          columnsTeam,
        }));
        return;
      }
      const index = times.indexOf(meTimes);
      times.splice(index, 1); // remove my times

      this.setState(() => ({
        times: [meTimes, ...times], // put current user Times as first element
        columns,
        columnsTeam,
        teams: teams.data.results,
        expandedKeys:
          expandedKeysStorage?.length > 0
            ? expandedKeysStorage
            : teams.data.results.map((team) => `team${team.group_id}`),
        showGrouped: showGroupedStorage
          ? loadFromLocalStorage('showGrouped')
          : teams.data.results.some((team) =>
              team.users.some((user) => user.name === me.data.name)
            ),
      }));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getMobileUserName(fullname) {
    // Changes first name to letter, if exists
    // Adds ellipsis if text is longer than specified length
    const MAX_LEN = 15;

    let firstWord = fullname.substr(0, fullname.indexOf(' '));
    const restWords = fullname.substr(fullname.indexOf(' ') + 1);
    if (firstWord) {
      firstWord = `${firstWord[0]}. `;
    }
    const mobileUserName = `${firstWord}${restWords}`;
    const ellipsisNeeded = mobileUserName.length > MAX_LEN;
    return `${mobileUserName.slice(0, MAX_LEN)}${ellipsisNeeded ? '...' : ''}`;
  }

  handleTotalChange = (showTotal) => {
    this.setState(() => ({
      showTotal,
    }));
  };

  checkUserInTeam = (teamName, user) => {
    const { teams } = this.props;
    return teams.data.results
      .find((team) => team.name === teamName)
      .users.some((member) => member.id === user.id);
  };

  createColumns(days, currentDate) {
    const columnsDays = [];
    Object.keys(days).forEach((date) => {
      const day = date.split('-')[2];
      const classNames = ['raport-day'];
      if (days[date]) {
        classNames.push('non-working');
      }
      if (date === formatDate(new Date())) {
        classNames.push('working');
      }
      columnsDays.push({
        title: (
          <Tooltip title={days[date]} className="full-block">
            {day}
          </Tooltip>
        ),
        dataIndex: ['times', `${date}`],
        key: date,
        render: (time, record) => {
          if (!time) {
            return;
          }

          const isFutureDay = new Date(date) > dayjs();

          const timeUrl = `/users/${record.user.id}/times?date=${date}`;
          let title = '';
          if (time.days_off > 0)
            title = `${time.days_off.toString()} Day(s) off`;
          let inscribedAnotherDay = null;
          if (time.inscribed_another_day) {
            inscribedAnotherDay = (
              <Tooltip
                title="Inscribed another day"
                className="monthly-inscribed-wrong-day"
              >
                <WarningOutlined />
              </Tooltip>
            );
          }
          let businessTrip = null;
          if (time.office_work) {
            businessTrip = (
              <Tooltip title="Business trip" className="businessTrip">
                <CarOutlined />
              </Tooltip>
            );
          }
          /* eslint-disable consistent-return */
          return (
            <Tooltip title={title} className="full-block">
              <Link className={isFutureDay ? styles.hide : ''} to={timeUrl}>
                {formatTime(time.sum)}
              </Link>
              {inscribedAnotherDay}
              {businessTrip}
            </Tooltip>
          );
        },
        className: classNames.join(' '),
        onCell: (record) => {
          if (record.times.length <= 0 || !record.times[date]) {
            return;
          }
          const cellClassNames = [...classNames];
          if (!record.times[date].sum) {
            cellClassNames.push('zero-time');
          }
          if (record.times[date].days_off > 0) {
            cellClassNames.push('day-off');
          }
          if (record.times[date].remote_work) {
            cellClassNames.push('remote-work');
          }
          if (
            record.user.daily_hours > 0 &&
            !record.times[date].day_off &&
            !days[date] &&
            record.times[date].sum < record.user.daily_hours
          ) {
            cellClassNames.push('chaotic-employee');
          }
          return {
            className: cellClassNames.join(' '),
          };
        },
      });
    });
    let columns = [
      {
        title: 'Name',
        dataIndex: 'user',
        fixed: 'left',
        width: 200,
        className: 'user-link',
        render: (user) => {
          const mobileUserName = this.getMobileUserName(user?.name);
          const isLeader = this.checkUserInTeam('Leaders', user);

          const isHR = this.checkUserInTeam('HR', user);
          if (user.daily_hours === undefined) {
            return (
              <Link className={styles.teamHeader} to="/monthly">
                <span
                  className={styles.teamDot}
                  style={{
                    backgroundColor: `${user.color1}80`,
                  }}
                />
                <span className={styles.desktopUserName}>{user.name}</span>
                <span className={styles.mobileUserName}>{mobileUserName}</span>
              </Link>
            );
          }
          /* eslint-disable no-nested-ternary */
          return (
            <UserTooltip user={user}>
              <Link to={`/users/${user.id}`}>
                <UserBadge user={user} />
                <span className={styles.desktopUserName}>{user.name}</span>
                <span className={styles.mobileUserName}>{mobileUserName}</span>
                {isHR && isLeader ? (
                  <Tooltip title="Team Leader & HR">
                    <div className={clsx(styles.hr, styles.joinedHR)}>H</div>
                    <div className={clsx(styles.leader, styles.joinedLeader)}>
                      L
                    </div>
                  </Tooltip>
                ) : isLeader ? (
                  <Tooltip title="Team Leader" className={styles.leader}>
                    L
                  </Tooltip>
                ) : isHR ? (
                  <Tooltip title="Human Resources" className={styles.hr}>
                    H
                  </Tooltip>
                ) : (
                  ''
                )}
              </Link>
            </UserTooltip>
          );
        },
      },
      {
        title: currentDate.format('MMMM YYYY'),

        // children: columnsDays,
        children: columnsDays.map((column) => ({
          ...column,
          width: 46, // ustawienie szerokości kolumny
        })),
      },
      {
        title: 'Worked',
        key: 'worked',
        fixed: 'right',
        dataIndex: 'sum',
        className: 'raport-day vertical-text total-col',

        width: 64,
        render: (time) => <span>{time?.toFixed(2)}</span>,
      },
      {
        title: 'Expected',
        key: 'expect',
        fixed: 'right',
        dataIndex: 'expected',
        className: 'raport-day vertical-text',

        width: 64,
        render: (time, row) => {
          /* eslint-disable camelcase */
          return row.user?.daily_hours === 0 ? (
            'N/A'
          ) : (
            <span>{time ? time.toFixed(2) : ''}</span>
          );
        },
        onCell: (record) => {
          const cellClassNames = ['raport-day', 'total-col'];
          if (record.user.daily_hours === 0)
            cellClassNames.push('part-time-employee');
          return {
            className: cellClassNames.join(' '),
          };
        },
      },
      {
        title: 'Balance',
        key: 'off',
        fixed: 'right',
        dataIndex: 'diff',
        className: 'raport-day vertical-text',

        width: 64,
        render: (time, row) => {
          return row.user?.daily_hours === 0 ? (
            'N/A'
          ) : (
            <span>{time?.toFixed(2)}</span>
          );
        },
        onCell: (record) => {
          const timeDiff = record.diff;
          const cellClassNames = ['raport-day', 'total-col'];
          if (record.user.daily_hours === 0)
            cellClassNames.push('part-time-employee');
          else if (timeDiff < -8) cellClassNames.push('bad-employee');
          else if (timeDiff < 0) cellClassNames.push('chaotic-employee');
          else if (timeDiff > 0) cellClassNames.push('good-employee');
          return {
            className: cellClassNames.join(' '),
          };
        },
      },
    ];

    const columnsTeam = [
      {
        title: 'Team1',
        dataIndex: 'user',
        width: 200,
        fixed: 'left',
      },
    ];
    if (!days) {
      columns = [];
    }
    return { columns, columnsTeam };
  }
  // eslint-disable-next-line
  toggleView = () => {
    this.setState((oldState) => ({
      showGrouped: !oldState.showGrouped,
    }));

    if (this.state.showGrouped) {
      this.setState(() => ({
        selectedTeam: 'Select Team',
      }));
    }
  };

  updateRaport(date) {
    const { dispatch } = this.props;
    this.setState(() => ({
      currentDate: date,
    }));
    dispatch(
      raportActions.fetchRaportRequest(
        {
          date: formatDate(date),
        },
        'raportPage'
      )
    );
  }

  handleDate(date) {
    const currentDate = dayjs();
    const isCurrentMonth = date.isSame(currentDate, 'month');

    const queryParams = new URLSearchParams(this.props.location.search);

    const sendDate = isCurrentMonth ? currentDate : date.clone().endOf('month');

    queryParams.set('month', sendDate.format('YYYY-MM-DD'));

    this.props.history.push({
      search: queryParams.toString(),
    });
  }

  handleChange = (value) => {
    const queryParams = new URLSearchParams(this.props.location.search);
    if (value !== 'Select Team') {
      this.setState(() => ({ showGrouped: true }));

      queryParams.set('team', value.split(' ').join('_'));
    } else {
      queryParams.delete('team');
    }

    this.props.history.push({
      search: queryParams.toString(),
    });

    this.setState(() => ({ selectedTeam: value }));
  };

  toggleTeam(row) {
    if (!row.children) return;

    this.setState((prevState) => ({
      expandedKeys: toggleElement(prevState.expandedKeys, row.key),
    }));
    saveToLocalStorage('expandedKeys', this.state.expandedKeys);
  }

  renderExpandIcon(props) {
    const { expanded, record } = props;

    if (!this.state.showGrouped || !record.children) {
      return;
    }

    return expanded ? (
      <MinusOutlined className={styles.toggleTeam} />
    ) : (
      <PlusOutlined className={styles.toggleTeam} />
    );
  }

  toggleMeOnTop = () => {
    this.setState((oldState) => ({ meOnTop: !oldState.meOnTop }));
  };

  render() {
    const {
      times,
      currentDate,
      showTotal,
      columns,
      showGrouped,
      teams,
      selectedTeam,
    } = this.state;
    const { me, raport } = this.props;

    const isAdmin = getIsAdmin(this.props.permissions);

    const activeTeams = teams.filter((team) => team.state === 'active');

    const publicTeams = activeTeams.filter((team) => team.type === 'public');

    const permissedTeams = isAdmin ? activeTeams : publicTeams;

    const sortedTeams = [...permissedTeams].filter(
      (team) => team.users.length !== 0
    );
    sortedTeams.sort((a, b) => compareAlphabetically(a.name, b.name));
    sortedTeams.sort((a, b) => sortByCurrentUser(a, b, me));

    const teamView = sortedTeams
      .map((team) => {
        const names = team.users.map((item) => item.id);
        const children = times.filter((time) => names.includes(time.user.id));

        const changedChildren = (this.state.meOnTop &&
        this.state.selectedTeam === 'Select Team'
          ? children.filter((child) => child.user.id !== me.data.id)
          : children
        ).map((child, childIndex) => {
          return { ...child, key: `team${team.group_id}-${childIndex}` };
        });

        return {
          key: `team${team.group_id}`,
          user: {
            id: `team${team.group_id}`,
            name: team.name,
            color1: team.color1,
            color2: team.color2,
            type: team.type,
          },
          times: [],
          children: changedChildren,
        };
      })
      .filter((team) => team.children[0]);

    if (
      this.state.meOnTop &&
      times[0] &&
      this.state.selectedTeam === 'Select Team'
    ) {
      teamView.unshift({
        key: me.data.name,
        ...times.filter((time) => time.user.id === me.data.id)[0],
      });
    }

    const filteredTeam = teamView.filter((i) => i.user?.name === selectedTeam);

    const finalTeamView =
      selectedTeam === 'Select Team'
        ? teamView.filter((team) => team.user?.type !== 'utility')
        : filteredTeam;

    times.forEach((data, index) => {
      times[index].key = data.user.id;
    });

    const menu = [
      <Select.Option key="o0" value="Select Team">
        Select Team
      </Select.Option>,
      ...sortedTeams.map((i, index) => (
        <Select.Option key={`o${index + 1}`} value={i.name}>
          {i.name}
        </Select.Option>
      )),
    ];

    return (
      <Loader loading={times.length === 0 || raport.raportPage?.fetching}>
        <div
          className={`monthly-view ${
            showTotal ? '' : 'monthly-view--hide-total'
          }`}
        >
          <Helmet title="Monthly Report" />
          <div className={clsx('table-operations', styles.tableViewMenu)}>
            <div className={styles.filters}>
              <MonthPicker
                placeholder="Select month"
                value={currentDate}
                onChange={this.handleDate}
                allowClear={false}
              />
              <Switch
                className={[
                  styles.totalSwitch,
                  showTotal ? styles.checked : '',
                ].join(' ')}
                defaultChecked
                onChange={this.handleTotalChange}
              />
            </div>
            <Row className="viewContainer" type="flex" justify="end">
              {showGrouped && this.state.selectedTeam === 'Select Team' && (
                <Button className="switchView" onClick={this.toggleMeOnTop}>
                  {this.state.meOnTop ? 'Unpin' : 'Pin'}
                </Button>
              )}
              <Button onClick={this.toggleView}>
                {showGrouped ? 'All' : 'Teams'}
              </Button>
              <Select
                onChange={this.handleChange}
                style={{ width: 120 }}
                defaultValue="Select Team"
                value={selectedTeam}
              >
                {menu}
              </Select>
            </Row>
          </div>
          <div className="table-wrapper">
            {activeTeams.length > 0 && (
              <Table
                className={clsx('no-content', showGrouped && 'teams')}
                size="small"
                scroll={{ x: true }}
                bordered
                columns={columns}
                dataSource={showGrouped ? finalTeamView : times}
                pagination={false}
                expandRowByClick
                expandedRowKeys={this.state.expandedKeys}
                expandIcon={(props) => this.renderExpandIcon(props)}
                indentSize={5}
                onRow={(record) => {
                  return { onClick: () => this.toggleTeam(record) };
                }}
                rowClassName={(record) => {
                  if (
                    me.data.groups
                      .map((group) => group.name)
                      .some((team) => team === record.user.name)
                  ) {
                    return styles.myTeam;
                  }
                  if (!record.expected) {
                    return styles.team;
                  }
                }}
              />
            )}
          </div>
          <Sprite />
        </div>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  const raport = state.raport;
  const me = state.me;
  const teams = state.teams;
  const permissions = state.permissions;
  return {
    me,
    raport,
    teams,
    permissions,
  };
}

export default connect(mapStateToProps)(MonthlyReport);
