import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { differenceInCalendarDays } from 'date-fns';
import getMaxPeriodNumber from 'helpers/getMaxPeriodNumber';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './AddOfficeWorkModal.less';

const AddOfficeWorkModal = ({ onOk, onCancel, visible }) => {
  const [reoccurrenceMode, setReoccurrenceMode] = useState(false);
  const [maxPeriodNumber, setMaxPeriodNumber] = useState(2);
  const [selectedDays, setSelectedDays] = useState(0);
  const [form] = Form.useForm();
  const me = useSelector((state) => state.me);
  const showBusinessTrip = me.data.planned?.office_work; // TO DELETE

  const acceptData = async () => {
    try {
      const values = await form.validateFields();
      const { dateRange } = values;
      const dateStart = dateRange[0].format('YYYY-MM-DD');
      const dateEnd = dateRange[1].format('YYYY-MM-DD');
      const result = {
        /* eslint-disable camelcase */
        date_start: dateStart,
        date_end: dateEnd,
        reoccurrence: reoccurrenceMode,
        period: values.period,
        repetition: values.repetition,
        frequency: values.frequency,
        business_trip: values.business,
      };
      onOk(result);
      form.resetFields();
      // eslint-disable-next-line
    } catch (err) {}
  };

  const handleDateRangeChange = (formValues) => {
    const dates = formValues.dateRange;

    if (dates && dates.length === 2) {
      const dateStart = new Date(dates[0]);
      const dateEnd = new Date(dates[1]);

      const selectedDates =
        differenceInCalendarDays(new Date(dateEnd), new Date(dateStart)) + 1;

      setSelectedDays(selectedDates);

      if (selectedDates > 7) {
        form.setFieldValue('period', 'month');
      }

      if (selectedDates > 31) {
        setReoccurrenceMode(false);
      }
    }
  };

  const handleChangeMode = () => {
    setReoccurrenceMode((oldState) => !oldState);
  };

  const { RangePicker } = DatePicker;

  return (
    <Modal
      open={visible}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        acceptData();
      }}
      title="Add Office Work"
    >
      <p className={styles.description}>
        Remove specified date range from previously declared remote work. If you
        have no remote work planned in selected dates this will have no effect
      </p>
      <Form
        layout="vertical"
        form={form}
        name="add_office_work"
        onValuesChange={(_, allValues) => {
          setMaxPeriodNumber(getMaxPeriodNumber(allValues));
          handleDateRangeChange(allValues);
        }}
      >
        <Form.Item
          label="From - To"
          name="dateRange"
          rules={[
            {
              required: true,
              message: 'Please select time.',
            },
          ]}
        >
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
        {showBusinessTrip && (
          <Form.Item name="business" valuePropName="checked">
            <Checkbox>
              <span>Business trip </span>
              <Tooltip title="Mark this office work as business trip: you will be traveling to the office over 100km and you will need business trip expenses reimbursement">
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item name="reoccurrence">
          <Checkbox
            style={{ transform: 'translateY(-10px)' }}
            checked={reoccurrenceMode}
            onChange={handleChangeMode}
            disabled={selectedDays > 31}
          >
            Reoccurrence
          </Checkbox>
        </Form.Item>
        {selectedDays > 31 && (
          <p className={styles.error}>
            Cannot set reoccurrence if more than 31 days are selected
          </p>
        )}
        {reoccurrenceMode && selectedDays <= 31 && (
          <Row gutter={8}>
            <Col>
              <Form.Item
                rules={[{ required: true }]}
                initialValue="week"
                label="Period"
                name="period"
              >
                <Select
                  options={
                    selectedDays <= 7
                      ? [
                          {
                            value: 'week',
                            label: 'weekly',
                          },
                          { value: 'month', label: 'monthly' },
                        ]
                      : [{ value: 'month', label: 'monthly' }]
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                initialValue={1}
                label="Repetition"
                name="repetition"
                rules={[{ required: true }]}
              >
                <InputNumber min={1} max={maxPeriodNumber} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                initialValue={1}
                label={
                  <>
                    <span>Frequency</span>
                    <Tooltip title='The default value is 1. If you change it to, for instance 2, and the time period is set to "week," it means the occurrence is established every two weeks instead of every week.'>
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </>
                }
                name="frequency"
              >
                <InputNumber min={1} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

AddOfficeWorkModal.displayName = 'AddOfficeWorkModal';
/* eslint-disable react/require-default-props */
AddOfficeWorkModal.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
};

export default AddOfficeWorkModal;
