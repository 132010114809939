import permissionsConstants from '../constants/permissions.constants';

export default function permissions(state = '', action) {
  switch (action.type) {
    case permissionsConstants.FETCH_PERMISSIONS_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case permissionsConstants.FETCH_PERMISSIONS_SUCCESS: {
      return {
        permissionLevel: action.permissionLevel.permission_level,
        fetched: true,
      };
    }

    case permissionsConstants.FETCH_PERMISSIONS_FAILURE: {
      return { ...state, fetching: false, error: action.error };
    }
    default:
      return state;
  }
}
