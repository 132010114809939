import { Button, Input, Form, ConfigProvider, theme, Alert } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import styles from './LoginFreelancer.less';
import loginActions from 'actions/login.actions';
import AuthLayout from 'layout/AuthLayout';

const RegisterFreelancer = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const urlSegments = window.location.pathname.split('/');
  const token = urlSegments[urlSegments.length - 1];
  const onFinish = (values) => {
    dispatch(
      loginActions.registerRequest(values, token !== 'register' && token)
    );
  };

  useEffect(() => {
    if (login.errors) {
      Object.keys(login.errors).forEach((field) => {
        formRef.current.setFields([
          {
            name: field,
            errors: [login.errors[field]],
          },
        ]);
      });
    }
  }, [login]);

  return (
    <AuthLayout>
      <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
        <div className={styles.main}>
          <Form
            ref={formRef}
            name="freelancer register"
            className={styles.freelancerForm}
            onFinish={onFinish}
          >
            {login?.errors?.token && (
              <Alert
                style={{ marginBottom: '20px' }}
                message={login?.errors?.token}
                type="error"
              />
            )}
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="surname"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Surname" />
            </Form.Item>
            <Form.Item
              name="login"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="E-Mail" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={login.fetching}
                type="primary"
                htmlType="submit"
                className={styles.login}
              >
                Register
              </Button>
            </Form.Item>
          </Form>
        </div>
      </ConfigProvider>
    </AuthLayout>
  );
};

export default RegisterFreelancer;
