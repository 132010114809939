// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zFC5kyfnwJnwRZmyNsL0 {
  width: 368px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 576px) {
  .zFC5kyfnwJnwRZmyNsL0 {
    width: 95%;
  }
}
.zFC5kyfnwJnwRZmyNsL0 .KTgeEcjVwq8HDA1FP3H7 {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.zFC5kyfnwJnwRZmyNsL0 .KTgeEcjVwq8HDA1FP3H7:hover {
  color: #e6f7ff;
}
.zFC5kyfnwJnwRZmyNsL0 .SCM5GiEngBTEb7fhvpCd {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.zFC5kyfnwJnwRZmyNsL0 .SCM5GiEngBTEb7fhvpCd .xoDXC_pdXSlhBUK8vTyB {
  float: right;
}
._IgTk7ky1xCHWCnWgm4B {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border: 1px solid transparent;
}
.PAlD8WP5igZdElwE22Ue {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Login.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADF;AAEE;EAAA;IACE,UAAA;EACF;AACF;AAVA;EAYI,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,sBAAA;AACJ;AACI;EACE,cAAA;AACN;AArBA;EAyBI,gBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AA1BA;EA8BM,YAAA;AADN;AAMA;EACE,uBAAA;EACA,0BAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oFAAA;EAEA,6BAAA;AALF;AAQA;EACE,cAAA;AANF","sourcesContent":["@import '../../variables.less';\n\n.main {\n  width: 368px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  @media screen and (max-width: @screen-sm) {\n    width: 95%;\n  }\n\n  .icon {\n    font-size: 24px;\n    color: rgba(0, 0, 0, 0.2);\n    margin-left: 16px;\n    vertical-align: middle;\n    cursor: pointer;\n    transition: color 0.3s;\n\n    &:hover {\n      color: #e6f7ff;\n    }\n  }\n\n  .other {\n    text-align: left;\n    margin-top: 24px;\n    line-height: 22px;\n\n    .register {\n      float: right;\n    }\n  }\n}\n\n.login {\n  background-color: white;\n  color: rgba(0, 0, 0, 0.54);\n  border-radius: 2px;\n  padding: 20px 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,\n    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;\n  border: 1px solid transparent;\n}\n\n.link {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `zFC5kyfnwJnwRZmyNsL0`,
	"icon": `KTgeEcjVwq8HDA1FP3H7`,
	"other": `SCM5GiEngBTEb7fhvpCd`,
	"register": `xoDXC_pdXSlhBUK8vTyB`,
	"login": `_IgTk7ky1xCHWCnWgm4B`,
	"link": `PAlD8WP5igZdElwE22Ue`
};
export default ___CSS_LOADER_EXPORT___;
