import locationsConstants from 'constants/locations.constants';

/* eslint-disable */
export default function locations(state = {}, action) {
  switch (action.type) {
    case locationsConstants.FETCH_LOCATIONS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
      });

    case locationsConstants.FETCH_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        locations: action.locations,
        fetched: true,
      });

    case locationsConstants.FETCH_LOCATIONS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        locations: {},
      });

    case locationsConstants.CREATE_LOCATION_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      });

    case locationsConstants.CREATE_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
      });

    case locationsConstants.CREATE_LOCATION_FAILURE:
      return Object.assign({}, state, {
        creating: false,
      });

    case locationsConstants.DELETE_LOCATION_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      });

    case locationsConstants.DELETE_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      });

    case locationsConstants.DELETE_LOCATION_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
      });

    default:
      return state;
  }
}
