import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Card } from 'antd';
import { Helmet } from 'react-helmet';
import Access from './Access';

class Settings extends React.Component {
  // eslint-disable-next-line
  displayName = 'Settings';

  tabChange(tabKey) {
    this.props.history.push({
      pathname: `/settings/${tabKey}`,
    });
  }

  render() {
    const { me } = this.props;

    const urlTab = this.props.match.params.tab;

    if (!me.data.is_superuser) {
      return null;
    }

    return (
      <Card>
        <Helmet title="Settings" />
        <Tabs
          defaultActiveKey="access"
          activeKey={urlTab}
          onChange={(tabKey) => this.tabChange(tabKey)}
          items={[{ label: 'Access', key: 'access', children: <Access /> }]}
        />
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { me } = state;
  return {
    me,
  };
}
export default connect(mapStateToProps)(Settings);
