import publicLinksConstants from 'constants/publicLinks.constants';

function fetchPublicLinks(params) {
  return {
    type: publicLinksConstants.FETCH_PUBLIC_LINKS_REQUEST,
    params,
  };
}

function fetchPublicLinksSuccess(fetchedData) {
  return {
    type: publicLinksConstants.FETCH_PUBLIC_LINKS_SUCCESS,
    fetchedData,
  };
}

function fetchPublicLinksFailure(fetchedData) {
  return {
    type: publicLinksConstants.FETCH_PUBLIC_LINKS_FAILURE,
    fetchedData,
  };
}

function patchPublicLink(id, params) {
  return {
    type: publicLinksConstants.PATCH_PUBLIC_LINK_REQUEST,
    id,
    params,
  };
}

function patchPublicLinkSuccess(id, params) {
  return {
    type: publicLinksConstants.PATCH_PUBLIC_LINK_REQUEST,
    id,
    params,
  };
}

function patchPublicLinkFailure(id, params) {
  return {
    type: publicLinksConstants.PATCH_PUBLIC_LINK_REQUEST,
    id,
    params,
  };
}

function getPublicLink(id) {
  return {
    type: publicLinksConstants.GET_PUBLIC_LINK_REQUEST,
    id,
  };
}

function getPublicLinkSuccess(id, fetchedData) {
  return {
    type: publicLinksConstants.GET_PUBLIC_LINK_SUCCESS,
    id,
    fetchedData,
  };
}

function getPublicLinkFailure(id) {
  return {
    type: publicLinksConstants.GET_PUBLIC_LINK_FAILURE,
    id,
  };
}

const publicLinksActions = {
  fetchPublicLinks,
  fetchPublicLinksSuccess,
  fetchPublicLinksFailure,
  patchPublicLink,
  patchPublicLinkSuccess,
  patchPublicLinkFailure,
  getPublicLink,
  getPublicLinkSuccess,
  getPublicLinkFailure,
};

export default publicLinksActions;
