import notificationsConstants from 'constants/notifications.constants';

/* eslint-disable camelcase */
function startFetchNotifications() {
  return {
    type: notificationsConstants.START_FETCH_NOTIFICATIONS,
  };
}

function fetchNotificationsRequest() {
  return {
    type: notificationsConstants.FETCH_NOTIFICATIONS_REQUEST,
  };
}

function fetchNotificationsSuccess(data) {
  return {
    type: notificationsConstants.FETCH_NOTIFICATIONS_SUCCESS,
    data,
  };
}

function fetchNotificationsFailure(error) {
  return {
    type: notificationsConstants.FETCH_NOTIFICATIONS_FAILURE,
    error,
  };
}

function cancelFetchNotifications() {
  return {
    type: notificationsConstants.CANCEL_FETCH_NOTIFICATIONS,
  };
}

function patchNotificationsRequest(ids, is_viewed) {
  return {
    type: notificationsConstants.PATCH_NOTIFICATIONS_REQUEST,
    ids,
    is_viewed,
  };
}

function patchNotificationsSuccess(data) {
  return {
    type: notificationsConstants.PATCH_NOTIFICATIONS_SUCCESS,
    data,
  };
}

function patchNotificationsFailure(error) {
  return {
    type: notificationsConstants.PATCH_NOTIFICATIONS_FAILURE,
    error,
  };
}

function archiveNotificationsRequest(ids) {
  return {
    type: notificationsConstants.ARCHIVE_NOTIFICATIONS_REQUEST,
    ids,
  };
}

function archiveNotificationsSuccess(data) {
  return {
    type: notificationsConstants.ARCHIVE_NOTIFICATIONS_SUCCESS,
    data,
  };
}

function archiveNotificationsFailure(error) {
  return {
    type: notificationsConstants.ARCHIVE_NOTIFICATIONS_FAILURE,
    error,
  };
}

function createNotificationsRequest(data) {
  return {
    type: notificationsConstants.CREATE_NOTIFICATIONS_REQUEST,
    data,
  };
}

function createNotificationsSuccess(data) {
  return {
    type: notificationsConstants.CREATE_NOTIFICATIONS_SUCCESS,
    data,
  };
}

function createNotificationsFailure(error) {
  return {
    type: notificationsConstants.CREATE_NOTIFICATIONS_FAILURE,
    error,
  };
}

const notificationsActions = {
  startFetchNotifications,
  fetchNotificationsRequest,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  cancelFetchNotifications,
  patchNotificationsRequest,
  patchNotificationsSuccess,
  patchNotificationsFailure,
  archiveNotificationsRequest,
  archiveNotificationsSuccess,
  archiveNotificationsFailure,
  createNotificationsRequest,
  createNotificationsSuccess,
  createNotificationsFailure,
};

export default notificationsActions;
