import userConstants from 'constants/user.constants';

function clearUser() {
  return {
    type: userConstants.CLEAR_USER,
  };
}

function retrieveUserRequest(id) {
  return {
    type: userConstants.RETRIEVE_USER_REQUEST,
    id,
  };
}

function retrieveUserSuccess(data) {
  return {
    type: userConstants.RETRIEVE_USER_SUCCESS,
    data,
  };
}

function retrieveUserFailure(error) {
  return {
    type: userConstants.RETRIEVE_USER_FAILURE,
    error,
  };
}

function patchUserRequest(id, data) {
  return {
    type: userConstants.PATCH_USER_REQUEST,
    id,
    data,
  };
}

function patchUserSuccess(id) {
  return {
    type: userConstants.PATCH_USER_SUCCESS,
    id,
  };
}

function patchUserFailure(error) {
  return {
    type: userConstants.PATCH_USER_FAILURE,
    error,
  };
}

function setUserGroup(id, data) {
  return {
    type: userConstants.SET_USER_GROUP,
    id,
    data,
  };
}

function removeUserGroup(id, data) {
  return {
    type: userConstants.REMOVE_USER_GROUP,
    id,
    data,
  };
}

const userActions = {
  clearUser,
  retrieveUserRequest,
  retrieveUserSuccess,
  retrieveUserFailure,
  patchUserRequest,
  patchUserSuccess,
  patchUserFailure,
  setUserGroup,
  removeUserGroup,
};

export default userActions;
