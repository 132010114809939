// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n6e_fL9GKT7ZUk7arGYI {
  margin-top: 30px;
  width: 300px;
}
.mq2Pqb47Nepm1JwFbNFu {
  width: 100%;
}
.AYyTINrqxa7GuLujK7kF {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/components/LoginFreelancer.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;AAEA;EACE,WAAA;AAAF;AAGA;EACE,aAAA;EACA,uBAAA;AADF","sourcesContent":[".freelancerForm {\n  margin-top: 30px;\n  width: 300px;\n}\n\n.login {\n  width: 100%;\n}\n\n.main {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"freelancerForm": `n6e_fL9GKT7ZUk7arGYI`,
	"login": `mq2Pqb47Nepm1JwFbNFu`,
	"main": `AYyTINrqxa7GuLujK7kF`
};
export default ___CSS_LOADER_EXPORT___;
