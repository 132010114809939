import dayjs from 'dayjs';
import timesConstants from 'constants/times.constants';

/* eslint-disable prefer-object-spread */
export default function times(state = {}, action) {
  switch (action.type) {
    case timesConstants.FETCH_NEXT_TIMES_REQUEST: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        fetching: true,
      };
      return newState;
    }

    case timesConstants.FETCH_NEXT_TIMES_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        times: action.times,
        fetching: false,
        fetched: true,
        fetchedAt: dayjs(),
      };
      return newState;
    }

    case timesConstants.FETCH_TIMES_REQUEST: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        fetching: true,
      };
      return newState;
    }

    case timesConstants.FETCH_TIMES_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        times: action.times,
        fetching: false,
        fetched: true,
        fetchedAt: dayjs(),
      };
      return newState;
    }

    case timesConstants.FETCH_TIMES_FAILURE: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        fetching: false,
        error: action.error,
      };
      return newState;
    }

    case timesConstants.DELETE_TIME_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      });

    case timesConstants.DELETE_TIME_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      });

    case timesConstants.DELETE_TIME_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
      });

    case timesConstants.PATCH_TIME_REQUEST:
      return Object.assign({}, state, {
        patching: true,
      });

    case timesConstants.PATCH_TIME_SUCCESS:
      return Object.assign({}, state, {
        patching: false,
      });

    case timesConstants.PATCH_TIME_FAILURE:
      return Object.assign({}, state, {
        patching: false,
      });

    case timesConstants.CREATE_TIME_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      });

    case timesConstants.CREATE_TIME_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
      });

    case timesConstants.CREATE_TIME_FAILURE:
      return Object.assign({}, state, {
        creating: false,
      });

    default:
      return state;
  }
}
