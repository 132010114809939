const userTheme = (backendTheme) => {
  if (backendTheme === 'system') {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return 'dark_mode';
    }
    return 'light_mode';
  }
  return backendTheme;
};

export default userTheme;
