const officeIPsConstants = {
  FETCH_IPS_REQUEST: 'OFFICE_IPS_FETCH_IPS_REQUEST',
  FETCH_IPS_SUCCESS: 'OFFICE_IPS_FETCH_IPS_SUCCESS',
  FETCH_IPS_FAILURE: 'OFFICE_IPS_FETCH_IPS_FAILURE',
  CREATE_IP_REQUEST: 'OFFICE_IPS_CREATE_IP_REQUEST',
  CREATE_IP_SUCCESS: 'OFFICE_IPS_CREATE_IP_SUCCESS',
  CREATE_IP_FAILURE: 'OFFICE_IPS_CREATE_IP_FAILURE',
  DELETE_IP_REQUEST: 'OFFICE_IPS_DELETE_IP_REQUEST',
  DELETE_IP_SUCCESS: 'OFFICE_IPS_DELETE_IP_SUCCESS',
  DELETE_IP_FAILURE: 'OFFICE_IPS_DELETE_IP_FAILURE',
};

export default officeIPsConstants;
