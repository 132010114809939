// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F3IhtlXDboP6MU1vVpMw {
  width: 80%;
  display: inline-block;
}
.iHZfhy5YYxyCbEHih8ag {
  width: 20%;
  display: inline-block;
}
@media screen and (max-width: 600px) {
  .F3IhtlXDboP6MU1vVpMw {
    width: 60%;
    margin-left: 20%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Times/EditTimeModal.less"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,qBAAA;AACF;AAEA;EACE,UAAA;EACA,qBAAA;AAAF;AAGA;EACE;IACE,UAAA;IACA,gBAAA;EADF;AACF","sourcesContent":[".inputTaskID {\n  width: 80%;\n  display: inline-block;\n}\n\n.inputHours {\n  width: 20%;\n  display: inline-block;\n}\n\n@media screen and (max-width: 600px) {\n  .inputTaskID {\n    width: 60%;\n    margin-left: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputTaskID": `F3IhtlXDboP6MU1vVpMw`,
	"inputHours": `iHZfhy5YYxyCbEHih8ag`
};
export default ___CSS_LOADER_EXPORT___;
