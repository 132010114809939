import loginConstants from '../constants/login.constants';

export default function login(state = {}, action) {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        ...state,
        errors: {},
        fetching: true,
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        errors: {},
        fetched: true,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.error.response,
      };
    case loginConstants.REGISTER_REQUEST:
      return {
        ...state,
        errors: {},
        fetching: true,
      };
    case loginConstants.REGISTER_SUCCESS:
      return {
        ...state,
        errors: {},
        fetched: true,
      };
    case loginConstants.REGISTER_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.error.response,
      };
    default:
      return {
        ...state,
      };
  }
}
