const publicLinksConstants = {
  FETCH_PUBLIC_LINKS_REQUEST: 'PUBLIC_LINKS_FETCH_PUBLIC_LINKS_REQUEST',
  FETCH_PUBLIC_LINKS_SUCCESS: 'PUBLIC_LINKS_FETCH_PUBLIC_LINKS_SUCCESS',
  FETCH_PUBLIC_LINKS_FAILURE: 'PUBLIC_LINKS_FETCH_PUBLIC_LINKS_FAILURE',

  PATCH_PUBLIC_LINK_REQUEST: 'PUBLIC_LINK_PATCH_PUBLIC_LINK',
  PATCH_PUBLIC_LINK_SUCCESS: 'PUBLIC_LINK_PATCH_PUBLIC_LINK_SUCCESS',
  PATCH_PUBLIC_LINK_FAILURE: 'PUBLIC_LINK_PATCH_PUBLIC_LINK_FAILURE',

  GET_PUBLIC_LINK_REQUEST: 'PUBLIC_LINKS_GET_PUBLIC_LINK_REQUEST',
  GET_PUBLIC_LINK_SUCCESS: 'PUBLIC_LINKS_GET_PUBLIC_LINK_SUCCESS',
  GET_PUBLIC_LINK_FAILURE: 'PUBLIC_LINKS_GET_PUBLIC_LINK_FAILURE',
};

export default publicLinksConstants;
