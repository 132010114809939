import groupsConstants from 'constants/groups.constants';

export default function groups(state = {}, action) {
  switch (action.type) {
    case groupsConstants.FETCH_GROUPS_REQUEST:
      return {
        params: action.params,
        fetching: true,
      };

    case groupsConstants.FETCH_GROUPS_SUCCESS:
      return {
        groups: action.groups,
        fetched: true,
      };

    case groupsConstants.FETCH_GROUPS_FAILURE:
      return {};

    default:
      return state;
  }
}
