// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zAN6FlOgErS0H3hHIET0 {
  color: #b20000;
  margin-top: -40px;
}
.sUpIAN7o_jC8k7lsAVsw {
  color: #dc4466;
  margin-inline-end: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AddRemoteWork/AddRemoteWorkModal.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;AACF;AAEA;EACE,cAAA;EACA,sBAAA;AAAF","sourcesContent":[".error {\n  color: rgb(178, 0, 0);\n  margin-top: -40px;\n}\n\n.required {\n  color: #dc4466;\n  margin-inline-end: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `zAN6FlOgErS0H3hHIET0`,
	"required": `sUpIAN7o_jC8k7lsAVsw`
};
export default ___CSS_LOADER_EXPORT___;
