import { Button, Input, Form } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import styles from './LoginFreelancer.less';
import loginActions from 'actions/login.actions';

const LoginFreelancer = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const onFinish = (values) => {
    dispatch(loginActions.loginRequest(values));
  };

  useEffect(() => {
    if (login.errors) {
      Object.keys(login.errors).forEach((field) => {
        formRef.current.setFields([
          {
            name: field,
            errors: [login.errors[field]],
          },
        ]);
      });
    }
  }, [login]);

  return (
    <>
      <Link to="/login">
        <Button>Ulam Login</Button>
      </Link>
      <Form
        ref={formRef}
        name="freelancer login"
        className={styles.freelancerForm}
        onFinish={onFinish}
      >
        <Form.Item
          name="login"
          rules={[
            {
              required: true,
              message: 'Please input your Username!',
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Login" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={login.fetching}
            type="primary"
            htmlType="submit"
            className={styles.login}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginFreelancer;
