// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rARqDd4HUk6u4PkWvmIK .ant-badge-dot {
  background-color: #00bb00;
  box-shadow: 0 0 0 1px #fff;
}
.eNC6zYLQahGwKTtjWsX9 .ant-badge-dot {
  background-color: white;
  box-shadow: 0 0 0 1px grey;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserBadge/UserBadge.less"],"names":[],"mappings":"AAAA;EAEI,yBAAA;EACA,0BAAA;AAAJ;AAIA;EAEI,uBAAA;EACA,0BAAA;AAHJ","sourcesContent":[".userActive {\n  :global(.ant-badge-dot) {\n    background-color: rgb(0, 187, 0);\n    box-shadow: 0 0 0 1px #fff;\n  }\n}\n\n.userInactive {\n  :global(.ant-badge-dot) {\n    background-color: white;\n    box-shadow: 0 0 0 1px grey;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userActive": `rARqDd4HUk6u4PkWvmIK`,
	"userInactive": `eNC6zYLQahGwKTtjWsX9`
};
export default ___CSS_LOADER_EXPORT___;
