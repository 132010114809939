import notificationsActions from 'actions/notifications.actions';
import { Modal, message } from 'antd';
import notificationsConstants from 'constants/notifications.constants';
import { get, patch, post } from 'helpers/ajax';
import {
  call,
  cancel,
  delay,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

function* fetchNotifications() {
  while (true) {
    try {
      yield put(notificationsActions.fetchNotificationsRequest());

      const ajax = yield get('/api/v1/notification', null, true);
      const response = yield call([ajax, ajax.toPromise]);

      yield put(
        notificationsActions.fetchNotificationsSuccess(
          response.response.results
        )
      );
    } catch (error) {
      yield put(notificationsActions.fetchNotificationsFailure(error));
    }
    yield delay(600000); // every 10 minutes
  }
}

function* watchFetchNotifications() {
  const task = yield fork(fetchNotifications);

  yield take(notificationsConstants.CANCEL_FETCH_NOTIFICATIONS);

  yield cancel(task);
}

function* patchNotifications(action) {
  try {
    const { ids, is_viewed } = action;
    // eslint-disable-next-line
    const data = { notifications: ids, is_viewed };

    const ajax = yield patch(
      '/api/v1/notification/read_notifications',
      data,
      { 'Content-Type': 'application/json' },
      true
    );
    const response = yield call([ajax, ajax.toPromise]);

    yield put(
      notificationsActions.patchNotificationsSuccess(response.response)
    );
  } catch (error) {
    yield put(notificationsActions.patchNotificationsFailure(error));
  }
}

function* archiveNotifications(action) {
  try {
    const { ids } = action;
    const data = { notifications: ids };

    yield new Promise((resolve, reject) => {
      Modal.confirm({
        title: 'Are you sure?',
        content: `Please be aware that once a notification is archived, it cannot be undone. Proceed with caution.`,
        okText: 'Archive',
        onOk() {
          resolve();
        },
        onCancel() {
          reject(new Error('Archiving canceled'));
        },
      });
    });

    const ajax = yield patch(
      '/api/v1/notification/archive_notifications',
      data,
      { 'Content-Type': 'application/json' },
      true
    );
    const response = yield call([ajax, ajax.toPromise]);

    yield put(
      notificationsActions.archiveNotificationsSuccess(response.response)
    );
  } catch (error) {
    yield put(notificationsActions.archiveNotificationsFailure(error));
  }
}

function* createNotifications(action) {
  try {
    const ajax = yield post(
      '/api/v1/notification/send_notifications',
      action.data,
      { 'Content-Type': 'application/json' },
      true
    );
    const response = yield call([ajax, ajax.toPromise]);

    yield put(
      notificationsActions.createNotificationsSuccess(response.response)
    );
    message.open({
      type: 'success',
      content: 'Message successfully sent',
    });
  } catch (error) {
    yield put(notificationsActions.createNotificationsFailure(error));
    message.open({
      type: 'error',
      content: 'Something went wrong!',
    });
  }
}

export default function* notificationsSaga() {
  yield takeLatest(
    notificationsConstants.START_FETCH_NOTIFICATIONS,
    watchFetchNotifications
  );
  yield takeLatest(
    notificationsConstants.PATCH_NOTIFICATIONS_REQUEST,
    patchNotifications
  );
  yield takeLatest(
    notificationsConstants.ARCHIVE_NOTIFICATIONS_REQUEST,
    archiveNotifications
  );
  yield takeLatest(
    notificationsConstants.CREATE_NOTIFICATIONS_REQUEST,
    createNotifications
  );
}
