import { createSelector } from 'reselect';

// selector
const selectPermissions = (state) => state.permissions;

// util function
export const getIsAdmin = (permissions) =>
  permissions.permissionLevel === 'admin';

export const selectIsAdmin = createSelector(selectPermissions, (permissions) =>
  getIsAdmin(permissions)
);
