import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  TimePicker,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { localToTimeStr, timeStrToLocal } from 'helpers/timeConvert';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import userActions from '../../actions/user.actions';
import './UserProfile.css';
import styles from './UserProfile.less';

const phoneRegex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

class UserProfileForm extends Component {
  formRef = React.createRef();
  // eslint-disable-next-line
  static propTypes = {
    // eslint-disable-next-line
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.sendData = this.sendData.bind(this);
  }

  handlePhoneNumberChange = () => {
    this.formRef.current.setFieldsValue({
      /* eslint-disable camelcase */
      phone_number: this.formRef.current.getFieldValue('phone_number'),
    });
  };

  handleChangeTheme(value) {
    const { modifyUser } = this.props;
    modifyUser('me', { theme: value });
  }

  async sendData() {
    try {
      const data = await this.formRef.current.validateFields();

      data.work_end_time = localToTimeStr(
        data.work_time_range[1].format('HH:mm')
      );
      data.work_start_time = localToTimeStr(
        data.work_time_range[0].format('HH:mm')
      );

      if (data.leader && typeof data.leader === 'string') {
        const defaultLeader = this.props.leaders.find(
          (leader) => leader.name === data.leader
        );
        data.leader = defaultLeader ? defaultLeader.id : '';
      }
      data.leader = data.leader || '';
      data.base_job_tenure = data.base_job_tenure || '';
      const { phone_number } = data;

      if (phone_number && !phoneRegex.test(phone_number)) {
        this.formRef.current.setFields([
          {
            name: 'phone_number',
            value: phone_number,
            errors: [new Error('Invalid phone number format.')],
          },
        ]);
        return;
      }

      delete data.work_time_range;
      this.props.onSubmit(this.props.user.id, data);
      // eslint-disable-next-line
    } catch (errorInfo) {}
  }

  render() {
    const {
      user,
      isAdmin,
      visible,
      onCancel,
      leaders,
      isMe,
      me,
      handleChangeLoader,
    } = this.props;

    const loaderTheme = JSON.parse(localStorage.getItem('loader'));

    const leaderOptions = leaders
      ?.sort((a, b) => {
        const labelA = a.name.toLowerCase();
        const labelB = b.name.toLowerCase();

        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      })
      .map((leader) => (
        <Select.Option key={leader.id} value={leader.name}>
          {leader.name}
        </Select.Option>
      ));

    const contractTypes = Object.entries(user.contract_types).map(
      ([key, value]) => (
        <Select.Option key={key} value={key}>
          {value}
        </Select.Option>
      )
    );

    const dailyHoursOptions = [
      { value: '8', label: '8 (regular)' },
      { value: '7', label: '7' },
      { value: '6', label: '6' },
      { value: '5', label: '5' },
      { value: '4', label: '4 (half-time)' },
      { value: '3', label: '3' },
      { value: '2', label: '2' },
      { value: '1', label: '1' },
      { value: '0', label: 'No timesheet entries' },
    ];

    return (
      <Modal
        open={visible}
        onCancel={onCancel}
        title="Settings"
        onOk={this.sendData}
        destroyOnClose
      >
        <Form ref={this.formRef} layout="vertical" name="userProfileform">
          <h2 className={styles.sectionHeader}>Profile</h2>
          <Form.Item
            label="Name:"
            name="name"
            initialValue={user.name}
            rules={[{ required: true, message: 'Please enter your name.' }]}
          >
            <Input className="user-form-input" placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="What I do"
            name="position"
            initialValue={user.position}
            rules={[{ required: true, message: 'Please enter your position.' }]}
          >
            <Input className="user-form-input" placeholder="Position" />
          </Form.Item>
          <Form.Item
            label="Phone Number:"
            name="phone_number"
            initialValue={user.phone_number}
          >
            <Input
              onChange={this.handlePhoneNumberChange}
              className="user-form-input"
              placeholder="Phone number"
            />
          </Form.Item>
          <div className={styles.workingHours}>
            <Form.Item
              label={
                // eslint-disable-next-line
                <label className={styles.hourLabel}>
                  Working Hours{' '}
                  <Tooltip title="Specify your everyday work hours to help others see when they can usually reach you">
                    <InfoCircleOutlined />
                  </Tooltip>
                </label>
              }
              name="work_time_range"
              rules={[
                {
                  required: true,
                  message: 'Please select start and end time.',
                },
              ]}
              initialValue={
                user.work_start_time && user.work_end_time
                  ? [
                      dayjs(
                        timeStrToLocal(user.work_start_time, user.timezone),
                        'HH:mm'
                      ),
                      dayjs(
                        timeStrToLocal(user.work_end_time, user.timezone),
                        'HH:mm'
                      ),
                    ]
                  : undefined
              }
            >
              <TimePicker.RangePicker
                order={false}
                format="HH:mm"
                placeholder={['Start', 'End']}
                allowClear={false}
                onOpenChange={(boolean) => {
                  if (boolean && !user.work_start_time) {
                    this.formRef.current.setFieldValue('work_time_range', [
                      dayjs('00:00', 'HH:mm'),
                      dayjs('00:00', 'HH:mm'),
                    ]);
                  }
                }}
              />
            </Form.Item>
          </div>
          {isAdmin && (
            <>
              <Form.Item
                className={styles.adminForm}
                label="Select Leader"
                name="leader"
                initialValue={user.leader}
              >
                <Select placeholder="Please select a leader">
                  {leaderOptions}
                </Select>
              </Form.Item>
              <Form.Item
                label="Contract Type"
                name="contract_type"
                initialValue={user.contract_type}
              >
                <Select placeholder="Please select a contract type">
                  {contractTypes}
                </Select>
              </Form.Item>
              <Form.Item
                label="Expected daily hours"
                name="daily_hours"
                initialValue={user.daily_hours}
              >
                <Select placeholder="Pick hours">
                  {dailyHoursOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Job tenure before Ulam Labs"
                name="base_job_tenure"
                initialValue={user.base_job_tenure}
              >
                <InputNumber
                  placeholder="Pick years"
                  min="0"
                  max="100"
                  formatter={(value) => Math.round(value)}
                />
              </Form.Item>
            </>
          )}
          {isMe && (
            <>
              <h2 className={styles.sectionHeader}>Appearance</h2>
              <Form.Item
                initialValue={me.data.theme}
                label="Theme"
                name="theme"
              >
                <Select onChange={(value) => this.handleChangeTheme(value)}>
                  <Select.Option value="system">System</Select.Option>
                  <Select.Option value="dark_mode">Dark mode</Select.Option>
                  <Select.Option value="light_mode">Light mode</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Loader"
                name="loader"
                initialValue={loaderTheme || 'default'}
              >
                <Select
                  onChange={handleChangeLoader}
                  options={[
                    { value: 'cat', label: 'Cat loader' },
                    { value: 'default', label: 'Default loader' },
                  ]}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const me = state.me;
  return {
    me,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modifyUser: (id, data) => dispatch(userActions.patchUserRequest(id, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileForm);
