const userConstants = {
  RETRIEVE_USER_REQUEST: 'USER_RETRIEVE_USER_REQUEST',
  RETRIEVE_USER_SUCCESS: 'USER_RETRIEVE_USER_SUCCESS',
  RETRIEVE_USER_FAILURE: 'USER_RETRIEVE_USER_FAILURE',
  PATCH_USER_REQUEST: 'USER_PATCH_USER_REQUEST',
  PATCH_USER_SUCCESS: 'USER_PATCH_USER_SUCCESS',
  PATCH_USER_FAILURE: 'USER_PATCH_USER_FAILURE',
  CLEAR_USER: 'USER_CLEAR_USER',
  SET_USER_GROUP: 'SET_USER_GROUP',
  REMOVE_USER_GROUP: 'REMOVE_USER_GROUP',
};

export default userConstants;
