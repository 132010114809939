// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s1YaHzCSM7mceA3AtBFJ {
  color: #990000;
}
.rpbSdbvsui2s9X7Y5CNf {
  color: green;
}
.fkA2aqFTaRwxhVaEWaDO {
  color: #b20000;
  margin-top: -40px;
}
.HTJxrMteK6o5Ll37D3ta {
  color: #6d6d6d;
  font-size: 12px;
  font-weight: 400;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 0;
  line-height: 20px;
  border-radius: 5px 7px;
  -webkit-box-shadow: inset -3px -3px 19px -17px #42445a;
  -moz-box-shadow: inset -3px -3px 19px -17px #42445a;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-top: -20px;
  margin-bottom: 5px;
}
.RmZuPjZtymM9OTCDfOWD {
  transform: translateY(-15px);
}
.darkMode .HTJxrMteK6o5Ll37D3ta {
  background-color: #2e2e2e;
  color: rgba(255, 255, 255, 0.65);
}
@media (max-width: 768px) {
  .ant-picker-panels {
    display: flex !important;
    flex-direction: column !important;
    max-height: 250px !important;
    overflow: auto !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AddDaysOff/AddDaysOffModal.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEA;EACE,YAAA;AAAF;AAGA;EACE,cAAA;EACA,iBAAA;AADF;AAIA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,SAAA;EACA,iBAAA;EACA,sBAAA;EACA,sDAAA;EACA,mDAAA;EACA,+CAAA;EACA,iBAAA;EACA,kBAAA;AAFF;AAKA;EACE,4BAAA;AAHF;AAMA;EAEI,yBAAA;EACA,gCAAA;AALJ;AASA;EACE;IACE,wBAAA;IACA,iCAAA;IACA,4BAAA;IACA,yBAAA;EAPF;AACF","sourcesContent":[".red {\n  color: rgb(153, 0, 0);\n}\n\n.green {\n  color: green;\n}\n\n.error {\n  color: rgb(178, 0, 0);\n  margin-top: -40px;\n}\n\n.description {\n  color: #6d6d6d;\n  font-size: 12px;\n  font-weight: 400;\n  background-color: #f5f5f5;\n  padding: 10px;\n  margin: 0;\n  line-height: 20px;\n  border-radius: 5px 7px;\n  -webkit-box-shadow: inset -3px -3px 19px -17px rgba(66, 68, 90, 1);\n  -moz-box-shadow: inset -3px -3px 19px -17px rgba(66, 68, 90, 1);\n  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);\n  margin-top: -20px;\n  margin-bottom: 5px;\n}\n\n.modeCheckbox {\n  transform: translateY(-15px);\n}\n\n:global(.darkMode) {\n  .description {\n    background-color: #2e2e2e;\n    color: rgba(255, 255, 255, 0.65);\n  }\n}\n\n@media (max-width: 768px) {\n  :global(.ant-picker-panels) {\n    display: flex !important;\n    flex-direction: column !important;\n    max-height: 250px !important;\n    overflow: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": `s1YaHzCSM7mceA3AtBFJ`,
	"green": `rpbSdbvsui2s9X7Y5CNf`,
	"error": `fkA2aqFTaRwxhVaEWaDO`,
	"description": `HTJxrMteK6o5Ll37D3ta`,
	"modeCheckbox": `RmZuPjZtymM9OTCDfOWD`
};
export default ___CSS_LOADER_EXPORT___;
