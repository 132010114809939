import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Modal, Popover, Row, Table } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import timesActions from 'actions/times.actions';
import Loader from 'components/Loader/Loader';
import DatePicker from 'components/QuickDatePicker';
import { formatDate, formatDateTime, formatTime } from 'helpers/dateTime';
import AddTimeForm from './AddTimeForm';
import EditTimeModal from './EditTimeModal';

import usersActions from '../../actions/users.actions';
import UserGravatar from '../../components/UserGravatar/UserGravatar';
import styles from './Times.less';

const ScrumTasksDictionary = [
  { value: 'Standup' },
  { value: 'Planning' },
  { value: 'Preplanning' },
  { value: 'Grooming' },
  { value: 'Review' },
  { value: 'Retrospective' },
];

class Times extends Component {
  // eslint-disable-next-line
  displayName = 'Times';

  formRef = React.createRef();

  editFormRef = React.createRef();

  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const now = formatDate(dayjs());
    const date = dayjs(params.get('date') || now);
    let userId = props.match.params.id;
    if (userId === 'me') {
      userId = props.me.data.id;
    }

    this.state = {
      userId: Number(userId),
      date,
      times: [],
      lastProjects: [],
      // eslint-disable-next-line
      presence: [],
      editVisible: false,
      editedTimeEntry: {},
    };

    this.handleDate = this.handleDate.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.sendCreateRequest = this.sendCreateRequest.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateTimes = this.updateTimes.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { userId, date } = this.state;
    this.updateTimes(userId, date);
    dispatch(usersActions.fetchUsersRequest({}, 'users'));
  }

  componentDidUpdate(prevProps) {
    const { times, me } = this.props;
    const urlId = this.props.match.params.id;
    if (urlId === 'me') {
      this.changeUser(me.data, true);
      return;
    }
    const anotherUser =
      prevProps.location.pathname !== this.props.location.pathname;
    const anotherDate =
      prevProps.location.search !== this.props.location.search;
    if (anotherUser || anotherDate) {
      const params = new URLSearchParams(this.props.location.search);
      const newUserId = Number(this.props.match.params.id);
      const newDate = dayjs(params.get('date') || dayjs());
      this.updateTimes(newUserId, newDate);
    }
    if (!times) {
      return;
    }
    const newTimes = times.timesPage;
    const oldTimes = prevProps.times.timesPage;
    if (
      oldTimes &&
      !newTimes.fetching &&
      oldTimes.fetching &&
      newTimes.fetched
    ) {
      // eslint-disable-next-line
      this.setState({
        times: newTimes.times.results,
      });
      if (Number(urlId) === me.data.id) {
        this.fetchLastTimes(urlId);
      }
    }
    const newLastTimes = times.lastTimes;
    const oldLastTimes = prevProps.times.lastTimes;
    if (oldLastTimes && !newLastTimes.fetching && oldLastTimes.fetching) {
      this.getLastProjects(newLastTimes.times.results);
    }
  }

  getActionUI({ record, user, forMobile = false }) {
    const popoverContent = (
      <div>
        <p>Created at: {formatDateTime(record.created_at)}</p>
        <p>Modified at: {formatDateTime(record.modified_at)}</p>
      </div>
    );
    const actionUI = (
      <div
        className={
          forMobile ? '' : 'action-buttons table-operations action-overlay'
        }
      >
        {!record.is_past &&
        !record.deleted &&
        user.id === this.props.me.data.id ? (
          <Button type="default" onClick={() => this.showEditModal(record)}>
            Edit
          </Button>
        ) : (
          ''
        )}
        <span style={{ marginRight: '4px' }} />
        {!record.deleted && user.id === this.props.me.data.id ? (
          <Button danger onClick={() => this.showDeleteModal(record)}>
            Delete
          </Button>
        ) : (
          ''
        )}
        <span style={{ marginRight: '4px' }} />
        {!record.deleted && user.id === this.props.me.data.id ? (
          <Button type="default" onClick={() => this.copyEntryToForm(record)}>
            <CopyOutlined />
          </Button>
        ) : (
          ''
        )}
        <Popover content={popoverContent} placement="topRight">
          <QuestionCircleOutlined className="question-popover" />
        </Popover>
      </div>
    );
    return actionUI;
  }

  /* eslint-disable class-methods-use-this */
  getRowClassName(record) {
    if (record.deleted) {
      return 'record--deleted action-overlay-area';
    }
    return 'action-overlay-area';
  }

  getTotalHours(times) {
    // eslint-disable-next-line
    times = times.filter((time) => !time.deleted);
    const total = times.reduce((accu, item) => accu + item.time, 0);
    return `Total Hours: ${formatTime(total)}`;
  }

  getLastProjects(lastTimes) {
    const lastProjects = lastTimes.map((item) => item.project);
    const results = [];
    // eslint-disable-next-line
    for (const project of lastProjects) {
      if (!results.map((proj) => proj.name).includes(project.name)) {
        if (project.active) {
          results.push(project);
        }
      }
    }
    this.setState({
      lastProjects: results.slice(0, 3),
    });
  }

  fetchLastTimes(userId) {
    const params = {
      withProject: true,
      limit: 10,
      ordering: '-created_at',
      deleted: 'False',
    };
    const { dispatch } = this.props;
    dispatch(
      timesActions.fetchTimesRequest(
        {
          user: userId,
          ...params,
        },
        'lastTimes',
        false
      )
    );
  }

  changeUser(user, replace = false) {
    if (replace) {
      return this.props.history.replace({
        pathname: `/users/${user.id}/times`,
      });
    }
    return this.props.history.push({
      pathname: `/users/${user.id}/times`,
    });
  }

  updateTimes(newUserId, newDate) {
    const params = { withProject: true, ordering: '-created_at' };
    const { dispatch } = this.props;
    this.setState({
      userId: newUserId,
      date: newDate,
    });
    dispatch(
      timesActions.fetchTimesRequest(
        {
          date: formatDate(newDate),
          user: newUserId,
          ...params,
        },
        'timesPage',
        false
      )
    );
  }
  // eslint-disable-next-line
  handleDelete(time) {
    const { dispatch } = this.props;
    const { userId, date } = this.state;
    const params = {
      date: formatDate(date),
      user: userId,
      withProject: true,
      ordering: '-created_at',
    };
    dispatch(timesActions.deleteTimeRequest(time.id, params));
  }

  handleEditCancel() {
    this.setState({ editVisible: false });
  }

  async handleEdit() {
    const { dispatch } = this.props;
    const { userId, date } = this.state;

    try {
      const values = await this.editFormRef.current.validateFields();
      const params = {
        date: formatDate(date),
        user: userId,
        withProject: true,
        ordering: '-created_at',
      };
      await dispatch(
        timesActions.patchTimeRequest(
          this.state.editedTimeEntry.id,
          {
            time: Number(values.hours),
            user: Number(userId),
            date: this.state.editedTimeEntry.date,
            task: values.task || '',
            description: values.description,
            project: Number(values.project),
          },
          params
        )
      );
      await this.editFormRef.current.resetFields();
      this.setState({ editVisible: false });
      /* eslint-disable no-empty */
    } catch (err) {}
  }

  async handleCreate() {
    const { date } = this.state;
    try {
      const values = await this.formRef.current.validateFields();
      const now = dayjs();

      if (now.format('YYYYMMDD') !== date.format('YYYYMMDD')) {
        Modal.confirm({
          title:
            'This is not the current day, are you sure you want to add it?',
          onOk: () => {
            Modal.warning({
              title:
                'Please make sure you add time entries before leaving the office.',
              content: (
                <div>
                  <p>
                    Your description and ticket ID matter - our clients have
                    access to them and use them to plan their spending.
                  </p>
                  <p>
                    Adding time on the same day, when you still remember what
                    you have done, will result in more detailed entries and
                    eventually help our clients.
                  </p>
                </div>
              ),
              onOk: () => {
                this.sendCreateRequest(values);
              },
            });
          },
        });
      } else {
        this.sendCreateRequest(values);
      }
    } catch (err) {}
  }

  sendCreateRequest(formValues) {
    const { userId, date } = this.state;
    const { dispatch } = this.props;
    const params = {
      date: formatDate(date),
      user: userId,
      withProject: true,
      ordering: '-created_at',
    };

    dispatch(
      timesActions.createTimeRequest(
        {
          time: Number(formValues.hours),
          user: Number(userId),
          date: formatDate(date),
          task: formValues.task || '',
          description: formValues.description,
          project: Number(formValues.project),
        },
        params
      )
    );
    this.formRef.current.submit();
    this.formRef.current.resetFields();
  }

  copyEntryToForm(record) {
    this.formRef.current.setFieldsValue({
      description: record.description,
      task: record.task,
      hours: record.time,
      project: record.project.id,
    });
  }

  showDeleteModal(time) {
    const confirm = Modal.confirm;
    const self = this;
    confirm({
      title: 'Delete Time',
      content: 'Are you sure?',
      okText: 'Delete',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        self.handleDelete(time);
      },
    });
  }

  showEditModal(timeEntry) {
    this.setState({
      editedTimeEntry: timeEntry,
      editVisible: true,
    });
    // eslint-disable-next-line
    this.editFormRef.current?.setFieldsValue({
      description: timeEntry.description,
      task: timeEntry.task,
      hours: timeEntry.time,
      project: timeEntry.project.id,
    });
  }

  handleDate(date) {
    this.props.history.push({
      search: `?date=${formatDate(date)}`,
    });
  }

  render() {
    const { times, userId, date } = this.state;
    const { users } = this.props;

    if (!users.length) {
      return <Loader />;
    }
    const user = users.find((usr) => usr.id === userId);
    if (!user) {
      return (
        <Alert
          style={{ marginBottom: 24 }}
          message="Undefined user"
          type="error"
          showIcon
        />
      );
    }

    const columns = [
      {
        title: 'Project',
        dataIndex: ['project', 'name'],
        key: 'project.id',
        render: (id) => <span className="action-overlayed">{id}</span>,
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (time, record) => {
          const createdAt = dayjs(record.created_at);
          const timeDate = dayjs(record.date);
          let className = 'action-overlayed';
          if (!createdAt.isSame(timeDate, 'day')) {
            className += ` ${styles.timeInscribedWrongDay}`;
          }
          return <span className={className}>{formatTime(time)}</span>;
        },
      },
      {
        title: 'Task Id',
        dataIndex: 'task',
        key: 'task',
        render: (text) => (
          <div className="wrapable action-overlayed">{text}</div>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        className: 'action-overlay-area',
        width: 450,
        render: (text, record) => (
          <div>
            <div className="wrapable action-overlayed">{text}</div>
            {this.getActionUI({ record, user })}
          </div>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'description',
        key: 'actions',
        className: styles.actionUIMobile,
        render: (_, record) => (
          <>{this.getActionUI({ record, user, forMobile: true })}</>
        ),
      },
    ];

    let tableWidth = 24;
    let createForm = null;
    if (user.id === this.props.me.data.id) {
      createForm = (
        <Col
          className={clsx(styles.colPadding, styles.addTimeFormWrapper)}
          span={24}
          lg={{ span: 7, offset: 1 }}
        >
          <Card title="Add Time">
            <AddTimeForm
              onCreate={this.handleCreate}
              lastProjects={this.state.lastProjects}
              autoCompleteSuggestions={ScrumTasksDictionary}
              form={this.formRef}
            />
          </Card>
        </Col>
      );
      tableWidth = 16;
    }
    const editModal = (
      <EditTimeModal
        form={this.editFormRef}
        visible={this.state.editVisible}
        onCancel={this.handleEditCancel}
        onEdit={this.handleEdit}
        editedTimeEntry={this.state.editedTimeEntry}
        autoCompleteSuggestions={ScrumTasksDictionary}
      />
    );

    return (
      <div className="times-view">
        <Helmet title="Times" />
        {editModal}
        <div className="table-operations">
          <div className="page-filters">
            <DatePicker
              onChange={this.handleDate}
              allowClear={false}
              value={date}
              format="YYYY-MM-DD ddd"
            />
            <div className={styles.timesHeader}>
              <h3>
                <UserGravatar
                  className={styles.userAvatar}
                  email={user.email}
                  size="small"
                />
                <span className={styles.timesUserName}>{user.name}</span>
              </h3>
            </div>
          </div>
        </div>
        <Row>
          <Col lg={tableWidth}>
            <Table
              className={styles.timesTable}
              columns={columns}
              pagination={false}
              dataSource={times}
              rowKey="id"
              rowClassName={this.getRowClassName}
              footer={() => this.getTotalHours(times)}
            />
          </Col>
          {createForm}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const times = state.times;
  const me = state.me;
  let users = state.users;
  if (users.users && users.users.fetched) {
    users = users.users.users.results;
  } else {
    users = [];
  }
  return {
    times,
    me,
    users,
  };
}

export default connect(mapStateToProps)(Times);
