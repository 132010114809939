export default function isAuthorized(permission, permissions) {
  if (!permission) {
    return true;
  }
  return !!permissions.find((item) => item.codename === permission);
}

export function isLoggedIn() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user !== null && user?.token !== undefined) {
    return true;
  }
  return false;
}
