import { Observable } from 'rxjs';
import permissionsConstants from '../constants/permissions.constants';
import permissionsActions from '../actions/permissions.actions';
import { get } from 'helpers/ajax';

const permissionsEpic = (action$) =>
  Observable.merge(
    action$
      .ofType(permissionsConstants.FETCH_PERMISSIONS_REQUEST)
      .mergeMap(() =>
        get(`/api/v1/permission_level/`, {}, true)
          .map((response) =>
            permissionsActions.fetchPermissionsSuccess(response.response)
          )
          .catch((error) =>
            Observable.of(permissionsActions.fetchPermissionsFailure(error))
          )
      )
  );

export default permissionsEpic;
