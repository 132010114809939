import React from 'react';
import { Select } from 'antd';
import Fuse from 'fuse.js';
import './index.css';

export default class ProjectPicker extends React.Component {
  handleSelect = (value) => {
    this.props.onChange(value);
  };

  render() {
    const { projects, clients = [], ...props } = this.props;

    const fuseOptions = {
      keys: ['name', 'client.name'],
      threshold: 0.4,
    };
    const fuse = new Fuse([...projects, ...clients], fuseOptions);
    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <Select
        className="project-picker"
        {...props}
        showSearch
        optionLabelProp="children"
        filterOption={(input, option) => {
          const item = option.children[0].props.children;
          const result = fuse.search(input.toLowerCase());

          return result.some((r) => r.item.name === item);
        }}
        onSelect={this.handleSelect}
        value={this.props.value}
      >
        {projects
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((object) => {
            return (
              <Select.Option
                value={props.mode === 'tags' ? String(object.id) : object.id}
                key={object.id}
                style={{
                  borderColor: object.color,
                  borderStyle: 'none none none solid',
                  borderWidth: 'thick',
                }}
              >
                <span>{object.name}</span>
                {object.client && clients.length ? (
                  <span className="client-name">
                    {
                      clients.find((client) => client.id === object.client)
                        ?.name
                    }
                  </span>
                ) : null}
              </Select.Option>
            );
          })}
      </Select>
    );
  }
}
