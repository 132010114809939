import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fetchQuoteRequest from 'actions/quote.actions';
import styles from './SiderMenu.less';

const SiderMenuQuote = () => {
  const quoteObj = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchQuoteRequest());
  }, []);

  const getText = () => {
    if (quoteObj.errorWhileFetching) {
      return 'Cannot fetch quote of the day. Pity!';
    }
    return `"${quoteObj.quote}"`;
  };

  const containerClasses = [
    styles.quoteContainer,
    quoteObj.loading ? '' : styles.loaded,
  ].join(' ');

  return (
    <div className={containerClasses}>
      <p className={styles.quoteText}>{getText()}</p>
      <p className={styles.quoteAuthor}>~ {quoteObj.author}</p>
    </div>
  );
};

export default SiderMenuQuote;
