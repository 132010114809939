import { Button, Modal, Row, Table, Tag, Tooltip } from 'antd';
import Loader from 'components/Loader/Loader';
import UserBadge from 'components/UserBadge/UserBadge';
import UserTooltip from 'components/UserTooltip/UserTooltip';
import dayjs from 'dayjs';
import buildKeyAndChildren from 'helpers/buildKeyAndChildren';
import humanizeString from 'helpers/humanizeString';
import multiselectConversion from 'helpers/multiselectConversion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './DaysOffReport.less';

const DaysOffReport = ({ daysOff, user, onApprove, onDelete, isAdmin }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const daysOffWithKeyAndChildren = buildKeyAndChildren(daysOff);

  const showDeleteModal = (dayOff) => {
    const {
      structuredDays,
      allowedDays,
      showSelectedDaysInfo,
      selectedPast,
      isNotMe,
      noAllowedDays,
    } = multiselectConversion(dayOff, daysOffWithKeyAndChildren, isAdmin, user);

    const { confirm } = Modal;

    if (noAllowedDays && !isAdmin) {
      confirm({
        title: 'You are not allowed to delete selected days!',
      });
      return;
    }

    confirm({
      title: 'Delete Days Off',
      content: (
        <>
          <p>Are you sure?</p>
          {showSelectedDaysInfo && (
            <>
              {/* eslint-disable camelcase */}
              {/* eslint-disable consistent-return */}
              {/* eslint-disable react/no-unescaped-entities */}
              <p>You have selected these users' days off:</p>
              {structuredDays.map((dayOffData) => (
                <p key={dayOffData?.id}>
                  {dayOffData.name}: {dayOffData.days.length}
                </p>
              ))}
              {!isAdmin && isNotMe && (
                <p className={styles.info}>
                  Only yours day off will be deleted!
                </p>
              )}
              {selectedPast && !isAdmin && (
                <p className={styles.danger}>
                  Selected past days off will not be removed!
                </p>
              )}
            </>
          )}
        </>
      ),
      okText: 'Delete',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        onDelete(
          allowedDays.length === 1
            ? allowedDays[0]
            : { id: allowedDays.map((allowedDay) => allowedDay?.id) }
        );
      },
    });
  };

  const showApproveModal = (dayOff) => {
    const confirm = Modal.confirm;
    const user_days_off = dayOff?.day_off;
    const currentAllowance = user_days_off?.amount - user_days_off?.taken;
    const afterThisLeave = currentAllowance - dayOff.days_no;
    const hourly = dayOff?.hourly;
    /* eslint-disable valid-typeof */
    const content = (
      <div>
        <p>Type: {humanizeString(dayOff.type)}</p>
        {typeof +user_days_off?.amount === 'number' && (
          <>
            <p>
              Current Allowance:{' '}
              <span style={{ color: currentAllowance > 0 ? 'green' : 'red' }}>
                {currentAllowance} {hourly ? 'hours' : 'days'}
              </span>
            </p>
            <p>
              After this leave:{' '}
              <span style={{ color: afterThisLeave >= 0 ? 'green' : 'red' }}>
                {afterThisLeave} {hourly ? 'hours' : 'days'}
              </span>
            </p>
          </>
        )}
        Are you sure?
      </div>
    );

    const showInfoTypes = ['Paid', 'on_demand', 'parental'];

    confirm({
      title: 'Approve Days Off',
      content: showInfoTypes.some((type) => type === dayOff.type)
        ? content
        : 'Are you sure?',
      okText: 'Approve',
      okType: 'primary',
      maskClosable: true,
      onOk: () => onApprove(dayOff),
    });
  };

  const rowSelection = {
    onChange: (rowKeys) => {
      setSelectedRowKeys(rowKeys);
    },
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'user',
      render: (userInfo) => {
        if (!userInfo?.id) {
          return;
        }
        return (
          <UserTooltip user={userInfo}>
            <Link to={userInfo?.id ? `/users/${userInfo?.id}` : '/monthly'}>
              <span className={styles.userName}>
                <UserBadge user={userInfo} /> {userInfo?.name}
              </span>
            </Link>
          </UserTooltip>
        );
      },
    },
    { title: 'From', dataIndex: 'date_start' },
    { title: 'To', dataIndex: 'date_end' },
    {
      title: 'Days off',
      dataIndex: 'days_no',
      render: (text, record) =>
        text && (
          <span>
            {/* eslint-disable eqeqeq */}
            {`${text} ${record?.hourly ? 'hour' : 'day'}${
              text == 1 ? '' : 's'
            }`}
          </span>
        ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text, record) =>
        record?.type && <span>{humanizeString(record?.type)}</span>,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      className: 'remarks-table-column',
      render: (text, record) => {
        const remarkText =
          isAdmin || record.user?.id === user?.id ? text : 'Leave';
        return (
          <Tooltip title={remarkText} placement="right">
            <span>{remarkText}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      dataIndex: 'approved_at',
      render: (text, record) => {
        if (record.children) {
          return;
        }
        let isApproved = 'Not Approved';
        let color = 'blue';
        if (record.approved_at) {
          isApproved = 'Approved';
          color = 'green';
        }
        let lateTag = null;
        const showIsLate =
          (isAdmin || record.user?.id === user?.id) && record?.is_late;
        if (showIsLate) {
          lateTag = <Tag color="gold">Late</Tag>;
        }

        return (
          <span>
            <Tag color={color}>{isApproved}</Tag>
            {lateTag}
          </span>
        );
      },
    },
    {
      title: 'Action',
      className: 'action-column--visible text-center--force',
      render: (text, record) => {
        if (record.children) {
          return;
        }

        let DeleteButton = null;
        if (
          (!record.approved_by &&
            record.user?.id === user?.id &&
            dayjs(record.date_start) >= dayjs()) ||
          isAdmin
        ) {
          DeleteButton = (
            <Button danger onClick={() => showDeleteModal(record)}>
              Delete
            </Button>
          );
        }
        let ApproveButton = null;
        if (isAdmin && !record.approved_by && !record.approved_at) {
          ApproveButton = (
            <Button type="default" onClick={() => showApproveModal(record)}>
              Approve
            </Button>
          );
        }
        const render = (
          <div className="action-buttons float-right">
            {DeleteButton}
            <span style={{ marginRight: '4px' }} />
            {ApproveButton}
          </div>
        );
        return render;
      },
    },
  ];

  return (
    <Loader loading={!daysOff}>
      <Row>
        <Button
          onClick={() => showDeleteModal(selectedRowKeys)}
          danger
          disabled={selectedRowKeys.length === 0}
        >
          Delete Selected
        </Button>
      </Row>
      <Table
        className="days-off-table"
        dataSource={daysOffWithKeyAndChildren}
        rowKey="key"
        pagination={false}
        style={{ overflow: 'auto' }}
        columns={columns}
        expandable={{ expandRowByClick: true }}
        rowSelection={{
          hideSelectAll: true,
          type: 'checkbox',
          checkStrictly: false,
          ...rowSelection,
        }}
      />
    </Loader>
  );
};

export default DaysOffReport;
