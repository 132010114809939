// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vzs5314x8LfpKimOAsEJ {
  color: rgba(0, 0, 0, 0.65);
}
.darkMode .vzs5314x8LfpKimOAsEJ {
  color: rgba(255, 255, 255, 0.85);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Presence/Presence.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AACA;EAEI,gCAAA;AAAJ","sourcesContent":[".userName {\n  color: rgba(0, 0, 0, 0.65);\n}\n:global(.darkMode) {\n  .userName {\n    color: rgba(255, 255, 255, 0.85);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userName": `vzs5314x8LfpKimOAsEJ`
};
export default ___CSS_LOADER_EXPORT___;
