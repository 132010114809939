import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userActions from '../actions/user.actions';

const useSyncUserTimezone = () => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me?.data);

  useEffect(() => {
    const myTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (me?.data?.timezone && me?.data?.timezone !== myTimezone) {
      dispatch(
        userActions.patchUserRequest(me?.data?.id, { timezone: myTimezone })
      );
    }
  }, [me]);
};

export default useSyncUserTimezone;
