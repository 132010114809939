import {
  BookOutlined,
  CalendarOutlined,
  CarOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  SettingOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import wideLogo from 'assets/logo-wide-white.svg';
import logo from 'assets/logo.svg';
import { getMenuData } from 'common/menu';
import { debounce } from 'lodash';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import GlobalHeader from '../components/GlobalHeader';
import SiderMenu from '../components/SiderMenu/SiderMenu';
import styles from './Layout.less';

const { Content, Header } = Layout;

class BasicLayout extends Component {
  handleMobile = debounce(() => {
    const isMobile = window.innerWidth <= 425;
    this.setState((oldState) => {
      return {
        ...oldState,
        isMobile,
      };
    });
  }, 300);

  constructor(props) {
    super(props);
    const collapsed = !!localStorage.getItem('collapsed');
    this.state = {
      collapsed,
      isMobile: false,
    };
    this.handleMenuCollapse = this.handleMenuCollapse.bind(this);
    this.collapseOnNoState = this.collapseOnNoState.bind(this);
  }

  componentDidMount() {
    this.handleMobile();
    window.addEventListener('resize', this.handleMobile);
  }

  UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.handleMobile);
  }

  handleMenuCollapse() {
    const newCollapsed = !this.state.collapsed;
    this.setState((oldState) => ({
      collapsed: !oldState.collapsed,
    }));
    if (newCollapsed) localStorage.setItem('collapsed', 'true');
    else localStorage.setItem('collapsed', '');
  }

  collapseOnNoState() {
    if (localStorage.getItem('collapsed') === null) this.handleMenuCollapse();
  }

  render() {
    const { children, location, me, permissions } = this.props;
    const { collapsed, isMobile } = this.state;

    const isAdmin = permissions.permissionLevel === 'admin';

    if (!me.data) {
      return null;
    }
    const meData = me.data || {};
    let renderedLogo;
    if (collapsed) {
      renderedLogo = logo;
    } else {
      renderedLogo = wideLogo;
    }

    const menuData = [
      {
        name: 'Monthly Report',
        icon: <CalendarOutlined />,
        path: '/monthly',
        regex: /^\/monthly/,
      },
      {
        name: 'Times',
        icon: <ClockCircleOutlined />,
        path: '/users/me/times',
        regex: /^\/users\/(me|[0-9]+)\/times/,
      },
    ];
    if (!me.data.is_freelancer) {
      menuData.push(
        {
          name: 'Presence',
          icon: <CalendarOutlined />,
          path: '/presence',
          regex: /^\/presence/,
        },
        {
          name: 'Days Off',
          icon: <CarOutlined />,
          path: '/days-off',
          regex: /^\/days-off/,
        },
        {
          name: 'Remote Work',
          icon: <HomeOutlined />,
          path: '/remote-work',
          regex: /^\/remote-work/,
        },
        {
          name: 'Reports',
          icon: <DashboardOutlined />,
          path: '/reports',
          regex: /^\/reports/,
        }
      );
    }
    if (isAdmin) {
      menuData.push({
        name: 'Admin Panel',
        icon: <ToolOutlined />,
        path: '/admin-panel',
        regex: /^\/admin-panel/,
        children: [
          {
            name: 'Clients',
            icon: <ContactsOutlined />,
            path: '/admin',
            regex: /^\/admin/,
          },
          {
            name: 'Archive',
            icon: <DatabaseOutlined />,
            path: '/archive',
            regex: /^\/archive/,
          },
        ],
      });
    }

    if (me.data.is_superuser) {
      menuData
        .find((menu) => menu.name === 'Admin Panel')
        ?.children.push({
          name: 'Settings',
          icon: <SettingOutlined />,
          path: '/settings',
          regex: /^\/settings/,
        });
    }

    menuData.push({
      name: 'Knowledge Base',
      icon: <BookOutlined />,
      path: 'https://drive.google.com/drive/folders/0AMO1iFyiLarOUk9PVA',
      target: '_blank',
    });

    return (
      <Layout>
        <Helmet titleTemplate="%s - Ulam Labs Intranet" title="Undefined" />
        <SiderMenu
          logo={renderedLogo}
          collapsed={collapsed}
          menuData={getMenuData(menuData)}
          location={location}
          onCollapse={this.collapseOnNoState}
          forceCollapse={this.handleMenuCollapse}
          isMobile={isMobile}
        />
        <Layout
          className={[
            styles.contentLayout,
            collapsed ? styles.menuCollapsed : '',
          ].join(' ')}
        >
          <Header
            className={[
              styles.mainHeader,
              collapsed ? styles.menuCollapsed : '',
            ].join(' ')}
          >
            <GlobalHeader
              isMobile={isMobile}
              logo={renderedLogo}
              collapsed={collapsed}
              userName={meData.name}
              userEmail={meData.email}
              onCollapse={this.handleMenuCollapse}
            />
          </Header>
          <Content className={styles.mainContent}>{children}</Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const me = state.me;
  const permissions = state.permissions;
  return {
    me,
    permissions,
  };
}
export default connect(mapStateToProps)(BasicLayout);
