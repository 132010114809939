import React from 'react';
import * as icons from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';

const UlamIcon = () => (
  <svg
    width="1.1em"
    height="1.1em"
    viewBox="0 0 173 212"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M131.362 133.117C131.322 136.594 129.684 170.016 86.456 170.016C43.8834 170.016 42.269 136.594 42.245 133.149V14.8854H55.032V124.853C55.032 137.896 60.4265 157.764 86.456 157.764C113.021 157.764 118.567 137.896 118.567 124.853V0.5H104.182V124.869C104.265 129.78 103.135 134.728 99.7052 138.313C96.2751 141.898 91.419 143.757 86.472 143.379C81.6008 143.68 76.8533 141.77 73.5478 138.179C70.2424 134.588 69.2504 129.699 69.4334 124.869V0.5H27.8756V136.178C27.8756 136.665 28.539 184.457 86.496 184.457C145.124 184.457 145.812 136.665 145.812 136.178V14.8854H158.599V141.157C158.599 143.363 155.857 196.62 86.488 196.62C17.1186 196.62 14.3934 143.363 14.3854 141.181V0.5H0V142.699C0 143.387 2.4535 211.038 86.496 211.038C170.538 211.038 173 143.387 173 142.699V0.5H131.402L131.362 133.117Z"
      fill="currentColor"
    />
  </svg>
);

const DynamicIcon = ({ iconName }) => {
  const ChosenIcon = icons[iconName];
  return ChosenIcon ? <ChosenIcon /> : <Icon component={UlamIcon} />;
};

export default DynamicIcon;
