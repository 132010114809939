import freelancersConstants from '../constants/freelancers.constants';

function fetchFreelancersRequest() {
  return {
    type: freelancersConstants.FETCH_FREELANCERS_REQUEST,
  };
}

function createFreelancerRequest(data) {
  return {
    type: freelancersConstants.CREATE_FREELANCER_REQUEST,
    data,
  };
}

function createFreelancerFailure(error) {
  return {
    type: freelancersConstants.CREATE_FREELANCER_FAILURE,
    error,
  };
}

function deleteFreelancerRequest(id) {
  return {
    type: freelancersConstants.DELETE_FREELANCER_REQUEST,
    id,
  };
}

const freelancersActions = {
  fetchFreelancersRequest,
  createFreelancerRequest,
  createFreelancerFailure,
  deleteFreelancerRequest,
};

export default freelancersActions;
