import freelancersConstants from 'constants/freelancers.constants';

const initialState = { fetching: false };
export default function freelancers(state = initialState, action) {
  switch (action.type) {
    case freelancersConstants.FETCH_FREELANCERS_REQUEST:
      return { ...state, fetching: true };
    case freelancersConstants.FETCH_FREELANCERS_SUCCESS:
      return { ...state, freelancers: action.fetchedData, fetching: false };
    case freelancersConstants.FETCH_FREELANCERS_FAILURE:
      return { ...state, fetching: false };
    default:
      return state;
  }
}
