import holidaysConstants from 'constants/holidays.constants';

export default function holidays(state = [], action) {
  switch (action.type) {
    case holidaysConstants.FETCH_HOLIDAYS_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case holidaysConstants.FETCH_HOLIDAYS_SUCCESS: {
      return {
        data: action.holidays,
        fetched: true,
      };
    }

    case holidaysConstants.FETCH_HOLIDAYS_FAILURE: {
      return { ...state, fetching: false, error: action.error };
    }
    default:
      return state;
  }
}
