import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import PublicLayout from 'layout/PublicLayout';
import Reports from 'pages/Reports/Reports';

const Public = () => {
  return (
    <PublicLayout>
      <Switch>
        <Route
          exact
          path="/public/reports/:publicId"
          name="Reports"
          component={Reports}
        />
      </Switch>
    </PublicLayout>
  );
};

export default Public;
