import { createSelector } from 'reselect';

const selectUserGroups = (state) => state.teams.data?.results;

const selectLeaders = createSelector([selectUserGroups], (userGroups) =>
  userGroups?.find((group) => group.name === 'Leaders')
);

export const selectUserPrivateTeams = createSelector(
  [selectUserGroups, (state, user) => user],
  (userGroups, user) =>
    userGroups?.filter(
      (group) =>
        ['private', 'utility'].includes(group.type) &&
        group.users.some((member) => member.id === user.id)
    )
);

export const selectUserPublicTeams = createSelector(
  [selectUserGroups, (state, user) => user],
  (userGroups, user) =>
    userGroups?.filter(
      (group) =>
        group.type === 'public' &&
        group.users.some((member) => member.id === user.id)
    )
);

export const selectUserLeader = createSelector(
  [selectLeaders, (state, user) => user],
  (leaders, user) => {
    if (!leaders) return null;
    return leaders.users?.find((leader) => leader.name === user.leader);
  }
);
