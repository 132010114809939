import teamsConstants from 'constants/teams.constants';

export default function teams(state = [], action) {
  switch (action.type) {
    case teamsConstants.FETCH_TEAMS_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case teamsConstants.FETCH_TEAMS_SUCCESS: {
      return {
        data: action.teams,
        fetched: true,
      };
    }

    case teamsConstants.FETCH_TEAMS_FAILURE: {
      return { ...state, fetching: false, error: action.error };
    }
    default:
      return state;
  }
}
