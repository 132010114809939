import { NotificationManager } from 'react-notifications';
import authConstants from 'constants/auth.constants';

function loginRequest(payload) {
  return { type: authConstants.LOGIN_REQUEST, payload };
}
function loginSuccess(token) {
  return { type: authConstants.LOGIN_SUCCESS, token };
}
function loginFailure(error) {
  if (error.status >= 400 && error.status < 600)
    NotificationManager.error(
      `Server returned status: ${error.status}`,
      'Server Error',
      5000
    );
  return { type: authConstants.LOGIN_FAILURE, error };
}

function logout() {
  return { type: authConstants.LOGOUT };
}

const authActions = {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
};

export default authActions;
