import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import sprite from 'assets/cat-walk.png';
import styles from './Sprite.less';

const playerSize = 64;
const playerBackgroud = new Image();
playerBackgroud.src = sprite;

//  It will work only for one component, use useRef if you would like to use in multiple components
const playerLeft = 0;
let playerTopIdx = 0;
let playerTop = 0;
let lastTime = 0;
let animationFrameId = null;

const draw = (ctx, now) => {
  if (!ctx) {
    return;
  }
  if (now - lastTime < 50) {
    return;
  }
  lastTime = now;
  playerTopIdx = (playerTopIdx + 1) % 13;
  playerTop = -playerSize * playerTopIdx;

  ctx.clearRect(0, 0, playerSize * 2, playerSize * 2);
  ctx.drawImage(
    playerBackgroud,
    playerLeft,
    playerTop,
    playerSize * 8,
    playerSize * 13
  );
};

const Sprite = () => {
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const [rng] = useState(Math.floor(Math.random() * 300));

  const animatePlayerLoop = useCallback(
    (now) => {
      if (!ctx) return;
      draw(ctx, now);
      animationFrameId = window.requestAnimationFrame(animatePlayerLoop);
    },
    [ctx]
  );

  useEffect(() => {
    setCtx(canvasRef.current.getContext('2d'));

    animationFrameId = window.requestAnimationFrame(animatePlayerLoop);

    return () => {
      if (animationFrameId) {
        window.cancelAnimationFrame(animationFrameId);
      }
    };
  }, [canvasRef, animatePlayerLoop]);

  return (
    <canvas
      ref={canvasRef}
      className={clsx(styles.sprite, rng === 2 && styles.active)}
      width="128"
      height="64"
    />
  );
};
export default Sprite;
