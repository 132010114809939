import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import wideLogo from 'assets/logo-wide-black.png';

const { Content } = Layout;

const PublicLayout = ({ children }) => {
  return (
    <Layout>
      <Helmet titleTemplate="%s - Ulam Labs Intranet" title="Undefined" />
      <Content style={{ margin: '24px 24px 0', height: '100%' }}>
        <img src={wideLogo} alt="logo" className="logo" />
        {children}
      </Content>
    </Layout>
  );
};

export default PublicLayout;
