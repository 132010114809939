// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enwR0plWx0x6mkqb_zFg {
  min-width: 100px;
}
.oq1jucajDyDPRPI_E3vM {
  color: rgba(0, 0, 0, 0.65);
}
.omQO1i6PUUh7qfXfah_y {
  color: #bbbb01;
}
.C55SiDntLe7CzdseMbwA {
  color: #b30000;
}
.darkMode .oq1jucajDyDPRPI_E3vM {
  color: rgba(255, 255, 255, 0.85);
}
@media screen and (max-width: 425px) {
  .enwR0plWx0x6mkqb_zFg {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/RemoteWork/RemoteWork.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,0BAAA;AAAF;AAGA;EACE,cAAA;AADF;AAIA;EACE,cAAA;AAFF;AAKA;EAEI,gCAAA;AAJJ;AAQA;EACE;IACE,WAAA;EANF;AACF","sourcesContent":[".userSelect {\n  min-width: 100px;\n}\n\n.userName {\n  color: rgba(0, 0, 0, 0.65);\n}\n\n.info {\n  color: rgb(187, 187, 1);\n}\n\n.danger {\n  color: rgb(179, 0, 0);\n}\n\n:global(.darkMode) {\n  .userName {\n    color: rgba(255, 255, 255, 0.85);\n  }\n}\n\n@media screen and (max-width: 425px) {\n  .userSelect {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userSelect": `enwR0plWx0x6mkqb_zFg`,
	"userName": `oq1jucajDyDPRPI_E3vM`,
	"info": `omQO1i6PUUh7qfXfah_y`,
	"danger": `C55SiDntLe7CzdseMbwA`
};
export default ___CSS_LOADER_EXPORT___;
