import { EditOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import notificationsActions from 'actions/notifications.actions';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Row,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import clsx from 'clsx';
import DynamicIcon from 'components/DynamicIcon/DynamicIcon';
import { format } from 'date-fns';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { selectNotifications } from 'selectors/notifications.selectors';
import { selectIsAdmin } from '../../selectors/permissions.selectors';
import './MessagesCenter.css';
import styles from './MessagesCenter.less';
import ArchiveIcon from './components/ArchiveIcon';
import MessageCreation from './components/MessageCreation';
import NotificationMessage from './components/NotificationMessage';

const MessagesCenter = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sendOpen, setSendOpen] = useState(false);
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isAdmin = useSelector(selectIsAdmin);
  const [markValue, setMarkValue] = useState('');

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(notificationsActions.createNotificationsRequest(values));
        setSendOpen(false);
        form.resetFields();
        setMarkValue('');
      })
      .catch((errorInfo) => {
        dispatch(notificationsActions.createNotificationsFailure(errorInfo));
      });
  };

  const archiveNotifications = (ids) => {
    dispatch(notificationsActions.archiveNotificationsRequest(ids));
    setSelectedRowKeys([]);
  };

  const markAsViewed = (notification) => {
    if (notification.viewed_at) {
      return;
    }
    dispatch(
      notificationsActions.patchNotificationsRequest(
        [notification.notification_id],
        true
      )
    );
  };

  const columns = [
    {
      title: (
        <Button
          onClick={() => archiveNotifications(selectedRowKeys)}
          disabled={selectedRowKeys.length === 0}
        >
          Remove selected
        </Button>
      ),
      dataIndex: 'notificationData',
      key: 'notificationData',
      render: (_, record) => {
        return (
          <NavLink
            activeClassName={styles.active}
            className={clsx(
              styles.notificationItem,
              record.viewed_at && styles.opened
            )}
            onClick={() => markAsViewed(record)}
            to={`/messages-center/${record.notification_id}`}
          >
            <div className={styles.flexContainer}>
              <DynamicIcon iconName={record.icon} />
              <p className={styles.messageTitle}>{record.title}</p>
            </div>
            <div className={styles.flexContainer}>
              <p>{format(new Date(record.created_at), 'dd-MM-yyyy')}</p>
              <Tooltip className={styles.actionButton} title="Archive">
                <Icon
                  onClick={(e) => {
                    e.preventDefault();
                    archiveNotifications([record.notification_id]);
                  }}
                  component={ArchiveIcon}
                />
              </Tooltip>
            </div>
          </NavLink>
        );
      },
    },
  ];

  const rowSelection = {
    type: 'checkbox',
    onChange: (rowKeys) => {
      setSelectedRowKeys(rowKeys);
    },
  };

  return (
    <div className={styles.messagesCenter}>
      <Helmet title="Messages Center" />
      <Card title="Messages Center">
        <Col className={styles.mobileView}>
          {notifications.fetching ? (
            <Spin className={styles.loading} size="large" />
          ) : (
            <Switch>
              <Route exact path="/messages-center">
                <Table
                  columns={columns}
                  dataSource={notifications.notifications}
                  rowKey="notification_id"
                  pagination={false}
                  rowClassName={styles.tableRow}
                  rowSelection={rowSelection}
                  sticky
                  size="small"
                />
                {isAdmin && (
                  <Button
                    onClick={() => setSendOpen(true)}
                    className={styles.openCreation}
                    shape="circle"
                    icon={<EditOutlined />}
                  />
                )}
              </Route>
              <Route
                exact
                path="/messages-center/:id"
                render={(props) => (
                  <NotificationMessage
                    isFetching={notifications.fetching}
                    onArchiveNotifications={archiveNotifications}
                    // eslint-disable-next-line
                    {...props}
                  />
                )}
              />
            </Switch>
          )}
        </Col>
        {notifications.fetching ? (
          <Spin className={styles.loading} size="large" />
        ) : (
          <Row className={styles.topRow} type="flex">
            <Col
              lg={10}
              style={{
                height: '100%',
                overflow: 'scroll',
                position: 'relative',
              }}
            >
              <Table
                columns={columns}
                dataSource={notifications.notifications}
                rowKey="notification_id"
                pagination={false}
                rowClassName={styles.tableRow}
                rowSelection={rowSelection}
                sticky
                size="small"
              />
              {isAdmin && (
                <Button
                  onClick={() => setSendOpen(true)}
                  className={styles.openCreation}
                  icon={<EditOutlined />}
                  shape="circle"
                />
              )}
            </Col>
            <Col lg={14} className={styles.messageContainer}>
              <Switch>
                <Route exact path="/messages-center">
                  <Empty
                    className={styles.emptyState}
                    description="Select message"
                  />
                </Route>
                <Route
                  exact
                  path="/messages-center/:id"
                  render={(props) => (
                    <NotificationMessage
                      isFetching={notifications.fetching}
                      archiveNotifications={archiveNotifications}
                      // eslint-disable-next-line
                      {...props}
                    />
                  )}
                />
              </Switch>
            </Col>
          </Row>
        )}
      </Card>
      <MessageCreation
        open={sendOpen}
        form={form}
        handleOk={handleOk}
        markValue={markValue}
        setMarkValue={setMarkValue}
        handleCancel={() => {
          setSendOpen(false);
          form.resetFields();
        }}
      />
    </div>
  );
};
export default MessagesCenter;
