import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';

/**
 * Converts selected items from a multiselect table's source data to an appropriate structure.
 * @param {object | number[]} selectedDaysData - selected day object or array of IDs representing selected days.
 * @param {object[]} allDays - Array containing all rows data used in the table.
 * @param {boolean} isAdmin - Boolean indicating whether the current logged-in user is an admin.
 * @param {object} currentUser - Object representing the details of the current logged-in user.
 * @returns {object} An object containing two properties: structuredDays, allowedDays, showSelectedDaysInfo: boolean and selectedPast: boolean.
 */
const multiselectConversion = (
  selectedDaysData,
  allDays,
  isAdmin,
  currentUser
) => {
  const isArray = Array.isArray(selectedDaysData);

  const transformedDays = allDays.flatMap((day) => {
    if (day.items) {
      return day.items?.map((item) => {
        return { ...item, user: day.user };
      });
    }
    return day;
  });

  const selectedDays =
    isArray &&
    transformedDays.filter((day) => selectedDaysData.includes(day.id));

  const structuredDays = Object.values(
    groupBy(isArray ? selectedDays : [selectedDaysData], 'user.id')
  ).map((group) => ({
    id: group[0].user.id,
    name: group[0].user.name,
    days: group,
  }));

  const allowedDays = isAdmin
    ? structuredDays.flatMap((structuredDay) => structuredDay.days)
    : structuredDays
        .filter((structuredDay) => structuredDay.id === currentUser.id)
        .flatMap((structuredDay) =>
          structuredDay.days.filter(
            (day) => dayjs(day.date_start) >= dayjs() && !day.approved_at
          )
        );

  const isNotMe = structuredDays.some(
    (structuredDay) => structuredDay.id !== currentUser.id
  );

  const showSelectedDaysInfo =
    structuredDays.some((structuredDay) => structuredDay.days.length > 1) ||
    structuredDays.length > 1 ||
    isNotMe;

  const selectedPast = structuredDays.some((structuredDay) =>
    structuredDay.days.some((day) => dayjs(day.date_start) < dayjs())
  );

  const noAllowedDays = allowedDays.length === 0;

  return {
    structuredDays,
    allowedDays,
    showSelectedDaysInfo,
    selectedPast,
    isNotMe,
    noAllowedDays,
  };
};

export default multiselectConversion;
