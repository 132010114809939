// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RC4ddXpS9uZEJkmUgCA4 {
  line-height: 1.5 !important;
  margin-left: 4px;
  margin-right: 4px;
}
.rutipQRqbKcPND3zT8Sg {
  display: flex;
  flex-wrap: nowrap;
}
@media screen and (max-width: 370px) {
  .rutipQRqbKcPND3zT8Sg .RC4ddXpS9uZEJkmUgCA4 {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/QuickMonthPicker/index.less"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEA;EACI,aAAA;EACA,iBAAA;AAAJ;AACI;EAAA;IAEO,aAAA;EACT;AACF","sourcesContent":[":local(.dateButton) {\n    line-height: 1.5 !important;\n    margin-left: 4px;\n    margin-right: 4px;\n}\n\n.quickMonthPicker {\n    display: flex;\n    flex-wrap: nowrap;\n    @media screen and (max-width: 370px) {\n       .dateButton {\n           display: none;\n       } \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateButton": `RC4ddXpS9uZEJkmUgCA4`,
	"quickMonthPicker": `rutipQRqbKcPND3zT8Sg`
};
export default ___CSS_LOADER_EXPORT___;
