import projectsConstants from 'constants/projects.constants';

function fetchProjectsRequest(params, storeId = 'timesPage') {
  return {
    type: projectsConstants.FETCH_PROJECTS_REQUEST,
    params,
    storeId,
  };
}

function fetchProjectsSuccess(projects, storeId) {
  return {
    type: projectsConstants.FETCH_PROJECTS_SUCCESS,
    projects,
    storeId,
  };
}

function fetchProjectsFailure(error, storeId) {
  return {
    type: projectsConstants.FETCH_PROJECTS_FAILURE,
    error,
    storeId,
  };
}

function deleteProjectRequest(id, storeId) {
  return {
    type: projectsConstants.DELETE_PROJECT_REQUEST,
    id,
    storeId,
  };
}

function deleteProjectSuccess(id, storeId) {
  return {
    type: projectsConstants.DELETE_PROJECT_SUCCESS,
    id,
    storeId,
  };
}

function deleteProjectFailure(error, storeId) {
  return {
    type: projectsConstants.DELETE_PROJECT_FAILURE,
    error,
    storeId,
  };
}

function patchProjectRequest(id, data, storeId) {
  return {
    type: projectsConstants.PATCH_PROJECT_REQUEST,
    id,
    data,
    storeId,
  };
}

function patchProjectSuccess(id, storeId) {
  return {
    type: projectsConstants.PATCH_PROJECT_SUCCESS,
    id,
    storeId,
  };
}

function patchProjectFailure(error, storeId) {
  return {
    type: projectsConstants.PATCH_PROJECT_FAILURE,
    error,
    storeId,
  };
}

function createProjectRequest(data, storeId) {
  return {
    type: projectsConstants.CREATE_PROJECT_REQUEST,
    data,
    storeId,
  };
}

function createProjectSuccess(data, storeId) {
  return {
    type: projectsConstants.CREATE_PROJECT_SUCCESS,
    data,
    storeId,
  };
}

function createProjectFailure(error, storeId) {
  return {
    type: projectsConstants.CREATE_PROJECT_FAILURE,
    error,
    storeId,
  };
}

const projectsActions = {
  fetchProjectsRequest,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  deleteProjectRequest,
  deleteProjectSuccess,
  deleteProjectFailure,
  patchProjectRequest,
  patchProjectSuccess,
  patchProjectFailure,
  createProjectRequest,
  createProjectSuccess,
  createProjectFailure,
};

export default projectsActions;
