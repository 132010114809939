import { put, call, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import { get, patch } from 'helpers/ajax';
import daysOffAllowancesActions from 'actions/daysOffAllowances.actions';
import daysOffAllowancesConstants from 'constants/daysOffAllowances.constants';

function* fetchDaysOffAllowances(action) {
  const params = queryString.stringify(action.params);
  try {
    const ajax = yield get(
      `/api/v1/days_off_allowances/report?${params}`,
      null,
      true
    );
    const rsp = yield call([ajax, ajax.toPromise]);
    yield put(
      daysOffAllowancesActions.fetchDaysOffAllowancesSuccess(rsp.response)
    );
  } catch (error) {
    yield put(daysOffAllowancesActions.fetchDaysOffAllowancesFailure(error));
  }
}

function* patchDaysOffAllowance(action) {
  try {
    const ajax = yield patch(`/api/v1/days_off_allowances/${action.id}`, {
      amount: action.amount,
    });
    yield call([ajax, ajax.toPromise]);
    yield put(daysOffAllowancesActions.patchDaysOffAllowancesSuccess());
  } catch (error) {
    yield put(daysOffAllowancesActions.patchDaysOffAllowancesFailure(error));
  }
}

export default function* daysOffAllowancesSaga() {
  yield takeLatest(
    daysOffAllowancesConstants.FETCH_DAYS_OFF_ALLOWANCES_REQUEST,
    fetchDaysOffAllowances
  );
  yield takeLatest(
    daysOffAllowancesConstants.PATCH_DAYS_OFF_ALLOWANCES_REQUEST,
    patchDaysOffAllowance
  );
}
