import domainsConstants from 'constants/domains.constants';

/* eslint-disable */
export default function domains(state = {}, action) {
  switch (action.type) {
    case domainsConstants.FETCH_DOMAINS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        params: action.params,
      });

    case domainsConstants.FETCH_DOMAINS_SUCCESS:
      return Object.assign({}, state, {
        domains: action.domains,
        fetched: true,
      });

    case domainsConstants.FETCH_DOMAINS_FAILURE:
      return Object.assign({}, state, {
        domains: {},
        fetching: false,
      });

    case domainsConstants.CREATE_DOMAIN_REQUEST:
      return Object.assign({}, state, {
        creating: true,
        created: false,
      });

    case domainsConstants.CREATE_DOMAIN_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        created: true,
      });

    case domainsConstants.CREATE_DOMAIN_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        error: action.error,
      });

    case domainsConstants.DELETE_DOMAIN_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      });

    case domainsConstants.DELETE_DOMAIN_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      });

    case domainsConstants.DELETE_DOMAIN_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
      });

    default:
      return state;
  }
}
