import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Badge, Dropdown, Spin } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Debounce from 'lodash-decorators/debounce';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './GlobalHeader.less';

import authActions from 'actions/auth.actions';
import notificationsActions from 'actions/notifications.actions';
import UserGravatar from 'components/UserGravatar/UserGravatar';
import { selectUnreadNotificationsCount } from 'selectors/notifications.selectors';
import MessageIcon from '../MessageIcon/MessageIcon';

class GlobalHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.displayName = 'GlobalHeader';
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(notificationsActions.startFetchNotifications());
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    this.triggerResizeEvent();
  };

  UNSAFE_componentWillUnmount() {
    this.props.dispatch(notificationsActions.cancelFetchNotifications());
    this.triggerResizeEvent.cancel();
  }
  /* eslint-disable*/
  @Debounce(600)
  triggerResizeEvent() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }

  handleLogout() {
    const { dispatch } = this.props;
    dispatch(authActions.logout());
  }

  render() {
    const {
      userName,
      userEmail,
      collapsed,
      isMobile,
      logo,
      onMenuClick,
      presenceTick,
      me,
      notificationsCount,
    } = this.props;

    const items = [
      {
        label: <Link to="/users/me">Profile</Link>,
        key: 'profile',
        icon: <UserOutlined />,
      },
      {
        label: <Link to="/messages-center">Messages Center</Link>,
        key: 'notifications',
        icon: (
          <Badge
            offset={[8, 0]}
            className={styles.newMessages}
            dot={notificationsCount > 0}
          >
            <Icon component={MessageIcon} />
          </Badge>
        ),
      },
      { key: 'divider-1', type: 'divider' },
      {
        label: 'Logout',
        key: 'logout',
        icon: <LogoutOutlined />,
        onClick: this.handleLogout,
      },
    ];
    let timesText = '';
    if (
      presenceTick &&
      presenceTick.data &&
      presenceTick.data.first &&
      presenceTick.data.last
    ) {
      const start = (
        <strong>{dayjs(presenceTick.data.first).format('HH:mm')}</strong>
      );
      const end = (
        <strong>{dayjs(presenceTick.data.last).format('HH:mm')}</strong>
      );
      const time = <strong>{presenceTick.data.time.toFixed(2)}h</strong>;
      let diffText = '';
      if (presenceTick.data.diff) {
        const diff = <strong>{presenceTick.data.diff.toFixed(2)}h</strong>;
        diffText = <span>, {diff} to add</span>;
      }
      timesText = (
        <>
          <span className={styles.hoursBig}>
            Today from {start} to {end}, {time} were added{diffText}
          </span>
          <span
            className={[
              styles.hoursSmall,
              collapsed ? '' : styles.siderExpanded,
            ].join(' ')}
          >
            {start}-{end}
            {diffText && diffText}
          </span>
        </>
      );
    }

    const missingHours =
      me.data?.daily_hours > 0
        ? -me.data?.work_stats.this_month_hours_stats.diff
        : 0;

    return (
      <div className={styles.header}>
        {collapsed ? (
          <MenuUnfoldOutlined
            className={styles.trigger}
            onClick={this.toggle}
          />
        ) : (
          <MenuFoldOutlined className={styles.trigger} onClick={this.toggle} />
        )}
        {timesText}
        {missingHours >= 40 && (
          <span className={styles.missingHours}>
            {`Missing ${missingHours}${isMobile ? 'h!' : ' hours!'}`}
          </span>
        )}
        <span
          className={[
            styles.right,
            collapsed ? styles.siderCollapsed : '',
          ].join(' ')}
        >
          <Dropdown menu={{ items }}>
            <Link
              to={isMobile ? '#' : '/users/me'}
              className={`${styles.action} ${styles.account}`}
            >
              {me.fetched ? (
                <span>
                  <Badge
                    className={clsx(
                      styles.messagesCount,
                      10 > 9 && styles.overNine
                    )}
                    count={notificationsCount}
                    overflowCount={9}
                    size="small"
                  >
                    <UserGravatar
                      className={styles.avatar}
                      email={userEmail}
                      userName={userName}
                      size="small"
                    />
                  </Badge>
                  <span className={styles.name}>{userName}</span>
                </span>
              ) : (
                <Spin size="small" style={{ marginLeft: 8 }} />
              )}
            </Link>
          </Dropdown>
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const presenceTick = state.presenceTick;
  const me = state.me;
  const notificationsCount = selectUnreadNotificationsCount(state);
  return {
    presenceTick,
    me,
    notificationsCount,
  };
}

export default connect(mapStateToProps)(GlobalHeader);
