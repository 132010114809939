import daysOffActions from 'actions/daysOff.actions';
import { Button, Col, Row, Select } from 'antd';
import AddModal from 'components/AddDaysOff/AddDaysOffModal';
import DaysOffReport from 'components/DaysOffReport/DaysOffReport';
import { post } from 'helpers/ajax';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAdmin } from 'selectors/permissions.selectors';
import styles from '../DaysOff.less';

const Option = Select.Option;

const DaysOff = ({ usersFilter, holidays, history }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const { daysOff, fetching, next } = useSelector((state) => state.daysOff);

  const isAdmin = useSelector(selectIsAdmin);
  // eslint-disable-next-line
  const params = new URLSearchParams(location.search);

  const typeParam = params.get('type');
  const [typeFilter, setTypeFilter] = useState(typeParam);

  const approveParam = params.get('approved') || 'all';
  const [showApprove, setShowApprove] = useState(approveParam);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const fetchDaysOff = (parameters) => {
    dispatch(daysOffActions.fetchDaysOffRequest(parameters, false));
  };

  const handleCreateDayOff = async (data, parameters) => {
    dispatch(daysOffActions.checkAndCreateDaysOffAction(data, parameters));
  };

  const deleteDayOff = (id, parameters = {}) => {
    dispatch(daysOffActions.deleteDaysOffRequest(id, parameters));
  };

  const fetchNextDaysOff = (parameters) => {
    dispatch(daysOffActions.fetchNextDaysOffRequest(parameters));
  };

  const onApprove = (dayOff) => {
    return post(`/api/v1/days_off/${dayOff.id}/approve`, {}, {}, true)
      .toPromise()
      .then(() =>
        fetchDaysOff({
          user: usersFilter,
          type: typeFilter,
          approved: showApprove || 'all',
        })
      );
  };

  const approvalView = (view) => {
    // eslint-disable-next-line
    const queryParams = new URLSearchParams(location.search);

    setShowApprove(view);
    if (view) {
      queryParams.set('approved', view);
    } else {
      queryParams.delete('approved');
    }

    history.push({
      search: queryParams.toString(),
    });
  };

  const handleSetType = (newType) => {
    // eslint-disable-next-line
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('type', newType);
    setTypeFilter(newType);
    if (!newType) {
      queryParams.delete('type');
    }

    history.push({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    fetchDaysOff({
      user: usersFilter,
      type: typeFilter,
      approved: showApprove || 'all',
    });
  }, [usersFilter.toString(), typeFilter?.toString(), showApprove]);

  const dayOffTypes = [
    <Option key="Paid" value="Paid">
      Paid
    </Option>,
    <Option key="Unpaid" value="Unpaid">
      Unpaid
    </Option>,
    <Option key="L4" value="L4">
      L4
    </Option>,
    <Option key="force_majeure" value="force_majeure">
      Force Majeure
    </Option>,
    <Option key="on_demand" value="on_demand">
      On Demand
    </Option>,
    <Option key="family_carer" value="family_carer">
      Dependency leave (Family)
    </Option>,
    <Option key="child_carer" value="child_carer">
      Dependency leave (Child)
    </Option>,
    <Option key="parental" value="parental">
      Parental
    </Option>,
    <Option key="special" value="special">
      Special
    </Option>,
  ];

  let scrollParentRef = null;

  return (
    // eslint-disable-next-line
    <div ref={(ref) => (scrollParentRef = ref)}>
      <Row gutter={16} className="days-off-controls">
        <Col md={6}>
          <Select
            allowClear
            placeholder="Type"
            className="group-select"
            onChange={(newType) => handleSetType(newType)}
            value={typeFilter}
          >
            {dayOffTypes}
          </Select>
        </Col>
        <Col md={6}>
          <Select
            className={styles.approval}
            value={showApprove}
            onChange={(view) => approvalView(view)}
            defaultValue="all"
          >
            <Option value="all">All</Option>
            <Option value="True">Approved</Option>
            <Option value="False">Not approved</Option>
          </Select>
        </Col>
        <Col
          span={24}
          xxl={{ span: 3, offset: 9 }}
          xl={{ span: 4, offset: 8 }}
          lg={{ span: 5, offset: 7 }}
          className="controls-right"
        >
          <Button
            type="primary"
            className="day-off-button-add"
            onClick={() => setAddModalVisible(true)}
          >
            Add Days Off
          </Button>
        </Col>
      </Row>
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchNextDaysOff}
        hasMore={!fetching && next !== null}
        getScrollParent={() => scrollParentRef}
      >
        <DaysOffReport
          isAdmin={isAdmin}
          daysOff={daysOff}
          user={me.data}
          onDelete={(dayOff) =>
            deleteDayOff(dayOff.id, {
              user: usersFilter,
              type: typeFilter,
              approved: showApprove || 'all',
            })
          }
          onApprove={onApprove}
        />
      </InfiniteScroll>
      <AddModal
        user={me.data}
        holidays={holidays}
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        isAdmin={isAdmin}
        onOk={(data) => {
          handleCreateDayOff(data, {
            user: usersFilter,
            type: typeFilter,
            approved: showApprove || 'all',
          });
          setAddModalVisible(false);
        }}
      />
    </div>
  );
};

export default DaysOff;
