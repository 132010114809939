import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from 'antd';
import dayjs from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Reports.css';

import clientsActions from 'actions/clients.actions';
import projectsActions from 'actions/projects.actions';
import publicLinksActions from 'actions/publicLinks.actions';
import timesActions from 'actions/times.actions';
import usersActions from 'actions/users.actions';
import Loader from 'components/Loader/Loader';
import Fuse from 'fuse.js';
import { get } from 'helpers/ajax';
import { formatDate } from 'helpers/dateTime';
import userTheme from 'helpers/userTheme';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';
import ProjectPicker from '../../components/ProjectPicker';
import UserGravatar from '../../components/UserGravatar/UserGravatar';
import ManagePublicLinksModal from './ManagePublicLinksModal';

const Option = Select.Option;
const { RangePicker } = DatePicker;

class Reports extends Component {
  constructor(props) {
    super(props);

    this.rangeDatePickerRanges = [
      {
        label: 'Today',
        value: [dayjs(), dayjs()],
      },
      {
        label: 'This Week',
        value: [dayjs().startOf('week'), dayjs().endOf('week')],
      },
      {
        label: 'Last Week',
        value: [
          dayjs()
            .subtract(1, 'week')
            .startOf('week'),
          dayjs()
            .subtract(1, 'week')
            .endOf('week'),
        ],
      },
      {
        label: 'This Month',
        value: [dayjs().startOf('month'), dayjs().endOf('month')],
      },
      {
        label: 'Last Month',
        value: [
          dayjs()
            .subtract(1, 'month')
            .startOf('month'),
          dayjs()
            .subtract(1, 'month')
            .endOf('month'),
        ],
      },
      {
        label: 'This Year',
        value: [dayjs().startOf('year'), dayjs().endOf('year')],
      },
      {
        label: 'Last Year',
        value: [
          dayjs()
            .subtract(1, 'year')
            .startOf('year'),
          dayjs()
            .subtract(1, 'year')
            .endOf('year'),
        ],
      },
    ];

    this.state = {
      clients: [],
      clientsDict: {},
      projects: [],
      projectsDict: {},
      users: [],
      usersDict: {},
      // eslint-disable-next-line
      timesPerDay: [],
      timesPerDaySum: 0,
      timesPerDayChartOptions: {},
      timesPerClient: [],
      timesPerProject: [],
      timesPerUser: [],
      startDate: this.rangeDatePickerRanges[4].value[0],
      endDate: this.rangeDatePickerRanges[4].value[1],
      clientsFilter: [],
      projectsFilter: [],
      usersFilter: [],
      timesGrouper: '',
      timesSummer: 'day',
      times: [],
      timesTables: [],
      hasMore: false,
      nextTimes: null,
      showPublicLinkModal: false,
      publicLinkId: '',
      showManagePublicLinks: false,
      searchFilter: '',
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handleProjectsChange = this.handleProjectsChange.bind(this);
    this.handleClientsChange = this.handleClientsChange.bind(this);
    this.generateTimesTable = this.generateTimesTable.bind(this);

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'user_data',
        key: 'user',
        width: 200,
        render: (user) => (
          <span>
            <UserGravatar email={user.email} /> {user.name}
          </span>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: 120,
      },
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        width: 165,
        render: (text) => <div className="wrapable">{text}</div>,
      },
      {
        title: 'Project',
        dataIndex: 'project',
        key: 'project',
        width: 200,
        render: (text) => <div className="wrapable">{text}</div>,
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: 85,
        align: 'right',
        render: (time) => time.toFixed(2),
      },
      {
        title: 'Task ID',
        dataIndex: 'task',
        key: 'task',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => <div className="wrapable">{text}</div>,
      },
    ];
  }

  componentDidMount() {
    const { publicId } = this.props.match.params;
    if (!publicId) {
      this.updateClients();
      this.loadReportData();
    } else {
      this.props.getPublicLink(publicId);
    }
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps, prevState) {
    const { publicId } = this.props.match.params;
    const { clients, projects, users, times, link } = this.props;
    const prevClientsQuery = prevProps.clients.dashboardPage;
    const clientsQuery = clients.dashboardPage;
    if (
      publicId &&
      link.fetched &&
      prevProps.link.fetchedAt !== link.fetchedAt
    ) {
      const client = {
        id: link.client,
        name: link.client_name,
      };
      const clientsDict = { ver: dayjs() };
      clientsDict[client.id] = client;
      this.setState({
        clientsDict,
        clientsFilter: String(link.client),
        clients: [client],
      });
      this.loadReportData();
    }
    if (
      prevClientsQuery &&
      clientsQuery.clients &&
      prevClientsQuery.fetchedAt !== clientsQuery.fetchedAt
    ) {
      const clientsDict = { ver: dayjs() };
      clientsQuery.clients.results.forEach((value) => {
        clientsDict[value.id] = value;
      });
      this.setState({
        clientsDict,
        clients: clientsQuery.clients.results,
      });
    }
    const prevProjectsQuery = prevProps.projects.dashboardPage;
    const projectsQuery = projects.dashboardPage;
    if (
      prevProjectsQuery &&
      projectsQuery &&
      prevProjectsQuery.fetchedAt !== projectsQuery.fetchedAt
    ) {
      const projectsDict = { ver: dayjs() };
      projectsQuery.projects.results.forEach((project) => {
        projectsDict[project.id] = project;
      });
      this.setState({
        projectsDict,
        projects: projectsQuery.projects.results,
      });
    }
    const prevUsersQuery = prevProps.users.dashboardPage;
    const usersQuery = users.dashboardPage;
    if (
      prevUsersQuery &&
      usersQuery.users &&
      prevUsersQuery.fetchedAt !== usersQuery.fetchedAt
    ) {
      const usersDict = { ver: dayjs() };
      usersQuery.users.results.forEach((user) => {
        usersDict[user.id] = user;
      });
      this.setState({
        usersDict,
        users: usersQuery.users.results,
      });
    }
    const prevTimesPerDayQuery = prevProps.times.timesPerDay;
    const timesPerDayQuery = times.timesPerDay;
    if (
      prevTimesPerDayQuery &&
      timesPerDayQuery.times &&
      prevTimesPerDayQuery.fetchedAt !== timesPerDayQuery.fetchedAt
    ) {
      this.setState({
        // eslint-disable-next-line
        timesPerDay: timesPerDayQuery.times.results,
        timesPerDaySum: timesPerDayQuery.times.results.reduce(
          (a, b) => a + b.total,
          0
        ),
        timesPerDayChartOptions: this.getBarChartOption(
          timesPerDayQuery.times.results
        ),
      });
    }
    const prevTimesPerClientQuery = prevProps.times.timesPerClient;
    const timesPerClientQuery = times.timesPerClient;
    const prevTimesPerClientQueryFetched =
      prevTimesPerClientQuery &&
      prevTimesPerClientQuery.fetchedAt !== timesPerClientQuery.fetchedAt;
    const clientsDictChanged =
      prevState.clientsDict.ver !== this.state.clientsDict.ver;
    if (
      timesPerClientQuery &&
      timesPerClientQuery.fetched &&
      (prevTimesPerClientQueryFetched || clientsDictChanged)
    ) {
      timesPerClientQuery.times.results.forEach((obj) => {
        const client = this.state.clientsDict[obj.client_id] || {};
        /* eslint-disable no-param-reassign */
        obj.name = `${client.name}: ${obj.total.toFixed(2)}h`;
        obj.value = obj.total;
      });
      this.setState({
        timesPerClient: timesPerClientQuery.times.results,
      });
    }
    const prevTimesPerProjectQuery = prevProps.times.timesPerProject;
    const timesPerProjectQuery = times.timesPerProject;
    const timesPerProjectQueryFetched =
      prevTimesPerProjectQuery &&
      prevTimesPerProjectQuery.fetchedAt !== timesPerProjectQuery.fetchedAt;
    const projectsDictChanged =
      prevState.projectsDict.ver !== this.state.projectsDict.ver;
    if (
      timesPerProjectQuery &&
      timesPerProjectQuery.fetched &&
      (timesPerProjectQueryFetched || projectsDictChanged)
    ) {
      timesPerProjectQuery.times.results.forEach((obj) => {
        const project = this.state.projectsDict[obj.project_id] || {};
        obj.name = `${project.name}: ${obj.total.toFixed(2)}h`;
        obj.value = obj.total;
        obj.itemStyle = { color: project.color };
      });
      this.setState({
        timesPerProject: timesPerProjectQuery.times.results,
      });
    }
    const prevTimesPerUserQuery = prevProps.times.timesPerUser;
    const timesPerUserQuery = times.timesPerUser;
    const timesPerUserQueryFetched =
      prevTimesPerUserQuery &&
      prevTimesPerUserQuery.fetchedAt !== timesPerUserQuery.fetchedAt;
    const usersDictChanged =
      prevState.usersDict.ver !== this.state.usersDict.ver;
    if (
      timesPerUserQuery &&
      timesPerUserQuery.fetched &&
      (timesPerUserQueryFetched || usersDictChanged)
    ) {
      timesPerUserQuery.times.results.forEach((obj) => {
        const user = this.state.usersDict[obj.user_id] || {};
        obj.name = `${user.name}: ${obj.total.toFixed(2)}h`;
        obj.value = obj.total;
      });
      this.setState({
        timesPerUser: timesPerUserQuery.times.results,
      });
    }
    const prevTimesQuery = prevProps.times.timesList;
    const timesQuery = times.timesList;
    const timesQueryFetched =
      prevTimesQuery && prevTimesQuery.fetchedAt !== timesQuery.fetchedAt;
    const projectAndClientsDictCreated =
      this.state.clientsDict.ver && this.state.projectsDict.ver;
    const projectOrClientsDict = clientsDictChanged || projectsDictChanged;
    if (
      timesQuery &&
      timesQuery.fetched &&
      projectAndClientsDictCreated &&
      (timesQueryFetched || projectOrClientsDict)
    ) {
      // to setup table Times I need to already have fetched data project, user and clients
      const timesTable = this.generateTimesTable(
        timesQuery.times.results,
        0,
        true
      );
      this.setState({
        times: timesQuery.times.results,
        nextTimes: timesQuery.times.next,
        timesTables: [timesTable],
        hasMore: !!timesQuery.times.next,
      });
    }
    const nextTimesQuery = times.nextTimesList;
    if (
      nextTimesQuery &&
      nextTimesQuery.fetched &&
      projectAndClientsDictCreated
    ) {
      nextTimesQuery.fetched = false;
      if (this.state.times && nextTimesQuery.times) {
        const timesTable = this.generateTimesTable(
          nextTimesQuery.times.results,
          this.state.timesTables.length,
          false
        );
        this.setState((previousState) => ({
          timesTables: [...previousState.timesTables, timesTable],
          nextTimes: nextTimesQuery.times.next,
          hasMore: !!timesQuery.times.next,
        }));
      }
    }
  }

  async getFile() {
    const { publicId } = this.props.match.params;
    const {
      startDate,
      endDate,
      usersFilter,
      clientsFilter,
      projectsFilter,
      searchFilter,
    } = this.state;

    /* eslint-disable camelcase */
    const resp = await get(`/api/v1/get_token`, {}, true).toPromise();
    const token = resp.response.token;
    const params = {
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      user: usersFilter,
      client: clientsFilter,
      project: projectsFilter,
      public: publicId,
      deleted: 'False',
      jwt: token,
    };
    if (searchFilter) {
      /* eslint-disable dot-notation */
      params['search'] = searchFilter;
    }

    const url = `${window.__API__}/api/v1/csv/time?${queryString.stringify(
      params
    )}`;

    window.location.replace(url);
  }

  getNextTimes() {
    const { nextTimes } = this.state;
    if (!nextTimes) return;
    this.props.fetchNextTimes(
      {
        url: nextTimes,
      },
      'nextTimesList'
    );
  }

  getBarChartGroupData(value) {
    const { timesGrouper } = this.state;
    switch (timesGrouper) {
      case 'project':
        /* eslint-disable no-case-declarations */
        const project = this.getProject(value.project_id);
        return {
          key: value.project_id,
          label: project.name,
          itemStyle: { color: project.color },
        };
      case 'client':
        const client = this.getClient(value.client_id);
        return {
          key: value.client_id,
          label: client.name,
        };
      case 'employee':
        const employee = this.getUser(value.user_id);
        return {
          key: value.user_id,
          label: employee.name,
        };
      default:
        return {
          key: 'Hours',
          label: 'Hours',
          itemStyle: { color: null },
        };
    }
  }

  getXAxisKey(value) {
    const { timesSummer } = this.state;
    const { date, week, month, year } = value;
    switch (timesSummer) {
      case 'week':
        return `Week ${week}, ${year}`;
      case 'month':
        return dayjs(`${year}-${month}`).format('MMMM, YYYY');
      default:
        return date;
    }
  }

  getBarChartOption(timesPerDay) {
    const xAxis = [...new Set(timesPerDay.map((x) => this.getXAxisKey(x)))];
    const seriesDict = {};

    timesPerDay.forEach((value) => {
      const { key, label, itemStyle } = this.getBarChartGroupData(value);

      // initialize Object key if not set
      if (!seriesDict[key]) {
        seriesDict[key] = {
          itemStyle,
          label,
          data: new Array(xAxis.length),
        };
      }

      const index = xAxis.indexOf(this.getXAxisKey(value));
      seriesDict[key].data[index] = {
        value: value.total,
      };
    });

    // generate options for ECharts
    return {
      tooltip: {
        trigger: 'axis',
        formatter: (objArray) => {
          return (
            // eslint-disable-next-line
            `<div>${objArray[0].name}</div>` +
            objArray
              .filter((obj) => obj.data)
              .map((obj) => {
                return `<div>${obj.marker}${
                  obj.seriesName
                }: ${obj.value.toFixed(2)}`;
              })
              .join('')
          );
        },
      },
      xAxis: {
        data: xAxis,
      },
      legend: {
        display: true,
      },
      yAxis: {},
      series: Object.values(seriesDict).map(({ itemStyle, label, data }) => {
        return {
          name: label,
          type: 'bar',
          stack: 'group',
          data,
          itemStyle,
        };
      }),
    };
  }

  getProject = (id) => {
    const { projectsDict } = this.state;
    if (id in projectsDict) {
      return projectsDict[id];
    }
    return {};
  };

  getUser = (id) => {
    const { usersDict } = this.state;
    if (id in usersDict) {
      return usersDict[id];
    }
    return {};
  };

  getClient = (id) => {
    const { clientsDict } = this.state;
    if (id in clientsDict) {
      return clientsDict[id];
    }
    return {};
  };

  getPieChartOption(data) {
    return {
      tooltip: {
        trigger: 'item',
        formatter: (obj) => `${obj.marker} ${obj.name}`,
      },
      legend: {
        orient: 'horizontal',
        x: 'left',
        type: 'scroll',
        top: 270,
        textStyle: {
          color:
            userTheme(this.props.me.data?.theme) === 'dark_mode'
              ? 'rgba(255,255,255,0.85)'
              : 'rgba(0,0,0,0.65',
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: 'center',
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '15',
                fontWeight: 'bold',
              },
              formatter: (obj) => obj.data.name.split(':')[0],
            },
          },
          data,
        },
      ],
    };
  }

  handleGrouperChange = (timesGrouper) => {
    this.setState(
      {
        timesGrouper,
      },
      () => {
        this.updateTimesPerDay();
      }
    );
  };

  handleSummerChange = (timesSummer) => {
    this.setState(
      {
        timesSummer,
      },
      () => {
        this.updateTimesPerDay();
      }
    );
  };

  handleUsersChange(usersFilter) {
    this.setState(
      {
        usersFilter,
      },
      () => {
        this.updateTimesPerDay();
        this.updateTimesPerClient();
        this.updateTimesPerProject();
        this.updateTimesPerUser();
        this.updateTimes();
      }
    );
  }

  handleProjectsChange(projectsFilter) {
    this.setState(
      {
        projectsFilter,
      },
      () => {
        this.updateTimesPerDay();
        this.updateTimesPerClient();
        this.updateTimesPerProject();
        this.updateTimesPerUser();
        this.updateTimes();
        this.updateUsers();
      }
    );
  }

  handleClientsChange(clientsFilter) {
    this.setState(
      {
        clientsFilter,
      },
      () => {
        this.updateProjects();
        this.updateTimesPerDay();
        this.updateTimesPerClient();
        this.updateTimesPerProject();
        this.updateTimesPerUser();
        this.updateTimes();
        this.updateUsers();
      }
    );
  }

  handleDateChange(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        this.updateTimesPerClient();
        this.updateTimesPerDay();
        this.updateTimesPerProject();
        this.updateTimesPerUser();
        this.updateTimes();
      }
    );
  }

  handleSearch(value) {
    this.setState(
      {
        searchFilter: value,
      },
      () => {
        this.updateTimesPerClient();
        this.updateTimesPerDay();
        this.updateTimesPerProject();
        this.updateTimesPerUser();
        this.updateTimes();
      }
    );
  }

  updateTimesPerUser() {
    const { publicId } = this.props.match.params;
    const {
      startDate,
      endDate,
      usersFilter,
      clientsFilter,
      projectsFilter,
      searchFilter,
    } = this.state;
    const params = {
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      sum: 'user',
      user: usersFilter,
      client: clientsFilter,
      project: projectsFilter,
      public: publicId,
      deleted: 'False',
    };
    if (searchFilter) {
      params['search'] = searchFilter;
    }
    this.props.fetchTimes(params, 'timesPerUser');
  }

  updateTimes() {
    const { publicId } = this.props.match.params;
    const {
      startDate,
      endDate,
      usersFilter,
      clientsFilter,
      projectsFilter,
      searchFilter,
    } = this.state;
    const params = {
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      user: usersFilter,
      client: clientsFilter,
      project: projectsFilter,
      public: publicId,
      deleted: 'False',
      ordering: '-date',
    };
    if (searchFilter) {
      params['search'] = searchFilter;
    }

    this.props.fetchTimes(params, 'timesList', false);
  }

  updateTimesPerProject() {
    const { publicId } = this.props.match.params;
    const {
      startDate,
      endDate,
      usersFilter,
      clientsFilter,
      projectsFilter,
      searchFilter,
    } = this.state;
    const params = {
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      sum: 'project',
      user: usersFilter,
      client: clientsFilter,
      project: projectsFilter,
      public: publicId,
      deleted: 'False',
    };
    if (searchFilter) {
      params['search'] = searchFilter;
    }
    this.props.fetchTimes(params, 'timesPerProject');
  }

  updateTimesPerClient() {
    const { publicId } = this.props.match.params;
    const {
      startDate,
      endDate,
      usersFilter,
      clientsFilter,
      projectsFilter,
      searchFilter,
    } = this.state;
    const params = {
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      sum: 'client',
      user: usersFilter,
      client: clientsFilter,
      project: projectsFilter,
      public: publicId,
      deleted: 'False',
    };
    if (searchFilter) {
      params['search'] = searchFilter;
    }
    this.props.fetchTimes(params, 'timesPerClient');
  }

  updateTimesPerDay() {
    const { publicId } = this.props.match.params;
    const {
      startDate,
      endDate,
      usersFilter,
      clientsFilter,
      projectsFilter,
      searchFilter,
      timesGrouper,
      timesSummer,
    } = this.state;
    const params = {
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      sum: timesSummer,
      group: timesGrouper,
      user: usersFilter,
      client: clientsFilter,
      project: projectsFilter,
      public: publicId,
      deleted: 'False',
    };
    if (searchFilter) {
      params['search'] = searchFilter;
    }
    this.props.fetchTimes(params, 'timesPerDay');
  }

  updateUsers() {
    const { clientsFilter, projectsFilter } = this.state;
    const { publicId } = this.props.match.params;
    this.props.fetchUsers(
      {
        public: publicId,
        project: [projectsFilter],
        client: [clientsFilter],
      },
      'dashboardPage'
    );
  }

  updateClients() {
    this.props.fetchClients({}, 'dashboardPage');
  }

  updateProjects() {
    const { clientsFilter } = this.state;
    const { publicId } = this.props.match.params;
    this.props.fetchProjects(
      {
        client: clientsFilter,
        public: publicId,
      },
      'dashboardPage'
    );
  }

  generateTimesTable(data, key, first) {
    const { clientsDict, projectsDict } = this.state;
    const formattedTimes = data.map((value) => {
      const newValue = { ...value };
      const project = projectsDict[value.project] || {};
      const client = clientsDict[project.client] || {};
      newValue.project = project.name;
      newValue.client = client.name;
      return newValue;
    });
    return (
      <Table
        key={key}
        columns={this.columns}
        showHeader={first}
        dataSource={formattedTimes}
        pagination={false}
        rowKey="id"
      />
    );
  }

  loadReportData() {
    this.updateTimesPerDay();
    this.updateTimesPerClient();
    this.updateTimesPerProject();
    this.updateTimesPerUser();
    this.updateTimes();
    this.updateUsers();
    this.updateProjects();
  }

  loadFunc() {
    this.setState({ hasMore: false });
    this.getNextTimes();
  }

  async generatePublicLink() {
    const { clientsFilter } = this.state;
    const publicLink = await get(
      `/api/v1/public_links/manage?client=${clientsFilter[0]}`,
      {},
      true
    ).toPromise();
    if (publicLink.status === 200) {
      this.setState({
        showPublicLinkModal: true,
        publicLinkId: publicLink.response.id,
      });
    }
  }

  managePublicLinks() {
    this.setState({
      showManagePublicLinks: true,
    });
  }

  renderManagePublicLinks() {
    const { me } = this.props;
    if (!me.data) {
      return '';
    }
    const permissions = me.data.user_permissions.map((value) => {
      return value.codename;
    });
    const noNeededPerms =
      permissions.indexOf('public_links.change_reportlink') === -1;
    if (!me.data.is_superuser && noNeededPerms) {
      return '';
    }
    return (
      // eslint-disable-next-line
      <Link to="#" onClick={() => this.managePublicLinks()}>
        Manage public links
      </Link>
    );
  }

  renderSpecialLinks() {
    const csv = this.renderCSVUrl();
    const publicLink = this.renderGeneratePublicLink();
    const managePublicLinks = this.renderManagePublicLinks();
    const links = [];
    if (csv) {
      links.push(csv);
    }
    if (publicLink) {
      links.push(publicLink);
    }
    if (managePublicLinks) {
      links.push(managePublicLinks);
    }
    return (
      <div>
        {links
          .map((item, i) => {
            // eslint-disable-next-line
            return <span key={i}>{item}</span>;
          })
          .reduce((prev, curr) => [prev, ' | ', curr])}
      </div>
    );
  }

  renderCSVUrl() {
    const { publicId } = this.props.match.params;

    if (!publicId)
      return (
        // eslint-disable-next-line
        <Link to="#" onClick={this.getFile.bind(this)}>
          CSV
        </Link>
      );

    const {
      startDate,
      endDate,
      usersFilter,
      clientsFilter,
      projectsFilter,
      searchFilter,
    } = this.state;

    const params = {
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      user: usersFilter,
      client: clientsFilter,
      project: projectsFilter,
      public: publicId,
      deleted: 'False',
    };
    if (searchFilter) {
      params['search'] = searchFilter;
    }

    const url = `${window.__API__}/api/v1/csv/time?${queryString.stringify(
      params
    )}`;
    return <a href={url}>CSV</a>;
  }
  // eslint-disable-next-line
  renderGeneratePublicLink() {
    const { publicId } = this.props.match.params;
    const { me } = this.props;
    if (!me.data) {
      return '';
    }
    const permissions = me.data.user_permissions.map((value) => {
      return value.codename;
    });
    const noNeededPerms =
      permissions.indexOf('public_links.get_reportlink') === -1;
    if (!me.data.is_superuser && noNeededPerms) {
      return '';
    }
    if (this.state.clientsFilter.length === 1 && !publicId) {
      return (
        // eslint-disable-next-line
        <Link to="#" onClick={() => this.generatePublicLink()}>
          Generate public link
        </Link>
      );
    }
  }

  renderClientOptions() {
    const { clients } = this.state;
    const { publicId } = this.props.match.params;
    /* eslint-disable prefer-template */
    return clients
      .sort((a, b) => ('' + a.name).localeCompare(b.name))
      .map((value) => {
        if (value.active || publicId) {
          return (
            <Option value={String(value.id)} key={value.id} selected>
              {value.name}
            </Option>
          );
        }
        return '';
      });
  }

  render() {
    const {
      projects,
      users,
      timesTables,
      showPublicLinkModal,
      publicLinkId,
      clientsFilter,
      hasMore,
      timesPerDaySum,
      timesPerDayChartOptions,
      timesPerClient,
      timesPerProject,
      timesPerUser,
      timesSummer,
      showManagePublicLinks,
      startDate,
      endDate,
      clients,
    } = this.state;
    const { publicId } = this.props.match.params;
    const { link } = this.props;
    const baseUrl = window.location.protocol + '//' + window.location.host;

    if (publicId && (!link || !link.fetched)) {
      if (link && !link.fetching) {
        return (
          <Alert
            message="Public link is invalid or archived"
            type="error"
            showIcon
          />
        );
      }
      return (
        <div>
          <Row type="flex" justify="center">
            <Spin />
          </Row>
        </div>
      );
    }

    return (
      <Loader
        loading={
          projects.length === 0 || users.length === 0 || clients.length === 0
        }
      >
        <Helmet title="Reports" />
        <Card>
          <Modal
            onOk={() => this.setState({ showPublicLinkModal: false })}
            onCancel={() => this.setState({ showPublicLinkModal: false })}
            open={showPublicLinkModal}
          >
            <Link to={`/public/reports/${publicLinkId}`}>
              {baseUrl}/public/reports/{publicLinkId}
            </Link>
          </Modal>
          <ManagePublicLinksModal
            open={showManagePublicLinks}
            onCancel={() => this.setState({ showManagePublicLinks: false })}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => this.setState({ showManagePublicLinks: false })}
              >
                Close
              </Button>,
            ]}
          />
          <Row gutter={16} className="search-operations">
            <Col md={24}>
              <Input.Search
                className="group-select"
                onSearch={this.handleSearch}
                placeholder="Description or Task ID"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={6}>
              <Select
                placeholder="Clients"
                mode="tags"
                className="group-select"
                onChange={this.handleClientsChange}
                disabled={!!publicId}
                value={clientsFilter}
                showSearch
                filterOption={(input, option) => {
                  const fuse = new Fuse(this.state.clients, {
                    keys: ['name'],
                    threshold: 0.2,
                  });
                  const results = fuse.search(input);
                  return results
                    .map((res) => res.item.name)
                    .includes(option.props.children);
                }}
              >
                {this.renderClientOptions()}
              </Select>
            </Col>
            <Col md={6}>
              <ProjectPicker
                placeholder="Projects"
                mode="tags"
                className="group-select"
                onChange={this.handleProjectsChange}
                projects={projects}
              />
            </Col>
            <Col md={6}>
              <Select
                placeholder="Users"
                mode="tags"
                className="group-select"
                onChange={this.handleUsersChange}
                showSearch
                filterOption={(input, option) => {
                  const fuse = new Fuse(users, {
                    keys: ['name'],
                    threshold: 0.2,
                  });
                  const results = fuse.search(input);
                  return results
                    .map((res) => res.item.name)
                    .includes(option.props.children);
                }}
              >
                {users
                  .sort((a, b) => ('' + a.name.attr).localeCompare(b.name.attr))
                  .map((value) => {
                    return (
                      <Option value={String(value.id)} key={value.id}>
                        {value.name}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col md={6}>
              <RangePicker
                presets={this.rangeDatePickerRanges}
                defaultValue={[startDate, endDate]}
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Col>
          </Row>
          <Row gutter={16} className="filter-operations">
            <Col md={12}>{this.renderSpecialLinks()}</Col>
            <Col md={6}>
              <Select
                value={timesSummer}
                className="group-select"
                onChange={this.handleSummerChange}
              >
                <Option value="day">Daily</Option>
                <Option value="week">Weekly</Option>
                <Option value="month">Monthly</Option>
              </Select>
            </Col>
            <Col md={6}>
              <Select
                placeholder="Group by..."
                allowClear
                className="group-select"
                onChange={this.handleGrouperChange}
              >
                <Option value="client">Client</Option>
                <Option value="project">Project</Option>
                <Option value="employee">Employee</Option>
              </Select>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <h2>Total hours: {timesPerDaySum.toFixed(2)}</h2>
          </Row>
          <ReactEcharts option={timesPerDayChartOptions} notMerge lazyUpdate />
        </Card>
        <div className="card-container">
          <Tabs
            defaultActiveKey="1"
            onChange={this.handleTabChange}
            type="card"
            className="overflow-x-scroll"
            items={[
              {
                label: 'Times',
                children: (
                  <InfiniteScroll
                    // eslint-disable-next-line
                    loadMore={this.loadFunc.bind(this)}
                    hasMore={hasMore}
                  >
                    <div className="tab-table">{timesTables}</div>
                  </InfiniteScroll>
                ),
                key: '0',
              },
              {
                label: 'Clients',
                key: '1',
                children: (
                  <ReactEcharts
                    option={this.getPieChartOption(timesPerClient)}
                  />
                ),
              },
              {
                label: 'Projects',
                key: '2',
                children: (
                  <ReactEcharts
                    option={this.getPieChartOption(timesPerProject)}
                  />
                ),
              },
              {
                label: 'Users',
                key: '3',
                children: (
                  <ReactEcharts option={this.getPieChartOption(timesPerUser)} />
                ),
              },
            ]}
          />
        </div>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  const { clients, projects, users, times, me } = state;
  const { link } = state.publicLinks;
  return {
    link,
    clients,
    projects,
    users,
    times,
    me,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPublicLink: (id) => dispatch(publicLinksActions.getPublicLink(id)),
    fetchProjects: (params, storeId) =>
      dispatch(projectsActions.fetchProjectsRequest(params, storeId)),
    fetchClients: (params, storeId) =>
      dispatch(clientsActions.fetchClientsRequest(params, storeId)),
    fetchUsers: (params, storeId) =>
      dispatch(usersActions.fetchUsersRequest(params, storeId)),
    fetchTimes: (params, storeId, fetchAll = true) =>
      dispatch(timesActions.fetchTimesRequest(params, storeId, fetchAll)),
    fetchNextTimes: (params, storeId) =>
      dispatch(timesActions.fetchNextTimesRequest(params, storeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
