const domainsConstants = {
  FETCH_DOMAINS_REQUEST: 'DOMAINS_FETCH_DOMAINS_REQUEST',
  FETCH_DOMAINS_SUCCESS: 'DOMAINS_FETCH_DOMAINS_SUCCESS',
  FETCH_DOMAINS_FAILURE: 'DOMAINS_FETCH_DOMAINS_FAILURE',
  CREATE_DOMAIN_REQUEST: 'DOMAINS_CREATE_DOMAIN_REQUEST',
  CREATE_DOMAIN_SUCCESS: 'DOMAINS_CREATE_DOMAIN_SUCCESS',
  CREATE_DOMAIN_FAILURE: 'DOMAINS_CREATE_DOMAIN_FAILURE',
  DELETE_DOMAIN_REQUEST: 'DOMAINS_DELETE_DOMAIN_REQUEST',
  DELETE_DOMAIN_SUCCESS: 'DOMAINS_DELETE_DOMAIN_SUCCESS',
  DELETE_DOMAIN_FAILURE: 'DOMAINS_DELETE_DOMAIN_FAILURE',
};

export default domainsConstants;
