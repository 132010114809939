const clientsConstants = {
  FETCH_CLIENTS_REQUEST: 'CLIENTS_FETCH_CLIENTS_REQUEST',
  FETCH_CLIENTS_SUCCESS: 'CLIENTS_FETCH_CLIENTS_SUCCESS',
  FETCH_CLIENTS_FAILURE: 'CLIENTS_FETCH_CLIENTS_FAILURE',
  DELETE_CLIENT_REQUEST: 'CLIENTS_DELETE_CLIENT_REQUEST',
  DELETE_CLIENT_SUCCESS: 'CLIENTS_DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_FAILURE: 'CLIENTS_DELETE_CLIENT_FAILURE',
  PATCH_CLIENT_REQUEST: 'CLIENTS_PATCH_CLIENT_REQUEST',
  PATCH_CLIENT_SUCCESS: 'CLIENTS_PATCH_CLIENT_SUCCESS',
  PATCH_CLIENT_FAILURE: 'CLIENTS_PATCH_CLIENT_FAILURE',
  CREATE_CLIENT_SUCCESS: 'CLIENTS_CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_REQUEST: 'CLIENTS_CREATE_CLIENT_REQUEST',
  CREATE_CLIENT_FAILURE: 'CLIENTS_CREATE_CLIENT_FAILURE',
};

export default clientsConstants;
