import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';
import { remove, patch, post, get, getUrl } from 'helpers/ajax';
import timesActions from 'actions/times.actions';
import timesConstants from '../constants/times.constants';

function* deleteTimeRequest(action) {
  try {
    const id = action.id;
    const ajax = remove(
      `/api/v1/times/${id}?${queryString.stringify(action.params)}`,
      {},
      true
    );
    yield call([ajax, ajax.toPromise]);
    yield put({ type: timesConstants.DELETE_TIME_SUCCESS, id });
    yield put({
      type: timesConstants.FETCH_TIMES_REQUEST,
      params: action.params,
      storeId: action.storeId,
    });
    yield put({
      type: 'PRESENCES_SEND_PRESENCE_TICK_REQUEST',
    });
  } catch (err) {
    yield put({ type: timesConstants.DELETE_TIME_FAILURE, error: err });
  }
}

function* createTimeRequest(action) {
  try {
    const data = action.data;
    const ajax = post('/api/v1/times', data, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put({ type: timesConstants.CREATE_TIME_SUCCESS });
    yield put({
      type: timesConstants.FETCH_TIMES_REQUEST,
      params: action.params,
      storeId: action.storeId,
    });
    yield put({
      type: 'PRESENCES_SEND_PRESENCE_TICK_REQUEST',
    });
  } catch (err) {
    yield put({ type: timesConstants.CREATE_TIME_FAILURE, error: err });
  }
}

function* patchTimeRequest(action) {
  try {
    const data = action.data;
    const id = action.id;
    const ajax = patch(`/api/v1/times/${id}`, data, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: timesConstants.FETCH_TIMES_REQUEST,
      params: action.params,
      storeId: action.storeId,
    });
    yield put({
      type: 'PRESENCES_SEND_PRESENCE_TICK_REQUEST',
    });
  } catch (err) {
    yield put({ type: timesConstants.PATCH_TIME_FAILURE, error: err });
  }
}

function* fetchTimesRequest(action) {
  const params = queryString.stringify(action.params);
  const storeId = action.storeId;
  const fetchAll = action.fetchAll;
  try {
    let ajax = get(`/api/v1/times?${params}`, {}, true);
    let resp = yield call([ajax, ajax.toPromise]);
    const results = resp.response.results;

    while (resp.response.next && fetchAll) {
      ajax = getUrl(resp.response.next, {}, true);
      resp = yield call([ajax, ajax.toPromise]);
      results.push(...resp.response.results);
    }
    resp.response.results = results;
    yield put(timesActions.fetchTimesSuccess(resp.response, storeId));
  } catch (err) {
    yield put(timesActions.fetchTimesFailure(err, storeId));
  }
}

function* timesSaga() {
  yield takeLatest(timesConstants.DELETE_TIME_REQUEST, deleteTimeRequest);
  yield takeLatest(timesConstants.CREATE_TIME_REQUEST, createTimeRequest);
  yield takeLatest(timesConstants.PATCH_TIME_REQUEST, patchTimeRequest);
  yield takeEvery(timesConstants.FETCH_TIMES_REQUEST, fetchTimesRequest);
}

export default timesSaga;
