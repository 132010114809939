import locationsConstants from 'constants/locations.constants';

function fetchLocationsRequest(params) {
  return {
    type: locationsConstants.FETCH_LOCATIONS_REQUEST,
    params,
  };
}

function fetchLocationsSuccess(locations) {
  return {
    type: locationsConstants.FETCH_LOCATIONS_SUCCESS,
    locations,
  };
}

function fetchLocationsFailure(error) {
  return {
    type: locationsConstants.FETCH_LOCATIONS_FAILURE,
    error,
  };
}

function createLocationRequest(data) {
  return {
    type: locationsConstants.CREATE_LOCATION_REQUEST,
    data,
  };
}

function createLocationSuccess(data) {
  return {
    type: locationsConstants.CREATE_LOCATION_SUCCESS,
    data,
  };
}

function createLocationFailure(error) {
  return {
    type: locationsConstants.CREATE_LOCATION_FAILURE,
    error,
  };
}

function deleteLocationRequest(id) {
  return {
    type: locationsConstants.DELETE_LOCATION_REQUEST,
    id,
  };
}

function deleteLocationSuccess(id) {
  return {
    type: locationsConstants.DELETE_LOCATION_SUCCESS,
    id,
  };
}

function deleteLocationFailure(error) {
  return {
    type: locationsConstants.DELETE_LOCATION_FAILURE,
    error,
  };
}

const locationsActions = {
  fetchLocationsRequest,
  fetchLocationsSuccess,
  fetchLocationsFailure,
  createLocationRequest,
  createLocationSuccess,
  createLocationFailure,
  deleteLocationRequest,
  deleteLocationSuccess,
  deleteLocationFailure,
};

export default locationsActions;
