import { v4 as uuid } from 'uuid';
import alertsConstants from 'constants/alerts.constants';

export default function alertsReducer(state = [], action) {
  switch (action.type) {
    case alertsConstants.ADD_ALERT:
      return [
        ...state,
        {
          text: action.text,
          style: action.style,
          id: uuid(),
        },
      ];

    case alertsConstants.REMOVE_ALERT:
      return state.filter((alert) => {
        if (alert.id === action.id) {
          return false;
        }
        return true;
      });

    default:
      return state;
  }
}
