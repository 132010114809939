// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X8y0mmMFvICRk8mkZ0LS {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}
.MmZ3njMTyHh_LDLxYAPY {
  text-align: right;
  width: 100%;
  height: 40px;
  line-height: 44px;
}
.MmZ3njMTyHh_LDLxYAPY .ant-dropdown-trigger {
  margin-right: 24px;
}
.vu3NYvo0ej_QTAKHxUh1 {
  padding: 32px 0;
  flex: 1;
}
@media (min-width: 768px) {
  .X8y0mmMFvICRk8mkZ0LS {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
  .vu3NYvo0ej_QTAKHxUh1 {
    padding: 32px 0 24px 0;
  }
}
.xCCwvcwzz7I8E4O0U_Ec {
  text-align: center;
}
.ZuU5ESvMHDPIENrQyOQz {
  height: 44px;
  line-height: 44px;
}
.ZuU5ESvMHDPIENrQyOQz a {
  text-decoration: none;
}
.l_aLINtZKeMnx8fDPCQl {
  height: 44px;
  vertical-align: top;
  margin-right: 16px;
}
.ImqBWPBgwslI0F8ljE9x {
  font-size: 33px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.qcUkRo55RemNNOQqzooc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/layout/AuthLayout.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;AAAF;AAGA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AADF;AAHA;EAMI,kBAAA;AAAJ;AAIA;EACE,eAAA;EACA,OAAA;AAFF;AAKA;EACE;IACE,4FAAA;IACA,4BAAA;IACA,iCAAA;IACA,qBAAA;EAHF;EAMA;IACE,sBAAA;EAJF;AACF;AAOA;EACE,kBAAA;AALF;AAQA;EACE,YAAA;EACA,iBAAA;AANF;AAIA;EAII,qBAAA;AALJ;AASA;EACE,YAAA;EACA,mBAAA;EACA,kBAAA;AAPF;AAUA;EACE,eAAA;EACA,0BAAA;EACA,mEAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;AARF;AAWA;EACE,eAAA;EACA,0BAAA;EACA,gBAAA;EACA,mBAAA;AATF","sourcesContent":["@import '../variables.less';\n.container {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  overflow: auto;\n  background: #f0f2f5;\n}\n\n.lang {\n  text-align: right;\n  width: 100%;\n  height: 40px;\n  line-height: 44px;\n  :global(.ant-dropdown-trigger) {\n    margin-right: 24px;\n  }\n}\n\n.content {\n  padding: 32px 0;\n  flex: 1;\n}\n\n@media (min-width: @screen-md-min) {\n  .container {\n    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');\n    background-repeat: no-repeat;\n    background-position: center 110px;\n    background-size: 100%;\n  }\n\n  .content {\n    padding: 32px 0 24px 0;\n  }\n}\n\n.top {\n  text-align: center;\n}\n\n.header {\n  height: 44px;\n  line-height: 44px;\n  a {\n    text-decoration: none;\n  }\n}\n\n.logo {\n  height: 44px;\n  vertical-align: top;\n  margin-right: 16px;\n}\n\n.title {\n  font-size: 33px;\n  color: rgba(0, 0, 0, 0.85);\n  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;\n  font-weight: 600;\n  position: relative;\n  top: 2px;\n}\n\n.desc {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.45);\n  margin-top: 12px;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `X8y0mmMFvICRk8mkZ0LS`,
	"lang": `MmZ3njMTyHh_LDLxYAPY`,
	"content": `vu3NYvo0ej_QTAKHxUh1`,
	"top": `xCCwvcwzz7I8E4O0U_Ec`,
	"header": `ZuU5ESvMHDPIENrQyOQz`,
	"logo": `l_aLINtZKeMnx8fDPCQl`,
	"title": `ImqBWPBgwslI0F8ljE9x`,
	"desc": `qcUkRo55RemNNOQqzooc`
};
export default ___CSS_LOADER_EXPORT___;
