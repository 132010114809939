const rolesConstants = {
  FETCH_ROLES_REQUEST: 'ROLES_FETCH_ROLES_REQUEST',
  FETCH_ROLES_SUCCESS: 'ROLES_FETCH_ROLES_SUCCESS',
  FETCH_ROLES_FAILURE: 'ROLES_FETCH_ROLES_FAILURE',
  CREATE_ROLE_REQUEST: 'ROLES_CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'ROLES_CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'ROLES_CREATE_ROLE_FAILURE',
  DELETE_ROLE_REQUEST: 'ROLES_DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'ROLES_DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'ROLES_DELETE_ROLE_FAILURE',
};

export default rolesConstants;
