import raportConstants from 'constants/raport.constants';

export default function raport(state = {}, action) {
  switch (action.type) {
    case raportConstants.FETCH_RAPORT_REQUEST: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        params: action.params,
        fetching: true,
      };
      return newState;
    }

    case raportConstants.FETCH_RAPORT_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        raport: action.raport,
        fetching: false,
        fetched: true,
      };
      return newState;
    }

    case raportConstants.FETCH_RAPORT_FAILURE: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        fetching: false,
        error: action.error,
      };
      return newState;
    }
    default:
      return state;
  }
}
