import {
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  endOfYear,
} from 'date-fns';

const getMaxPeriodNumber = (values) => {
  const endDate = values.dateRange?.[1] || values.datePick;
  const { period, frequency } = values;

  if (!endDate || !period || !frequency) {
    return 2;
  }

  const weeksUntilEndOfYear = Math.floor(
    differenceInCalendarWeeks(endOfYear(new Date(endDate)), new Date(endDate), {
      weekStartsOn: 1,
    }) / frequency
  );
  const monthsUntilEndOfYear = Math.floor(
    (differenceInCalendarMonths(
      endOfYear(new Date(endDate)),
      new Date(endDate)
    ) +
      1) /
      frequency
  );
  // eslint-disable-next-line
  return period === 'week'
    ? weeksUntilEndOfYear === 0
      ? 1
      : weeksUntilEndOfYear
    : monthsUntilEndOfYear === 0
    ? 1
    : monthsUntilEndOfYear;
};

export default getMaxPeriodNumber;
