import dayjs from 'dayjs';
import projectsConstants from 'constants/projects.constants';

/* eslint-disable prefer-object-spread */
export default function projects(state = {}, action) {
  switch (action.type) {
    case projectsConstants.FETCH_PROJECTS_REQUEST: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        params: action.params,
        fetching: true,
      };
      return Object.assign({}, state, newState);
    }
    case projectsConstants.FETCH_PROJECTS_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        projects: action.projects,
        fetching: false,
        fetched: true,
        fetchedAt: dayjs(),
      };
      return Object.assign({}, state, newState);
    }
    case projectsConstants.FETCH_PROJECTS_FAILURE: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        fetching: false,
        projects: {},
      };
      return Object.assign({}, state, newState);
    }
    case projectsConstants.DELETE_PROJECT_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      });

    case projectsConstants.DELETE_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      });

    case projectsConstants.DELETE_PROJECT_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
      });

    case projectsConstants.PATCH_PROJECT_REQUEST:
      return Object.assign({}, state, {
        patching: true,
      });

    case projectsConstants.PATCH_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        patching: false,
      });

    case projectsConstants.PATCH_PROJECT_FAILURE:
      return Object.assign({}, state, {
        patching: false,
      });

    case projectsConstants.CREATE_PROJECT_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      });

    case projectsConstants.CREATE_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
      });

    case projectsConstants.CREATE_PROJECT_FAILURE:
      return Object.assign({}, state, {
        creating: false,
      });

    default:
      return state;
  }
}
