import usersActions from 'actions/users.actions';
import { Button, Col, Row, Select } from 'antd';
import Fuse from 'fuse.js';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import './DaysOff.css';
import styles from './DaysOff.less';
import DaysOff from './containers/DaysOff';
import DaysOffAllowances from './containers/DaysOffAllowances';

const Option = Select.Option;

class DaysOffPage extends Component {
  // eslint-disable-next-line
  displayName = 'DaysOff';

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      usersFilter: [],
      showPupils: false,
    };

    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handleInactiveFilterChange = this.handleInactiveFilterChange.bind(
      this
    );
  }

  componentDidMount() {
    this.props.fetchUsers({ active: true });
    const params = new URLSearchParams(this.props.location.search);
    const userParams = params.getAll('user');
    if (!userParams) {
      return;
    }
    this.setState({ usersFilter: userParams });
  }

  componentDidUpdate(prevProps, prevState) {
    const { users } = this.props;
    const prevUsersQuery = prevProps.users.daysOffPage;
    const usersQuery = users.daysOffPage;

    if (
      prevUsersQuery &&
      usersQuery.users &&
      prevUsersQuery.fetchedAt !== usersQuery.fetchedAt
    ) {
      // eslint-disable-next-line
      this.setState({
        users: usersQuery.users.results,
      });
    }

    if (prevState.usersFilter !== this.state.usersFilter) {
      const queryParams = new URLSearchParams(this.props.location.search);
      queryParams.delete('user');
      this.state.usersFilter.forEach((user) => {
        queryParams.append('user', user);
      });

      this.props.history.push({
        search: queryParams.toString(),
      });
    }
  }

  togglePupils = () => {
    if (this.state.showPupils) {
      this.setState({ usersFilter: [], showPupils: false });
      return;
    }
    const { me } = this.props;
    const { users } = this.state;
    const leaderPupils = users.filter((user) => user.leader === me.data.name);
    this.setState({
      usersFilter: leaderPupils.map((pupil) => `${pupil.id}`),
      showPupils: true,
    });
  };

  handleInactiveFilterChange(showInactive) {
    if (showInactive) this.props.fetchUsers({ active: undefined });
    else this.props.fetchUsers({ active: true });
  }

  handleUsersChange(usersFilter) {
    this.setState({
      usersFilter,
    });
  }

  render() {
    const { users, usersFilter, showPupils } = this.state;
    const { me, history, location } = this.props;

    const isLeader = me.data.groups.find((group) => group.name === 'Leaders');
    let changeButtonData = {
      href: '/days-off/allowances',
      display: 'User view',
    };
    if (this.props.location.pathname.includes('allowances')) {
      changeButtonData = {
        href: '/days-off',
        display: 'Days Off',
      };
    }
    if (this.state.usersFilter.length > 0) {
      const queryParams = new URLSearchParams();
      this.state.usersFilter.forEach((user) => {
        queryParams.append('user', user);
      });
      changeButtonData.href += `?${queryParams.toString()}`;
    }
    return (
      <div className="days-off">
        <Helmet title="Days Off" />
        <Row gutter={16}>
          <Col md={6} span={12}>
            <Select
              value={usersFilter}
              placeholder="Users"
              mode="tags"
              className="group-select"
              onChange={this.handleUsersChange}
              filterOption={(input, option) => {
                const fuse = new Fuse(users, {
                  keys: ['name'],
                  threshold: 0.3, // Adjust this value for desired fuzziness
                });
                const results = fuse.search(input);
                return results
                  .map((res) => res.item.name)
                  .includes(option.props.children);
              }}
            >
              {users
                // eslint-disable-next-line
                .sort((a, b) => ('' + a.name).localeCompare(b.name))
                .map((value) => {
                  return (
                    <Option value={String(value.id)} key={value.id}>
                      {value.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          <Col md={6} span={12}>
            {isLeader && (
              <Button className={styles.pupils} onClick={this.togglePupils}>
                {showPupils ? 'All' : 'My teams'}
              </Button>
            )}
            <Button
              onClick={() =>
                this.setState({
                  usersFilter: [`${me.data.id}`],
                  showPupils: false,
                })
              }
            >
              Me
            </Button>
          </Col>
          <Col
            xxl={{ span: 3, offset: 9 }}
            xl={{ span: 4, offset: 8 }}
            lg={{ span: 5, offset: 7 }}
            span={24}
            className="controls-right"
          >
            <Link to={changeButtonData.href}>
              <Button type="default" style={{ width: '100%' }}>
                {changeButtonData.display}
              </Button>
            </Link>
          </Col>
        </Row>
        <Switch>
          <Route exact path="/days-off">
            <DaysOff
              usersFilter={usersFilter}
              holidays={this.props.holidays.data?.holidays}
              history={history}
              location={location}
            />
          </Route>
          <Route exact path="/days-off/allowances">
            <DaysOffAllowances
              usersFilter={usersFilter}
              onInactiveFilterChange={this.handleInactiveFilterChange}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { users, holidays, me } = state;
  return {
    users,
    holidays,
    me,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (params = {}) =>
      dispatch(
        usersActions.fetchUsersRequest(
          {
            ...params,
          },
          'daysOffPage'
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DaysOffPage);
