import { Observable } from 'rxjs';
import timesConstants from 'constants/times.constants';
import timesActions from 'actions/times.actions';
import { getUrl } from 'helpers/ajax';

const timesEpic = (action$) =>
  Observable.merge(
    action$.ofType(timesConstants.FETCH_NEXT_TIMES_REQUEST).mergeMap((action) =>
      getUrl(action.params.url, {}, true)
        .map((response) =>
          timesActions.fetchNextTimesSuccess(response.response, action.storeId)
        )
        .catch((error) =>
          Observable.of(timesActions.fetchTimesFailure(error, action.storeId))
        )
    )
  );

export default timesEpic;
