import userConstants from 'constants/user.constants';

/* eslint-disable */
export default function user(state = {}, action) {
  switch (action.type) {
    case userConstants.RETRIEVE_USER_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
      });

    case userConstants.RETRIEVE_USER_SUCCESS:
      return Object.assign({}, state, {
        profile: action.data,
        fetched: true,
        fetching: false,
      });

    case userConstants.RETRIEVE_USER_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        profile: {},
      });

    case userConstants.PATCH_USER_REQUEST:
      return Object.assign({}, state, {
        patching: true,
      });

    case userConstants.PATCH_USER_SUCCESS:
      return Object.assign({}, state, {
        patching: false,
      });

    case userConstants.PATCH_USER_FAILURE:
      return Object.assign({}, state, {
        patching: false,
      });

    case userConstants.CLEAR_USER:
      return {
        fetched: false,
      };

    default:
      return state;
  }
}
