import { put, call, takeLatest } from 'redux-saga/effects';
import { get, patch } from 'helpers/ajax';
import archiveActions from 'actions/archive.actions';
import archiveConstants from 'constants/archive.constants';

function* fetchArchive(action) {
  const params = new URLSearchParams(action.params).toString();
  try {
    const ajax = yield get(`/api/v1/all_users?${params}`, null, true);
    const rsp = yield call([ajax, ajax.toPromise]);
    yield put(archiveActions.fetchArchiveSuccess(rsp.response));
  } catch (error) {
    yield put(archiveActions.fetchArchiveFailure(error));
  }
}

function* manageArchiveUser(action) {
  try {
    const ajax = yield patch(
      `/api/v1/all_users/${action.id}`,
      // eslint-disable-next-line
      { is_active: action.active },
      {},
      true
    );
    yield call([ajax, ajax.toPromise]);
    yield put(archiveActions.archiveSuccess());
    yield put(archiveActions.fetchArchiveRequest());
  } catch (error) {
    yield put(archiveActions.archiveFailure(error));
  }
}

export default function* archiveSaga() {
  yield takeLatest(archiveConstants.FETCH_ARCHIVE_REQUEST, fetchArchive);
  yield takeLatest(archiveConstants.ARCHIVE_REQUEST, manageArchiveUser);
}
