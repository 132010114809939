import { Observable } from 'rxjs';
import queryString from 'query-string';
import rolesConstants from 'constants/roles.constants';
import rolesActions from 'actions/roles.actions';
import { get, post, remove } from 'helpers/ajax';

const rolesEpic = (action$, store) =>
  Observable.merge(
    action$.ofType(rolesConstants.FETCH_ROLES_REQUEST).mergeMap((action) =>
      get(`/api/v1/roles?${queryString.stringify(action.params)}`, {}, true)
        .map((response) => rolesActions.fetchRolesSuccess(response.response))
        .catch((error) => Observable.of(rolesActions.fetchRolesFailure(error)))
    ),
    action$.ofType(rolesConstants.CREATE_ROLE_REQUEST).mergeMap((action) =>
      post('/api/v1/roles', action.data, {}, true)
        .map((response) => rolesActions.createRoleSuccess(response.response))
        .catch((error) => Observable.of(rolesActions.createRoleFailure(error)))
    ),
    action$
      .ofType(rolesConstants.CREATE_ROLE_SUCCESS)
      .map(() => rolesActions.fetchRolesRequest(store.getState().roles.params)),
    action$.ofType(rolesConstants.DELETE_ROLE_REQUEST).mergeMap((action) =>
      remove(`/api/v1/roles/${action.id}`, {}, true)
        .map(() => rolesActions.deleteRoleSuccess(action.id))
        .catch((error) => Observable.of(rolesActions.deleteRoleFailure(error)))
    ),
    action$
      .ofType(rolesConstants.DELETE_ROLE_SUCCESS)
      .map(() => rolesActions.fetchRolesRequest(store.getState().roles.params))
  );

export default rolesEpic;
