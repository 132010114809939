import holidaysConstants from 'constants/holidays.constants';

function fetchHolidaysRequest() {
  return {
    type: holidaysConstants.FETCH_HOLIDAYS_REQUEST,
  };
}

function fetchHolidaysSuccess(holidays) {
  return {
    type: holidaysConstants.FETCH_HOLIDAYS_SUCCESS,
    holidays,
  };
}

function fetchHolidaysFailure(error) {
  return {
    type: holidaysConstants.FETCH_HOLIDAYS_FAILURE,
    error,
  };
}

const holidaysActions = {
  fetchHolidaysRequest,
  fetchHolidaysSuccess,
  fetchHolidaysFailure,
};

export default holidaysActions;
