import { Observable } from 'rxjs';
import holidaysConstants from 'constants/holidays.constants';
import holidaysActions from 'actions/holidays.actions';
import { get } from 'helpers/ajax';

const holidaysEpic = (action$) =>
  Observable.merge(
    action$.ofType(holidaysConstants.FETCH_HOLIDAYS_REQUEST).mergeMap(() =>
      get('/api/v1/holidays', {}, true)
        .map((response) =>
          holidaysActions.fetchHolidaysSuccess(response.response)
        )
        .catch((error) =>
          Observable.of(holidaysActions.fetchHolidaysFailure(error))
        )
    )
  );

export default holidaysEpic;
