// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YLvsCUms0Da8i6LIvD92 {
  color: #ff0000;
  margin: 0;
}
.cja8_WodoSfYIRiE6Enl {
  margin-right: 5px;
}
.gG5tQerwIfIlXiYPSPKs {
  min-width: 128px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DaysOff/DaysOff.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,SAAA;AACF;AAEA;EACE,iBAAA;AAAF;AAGA;EACE,gBAAA;AADF","sourcesContent":[".overdue {\n  color: #ff0000;\n  margin: 0;\n}\n\n.pupils {\n  margin-right: 5px;\n}\n\n.approval {\n  min-width: 128px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overdue": `YLvsCUms0Da8i6LIvD92`,
	"pupils": `cja8_WodoSfYIRiE6Enl`,
	"approval": `gG5tQerwIfIlXiYPSPKs`
};
export default ___CSS_LOADER_EXPORT___;
