import userConstants from 'constants/user.constants';
import { get, patch, post, remove } from 'helpers/ajax';
import { NotificationManager } from 'react-notifications';
import { call, put, takeLatest } from 'redux-saga/effects';
import meActions from '../actions/me.actions';
import teamsActions from '../actions/teams.action';
import userActions from '../actions/user.actions';

function* retrieveUserRequest(action) {
  try {
    const id = action.id;
    const ajax = get(`/api/v1/users/${id}`, {}, true);
    const resp = yield call([ajax, ajax.toPromise]);
    yield put(userActions.retrieveUserSuccess(resp.response));
  } catch (err) {
    yield put(userActions.retrieveUserFailure(err));
  }
}

function* patchUserRequest(action) {
  try {
    const id = action.id;
    const data = action.data;
    const ajax = patch(`/api/v1/users/${id}`, data, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put(userActions.patchUserSuccess(id));
    yield put(meActions.retrieveMeRequest());
  } catch (err) {
    yield put(userActions.patchUserFailure(err));
  }
}

function* patchUserFailure(action) {
  NotificationManager.error(action.error.message);
  yield;
}

function* patchUserSuccess(action) {
  yield put(userActions.retrieveUserRequest(action.id));
}

function* updateUserGroup(action, unset = false) {
  try {
    const id = action.id;
    const data = {
      groups: [
        action.data.select_public,
        action.data.select_private,
        action.data.select_utility,
      ].filter((value) => value !== undefined),
    };

    const ajax = unset
      ? remove(`/api/v1/users/${id}/leave_group/${action.data}`, {}, true)
      : post(
          `/api/v1/users/${id}/enter_groups`,
          data,
          { 'Content-Type': 'application/json' },
          true
        );

    yield call([ajax, ajax.toPromise]);
    yield put(teamsActions.fetchTeamsRequest());
    yield put(userActions.patchUserSuccess(id));
  } catch (err) {
    yield put(userActions.patchUserFailure(err));
  }
}

function* setUserGroup(action) {
  yield* updateUserGroup(action);
}

function* removeUserGroup(action) {
  yield* updateUserGroup(action, true);
}

function* userSaga() {
  yield takeLatest(userConstants.RETRIEVE_USER_REQUEST, retrieveUserRequest);
  yield takeLatest(userConstants.PATCH_USER_REQUEST, patchUserRequest);
  yield takeLatest(userConstants.PATCH_USER_FAILURE, patchUserFailure);
  yield takeLatest(userConstants.PATCH_USER_SUCCESS, patchUserSuccess);
  yield takeLatest(userConstants.SET_USER_GROUP, setUserGroup);
  yield takeLatest(userConstants.REMOVE_USER_GROUP, removeUserGroup);
}

export default userSaga;
