import {
  CarOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import daysOffActions from 'actions/daysOff.actions';
import remoteWorkActions from 'actions/remoteWork.actions';
import userActions from 'actions/user.actions';
import { Button, Calendar, Card, Col, Modal, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import AddModal from 'components/AddDaysOff/AddDaysOffModal';
import AddOfficeWorkModal from 'components/AddOfficeWork/AddOfficeWorkModal';
import AddRemoteWorkModal from 'components/AddRemoteWork/AddRemoteWorkModal';
import DaysOffReport from 'components/DaysOffReport/DaysOffReport';
import Loader from 'components/Loader/Loader';
import UserGravatar from 'components/UserGravatar/UserGravatar';
import { eachDayOfInterval, isSameDay, isWeekend } from 'date-fns';
import dayjs from 'dayjs';
import { post } from 'helpers/ajax';
import compareAlphabetically from 'helpers/compareAlphabetically';
import { formatDate } from 'helpers/dateTime';
import isWorking from 'helpers/isWorking';
import { timeStrToLocal } from 'helpers/timeConvert';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getIsAdmin } from 'selectors/permissions.selectors';
import AddTeamModal from './AddTeamModal';
import './UserProfile.css';
import styles from './UserProfile.less';
import UserProfileForm from './UserProfileForm';
import UserWorkStats from './components/UserWorkStats';

class UserProfile extends React.Component {
  // eslint-disable-next-line
  displayName = 'UserProfile';

  constructor(props) {
    super(props);

    this.state = {
      addDaysVisible: false,
      addRemoteVisible: false,
      editProfileVisible: false,
      addTeamVisible: false,
      officeVisible: false,
      isCatLoader:
        new URLSearchParams(window.location.search).get('loader') === 'cat',
    };
  }

  componentDidMount() {
    const { fetchUser, match } = this.props;
    const userId = match.params.id;
    fetchUser(userId);
    if (this.state.isCatLoader) {
      this.handleChangeLoader('cat');
      setTimeout(() => {
        this.setState({ isCatLoader: false });
      }, 2000);
    }
  }
  // eslint-disable-next-line
  UNSAFE_componentWillUnmount() {
    this.props.clearUser();
  }

  editUser(id, data) {
    const { modifyUser } = this.props;

    modifyUser(id, data);
    this.setState({ editProfileVisible: false });
  }

  componentDidUpdate(prevProps) {
    const { match, fetchUser } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      fetchUser(match.params.id);
    }
  }
  // eslint-disable-next-line
  getDataWithUserId = (data) => {
    const { user, me } = this.props;
    if (user.profile.id === me.data.id) {
      return data;
    }

    return {
      ...data,
      /* eslint-disable camelcase */
      user_id: this.props.user.profile.id,
    };
  };

  handleCreateDayOff = (data) => {
    this.props.createDaysOff(this.getDataWithUserId(data));
  };

  handleCreateRemote = (data, isMe, office) => {
    this.props.createRemoteWork(
      this.getDataWithUserId(data),
      {},
      this.props.me,
      office
    );
  };

  deleteGroup = (team) => {
    const { user, removeUserGroup } = this.props;

    Modal.confirm({
      title: 'Are you sure?',
      content: `You will delete ${team.name} group`,
      okText: 'Delete',
      onOk() {
        removeUserGroup(user.profile.id, team.group_id);
      },
    });
  };

  handleAddTeam = (data) => {
    const { setUserGroup, user } = this.props;
    setUserGroup(user.profile.id, data);
  };

  // eslint-disable-next-line
  getDaysBetweenDates(dateStart, dateEnd) {
    return eachDayOfInterval({
      start: new Date(dateStart),
      end: new Date(dateEnd),
    });
  }

  getDayStatus(userStat) {
    return userStat?.planned
      .map((plannedItem) =>
        this.getDaysBetweenDates(
          plannedItem.date_start,
          plannedItem.date_end
        ).map((date) => ({
          date,
          remark: plannedItem.remarks,
        }))
      )
      .flat();
  }

  onApprove = (dayOff) => {
    const { fetchUser, match } = this.props;
    return post(`/api/v1/days_off/${dayOff.id}/approve`, {}, {}, true)
      .toPromise()
      .then(() => fetchUser(match.params.id));
  };

  onDelete = (id) => {
    const { fetchUser, deleteDayOff, match } = this.props;
    deleteDayOff(id);
    fetchUser(match.params.id);
  };
  // eslint-disable-next-line
  handleChangeLoader = (value) => {
    localStorage.setItem('loader', JSON.stringify(value));
  };

  render() {
    const { user, me, teams, holidays } = this.props;
    const {
      addDaysVisible,
      addRemoteVisible,
      editProfileVisible,
      addTeamVisible,
      isCatLoader,
    } = this.state;
    if (!user.fetched) {
      return <Loader />;
    }

    const holidaysData = holidays.data?.holidays;

    const isMe = user.profile.id === me.data.id;
    const isAdmin = getIsAdmin(this.props.permissions);

    const activeTeams = teams
      .filter((team) => team.state === 'active')
      .sort((a, b) => compareAlphabetically(a.name, b.name));

    const createGroupButtons = (type) => {
      return activeTeams
        .filter(
          (team) =>
            user.profile.groups.some((group) => group.name === team.name) &&
            team.type === type
        )
        .map((team) => {
          const teamMembers = team.users.filter(
            (userInfo) => userInfo.name !== team.leader
          );

          teamMembers.sort((a, b) => a.name.localeCompare(b.name));

          const leaders = activeTeams.find((group) => group.name === 'Leaders');

          return (
            <Tooltip
              title={
                team.type !== 'utility' && (
                  <ul className={styles.noBullets}>
                    {teamMembers.map((userInfo) => (
                      <li
                        key={userInfo.id}
                        className={
                          leaders.users.some(
                            (group) => group.name === userInfo.name
                          )
                            ? styles.leader
                            : ''
                        }
                      >
                        {userInfo.name}
                      </li>
                    ))}
                  </ul>
                )
              }
              key={team.group_id}
            >
              <Link
                to={`/monthly?team=${encodeURIComponent(
                  team.name.replaceAll(' ', '_')
                )}`}
                className={styles.privateButton}
                style={{
                  border: `2px solid ${team.color1}80`,
                }}
              >
                {(isAdmin || (isMe && type === 'public')) && (
                  <CloseOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      this.deleteGroup(team);
                    }}
                    className={styles.deleteTeam}
                  />
                )}
                {team.name}
              </Link>
            </Tooltip>
          );
        });
    };
    const privateGroups = createGroupButtons('private');
    const publicGroups = createGroupButtons('public');
    const utilityGroups = createGroupButtons('utility');

    const actionButtons = (
      <div className={styles.actionButtons}>
        <Tooltip
          title={
            <>
              <strong className={styles.instructionHeader}>
                Instructions:
              </strong>
              <ul>
                <li>Click this button.</li>
                <li>Create an account or log in to your ULAM mail.</li>
                <li>Click the link in the received email.</li>
                <li>Upload an image.</li>
              </ul>
            </>
          }
        >
          <Button
            href="https://en.gravatar.com/"
            target="_blank"
            className="ant-btn"
            rel="noopener noreferrer"
          >
            Avatar
          </Button>
        </Tooltip>

        <Button onClick={() => this.setState({ addTeamVisible: true })}>
          Add Team
        </Button>
        {!me.data.is_freelancer && (
          <Button onClick={() => this.setState({ addDaysVisible: true })}>
            Add Days Off
          </Button>
        )}
        {!me.data.is_freelancer && (
          <Button onClick={() => this.setState({ addRemoteVisible: true })}>
            Add Remote
          </Button>
        )}
        {!me.data.is_freelancer && (
          <Button onClick={() => this.setState({ officeVisible: true })}>
            Add Office Work
          </Button>
        )}
        <Button onClick={() => this.setState({ editProfileVisible: true })}>
          Settings
        </Button>
      </div>
    );

    const currentDate = dayjs();
    const nextMonth = currentDate.clone().add(1, 'month');

    const plannedDaysOff = this.getDayStatus(user.profile.day_off);
    const plannedRemotes = this.getDayStatus(user.profile.work_stats);
    const businessTrips = this.getDayStatus(user.profile.planned?.office_work);

    const dateFullCellRender = (value, calendar) => {
      let isPreviousMonth = false;

      const plannedDayOff = plannedDaysOff?.find((plannedDay) =>
        isSameDay(value.toDate(), plannedDay.date)
      );

      const plannedRemote = plannedRemotes?.find((plannedDay) =>
        isSameDay(value.toDate(), plannedDay.date)
      );

      const plannedBusinessTrip = businessTrips?.find((plannedDay) =>
        isSameDay(value.toDate(), plannedDay.date)
      );

      const formattedVal = formatDate(value);

      const isCurrentDay = isSameDay(value.toDate(), new Date());
      const isDayOff =
        isWeekend(value.toDate()) ||
        holidaysData?.some((holiday) => holiday === `${formattedVal}`);

      if (calendar === 'calendar1') {
        isPreviousMonth = value.month() !== currentDate.month();
      } else if (calendar === 'calendar2') {
        isPreviousMonth = value.month() !== nextMonth.month();
      }

      const showRemarks = isMe || isAdmin;

      return (
        <Tooltip
          title={
            showRemarks ? plannedDayOff?.remark || plannedRemote?.remark : ''
          }
        >
          <div
            className={clsx(
              styles.dateCell,
              isPreviousMonth ? styles.previousMonth : '',
              plannedDayOff ? styles.dayOff : '',
              plannedRemote ? styles.remote : '',
              isCurrentDay ? styles.today : '',
              isDayOff ? styles.weekend : ''
            )}
          >
            {value.date()}
          </div>
          {plannedBusinessTrip && (
            <Tooltip title="Business trip" className="businessTrip">
              <CarOutlined />
            </Tooltip>
          )}
        </Tooltip>
      );
    };

    const calendarHeader = ({ value }) => (
      <h2 className={styles.calendarHeader}>
        {value.format('MMMM')} {value.format('YYYY')}
      </h2>
    );

    const userLeader = activeTeams
      .find((group) => group.name === 'Leaders')
      ?.users.find((leader) => leader.name === user.profile.leader);

    const { current_job_tenure: jobTenure, job_tenure } = user.profile;

    return (
      <div className={styles.userProfile}>
        <Helmet title="User Profile" />
        <Loader loading={isCatLoader || !me.data || !user.profile}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
            <Card>
              <Row type="flex" justify="space-between">
                <h1>{user.profile.name}</h1>
                {(isAdmin || isMe) && actionButtons}
              </Row>
              <Row type="flex" className={styles.profileInfo}>
                <UserGravatar email={user.profile.email} size={100} />
                <div className="user-info">
                  <h4>{user.profile.position}</h4>
                  <h4>
                    {user.profile.phone_number
                      ? `${user.profile.email} | ${user.profile.phone_number}`
                      : user.profile.email}
                  </h4>
                  <h4>
                    Leader:{' '}
                    <Link
                      className={styles.userLink}
                      to={`/users/${userLeader?.id}`}
                    >
                      {userLeader?.name}
                    </Link>
                  </h4>
                  {user.profile.work_start_time && (
                    <>
                      <h4>
                        {`Usually working: ${timeStrToLocal(
                          user.profile.work_start_time,
                          user.profile.timezone
                        )} - ${timeStrToLocal(
                          user.profile.work_end_time,
                          user.profile.timezone
                        )}`}
                        <Tooltip title="Usually hard working during these hours">
                          <InfoCircleOutlined
                            style={{ marginLeft: '5px', color: 'grey' }}
                          />
                        </Tooltip>
                      </h4>
                      {jobTenure && (
                        <h4>
                          {`Job tenure: ${
                            jobTenure.years
                              ? `${jobTenure.years} ${
                                  jobTenure.years === 1 ? 'year' : 'years'
                                }`
                              : ''
                          } ${
                            jobTenure.months
                              ? `${jobTenure.months} ${
                                  jobTenure.months === 1 ? 'month' : 'months'
                                }`
                              : ''
                          } ${
                            isAdmin
                              ? `${
                                  job_tenure
                                    ? `(${job_tenure} ${
                                        job_tenure === 1
                                          ? 'year total'
                                          : 'years total'
                                      })`
                                    : ''
                                }`
                              : ''
                          }`}
                        </h4>
                      )}
                      <h4 className={styles.userStatus}>
                        Status:{' '}
                        <span
                          className={clsx(
                            styles.userDot,
                            isWorking(user.profile) &&
                              !user.profile.user_state?.currently_has_days_off
                              ? styles.active
                              : styles.inactive
                          )}
                        />{' '}
                        ({/* eslint-disable no-nested-ternary */}
                        {user.profile.user_state?.currently_has_days_off
                          ? 'vacationing'
                          : user.profile.user_state
                              ?.currently_working_remotely &&
                            isWorking(user.profile)
                          ? 'working remotely'
                          : isWorking(user.profile)
                          ? 'working'
                          : 'outside of office hours'}
                        )
                      </h4>
                    </>
                  )}
                  <div className={styles.teamsContainer}>
                    {privateGroups}
                    {utilityGroups}
                  </div>
                </div>
              </Row>
              {publicGroups[0] && (
                <Row className={styles.publicGroupsContainer}>
                  <h2>My Teams</h2>
                  <div className={styles.myTeamsContainer}>{publicGroups}</div>
                </Row>
              )}
              <UserWorkStats user={user} />
              <Row gutter={[24, 24]} type="flex" justify="center">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Calendar
                    value={currentDate}
                    fullCellRender={(date) =>
                      dateFullCellRender(date, 'calendar1')
                    }
                    fullscreen={false}
                    headerRender={calendarHeader}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Calendar
                    value={nextMonth}
                    fullCellRender={(date) =>
                      dateFullCellRender(date, 'calendar2')
                    }
                    fullscreen={false}
                    headerRender={calendarHeader}
                  />
                </Col>
              </Row>
            </Card>
            {user.profile.day_off.unapproved?.[0] && (
              <Card style={{ marginTop: '20px' }} title="Unapproved day offs">
                <DaysOffReport
                  isAdmin={isAdmin}
                  user={me.data}
                  daysOff={user.profile.day_off.unapproved}
                  onApprove={this.onApprove}
                  onDelete={(dayOff) => this.onDelete(dayOff.id)}
                />
              </Card>
            )}
          </Col>
        </Loader>
        <AddModal
          user={user.profile}
          me={me}
          holidays={holidaysData}
          visible={addDaysVisible}
          onCancel={() => this.setState({ addDaysVisible: false })}
          isAdmin={isAdmin}
          onOk={(data) => {
            this.handleCreateDayOff(data, isMe);
            this.setState({ addDaysVisible: false });
          }}
        />
        <AddRemoteWorkModal
          visible={addRemoteVisible}
          onCancel={() => this.setState({ addRemoteVisible: false })}
          onOk={(data) => {
            this.handleCreateRemote(data, isMe);
            this.setState({ addRemoteVisible: false });
          }}
        />
        <UserProfileForm
          leaders={activeTeams
            .filter((team) => team.name === 'Leaders')[0]
            ?.users.concat([{ id: '', name: 'No leader' }])}
          isAdmin={isAdmin}
          user={user.profile}
          onSubmit={(id, data) => this.editUser(id, data)}
          visible={editProfileVisible}
          onCancel={() => this.setState({ editProfileVisible: false })}
          isMe={isMe}
          handleChangeLoader={this.handleChangeLoader}
        />
        <AddTeamModal
          user={user}
          teams={activeTeams}
          visible={addTeamVisible}
          onOk={(data) => {
            this.handleAddTeam(data);
            this.setState({ addTeamVisible: false });
          }}
          isAdmin={isAdmin}
          onCancel={() => this.setState({ addTeamVisible: false })}
        />
        <AddOfficeWorkModal
          visible={this.state.officeVisible}
          onCancel={() => this.setState({ officeVisible: false })}
          onOk={(data) => {
            this.handleCreateRemote(data, isMe, true);
            this.setState({ officeVisible: false });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let user = state.user;
  const me = state.me;
  let teams = state.teams;
  const permissions = state.permissions;
  const holidays = state.holidays;
  if (!user.fetched || !user.profile) {
    user = {};
  }
  teams =
    state.teams.fetched && permissions.fetched ? state.teams.data.results : [];
  return {
    user,
    me,
    teams,
    permissions,
    holidays,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: (id) => dispatch(userActions.retrieveUserRequest(id)),
    modifyUser: (id, data) => dispatch(userActions.patchUserRequest(id, data)),
    clearUser: () => dispatch(userActions.clearUser()),
    createDaysOff: (data) =>
      dispatch(daysOffActions.checkAndCreateDaysOffAction(data)),
    createRemoteWork: (data, params, user, office = false) =>
      dispatch(
        remoteWorkActions.createRemoteWorkRequest(
          { ...data },
          { ...params },
          user,
          office
        )
      ),
    setUserGroup: (id, data) => dispatch(userActions.setUserGroup(id, data)),
    removeUserGroup: (id, data) =>
      dispatch(userActions.removeUserGroup(id, data)),
    deleteDayOff: (id, params = {}) =>
      dispatch(daysOffActions.deleteDaysOffRequest(id, params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
