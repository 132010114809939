import domainsConstants from 'constants/domains.constants';

function fetchDomainsRequest(params) {
  return {
    type: domainsConstants.FETCH_DOMAINS_REQUEST,
    params,
  };
}

function fetchDomainsSuccess(domains) {
  return {
    type: domainsConstants.FETCH_DOMAINS_SUCCESS,
    domains,
  };
}

function fetchDomainsFailure(error) {
  return {
    type: domainsConstants.FETCH_DOMAINS_FAILURE,
    error,
  };
}

function createDomainRequest(data) {
  return {
    type: domainsConstants.CREATE_DOMAIN_REQUEST,
    data,
  };
}

function createDomainSuccess(data) {
  return {
    type: domainsConstants.CREATE_DOMAIN_SUCCESS,
    data,
  };
}

function createDomainFailure(error) {
  return {
    type: domainsConstants.CREATE_DOMAIN_FAILURE,
    error,
  };
}

function deleteDomainRequest(id) {
  return {
    type: domainsConstants.DELETE_DOMAIN_REQUEST,
    id,
  };
}

function deleteDomainSuccess(id) {
  return {
    type: domainsConstants.DELETE_DOMAIN_SUCCESS,
    id,
  };
}

function deleteDomainFailure(error) {
  return {
    type: domainsConstants.DELETE_DOMAIN_FAILURE,
    error,
  };
}

const domainsActions = {
  fetchDomainsRequest,
  fetchDomainsSuccess,
  fetchDomainsFailure,
  createDomainRequest,
  createDomainSuccess,
  createDomainFailure,
  deleteDomainRequest,
  deleteDomainSuccess,
  deleteDomainFailure,
};

export default domainsActions;
