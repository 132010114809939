import React from 'react';
import { Form, Modal, Select } from 'antd';
import styles from './UserProfile.less';

const AddTeamModal = ({ user, teams, visible, onOk, isAdmin, onCancel }) => {
  const [form] = Form.useForm();

  const createOptions = (type) =>
    teams
      .filter(
        (team) =>
          team.type === type &&
          user.profile.groups.every((group) => group.name !== team.name)
      )
      .map((team) => (
        <Select.Option key={team.group_id} value={team.group_id}>
          {team.name}
        </Select.Option>
      ));

  const publicTeams = createOptions('public');
  const privateGroups = createOptions('private');
  const utilityGroups = createOptions('utility');

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onOk(values);
      form.resetFields();
      // eslint-disable-next-line
    } catch (error) {}
  };

  return (
    <Modal
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      open={visible}
      title="Add to a Team"
      onOk={() => {
        handleOk();
      }}
    >
      <Form layout="vertical" form={form} name="add_team_modal">
        <Form.Item label="Add me to the following team:" name="select_public">
          <Select placeholder="Select Team">{publicTeams}</Select>
        </Form.Item>
        {isAdmin && (
          <>
            <Form.Item
              className={styles.adminForm}
              label="Add this user to private group:"
              name="select_private"
            >
              <Select placeholder="Select Group">{privateGroups}</Select>
            </Form.Item>
            <Form.Item
              label="Add this user to utility groups:"
              name="select_utility"
            >
              <Select placeholder="Select Group">{utilityGroups}</Select>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddTeamModal;
