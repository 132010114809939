import { call, put, takeLatest } from 'redux-saga/effects';
import { post } from 'helpers/ajax';
import authActions from 'actions/auth.actions';
import loginConstants from '../constants/login.constants';
import loginActions from '../actions/login.actions';

function* loginRequest(action) {
  try {
    const ajax = yield post(
      `/api/v1/login/`,
      action.credentials,
      { 'Content-Type': 'application/json' },
      true
    );
    const response = yield call([ajax, ajax.toPromise]);
    yield put(loginActions.loginSuccess(response.response.token));
  } catch (error) {
    yield put(loginActions.loginFailure(error));
  }
}

function* registerRequest(action) {
  const data = { ...action.credentials, token: action.token };
  try {
    const ajax = yield post(
      `/api/v1/register/`,
      data,
      { 'Content-Type': 'application/json' },
      true
    );
    const response = yield call([ajax, ajax.toPromise]);
    yield put(loginActions.registerSuccess(response));
    yield put(loginActions.loginSuccess(response.response.token));
  } catch (error) {
    yield put(loginActions.registerFailure(error));
  }
}

function* loginSuccess(action) {
  yield put(authActions.loginSuccess(action.token));
}

function* loginSaga() {
  yield takeLatest(loginConstants.LOGIN_REQUEST, loginRequest);
  yield takeLatest(loginConstants.LOGIN_SUCCESS, loginSuccess);
  yield takeLatest(loginConstants.REGISTER_REQUEST, registerRequest);
}

export default loginSaga;
