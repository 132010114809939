const freelancersConstants = {
  FETCH_FREELANCERS_REQUEST: 'FREELANCERS_FETCH_FREELANCERS_REQUEST',
  FETCH_FREELANCERS_SUCCESS: 'FREELANCERS_FETCH_FREELANCERS_SUCCESS',
  FETCH_FREELANCERS_FAILURE: 'FREELANCERS_FETCH_FREELANCERS_FAILURE',
  DELETE_FREELANCER_REQUEST: 'FREELANCERS_DELETE_FREELANCER_REQUEST',
  DELETE_FREELANCER_SUCCESS: 'FREELANCERS_DELETE_FREELANCER_SUCCESS',
  DELETE_FREELANCER_FAILURE: 'FREELANCERS_DELETE_FREELANCER_FAILURE',
  CREATE_FREELANCER_REQUEST: 'FREELANCERS_CREATE_FREELANCER_REQUEST',
  CREATE_FREELANCER_SUCCESS: 'FREELANCERS_CREATE_FREELANCER_SUCCESS',
  CREATE_FREELANCER_FAILURE: 'FREELANCERS_CREATE_FREELANCER_FAILURE',
};

export default freelancersConstants;
