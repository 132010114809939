import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { differenceInCalendarDays } from 'date-fns';
import dayjs from 'dayjs';
import getMaxPeriodNumber from 'helpers/getMaxPeriodNumber';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styles from './AddRemoteWorkModal.less';

const AddRemoteWorkModal = ({ onOk, onCancel, visible }) => {
  const [teamInformed, setTeamInformed] = useState(false);
  const [dateValidationStatus, setDateValidationStatus] = useState(undefined);
  const [dateValidationText, setDateValidationText] = useState(undefined);
  const [reoccurrenceMode, setReoccurrenceMode] = useState(false);
  const [maxPeriodNumber, setMaxPeriodNumber] = useState(2);
  const [selectedDays, setSelectedDays] = useState(0);
  const [form] = Form.useForm();

  /* eslint-disable camelcase */
  const teamInformedChanged = (event) => {
    setTeamInformed(event.target.checked);
  };

  const checkIfDateTooEarly = (calendarValue) => {
    const [dateStart, dateEnd] = calendarValue;

    const selectedCalendarDays =
      differenceInCalendarDays(new Date(dateEnd), new Date(dateStart)) + 1;

    setSelectedDays(selectedCalendarDays);

    if (selectedCalendarDays > 7) {
      form.setFieldValue('period', 'month');
    }

    if (selectedCalendarDays > 31) {
      setReoccurrenceMode(false);
    }

    const today = dayjs().startOf('day');
    const lateRemoteWorkDuration = window.__NOTICE_DAYS_BEFORE_REMOTE_WORK__;
    if (today.add(lateRemoteWorkDuration, 'days') > dateStart) {
      setDateValidationStatus('warning');
      setDateValidationText(
        `Usually remote work should be submitted at least
        ${window.__NOTICE_DAYS_BEFORE_REMOTE_WORK__} days earlier`
      );
    } else {
      setDateValidationStatus(undefined);
      setDateValidationText(undefined);
    }
  };

  const acceptData = async () => {
    try {
      const values = await form.validateFields();
      const dateStart = values.dateRange[0].format('YYYY-MM-DD');
      const dateEnd = values.dateRange[1].format('YYYY-MM-DD');
      const result = {
        date_start: dateStart,
        date_end: dateEnd,
        remarks: values.remarks,
        reoccurrence: reoccurrenceMode,
        period: values.period,
        repetition: values.repetition,
        frequency: values.frequency,
      };
      onOk(result);
      form.resetFields();
      setDateValidationStatus(undefined);
      setDateValidationText(undefined);
      setTeamInformed(false);
      // eslint-disable-next-line
    } catch (error) {}
  };

  const handleChangeMode = () => {
    setReoccurrenceMode((oldState) => !oldState);
  };

  const { RangePicker } = DatePicker;
  const { TextArea } = Input;

  return (
    <Modal
      open={visible}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setTeamInformed(false);
      }}
      onOk={() => {
        acceptData();
      }}
      okButtonProps={{ disabled: !teamInformed }}
      title="Add Remote Work"
    >
      <Form
        form={form}
        layout="vertical"
        name="add_remote_work"
        onValuesChange={(_, allValues) =>
          setMaxPeriodNumber(getMaxPeriodNumber(allValues))
        }
      >
        <Form.Item
          label="From - To"
          validateStatus={dateValidationStatus}
          help={dateValidationText}
          name="dateRange"
          rules={[
            {
              required: true,
              message: 'Please select time.',
            },
          ]}
        >
          <RangePicker
            onChange={(date) => {
              checkIfDateTooEarly(date);
            }}
          />
        </Form.Item>
        <Form.Item name="reoccurrence">
          <Checkbox
            style={{ transform: 'translateY(-10px)' }}
            checked={reoccurrenceMode}
            onChange={handleChangeMode}
            disabled={selectedDays > 31}
          >
            Reoccurrence
          </Checkbox>
        </Form.Item>
        {selectedDays > 31 && (
          <p className={styles.error}>
            Cannot set reoccurrence if more than 31 days are selected
          </p>
        )}
        {reoccurrenceMode && selectedDays <= 31 && (
          <Row gutter={8}>
            <Col>
              <Form.Item
                rules={[{ required: true }]}
                initialValue="week"
                label="Period"
                name="period"
              >
                <Select
                  options={
                    selectedDays <= 7
                      ? [
                          {
                            value: 'week',
                            label: 'weekly',
                          },
                          { value: 'month', label: 'monthly' },
                        ]
                      : [{ value: 'month', label: 'monthly' }]
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                initialValue={1}
                label="Repetition"
                name="repetition"
                rules={[{ required: true }]}
              >
                <InputNumber min={1} max={maxPeriodNumber} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                initialValue={1}
                label={
                  <>
                    <span>Frequency</span>
                    <Tooltip title='The default value is 1. If you change it to, for instance 2, and the time period is set to "week," it means the occurrence is established every two weeks instead of every week.'>
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </>
                }
                name="frequency"
              >
                <InputNumber min={1} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item label="Remarks" required>
          <Form.Item
            name="remarks"
            rules={[
              {
                transform: (value) => value.trim(),
                required: true,
                message: 'Please provide a short description.',
              },
            ]}
            initialValue=""
          >
            <TextArea rows={3} />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="teamInformed"
          initialValue={false}
          valuePropName="checked"
        >
          <Checkbox onChange={teamInformedChanged}>
            {/* eslint-disable-next-line */}
            <span className={styles.required}>*</span>I've informed my team, and
            they approved this remote work.
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddRemoteWorkModal.displayName = 'AddRemoteWorkModal';
/* eslint-disable react/require-default-props */
AddRemoteWorkModal.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
};

export default AddRemoteWorkModal;
