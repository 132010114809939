import officeIPsConstants from 'constants/officeIPs.constants';

/* eslint-disable */
export default function officeIPs(state = {}, action) {
  switch (action.type) {
    case officeIPsConstants.FETCH_IPS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
      });

    case officeIPsConstants.FETCH_IPS_SUCCESS:
      return Object.assign({}, state, {
        ips: action.ips,
        fetched: true,
      });

    case officeIPsConstants.FETCH_IPS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        ips: [],
      });

    case officeIPsConstants.CREATE_IP_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      });

    case officeIPsConstants.CREATE_IP_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
      });

    case officeIPsConstants.CREATE_IP_FAILURE:
      return Object.assign({}, state, {
        creating: false,
      });

    case officeIPsConstants.DELETE_IP_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      });

    case officeIPsConstants.DELETE_IP_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      });

    case officeIPsConstants.DELETE_IP_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
      });

    default:
      return state;
  }
}
