import { add, format, parse } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

export const localToTimeStr = (localTimeStr) => {
  return `${localTimeStr}:00`;
};
/**
 * Converts a given time in a specified timezone to the local time of the user's device.
 *
 * @param {string} userTime - A time in the format 'HH:mm:ss', e.g., '7:00:00'.
 * @param {string} userTimezone - The IANA timezone identifier of the user, e.g., 'Europe/Warsaw'.
 * @returns {string} - The converted time in local time format, e.g., '15:00'.
 */
export const timeStrToLocal = (userTime, userTimezone = '') => {
  const myTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (myTimezone === userTimezone) {
    return userTime?.slice(0, -3);
  }

  const now = new Date();

  const myOffset = getTimezoneOffset(myTimezone, now);

  const userOffset = getTimezoneOffset(userTimezone, now);

  const timeDifference = (myOffset - userOffset) / (60 * 1000);
  const localTime = format(
    add(parse(userTime, 'HH:mm:ss', new Date()), {
      minutes: -timeDifference,
    }),
    'HH:mm'
  );
  return localTime;
};
