import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfigProvider, theme } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import history from 'helpers/history';
import { useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.css';

import Full from 'containers/Full/Full';
import Public from 'containers/Public/Public';
import Login from 'pages/Auth/Login';
import 'react-notifications/lib/notifications.css';
import useNotifications from './components/Notification/useNotifications';
import userTheme from './helpers/userTheme';
import useSyncUserTimezone from './hooks/useSyncUserTimeZone';
import RegisterFreelancer from './pages/Auth/components/RegisterFreelancer';

function App() {
  const me = useSelector((state) => state.me);
  useSyncUserTimezone();
  const { contextHolder } = useNotifications();

  const isDarkMode = userTheme(me.data?.theme) === 'dark_mode';

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
  }, [isDarkMode]);

  return (
    <div className={isDarkMode ? 'darkMode' : ''}>
      <GoogleOAuthProvider clientId="345042012470-5au7kal21vrmq6kshhjhot7kdcd61qeg.apps.googleusercontent.com">
        <ConfigProvider
          theme={{
            algorithm: isDarkMode
              ? theme.darkAlgorithm
              : theme.defaultAlgorithm,
          }}
        >
          {contextHolder}
          <NotificationContainer />
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/login" name="Login Page" render={() => <Login />} />
              <Route
                path="/freelancer-registration"
                name="Freelancer Registration Page"
                render={() => <RegisterFreelancer />}
              />
              <Route path="/public" name="Public" render={() => <Public />} />
              <Route path="/" name="Full" render={() => <Full />} />
            </Switch>
          </ConnectedRouter>
        </ConfigProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
