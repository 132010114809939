import { Observable } from 'rxjs';
import queryString from 'query-string';
import projectsConstants from 'constants/projects.constants';
import projectsActions from 'actions/projects.actions';
import { get, remove, patch, post } from 'helpers/ajax';

const projectsEpic = (action$, store$) =>
  Observable.merge(
    action$
      .ofType(projectsConstants.DELETE_PROJECT_REQUEST)
      .mergeMap((action) =>
        remove(`/api/v1/projects/${action.id}`, {}, true)
          .map(() =>
            projectsActions.deleteProjectSuccess(action.id, action.storeId)
          )
          .catch((error) =>
            Observable.of(
              projectsActions.deleteProjectFailure(error, action.storeId)
            )
          )
      ),
    action$
      .ofType(projectsConstants.DELETE_PROJECT_SUCCESS)
      .map((action) =>
        projectsActions.fetchProjectsRequest(
          store$.value.projects[action.storeId].params,
          action.storeId
        )
      ),
    action$
      .ofType(projectsConstants.FETCH_PROJECTS_REQUEST)
      .mergeMap((action) =>
        get(
          `/api/v1/projects?${queryString.stringify(action.params)}`,
          {},
          true
        )
          .map((response) =>
            projectsActions.fetchProjectsSuccess(
              response.response,
              action.storeId
            )
          )
          .catch((error) =>
            Observable.of(
              projectsActions.fetchProjectsFailure(error, action.storeId)
            )
          )
      ),
    action$.ofType(projectsConstants.PATCH_PROJECT_REQUEST).mergeMap((action) =>
      patch(`/api/v1/projects/${action.id}`, action.data, {}, true)
        .map(() =>
          projectsActions.patchProjectSuccess(action.id, action.storeId)
        )
        .catch((error) =>
          Observable.of(
            projectsActions.patchProjectFailure(error, action.storeId)
          )
        )
    ),
    action$
      .ofType(projectsConstants.PATCH_PROJECT_SUCCESS)
      .map((action) =>
        projectsActions.fetchProjectsRequest(
          store$.value.projects[action.storeId].params,
          action.storeId
        )
      ),
    action$
      .ofType(projectsConstants.CREATE_PROJECT_REQUEST)
      .mergeMap((action) =>
        post('/api/v1/projects', action.data, {}, true)
          .map((response) =>
            projectsActions.createProjectSuccess(
              response.response,
              action.storeId
            )
          )
          .catch((error) =>
            Observable.of(
              projectsActions.createProjectFailure(error, action.storeId)
            )
          )
      ),
    action$
      .ofType(projectsConstants.CREATE_PROJECT_SUCCESS)
      .map((action) =>
        projectsActions.fetchProjectsRequest(
          store$.value.projects[action.storeId].params,
          action.storeId
        )
      )
  );

export default projectsEpic;
