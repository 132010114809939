import permissionsConstants from '../constants/permissions.constants';

function fetchPermissionsRequest() {
  return {
    type: permissionsConstants.FETCH_PERMISSIONS_REQUEST,
  };
}

function fetchPermissionsSuccess(permissionLevel) {
  return {
    type: permissionsConstants.FETCH_PERMISSIONS_SUCCESS,
    permissionLevel,
  };
}

function fetchPermissionsFailure(error) {
  return {
    type: permissionsConstants.FETCH_PERMISSIONS_FAILURE,
    error,
  };
}

const permissionsActions = {
  fetchPermissionsRequest,
  fetchPermissionsSuccess,
  fetchPermissionsFailure,
};

export default permissionsActions;
