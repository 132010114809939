import { Observable } from 'rxjs';
import queryString from 'query-string';
import officeIPsConstants from 'constants/officeIPs.constants';
import officeIPsActions from 'actions/officeIPs.actions';
import { get, post, remove } from 'helpers/ajax';

const officeIPsEpic = (action$, store) =>
  Observable.merge(
    action$.ofType(officeIPsConstants.FETCH_IPS_REQUEST).mergeMap((action) =>
      get(`/api/v1/officeips?${queryString.stringify(action.params)}`, {}, true)
        .map((response) => officeIPsActions.fetchIPsSuccess(response.response))
        .catch((error) =>
          Observable.of(officeIPsActions.fetchIPsFailure(error))
        )
    ),
    action$.ofType(officeIPsConstants.CREATE_IP_REQUEST).mergeMap((action) =>
      post('/api/v1/officeips', action.data, {}, true)
        .map((response) => officeIPsActions.createIPSuccess(response.response))
        .catch((error) =>
          Observable.of(officeIPsActions.createIPFailure(error))
        )
    ),
    action$
      .ofType(officeIPsConstants.CREATE_IP_SUCCESS)
      .map(() =>
        officeIPsActions.fetchIPsRequest(store.getState().officeIPs.params)
      ),
    action$.ofType(officeIPsConstants.DELETE_IP_REQUEST).mergeMap((action) =>
      remove(`/api/v1/officeips/${action.id}`, {}, true)
        .map(() => officeIPsActions.deleteIPSuccess(action.id))
        .catch((error) =>
          Observable.of(officeIPsActions.deleteIPFailure(error))
        )
    ),
    action$
      .ofType(officeIPsConstants.DELETE_IP_SUCCESS)
      .map(() =>
        officeIPsActions.fetchIPsRequest(store.getState().officeIPs.params)
      )
  );

export default officeIPsEpic;
