import { Observable } from 'rxjs';
import queryString from 'query-string';
import locationsConstants from 'constants/locations.constants';
import locationsActions from 'actions/locations.actions';
import { get, post, remove } from 'helpers/ajax';

const locationsEpic = (action$, store) =>
  Observable.merge(
    action$
      .ofType(locationsConstants.FETCH_LOCATIONS_REQUEST)
      .mergeMap((action) =>
        get(
          `/api/v1/locations?${queryString.stringify(action.params)}`,
          {},
          true
        )
          .map((response) =>
            locationsActions.fetchLocationsSuccess(response.response)
          )
          .catch((error) =>
            Observable.of(locationsActions.fetchLocationsFailure(error))
          )
      ),
    action$
      .ofType(locationsConstants.CREATE_LOCATION_REQUEST)
      .mergeMap((action) =>
        post('/api/v1/locations', action.data, {}, true)
          .map((response) =>
            locationsActions.createLocationSuccess(response.response)
          )
          .catch((error) =>
            Observable.of(locationsActions.createLocationFailure(error))
          )
      ),
    action$
      .ofType(locationsConstants.CREATE_LOCATION_SUCCESS)
      .map(() =>
        locationsActions.fetchLocationsRequest(
          store.getState().locations.params
        )
      ),
    action$
      .ofType(locationsConstants.DELETE_LOCATION_REQUEST)
      .mergeMap((action) =>
        remove(`/api/v1/locations/${action.id}`, {}, true)
          .map(() => locationsActions.deleteLocationSuccess(action.id))
          .catch((error) =>
            Observable.of(locationsActions.deleteLocationFailure(error))
          )
      ),
    action$
      .ofType(locationsConstants.DELETE_LOCATION_SUCCESS)
      .map(() =>
        locationsActions.fetchLocationsRequest(
          store.getState().locations.params
        )
      )
  );

export default locationsEpic;
