import React from 'react';
import { Badge } from 'antd';
import UserGravatar from '../UserGravatar/UserGravatar';
import styles from './UserBadge.less';
import isWorking from 'helpers/isWorking';

const UserBadge = ({ user, margin = '3px 3px 0 0', size = 'small' }) => {
  return (
    <Badge
      dot
      style={{
        margin,
      }}
      className={
        isWorking(user) && !user.user_state?.currently_has_days_off // eslint-disable-line camelcase
          ? styles.userActive
          : styles.userInactive
      }
    >
      {' '}
      <UserGravatar size={size} email={user.email} />
    </Badge>
  );
};

export default UserBadge;
