// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k1mqQwilmvtaRJuP5ag5 {
  padding: 0 16px;
  margin: 48px 0 24px 0;
  text-align: center;
}
.k1mqQwilmvtaRJuP5ag5 .dpiZfkEOgHNYLviKyT3q {
  margin-bottom: 8px;
}
.k1mqQwilmvtaRJuP5ag5 .dpiZfkEOgHNYLviKyT3q a {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.k1mqQwilmvtaRJuP5ag5 .dpiZfkEOgHNYLviKyT3q a:not(:last-child) {
  margin-right: 40px;
}
.k1mqQwilmvtaRJuP5ag5 .dpiZfkEOgHNYLviKyT3q a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.k1mqQwilmvtaRJuP5ag5 .dFZsv7n7JyX5EASDT6p7 {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/GlobalFooter/index.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,qBAAA;EACA,kBAAA;AACF;AAJA;EAMI,kBAAA;AACJ;AAPA;EASM,0BAAA;EACA,oBAAA;AACN;AACM;EACE,kBAAA;AACR;AAEM;EACE,0BAAA;AAAR;AAjBA;EAuBI,0BAAA;EACA,eAAA;AAHJ","sourcesContent":[".globalFooter {\n  padding: 0 16px;\n  margin: 48px 0 24px 0;\n  text-align: center;\n\n  .links {\n    margin-bottom: 8px;\n\n    a {\n      color: rgba(0, 0, 0, 0.45);\n      transition: all 0.3s;\n\n      &:not(:last-child) {\n        margin-right: 40px;\n      }\n\n      &:hover {\n        color: rgba(0, 0, 0, 0.85);\n      }\n    }\n  }\n\n  .copyright {\n    color: rgba(0, 0, 0, 0.45);\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalFooter": `k1mqQwilmvtaRJuP5ag5`,
	"links": `dpiZfkEOgHNYLviKyT3q`,
	"copyright": `dFZsv7n7JyX5EASDT6p7`
};
export default ___CSS_LOADER_EXPORT___;
