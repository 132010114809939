import quoteConstants from 'constants/quote.constants';

const initialState = {
  quote: '',
  author: '',
  errorWhileFetching: false,
  loading: true,
};

export default function quote(state = initialState, action) {
  switch (action.type) {
    case quoteConstants.FETCH_QUOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case quoteConstants.FETCH_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.fetchedData.quote,
        author: action.fetchedData.author,
        loading: false,
      };
    case quoteConstants.FETCH_QUOTE_ERROR:
      return {
        ...state,
        errorWhileFetching: true,
        loading: false,
      };
    default: {
      return state;
    }
  }
}
