import clientsConstants from 'constants/clients.constants';

function fetchClientsRequest(params, storeId) {
  return {
    type: clientsConstants.FETCH_CLIENTS_REQUEST,
    params,
    storeId,
  };
}

function fetchClientsSuccess(clients, storeId) {
  return {
    type: clientsConstants.FETCH_CLIENTS_SUCCESS,
    clients,
    storeId,
  };
}

function fetchClientsFailure(error, storeId) {
  return {
    type: clientsConstants.FETCH_CLIENTS_FAILURE,
    error,
    storeId,
  };
}

function deleteClientRequest(id, storeId) {
  return {
    type: clientsConstants.DELETE_CLIENT_REQUEST,
    id,
    storeId,
  };
}

function deleteClientSuccess(id, storeId) {
  return {
    type: clientsConstants.DELETE_CLIENT_SUCCESS,
    id,
    storeId,
  };
}

function deleteClientFailure(error, storeId) {
  return {
    type: clientsConstants.DELETE_CLIENT_FAILURE,
    error,
    storeId,
  };
}

function patchClientRequest(id, data, storeId) {
  return {
    type: clientsConstants.PATCH_CLIENT_REQUEST,
    id,
    data,
    storeId,
  };
}

function patchClientSuccess(id, storeId) {
  return {
    type: clientsConstants.PATCH_CLIENT_SUCCESS,
    id,
    storeId,
  };
}

function patchClientFailure(error, storeId) {
  return {
    type: clientsConstants.PATCH_CLIENT_FAILURE,
    error,
    storeId,
  };
}

function createClientRequest(data, storeId) {
  return {
    type: clientsConstants.CREATE_CLIENT_REQUEST,
    data,
    storeId,
  };
}

function createClientSuccess(data, storeId) {
  return {
    type: clientsConstants.CREATE_CLIENT_SUCCESS,
    data,
    storeId,
  };
}

function createClientFailure(error, storeId) {
  return {
    type: clientsConstants.CREATE_CLIENT_FAILURE,
    error,
    storeId,
  };
}

const clientsActions = {
  fetchClientsRequest,
  fetchClientsSuccess,
  fetchClientsFailure,
  deleteClientRequest,
  deleteClientSuccess,
  deleteClientFailure,
  patchClientRequest,
  patchClientSuccess,
  patchClientFailure,
  createClientRequest,
  createClientSuccess,
  createClientFailure,
};

export default clientsActions;
