const locationsConstants = {
  FETCH_LOCATIONS_REQUEST: 'LOCATIONS_FETCH_LOCATIONS_REQUEST',
  FETCH_LOCATIONS_SUCCESS: 'LOCATIONS_FETCH_LOCATIONS_SUCCESS',
  FETCH_LOCATIONS_FAILURE: 'LOCATIONS_FETCH_LOCATIONS_FAILURE',
  CREATE_LOCATION_REQUEST: 'LOCATIONS_CREATE_LOCATION_REQUEST',
  CREATE_LOCATION_SUCCESS: 'LOCATIONS_CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAILURE: 'LOCATIONS_CREATE_LOCATION_FAILURE',
  DELETE_LOCATION_REQUEST: 'LOCATIONS_DELETE_LOCATION_REQUEST',
  DELETE_LOCATION_SUCCESS: 'LOCATIONS_DELETE_LOCATION_SUCCESS',
  DELETE_LOCATION_FAILURE: 'LOCATIONS_DELETE_LOCATION_FAILURE',
};

export default locationsConstants;
