import React from 'react';
import { Modal, Input, InputNumber, AutoComplete, Form } from 'antd';
import { connect } from 'react-redux';
import projectsActions from 'actions/projects.actions';
import clientsActions from 'actions/clients.actions';
import ProjectPicker from 'components/ProjectPicker';

import styles from './EditTimeModal.less';

const EditTimeModal =
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        projects: [],
        clients: [],
        editedTimeEntry: {},
        editingArchivedEntry: false,
        descriptionDisabled: false,
      };
      this.editedProject.bind(this);
    }

    componentDidMount() {
      this.updateProjects();
      this.updateClients();
    }

    UNSAFE_componentWillReceiveProps(props) {
      this.setState({
        editedTimeEntry: props.editedTimeEntry,
      });
      const { projects, clients, editedTimeEntry } = props;
      if (!(projects.fetched && clients.fetched) || !editedTimeEntry.project) {
        return;
      }
      const newProjects = [...projects.projects.results];

      // since we're filtering projects by active/client__active
      // we can assume that if it wasn't returned by the API, it's archived
      const editingArchivedEntry =
        newProjects.filter(
          (project) => editedTimeEntry.project.id === project.id
        ).length === 0;

      // add archived project to array so form doesn't break
      if (editingArchivedEntry) {
        newProjects.push(editedTimeEntry.project);
      }
      this.setState({
        projects: newProjects,
        clients: [...clients.clients.results],
        editingArchivedEntry,
      });
    }

    handleAutocompleteSelect = (value) => {
      this.updateDescriptionDisabled(value);
      this.props.form.current.setFieldsValue({
        description: value,
      });
    };
    // eslint-disable-next-line
    handleAutocompleteFilterOption = (inputValue, option) => {
      return (
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      );
    };

    handleAutocompleteOnChange = (value) => {
      this.updateDescriptionDisabled(value);
    };

    updateDescriptionDisabled = (value) => {
      const descriptionDisabled = this.props.autoCompleteSuggestions.some(
        (item) => item.value === value
      );
      if (!descriptionDisabled && this.state.descriptionDisabled) {
        this.props.form.current.setFieldsValue({
          description: '',
        });
      }
      this.setState({ descriptionDisabled });
    };

    resetFields = () => {
      // Hack for a stupid bug in antd that nobody bothers fixing
      // you'd probably expect onChange to be fired when field value is
      // changed, but for some reason it doesn't on resetFields/setFieldsValue
      // https://github.com/ant-design/ant-design/issues/6636
      this.props.form.current.resetFields();
      this.updateDescriptionDisabled();
    };

    setFieldsValue = (values) => {
      // Hack for a stupid bug in antd that nobody bothers fixing
      // you'd probably expect onChange to be fired when field value is
      // changed, but for some reason it doesn't on resetFields/setFieldsValue
      // https://github.com/ant-design/ant-design/issues/6636
      this.props.form.current.setFieldsValue(values);
      this.updateDescriptionDisabled(values.task);
    };

    updateClients(input) {
      const { dispatch } = this.props;
      dispatch(
        clientsActions.fetchClientsRequest(
          {
            search: input,
          },
          'timesPage'
        )
      );
    }

    updateProjects(input) {
      const { dispatch } = this.props;
      dispatch(
        projectsActions.fetchProjectsRequest({
          search: input,
          // eslint-disable-next-line
          client__active: 'True',
          active: 'True',
        })
      );
    }

    editedProject() {
      const proj = this.state.projects.find(
        (project) => project.id === this.state.editedTimeEntry.project.id
      );
      if (!proj) {
        return null;
      }
      return proj.id;
    }

    render() {
      const {
        visible,
        onCancel,
        onEdit,
        editedTimeEntry,
        autoCompleteSuggestions,
      } = this.props;
      const { editingArchivedEntry, descriptionDisabled } = this.state;

      return (
        <Modal
          open={visible}
          title="Edit time"
          okText="Save"
          onCancel={onCancel}
          onOk={onEdit}
        >
          <Form layout="vertical" ref={this.props.form}>
            <Form.Item
              label="Project"
              name="project"
              initialValue={this.editedProject()}
              rules={[
                {
                  required: true,
                  message: 'Select something!',
                },
              ]}
            >
              <ProjectPicker
                disabled={editingArchivedEntry}
                projects={this.state.projects}
                clients={this.state.clients}
              />
            </Form.Item>
            <Form.Item
              label="Hours"
              className={styles.inputHours}
              name="hours"
              initialValue={editedTimeEntry.time}
              rules={[
                {
                  required: true,
                  type: 'number',
                  message: 'Enter time!',
                },
              ]}
            >
              <InputNumber step={0.01} min={0.01} max={23} />
            </Form.Item>
            <Form.Item
              label="Task ID"
              className={styles.inputTaskID}
              name="task"
              initialValue={editedTimeEntry.task}
            >
              <AutoComplete
                options={autoCompleteSuggestions}
                onSelect={this.handleAutocompleteSelect}
                onChange={this.handleAutocompleteOnChange}
                filterOption={this.handleAutocompleteFilterOption}
                type="text"
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              initialValue={editedTimeEntry.description}
              rules={[
                {
                  required: true,
                  min: 2,
                  message: 'At least 2 characters!',
                },
              ]}
            >
              <Input.TextArea disabled={descriptionDisabled} />
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  };

EditTimeModal.displayName = 'EditTimeModal';

function mapStateToProps(state) {
  const projects = state.projects.timesPage;
  const clients = state.clients.timesPage;
  return {
    projects,
    clients,
  };
}

export default connect(mapStateToProps)(EditTimeModal);
