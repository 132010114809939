const sortByCurrentUser = (a, b, me) => {
  const hasMeInTeamA = a.users.some((user) => user.id === me.data.id);
  const hasMeInTeamB = b.users.some((user) => user.id === me.data.id);

  if (hasMeInTeamA && !hasMeInTeamB) {
    return -1; // Team A comes before Team B
  }
  if (!hasMeInTeamA && hasMeInTeamB) {
    return 1; // Team B comes before Team A
  }
  return 0; // No preference
};

export default sortByCurrentUser;
