import officeIPsConstants from 'constants/officeIPs.constants';

function fetchIPsRequest(params) {
  return {
    type: officeIPsConstants.FETCH_IPS_REQUEST,
    params,
  };
}

function fetchIPsSuccess(ips) {
  return {
    type: officeIPsConstants.FETCH_IPS_SUCCESS,
    ips,
  };
}

function fetchIPsFailure(error) {
  return {
    type: officeIPsConstants.FETCH_IPS_FAILURE,
    error,
  };
}

function createIPRequest(data) {
  return {
    type: officeIPsConstants.CREATE_IP_REQUEST,
    data,
  };
}

function createIPSuccess(data) {
  return {
    type: officeIPsConstants.CREATE_IP_SUCCESS,
    data,
  };
}

function createIPFailure(error) {
  return {
    type: officeIPsConstants.CREATE_IP_FAILURE,
    error,
  };
}

function deleteIPRequest(id) {
  return {
    type: officeIPsConstants.DELETE_IP_REQUEST,
    id,
  };
}

function deleteIPSuccess(id) {
  return {
    type: officeIPsConstants.DELETE_IP_SUCCESS,
    id,
  };
}

function deleteIPFailure(error) {
  return {
    type: officeIPsConstants.DELETE_IP_FAILURE,
    error,
  };
}

const officeIPsActions = {
  fetchIPsRequest,
  fetchIPsSuccess,
  fetchIPsFailure,
  createIPRequest,
  createIPSuccess,
  createIPFailure,
  deleteIPRequest,
  deleteIPSuccess,
  deleteIPFailure,
};

export default officeIPsActions;
