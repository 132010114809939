import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Modal } from 'antd';
import Column from 'antd/lib/table/Column';
import Loader from 'components/Loader/Loader';
import UserGravatar from '../../components/UserGravatar/UserGravatar';
import freelancersActions from '../../actions/freelancers.actions';
import AddFreelancerModal from './AddFreelancerModal';
import './Access.css';

class Access extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = 'AccessTab';

    this.state = {
      addModalVisible: false,
    };
  }

  componentDidMount() {
    this.props.fetchFreelancers();
  }

  onCreate(data) {
    this.props.createFreelancer(data);
    this.setState({
      addModalVisible: false,
    });
  }

  handleDelete(freelancer) {
    const { deleteFreelancer } = this.props;
    Modal.confirm({
      title: 'Delete Freelancer',
      content: 'Are you sure?',
      okText: 'Delete',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteFreelancer(freelancer.id);
      },
    });
  }

  renderAction(text, record) {
    return (
      <div className="action-buttons float-right">
        <Button type="danger" onClick={() => this.handleDelete(record)}>
          Delete
        </Button>
      </div>
    );
  }

  render() {
    const { freelancers } = this.props;
    const { addModalVisible } = this.state;

    return (
      <Loader loading={!freelancers}>
        <div style={{ textAlign: 'right' }}>
          <Button
            className="add-freelancer-button"
            type="primary"
            onClick={() => {
              this.setState({ addModalVisible: true });
            }}
          >
            Add Freelancer
          </Button>
        </div>

        <Table rowKey="id" pagination={false} dataSource={freelancers}>
          <Column
            title="Freelancer"
            dataIndex="email"
            rowKey="id"
            render={(email) => (
              <span>
                <UserGravatar size="small" email={email} /> {email}
              </span>
            )}
          />
          <Column
            title=""
            key="action"
            render={(text, record) => this.renderAction(text, record)}
          />
        </Table>

        <AddFreelancerModal
          visible={addModalVisible}
          onCancel={() => {
            this.setState({ addModalVisible: false });
          }}
          onCreate={(data) => this.onCreate(data)}
        />
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.freelancers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFreelancers: () =>
      dispatch(freelancersActions.fetchFreelancersRequest()),
    createFreelancer: (data) =>
      dispatch(freelancersActions.createFreelancerRequest(data)),
    deleteFreelancer: (id) =>
      dispatch(freelancersActions.deleteFreelancerRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Access);
