import { Button, Col, Modal, Row, Table } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import clientsActions from 'actions/clients.actions';
import projectsActions from 'actions/projects.actions';
import Loader from 'components/Loader/Loader';
import { formatDate } from 'helpers/dateTime';
import AddClientModal from './AddClientModal';
import AddProjectModal from './AddProjectModal';
import './Admin.css';
import EditClientModal from './EditClientModal';
import EditProjectModal from './EditProjectModal';

class Admin extends Component {
  // eslint-disable-next-line
  displayName = 'Admin';
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const now = formatDate(dayjs());
    const date = dayjs(params.get('date') || now);

    this.state = {
      // eslint-disable-next-line
      date,
      clients: [],
      projects: [],
      clientFilter: null,
      createClientVisible: false,
      createProjectVisible: false,
      editClientVisible: false,
      editProjectVisible: false,
      editedClientEntry: {},
      editedProjectEntry: {},
    };

    this.saveCreateClientFormRef = this.saveCreateClientFormRef.bind(this);
    this.saveCreateProjectFormRef = this.saveCreateProjectFormRef.bind(this);
    this.saveClientEditFormRef = this.saveClientEditFormRef.bind(this);
    this.saveProjectEditFormRef = this.saveProjectEditFormRef.bind(this);
    this.handleCreateClient = this.handleCreateClient.bind(this);
    this.handleClientEdit = this.handleClientEdit.bind(this);
    this.updateClients = this.updateClients.bind(this);
    this.handleCreateClient = this.handleCreateClient.bind(this);
    this.handleCreateProject = this.handleCreateProject.bind(this);
    this.updateProjects = this.updateProjects.bind(this);
    this.getClientRowClassName = this.getClientRowClassName.bind(this);
    this.handleClientRowClick = this.handleClientRowClick.bind(this);
    this.handleProjectEdit = this.handleProjectEdit.bind(this);
  }
  /* eslint-disable lines-between-class-members */
  clientRef = React.createRef();
  projectRef = React.createRef();
  editClientRef = React.createRef();
  editProjectRef = React.createRef();
  editTimeRef = React.createRef();

  componentDidMount() {
    this.updateClients();
    this.updateProjects();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { clients, projects } = props;
    const clientsQueryResult = clients.projectsPage;
    const projectsQueryResult = projects.projectsPage;
    if (clientsQueryResult && clientsQueryResult.fetched) {
      /* eslint-disable no-shadow */
      const clients = [];
      clientsQueryResult.clients.results.forEach((value) => {
        if (value.active) {
          clients.push(value);
        }
      });
      this.setState({
        clients,
      });
    }
    if (projectsQueryResult && projectsQueryResult.fetched) {
      const projects = [];
      projectsQueryResult.projects.results.forEach((value) => {
        if (value.active) {
          projects.push(value);
        }
      });
      this.setState({
        projects,
      });
    }
  }

  getClientRowClassName(record) {
    const { clientFilter } = this.state;
    let style = '';
    if (record.id === clientFilter) {
      style += 'selected';
    } else {
      style += 'selectable';
    }
    return style;
  }

  saveCreateClientFormRef(formRef) {
    this.createClientFormRef = formRef;
  }

  saveCreateProjectFormRef(formRef) {
    this.createProjectFormRef = formRef;
  }

  saveClientEditFormRef(formRef) {
    this.editClientFormRef = formRef;
  }

  saveProjectEditFormRef(formRef) {
    this.editProjectFormRef = formRef;
  }

  handleCreateClientCancel() {
    this.setState({ createClientVisible: false });
  }

  handleEditCancel() {
    // eslint-disable-next-line
    this.setState({ editVisible: false });
  }

  async handleProjectEdit() {
    try {
      const form = this.editProjectRef.current;
      const { dispatch } = this.props;
      const values = await form?.validateFields();
      await dispatch(
        projectsActions.patchProjectRequest(
          this.state.editedProjectEntry.id,
          {
            name: values.name,
            color: values.color.hex,
          },
          'projectsPage'
        )
      );
      this.setState({ editProjectVisible: false });
      /* eslint-disable no-empty */
    } catch (err) {}
  }

  async handleClientEdit() {
    try {
      const form = this.editClientRef.current;
      const { dispatch } = this.props;
      const values = await form?.validateFields();
      await dispatch(
        clientsActions.patchClientRequest(
          this.state.editedClientEntry.id,
          {
            name: values.name,
            color: values.color.hex,
          },
          'projectsPage'
        )
      );
      this.setState({ editClientVisible: false });
    } catch (err) {}
  }

  async handleCreateProject() {
    try {
      const form = this.projectRef.current;
      const { dispatch } = this.props;
      const { clientFilter } = this.state;
      const values = await form?.validateFields();
      await dispatch(
        projectsActions.createProjectRequest(
          {
            name: values.name,
            color: values.color.hex,
            client: clientFilter,
          },
          'projectsPage'
        )
      );
      await form?.resetFields();
      this.setState({ createProjectVisible: false });
    } catch (err) {}
  }

  async handleCreateClient() {
    try {
      const form = this.clientRef.current;
      const { dispatch } = this.props;
      const values = await form?.validateFields();
      await dispatch(
        clientsActions.createClientRequest(
          { name: values.name, color: values.color.hex },
          'projectsPage'
        )
      );
      await form?.resetFields();
      this.setState({ createClientVisible: false });
    } catch (err) {}
  }

  showArchiveProjectModal(event, project) {
    event.stopPropagation();
    const confirm = Modal.confirm;
    const { dispatch } = this.props;
    confirm({
      title: 'Archive Project',
      content: 'Are you sure?',
      okText: 'Archive',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        dispatch(
          projectsActions.deleteProjectRequest(project.id, 'projectsPage')
        );
      },
    });
  }

  showArchiveClientModal(event, client) {
    event.stopPropagation();
    const confirm = Modal.confirm;
    const { dispatch } = this.props;
    confirm({
      title: 'Archive Client',
      content: 'Are you sure?',
      okText: 'Archive',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        dispatch(clientsActions.deleteClientRequest(client.id, 'projectsPage'));
      },
    });
  }

  showEditProjectModal(event, projectEntry) {
    event.stopPropagation();
    const { name, color } = projectEntry;
    // eslint-disable-next-line
    this.editProjectRef.current?.setFieldsValue({
      name,
      color: {
        hex: color,
      },
    });
    this.setState({
      editedProjectEntry: projectEntry,
      editProjectVisible: true,
    });
  }

  showEditClientModal(event, clientEntry) {
    event.stopPropagation();
    const { name } = clientEntry;
    // eslint-disable-next-line
    this.editClientRef.current?.setFieldsValue({
      name,
    });
    this.setState({
      editedClientEntry: clientEntry,
      editClientVisible: true,
    });
  }

  updateClients() {
    const { dispatch } = this.props;
    dispatch(clientsActions.fetchClientsRequest({}, 'projectsPage'));
  }

  updateProjects() {
    const { dispatch } = this.props;
    const { clientFilter } = this.state;
    const params = {};
    if (clientFilter) {
      /* eslint-disable dot-notation */
      params['client'] = clientFilter;
    } else {
      params['client__active'] = 'True';
    }
    dispatch(projectsActions.fetchProjectsRequest(params, 'projectsPage'));
  }

  handleClientRowClick(row) {
    this.setState(
      {
        // eslint-disable-next-line
        clientFilter: this.state.clientFilter !== row.id ? row.id : undefined,
      },
      () => {
        this.updateProjects();
      }
    );
  }

  render() {
    const clientColumns = [
      {
        className: 'color-bar',
        key: 'colorBar',
        render: (record) => {
          return (
            <div
              style={{ background: record.color, width: '8px', height: '65px' }}
            />
          );
        },
      },
      {
        title: 'Client',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
          <div className="action-buttons float-right">
            <Button
              type="default"
              onClick={(event) => this.showEditClientModal(event, record)}
            >
              Edit
            </Button>
            <Button
              danger
              onClick={(event) => this.showArchiveClientModal(event, record)}
            >
              Archive
            </Button>
          </div>
        ),
      },
    ];
    const projectColumns = [
      {
        className: 'color-bar',
        key: 'colorBar',
        render: (record) => {
          return (
            <div
              style={{ background: record.color, width: '8px', height: '65px' }}
            />
          );
        },
      },
      {
        title: 'Project',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
          <div className="action-buttons float-right">
            <Button
              type="default"
              onClick={(event) => this.showEditProjectModal(event, record)}
            >
              Edit
            </Button>
            <Button
              danger
              onClick={(event) => this.showArchiveProjectModal(event, record)}
            >
              Archive
            </Button>
          </div>
        ),
      },
    ];
    const { clients, projects, clientFilter } = this.state;
    const createClientModal = (
      <AddClientModal
        form={this.clientRef}
        visible={this.state.createClientVisible}
        onCancel={() => this.setState({ createClientVisible: false })}
        onCreate={this.handleCreateClient}
      />
    );
    const editClientModal = (
      <EditClientModal
        form={this.editClientRef}
        visible={this.state.editClientVisible}
        onCancel={() => this.setState({ editClientVisible: false })}
        onEdit={this.handleClientEdit}
        entry={this.state.editedClientEntry}
      />
    );
    const createProjectModal = (
      <AddProjectModal
        form={this.projectRef}
        visible={this.state.createProjectVisible}
        onCancel={() => this.setState({ createProjectVisible: false })}
        onCreate={this.handleCreateProject}
      />
    );
    const editProjectModal = (
      <EditProjectModal
        form={this.editProjectRef}
        visible={this.state.editProjectVisible}
        onCancel={() => this.setState({ editProjectVisible: false })}
        onEdit={this.handleProjectEdit}
        entry={this.state.editedProjectEntry}
      />
    );

    return (
      <Loader loading={clients.length <= 0 || projects.length <= 0}>
        <Helmet title="Admin" />
        {createClientModal}
        {editClientModal}
        {createProjectModal}
        {editProjectModal}
        <Row gutter={16}>
          <Col span={12}>
            <div className="table-operations">
              <Button
                onClick={() => this.setState({ createClientVisible: true })}
              >
                Add Client
              </Button>
            </div>
            <Table
              columns={clientColumns}
              pagination={false}
              dataSource={clients}
              rowKey="id"
              style={{ overflow: 'auto' }}
              rowClassName={this.getClientRowClassName}
              onRow={(record) => {
                return {
                  onClick: () => this.handleClientRowClick(record),
                };
              }}
            />
          </Col>
          <Col span={12}>
            <div className="table-operations">
              <Button
                onClick={() => this.setState({ createProjectVisible: true })}
                disabled={!clientFilter}
              >
                Add Project
              </Button>
            </div>
            <Table
              columns={projectColumns}
              pagination={false}
              dataSource={projects}
              rowKey="id"
              style={{ overflow: 'auto' }}
            />
          </Col>
        </Row>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  const { times, me, clients, projects } = state;
  return {
    times,
    me,
    clients,
    projects,
  };
}

export default connect(mapStateToProps)(Admin);
