import usersConstants from 'constants/users.constants';

function fetchUsersRequest(params, storeId) {
  return {
    type: usersConstants.FETCH_USERS_REQUEST,
    params,
    storeId,
  };
}

function fetchUsersSuccess(users, storeId) {
  return {
    type: usersConstants.FETCH_USERS_SUCCESS,
    users,
    storeId,
  };
}

function fetchUsersFailure(error, storeId) {
  return {
    type: usersConstants.FETCH_USERS_FAILURE,
    error,
    storeId,
  };
}

const usersActions = {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
};

export default usersActions;
