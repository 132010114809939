import accessConstants from 'constants/access.constants';

/* eslint-disable */
export default function access(state = {}, action) {
  switch (action.type) {
    case accessConstants.RETRIEVE_ACCESS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
      });

    case accessConstants.RETRIEVE_ACCESS_SUCCESS:
      return Object.assign({}, state, {
        access: action.data,
        fetched: true,
      });

    case accessConstants.RETRIEVE_ACCESS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        access: {},
      });

    case accessConstants.PATCH_ACCESS_REQUEST:
      return Object.assign({}, state, {
        patching: true,
      });

    case accessConstants.PATCH_ACCESS_SUCCESS:
      return Object.assign({}, state, {
        patching: false,
      });

    case accessConstants.PATCH_ACCESS_FAILURE:
      return Object.assign({}, state, {
        patching: false,
      });

    default:
      return state;
  }
}
