import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row, Select, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Fuse from 'fuse.js';
import { format, parseISO } from 'date-fns';
import UserGravatar from 'components/UserGravatar/UserGravatar';
import archiveActions from 'actions/archive.actions';
import styles from './Archive.less';
import Loader from 'components/Loader/Loader';

const Archive = () => {
  const dispatch = useDispatch();
  const { archive } = useSelector((state) => state.archive);
  const [usersFilter, setUsersFilter] = useState([]);
  const [sortOption, setSortOption] = useState('alpha');

  useEffect(() => {
    dispatch(archiveActions.fetchArchiveRequest());
  }, []);

  const showArchiveModal = (user) => {
    const confirm = Modal.confirm;
    confirm({
      title: 'Archive user',
      content: 'Are you sure to archive user?',
      okText: 'Archive',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        dispatch(archiveActions.archiveRequest(user.id, false));
      },
    });
  };

  const showUnarchiveModal = (user) => {
    const confirm = Modal.confirm;
    confirm({
      title: 'Unarchive user',
      content: 'Are you sure to unarchive user?',
      okText: 'Unarchive',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        dispatch(archiveActions.archiveRequest(user.id, true));
      },
    });
  };

  const handleUsersChange = (filter) => {
    setUsersFilter(filter);
  };

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  const filteredArchive =
    usersFilter.length >= 1
      ? archive?.filter((data) =>
          usersFilter.some((userId) => userId === `${data.id}`)
        )
      : archive;

  const sortedArchive = filteredArchive?.toSorted((a, b) => {
    if (sortOption === 'activity') {
      const dateA = a.last_login ? parseISO(a.last_login) : new Date(0);
      const dateB = b.last_login ? parseISO(b.last_login) : new Date(0);
      return dateB - dateA;
    }
    return a.name.localeCompare(b.name);
  });

  return (
    <Loader loading={!sortedArchive}>
      <Helmet title="Archive" />
      <Row type="flex" gutter={6}>
        <Col md={6}>
          <Select
            value={usersFilter}
            placeholder="Users"
            className="group-select"
            mode="tags"
            onChange={handleUsersChange}
            filterOption={(input, option) => {
              const fuse = new Fuse(archive, {
                keys: ['name'],
                threshold: 0.3, // Adjust this value for desired fuzziness
              });
              const results = fuse.search(input);
              return results
                .map((res) => res.item.name)
                .includes(option.props.children);
            }}
          >
            {archive
              /* eslint-disable */
              ?.sort((a, b) => ('' + a.name.attr).localeCompare(b.name.attr))
              .map((value) => {
                return (
                  <Select.Option value={String(value.id)} key={value.id}>
                    {value.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Col>
        <Col md={8}>
          <label>Sort by: </label>
          {/* eslint-enable */}
          <Select
            value={sortOption}
            placeholder="Sort by"
            onChange={handleSortChange}
          >
            <Select.Option value="activity">Last activity</Select.Option>
            <Select.Option value="alpha">Aplhabetically</Select.Option>
          </Select>
        </Col>
      </Row>
      <Table
        size="middle"
        bordered
        pagination={false}
        scroll={{ x: true }}
        dataSource={sortedArchive}
        rowClassName={(record) => (record.is_active ? '' : styles.inactive)}
        rowKey={(record) => {
          return record.year === 'Totals'
            ? `${record.id}-${record.year}`
            : record.id;
        }}
      >
        <Column
          title="User"
          align="left"
          className="user-column"
          key="userCol"
          render={(text, row) => {
            return (
              <Link to={`/users/${row.id}`}>
                <UserGravatar user={row} email={row.email} /> {row.name}
              </Link>
            );
          }}
        />
        <Column
          title="Last active"
          dataIndex="last_login"
          render={(text) => (
            <span>{format(new Date(text), 'yyyy-MM-dd HH:mm')}</span>
          )}
        />
        <Column
          title="Actions"
          render={(text, row) => {
            return row.is_active ? (
              <Button onClick={() => showArchiveModal(row)} danger>
                Archive
              </Button>
            ) : (
              <Button onClick={() => showUnarchiveModal(row)}>Unarchive</Button>
            );
          }}
        />
      </Table>
    </Loader>
  );
};

export default Archive;
