import archiveConstants from 'constants/archive.constants';

function fetchArchiveRequest(params) {
  return {
    type: archiveConstants.FETCH_ARCHIVE_REQUEST,
    params,
  };
}

function fetchArchiveSuccess(response) {
  return {
    type: archiveConstants.FETCH_ARCHIVE_SUCCESS,
    fetchedData: response.results,
  };
}

function fetchArchiveFailure(error) {
  return {
    type: archiveConstants.FETCH_ARCHIVE_FAILURE,
    error,
  };
}

function archiveRequest(id, active) {
  return {
    type: archiveConstants.ARCHIVE_REQUEST,
    id,
    active,
  };
}

function archiveSuccess() {
  return {
    type: archiveConstants.ARCHIVE_SUCCESS,
  };
}

function archiveFailure(error) {
  return {
    type: archiveConstants.ARCHIVE_FAILURE,
    error,
  };
}

export default {
  fetchArchiveRequest,
  fetchArchiveSuccess,
  fetchArchiveFailure,
  archiveRequest,
  archiveSuccess,
  archiveFailure,
};
