import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from 'reducers/rootReducer';
import rootEpic from 'epic/root.epic';

import history from './history';
import rootSaga from '../sagas/rootSaga';

const routingMiddleware = routerMiddleware(history);

const epicMiddleware = createEpicMiddleware();

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware, routingMiddleware, epicMiddleware)
  )
);

sagaMiddleware.run(rootSaga);
epicMiddleware.run(rootEpic);

export default store;
