import notificationsConstants from 'constants/notifications.constants';

const initialState = {
  notifications: [],
  errors: null,
  fetching: false,
  isLoading: false,
};

/* eslint-disable no-case-declarations */
export default function notifications(state = initialState, action) {
  switch (action.type) {
    case notificationsConstants.FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, fetching: true, isLoading: true };
    case notificationsConstants.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.data,
        fetching: false,
        isLoading: false,
      };
    case notificationsConstants.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        errors: action.error,
        fetching: false,
        isLoading: false,
      };
    case notificationsConstants.PATCH_NOTIFICATIONS_REQUEST:
      return { ...state, fetching: true };
    case notificationsConstants.PATCH_NOTIFICATIONS_SUCCESS:
      const updatedNotifications = state.notifications.map((notification) => {
        const updatedNotification = action.data.find(
          (dataItem) =>
            dataItem.notification_id === notification.notification_id
        );

        if (updatedNotification) {
          return updatedNotification;
        }

        return notification;
      });
      return {
        ...state,
        notifications: updatedNotifications,
        fetching: false,
      };
    case notificationsConstants.PATCH_NOTIFICATIONS_FAILURE:
      return { ...state, errors: action.error, fetching: false };
    case notificationsConstants.ARCHIVE_NOTIFICATIONS_REQUEST:
      return { ...state, fetching: true };
    case notificationsConstants.ARCHIVE_NOTIFICATIONS_SUCCESS:
      const archivedNotificationIds = action.data.map(
        (dataItem) => dataItem.notification_id
      );
      const filteredNotifications = state.notifications.filter(
        (notification) => {
          return !archivedNotificationIds.includes(
            notification.notification_id
          );
        }
      );
      return {
        ...state,
        notifications: filteredNotifications,
        fetching: false,
      };
    case notificationsConstants.ARCHIVE_NOTIFICATIONS_FAILURE:
      return { ...state, errors: action.error, fetching: false };
    default:
      return state;
  }
}
