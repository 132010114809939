// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AYZb3UPiU67Lz59XTGjk {
  position: absolute;
  left: 0vw;
  bottom: 0vh;
  opacity: 0;
  z-index: 9;
  display: none;
}
.darkMode .AYZb3UPiU67Lz59XTGjk {
  filter: invert(1);
}
.tWEfJxzzxZF8SvdzxpcA {
  animation: PWyisg1YyyXDLDATf618 6s ease-in-out forwards;
  display: block;
  animation-delay: 2s;
  opacity: 1 !important;
}
@keyframes PWyisg1YyyXDLDATf618 {
  to {
    left: 100vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Sprite/Sprite.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,UAAA;EACA,aAAA;AACF;AAEA;EAEI,iBAAA;AADJ;AAKA;EACE,uDAAA;EACA,cAAA;EACA,mBAAA;EACA,qBAAA;AAHF;AAMA;EACE;IACE,WAAA;EAJF;AACF","sourcesContent":[".sprite {\n  position: absolute;\n  left: 0vw;\n  bottom: 0vh;\n  opacity: 0;\n  z-index: 9;\n  display: none;\n}\n\n:global(.darkMode) {\n  .sprite {\n    filter: invert(1);\n  }\n}\n\n.active {\n  animation: slideRight 6s ease-in-out forwards;\n  display: block;\n  animation-delay: 2s;\n  opacity: 1 !important;\n}\n\n@keyframes slideRight {\n  to {\n    left: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sprite": `AYZb3UPiU67Lz59XTGjk`,
	"active": `tWEfJxzzxZF8SvdzxpcA`,
	"slideRight": `PWyisg1YyyXDLDATf618`
};
export default ___CSS_LOADER_EXPORT___;
