import React, { useState, useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import './index.css';

const EditableContext = React.createContext();

/* eslint-disable react/jsx-props-no-spreading */
export const EditableRow = ({ index, ...props }) => (
  <EditableContext.Provider>
    <tr {...props} />
  </EditableContext.Provider>
);

export const EditableCell = (props) => {
  const {
    editable,
    dataIndex,
    title,
    record,
    index,
    handleSave,
    children,
    inputType,
    ...restProps
  } = props;

  const [editing, setEditing] = useState(false);

  const input = useRef(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (editing) input.current.focus();
  }, [editing]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      setEditing(false);
      handleSave({ ...record, ...values });
      // eslint-disable-next-line
    } catch (error) {}
  };

  const cancel = () => {
    setEditing(false);
  };

  const getInput = () => {
    return (
      <Input
        className="editable-cell-input"
        ref={input}
        onPressEnter={save}
        type="number"
        onBlur={cancel}
      />
    );
  };

  const renderCell = () => {
    const inputRule = {
      type: inputType,
      message: `${title} has to be ${inputType}.`,
    };
    if (inputType === 'integer')
      inputRule.transform = (value) => parseInt(value, 10);

    const rules = [
      inputRule,
      {
        required: true,
        message: `${title} is required.`,
      },
    ];

    return editing ? (
      <Form form={form}>
        <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
          {getInput()}
        </Form.Item>
      </Form>

    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onDoubleClick={() => setEditing(!editing)}
      >
        {children}
      </div>
    );
  };

  return <td {...restProps}>{editable ? renderCell() : children}</td>;
};
