import { Observable } from 'rxjs';
import meConstants from 'constants/me.constants';
import meActions from 'actions/me.actions';
import authActions from 'actions/auth.actions';
import { get } from 'helpers/ajax';

const meEpic = (action$) =>
  Observable.merge(
    action$.ofType(meConstants.RETRIEVE_ME_REQUEST).mergeMap(() =>
      get('/api/v1/users/me', {}, true)
        .map((response) => meActions.retrieveMeSuccess(response.response))
        .catch((error) => Observable.of(meActions.retrieveMeFailure(error)))
    ),
    action$
      .ofType(meConstants.RETRIEVE_ME_SUCCESS)
      .do((action) => localStorage.setItem('me', JSON.stringify(action.data)))
      .ignoreElements(),
    action$.ofType(meConstants.RETRIEVE_ME_FAILURE).mapTo(authActions.logout())
  );

export default meEpic;
