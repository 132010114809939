import { uniqWith } from 'lodash';
import { createSelector } from 'reselect';

export const usersList = (state) => state.users?.users?.users?.results || [];

export const selectNotifications = (state) => state.notifications;

export const selectUnreadNotifications = createSelector(
  selectNotifications,
  usersList,
  (notifications, users) =>
    uniqWith(
      // TO DELETE AFTER HANDLING IT ON BACKEND
      notifications.notifications,
      (n1, n2) =>
        n1.title === n2.title &&
        n1.title === 'Please fill in the working hours.'
    )
      ?.filter((notification) => !notification.viewed_at)
      .map((notification) => {
        return {
          ...notification,
          // eslint-disable-next-line
          senderName:
            users?.find((user) => user?.id === +notification?.sender_id)
              ?.name || notification?.sender_id,
        };
      })
);

export const selectUnreadNotificationsCount = createSelector(
  selectUnreadNotifications,
  (notifications) => notifications?.length
);

export const selectNotification = (id) =>
  createSelector(selectNotifications, (notifications) =>
    notifications.notifications?.find(
      // eslint-disable-next-line
      (notification) => notification.notification_id == id
    )
  );

export const selectNotificationSender = (id) =>
  createSelector(
    usersList,
    (users) => users?.find((user) => user.id === +id)?.name
  );
