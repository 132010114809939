import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import history from 'helpers/history';

// Reducers
import alertsReducer from './alerts.reducer';
import authentication from './auth.reducer';
import users from './users.reducer';
import clients from './clients.reducer';
import projects from './projects.reducer';
import times from './times.reducer';
import me from './me.reducer';
import user from './user.reducer';
import access from './access.reducer';
import locations from './locations.reducer';
import roles from './roles.reducer';
import officeIPs from './officeIPs.reducer';
import domains from './domains.reducer';
import groups from './groups.reducer';
import { presences, presenceTick } from './presences.reducer';
import raport from './raport.reducer';
import daysOff from './daysOff.reducer';
import daysOffAllowances from './daysOffAllowances.reducer';
import publicLinks from './publicLinks.reducer';
import freelancers from './freelancers.reducer';
import remoteWork from './remoteWork.reducer';
import quote from './quote.reducer';
import teams from './teams.reducer';
import permissions from './permissions.reducer';
import holidays from './holidays.reducer';
import archive from './archive.reducer';
import notifications from './notifications.reducer';
import login from './login.reducers';

export default combineReducers({
  router: connectRouter(history),
  authentication,
  alerts: alertsReducer,
  form: formReducer,
  users,
  clients,
  projects,
  times,
  me,
  user,
  access,
  locations,
  roles,
  officeIPs,
  domains,
  groups,
  presences,
  presenceTick,
  raport,
  daysOff,
  daysOffAllowances,
  publicLinks,
  freelancers,
  remoteWork,
  quote,
  teams,
  permissions,
  holidays,
  archive,
  notifications,
  login,
});
