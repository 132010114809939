import remoteWorkConstants from 'constants/remoteWork.constants';

const initialState = { fetching: false };
export default function remoteWork(state = initialState, action) {
  switch (action.type) {
    case remoteWorkConstants.FETCH_REMOTE_WORK_REQUEST:
    case remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_REQUEST:
      return { ...state, fetching: true };

    case remoteWorkConstants.FETCH_REMOTE_WORK_SUCCESS:
      return {
        ...state,
        remoteWork: action.fetchedData,
        fetching: false,
        next: action.next,
      };

    case remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_SUCCESS:
      return {
        ...state,
        remoteWork: state.remoteWork.slice().concat(action.fetchedData),
        next: action.next,
        fetching: false,
      };

    case remoteWorkConstants.FETCH_REMOTE_WORK_FAILURE:
    case remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_FAILURE:
      return { ...state, fetching: false };
    default: {
      return state;
    }
  }
}
