import { Observable } from 'rxjs';
import teamsConstants from 'constants/teams.constants';
import teamsActions from '../actions/teams.action';
import { get } from 'helpers/ajax';

const teamsEpic = (action$) =>
  Observable.merge(
    action$.ofType(teamsConstants.FETCH_TEAMS_REQUEST).mergeMap(() =>
      get(`/api/v1/teams`, {}, true)
        .map((response) => teamsActions.fetchTeamsSuccess(response.response))
        .catch((error) => Observable.of(teamsActions.fetchTeamsFailure(error)))
    )
  );

export default teamsEpic;
