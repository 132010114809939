import loginConstants from '../constants/login.constants';

function loginRequest(credentials) {
  return {
    type: loginConstants.LOGIN_REQUEST,
    credentials,
  };
}

function loginSuccess(token) {
  return {
    type: loginConstants.LOGIN_SUCCESS,
    token,
  };
}

function loginFailure(error) {
  return {
    type: loginConstants.LOGIN_FAILURE,
    error,
  };
}

function registerRequest(credentials, token) {
  return {
    type: loginConstants.REGISTER_REQUEST,
    credentials,
    token,
  };
}

function registerSuccess(response) {
  return {
    type: loginConstants.REGISTER_SUCCESS,
    response,
  };
}

function registerFailure(error) {
  return {
    type: loginConstants.REGISTER_FAILURE,
    error,
  };
}

const loginActions = {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
};

export default loginActions;
