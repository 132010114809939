import BasicLayout from 'layout/BasicLayout';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';

import meActions from 'actions/me.actions';
import presencesActions from 'actions/presences.actions';
import { isLoggedIn } from 'helpers/isAuthorized';
import Admin from 'pages/Admin/Admin';
import Archive from 'pages/Archive/Archive';
import DaysOff from 'pages/DaysOff';
import MessagesCenter from 'pages/MessagesCenter/MessagesCenter';
import MonthlyReport from 'pages/Monthly/Monthly';
import Presence from 'pages/Presence/Presence';
import RemoteWork from 'pages/RemoteWork';
import Reports from 'pages/Reports/Reports';
import Settings from 'pages/Settings/Settings';
import Times from 'pages/Times/Times';
import UserProfile from 'pages/UserProfile/UserProfile';
import holidaysActions from '../../actions/holidays.actions';
import permissionsActions from '../../actions/permissions.actions';
import teamsActions from '../../actions/teams.action';

class Full extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    this.refreshData();
    const minute = 60000 * 3;
    this.interval = setInterval(this.refreshData, minute);
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.interval);
  }

  refreshData() {
    const { dispatch } = this.props;
    dispatch(presencesActions.sendPresenceTick());
    dispatch(meActions.retrieveMeRequest());
    dispatch(teamsActions.fetchTeamsRequest());
    dispatch(holidaysActions.fetchHolidaysRequest());
    dispatch(permissionsActions.fetchPermissionsRequest());
  }

  render() {
    return (
      <BasicLayout>
        <Switch>
          <Route exact path="/presence" name="Presence" component={Presence} />
          <Route exact path="/users/:id/times" name="Times" component={Times} />
          <Route
            exact
            path="/admin-panel/admin"
            name="Admin"
            component={Admin}
          />
          <Route exact path="/reports" name="Reports" component={Reports} />
          <Route
            exact
            path="/"
            name="Monthly"
            render={() => {
              if (isLoggedIn()) {
                return <Redirect to="/monthly" />;
              }
              return <Redirect to="/login" />;
            }}
          />
          <Route
            exact
            path="/monthly"
            name="Monthly Report"
            component={MonthlyReport}
          />
          <Route path="/days-off" name="Days Off" component={DaysOff} />
          <Route
            exact
            path="/remote-work"
            name="Remote Work"
            component={RemoteWork}
          />
          <Route
            exact
            path="/users/:id"
            name="UserProfile"
            component={UserProfile}
          />
          <Route
            exact
            path="/admin-panel/settings"
            name="Settings"
            render={() => {
              return <Redirect to="/admin-panel/settings/access" />;
            }}
          />
          <Route
            exact
            path="/admin-panel/settings/:tab"
            name="Settings"
            component={Settings}
          />
          <Route
            exact
            path="/admin-panel/archive"
            name="Archive"
            component={Archive}
          />
          <Route
            path="/messages-center"
            name="Messages Center"
            component={MessagesCenter}
          />
        </Switch>
      </BasicLayout>
    );
  }
}

export default withRouter(connect()(Full));
