import { ajax } from 'rxjs/observable/dom/ajax';
/* eslint-disable */

function configureHeaders(requiredToken = false) {
  if (requiredToken) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null && typeof user.token !== undefined) {
      return {
        Authorization: `Token ${user.token}`,
      };
    }
    return {};
  }

  return {};
}

const __API__ = window.__API__;

export const getUrl = (url, headers, requireToken = false) =>
  ajax.get(url, Object.assign({}, configureHeaders(requireToken), headers));

export const get = (url, headers, requireToken = false, exactUrl = false) => {
  let requestUrl = url;
  if (!exactUrl) {
    requestUrl = __API__ + url;
  }
  return ajax.get(
    requestUrl,
    Object.assign({}, configureHeaders(requireToken), headers)
  );
};

export const post = (
  url,
  data,
  headers,
  requireToken = false,
  exactUrl = false
) => {
  let requestUrl = url;
  if (!exactUrl) {
    requestUrl = __API__ + url;
  }
  return ajax.post(
    requestUrl,
    data,
    Object.assign({}, configureHeaders(requireToken), headers)
  );
};

export const put = (
  url,
  data,
  headers,
  requireToken = false,
  exactUrl = false
) => {
  let requestUrl = url;
  if (!exactUrl) {
    requestUrl = __API__ + url;
  }
  return ajax.put(
    requestUrl,
    data,
    Object.assign({}, configureHeaders(requireToken), headers)
  );
};

export const remove = (url, headers, requireToken = true, exactUrl = false) => {
  let requestUrl = url;
  if (!exactUrl) {
    requestUrl = __API__ + url;
  }
  return ajax.delete(
    requestUrl,
    Object.assign({}, configureHeaders(requireToken), headers)
  );
};

export const patch = (
  url,
  data,
  headers,
  requireToken = true,
  exactUrl = false
) => {
  let requestUrl = url;
  if (!exactUrl) {
    requestUrl = __API__ + url;
  }
  return ajax.patch(
    requestUrl,
    data,
    Object.assign({}, configureHeaders(requireToken), headers)
  );
};
