import notificationsActions from 'actions/notifications.actions';
import usersActions from 'actions/users.actions';
import { notification } from 'antd';
import notificationConfig from 'components/Notification';
import history from 'helpers/history';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUnreadNotifications,
  usersList,
} from 'selectors/notifications.selectors';
import styles from './Notification.less';

const config = {
  stack: {
    threshold: 2,
  },
  maxCount: 4,
};

const useNotifications = () => {
  const dispatch = useDispatch();
  const [notificationApi, contextHolder] = notification.useNotification(config);
  const unread = useSelector(selectUnreadNotifications);
  const unreadFromUlam = unread?.filter(
    (noti) => noti.notification_id === 'ULAM LABS'
  );
  const users = useSelector(usersList);

  const isMessageCenter = window.location.pathname.includes('messages-center');

  useEffect(() => {
    dispatch(usersActions.fetchUsersRequest({}, 'users'));
  }, []);

  const closeNotification = (ids) => {
    dispatch(notificationsActions.patchNotificationsRequest(ids, true));
  };

  const clickNotification = (notificationId) => {
    history.push(
      `/messages-center${notificationId ? `/${notificationId}` : ''}`
    );
    dispatch(
      notificationsActions.patchNotificationsRequest([notificationId], true)
    );
  };

  const closeAllAutoAlerts = () => {
    const toCloseIds = unreadFromUlam.map((noti) => noti.notification_id);
    closeNotification(toCloseIds);
  };

  useEffect(() => {
    if (unread.length === 0 || isMessageCenter || !users) {
      return;
    }

    const unreadWithDismiss = [
      ...unread.slice(0, -3),
      {
        key: 'dismiss',
        description: 'Hide Auto Alerts',
        message: '',
        placement: 'bottomRight',
        closeIcon: null,
        className: styles.dismiss,
        duration: 20,
        onClick: () => closeAllAutoAlerts(),
      },
      ...unread.slice(-3),
    ];
    // eslint-disable-next-line
    unreadWithDismiss?.forEach((noti) => {
      if (noti.key === 'dismiss' && unreadFromUlam.length >= 2) {
        notificationApi.open(noti);
      }

      if (!noti.senderName) {
        return;
      }
      notificationApi.open(
        notificationConfig(
          noti,
          () => closeNotification([noti.notification_id]),
          () => clickNotification(noti.notification_id),
          noti.senderName
        )
      );
    });

    return () => {
      unreadWithDismiss.forEach((noti) => {
        notificationApi.destroy(noti.key);
      });
    };
  }, [unread]);

  return { contextHolder };
};

export default useNotifications;
