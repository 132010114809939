import { Observable } from 'rxjs';
import queryString from 'query-string';
import usersConstants from 'constants/users.constants';
import usersActions from 'actions/users.actions';
import { get } from 'helpers/ajax';

const usersEpic = (action$) =>
  Observable.merge(
    action$.ofType(usersConstants.FETCH_USERS_REQUEST).mergeMap((action) =>
      get(
        `/api/v1/users?include=user&active=true&page_size=300&${queryString.stringify(
          action.params
        )}`,
        {},
        true
      )
        .map((response) =>
          usersActions.fetchUsersSuccess(response.response, action.storeId)
        )
        .catch((error) =>
          Observable.of(usersActions.fetchUsersFailure(error, action.storeId))
        )
    )
  );

export default usersEpic;
