import React, { PureComponent } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
// eslint-disable-next-line
import pathToRegexp from 'path-to-regexp';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './SiderMenu.less';
import urlToList from '../_utils/pathTools';
import SiderMenuQuote from './SiderMenuQuote';

const { Sider } = Layout;

// Allow menu.js config icon as string or ReactNode
//   icon: 'setting',
//   icon: 'http://demo.com/icon.png',
//   icon: <Icon type="setting" />,
const getIcon = (icon) => {
  if (typeof icon === 'string' && icon.indexOf('http') === 0) {
    return (
      <img
        src={icon}
        alt="icon"
        className={`${styles.icon} sider-menu-item-img`}
      />
    );
  }
  if (icon) {
    return icon;
  }
  return icon;
};

export const getMenuMatchKeys = (flatMenuKeys, path) => {
  return flatMenuKeys.filter((item) => pathToRegexp(item).test(path));
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
});

export default
@connect(mapStateToProps)
class SiderMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.menus = props.menuData;
    this.flatMenuKeys = this.getFlatMenuKeys(props.menuData);
    this.state = {
      openKeys: this.getDefaultCollapsedSubMenus(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.setState({
        openKeys: this.getDefaultCollapsedSubMenus(nextProps),
      });
    }
    this.menus = nextProps.menuData;
  }

  componentDidUpdate() {
    this.menus = this.props.menuData;
    this.flatMenuKeys = this.getFlatMenuKeys(this.props.menuData);
  }

  /**
   * Convert pathname to openKeys
   * /list/search/articles = > ['list','/list/search']
   * @param  props
   */
  getDefaultCollapsedSubMenus(props) {
    const { pathname } = props || this.props;
    return urlToList(pathname)
      .map((item) => getMenuMatchKeys(this.flatMenuKeys, item)[0])
      .filter((item) => item);
  }

  /**
   * Recursively flatten the data
   * [{path:string},{path:string}] => {path,path2}
   * @param  menus
   */
  getFlatMenuKeys(menus) {
    let keys = [];
    menus.forEach((item) => {
      if (item.children) {
        keys = keys.concat(this.getFlatMenuKeys(item.children));
      }
      keys.push(item.path);
    });
    return keys;
  }

  /**
   * Judge whether it is http link.return a or Link
   * @memberof SiderMenu
   */
  getMenuItemPath = (item) => {
    const { pathname } = this.props;
    const itemPath = this.conversionPath(item.path);
    const icon = getIcon(item.icon);
    const { target, name, regex } = item;

    const isActive =
      itemPath === pathname ||
      (regex &&
        regex.test(
          pathname.split('/')[1] === 'admin-panel'
            ? pathname.split('/').slice(-1)
            : pathname
        ));

    // Is it a http link
    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}>
          {icon}
          <span>{name}</span>
          <LinkOutlined style={{ marginLeft: '5px' }} />
        </a>
      );
    }
    return (
      <Link
        to={itemPath}
        target={target}
        className={isActive ? styles.active : ''}
        replace={isActive}
        onClick={
          this.props.isMobile
            ? () => {
                this.props.onCollapse(true);
              }
            : undefined
        }
      >
        {icon}
        <span>{name}</span>
      </Link>
    );
  };

  /**
   * get SubMenu or Item
   */
  getSubMenuOrItem = (item) => {
    if (item.children && item.children.some((child) => child.name)) {
      const childrenItems = this.getNavMenuItems(item.children);
      if (childrenItems && childrenItems.length > 0) {
        return {
          key: item.path,
          label: item.icon ? (
            <span>
              {getIcon(item.icon)}
              <span>{item.name}</span>
            </span>
          ) : (
            item.name
          ),
          children: childrenItems,
        };
      }
      return null;
    }
    return { key: item.path, label: this.getMenuItemPath(item) };
  };

  /**
   * @memberof SiderMenu
   */
  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .filter((item) => item.name && !item.hideInMenu)
      .map((item) => {
        // make dom
        const ItemDom = this.getSubMenuOrItem(item);
        return this.checkPermissionItem(item.authority, ItemDom);
      })
      .filter((item) => item);
  };

  // Get the currently selected menu
  getSelectedMenuKeys = () => {
    const { pathname } = this.props;
    return urlToList(pathname).map((itemPath) =>
      getMenuMatchKeys(this.flatMenuKeys, itemPath).pop()
    );
  };

  // conversion Path
  // eslint-disable-next-line
  conversionPath = (path) => {
    if (path && path.indexOf('http') === 0) {
      return path;
    }
    return `/${path || ''}`.replace(/\/+/g, '/');
  };

  // permission to check
  checkPermissionItem = (authority, ItemDom) => {
    if (this.props.Authorized && this.props.Authorized.check) {
      const { check } = this.props.Authorized;
      return check(authority, ItemDom);
    }
    return ItemDom;
  };

  isMainMenu = (key) =>
    this.menus.some((item) => key && (item.key === key || item.path === key));

  handleOpenChange = (openKeys) => {
    const lastOpenKey = openKeys[openKeys.length - 1];
    const moreThanOne =
      openKeys.filter((openKey) => this.isMainMenu(openKey)).length > 1;
    this.setState({
      openKeys: moreThanOne ? [lastOpenKey] : [...openKeys],
    });
  };

  handleSiderClick = (e) => {
    // auto close sider after click on anchor

    const { forceCollapse, isMobile } = this.props;
    if (isMobile && e.target.nodeName === 'A') {
      forceCollapse();
    }
  };

  render() {
    const { logo, collapsed, onCollapse } = this.props;
    const { openKeys } = this.state;
    // Don't show popup menu when it is been collapsed
    const menuProps = collapsed
      ? {}
      : {
          openKeys,
        };
    // if pathname can't match, use the nearest parent's key
    let selectedKeys = this.getSelectedMenuKeys();
    if (!selectedKeys.length) {
      selectedKeys = [openKeys[openKeys.length - 1]];
    }
    let gitVersion = null;
    let QuoteOfTheDay = null;
    if (!collapsed) {
      gitVersion = (
        <div className={styles['git-version']}>{window.__GIT_VERSION__}</div>
      );
      QuoteOfTheDay = <SiderMenuQuote />;
    }

    const items = this.getNavMenuItems(this.menus);
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={onCollapse}
        width={256}
        className={[styles.sider, collapsed ? styles.collapsed : ''].join(' ')}
        onClick={this.handleSiderClick}
      >
        <div className={styles.logo} key="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={styles.siderChildrenContainer}>
          <Menu
            key="Menu"
            theme="dark"
            mode="inline"
            // eslint-disable-next-line
            {...menuProps}
            onOpenChange={this.handleOpenChange}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            style={{ padding: '16px 0', width: '100%' }}
            className={styles.siderChildMenu}
            items={items}
          />
          {QuoteOfTheDay}
          {gitVersion}
        </div>
      </Sider>
    );
  }
}
