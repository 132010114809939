import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Tooltip,
} from 'antd';
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  isSameDay,
  isWeekend,
} from 'date-fns';
import dayjs from 'dayjs';
import getMaxPeriodNumber from 'helpers/getMaxPeriodNumber';
import React from 'react';
import styles from './AddDaysOffModal.less';

const Option = Select.Option;

/* eslint-disable camelcase */
class AddDaysOffModal extends React.Component {
  // eslint-disable-next-line
  displayName = 'AddDaysOffModal';

  dayOff = this.props.user.day_off;

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    selectedWorkdays: 0,
    selectedType: {},
    reoccurrenceMode: false,
    maxPeriodNumber: 2,
    selectedDays: 0,
  };

  componentDidMount() {
    this.setState({ selectedType: this.generateDayOffTypes()[0] });
  }

  componentDidUpdate() {
    this.dayOff = this.props.user.day_off;

    const updatedSelectedType = this.generateDayOffTypes().find(
      (type) => type.value === this.state.selectedType.value
    );

    if (
      this.state.selectedType.taken !== updatedSelectedType.taken ||
      this.state.selectedType.pending !== updatedSelectedType.pending
    ) {
      // eslint-disable-next-line
      this.setState({ selectedType: updatedSelectedType });
    }
  }

  onSelectType = (val) => {
    const dayOffTypes = this.generateDayOffTypes();
    const selectedType = dayOffTypes.find((type) => type.value === val);
    this.setState({ selectedWorkdays: 0 });
    this.setState({ selectedType });
  };

  handleDateRangeChange = (
    formValues,
    previousDays = undefined,
    repetitions = 1
  ) => {
    const { holidays } = this.props;
    const { repetition, period, frequency, datePick, dateHours } = formValues;
    const dates = formValues.dateRange;

    if (datePick) {
      this.setState({
        selectedWorkdays: dateHours ? dateHours * (repetition || 1) : 0,
      });
      return;
    }

    if (dates && dates.length === 2) {
      let dateStart = new Date(dates[0]);
      const dateEnd = new Date(dates[1]);

      const selectedDays = differenceInCalendarDays(dateEnd, dateStart) + 1;

      this.setState({
        selectedDays,
      });

      if (selectedDays > 7) {
        this.formRef.current.setFieldValue('period', 'month');
      }

      if (selectedDays > 31) {
        this.setState({ reoccurrenceMode: false });
      }

      let businessDays = previousDays || 0;

      const isWeekendOrHoliday = (date) =>
        isWeekend(date) ||
        holidays?.some((holiday) => isSameDay(date, new Date(holiday)));

      while (dateStart <= dateEnd) {
        if (!isWeekendOrHoliday(dateStart)) {
          // eslint-disable-next-line
          businessDays++;
        }
        dateStart = addDays(dateStart, 1); // Move to the next day
      }
      if (
        this.state.reoccurrenceMode &&
        repetition > 1 &&
        repetitions < repetition
      ) {
        const newStartDate =
          period === 'week'
            ? addDays(new Date(dates[0]), 7 * frequency)
            : addMonths(new Date(dates[0]), frequency);
        const newEndDate =
          period === 'week'
            ? addDays(dateEnd, 7 * frequency)
            : addMonths(dateEnd, frequency);

        this.handleDateRangeChange(
          { ...formValues, dateRange: [newStartDate, newEndDate] },
          businessDays,
          repetitions + 1
        );
        return;
      }
      this.setState({ selectedWorkdays: businessDays });
    } else {
      this.setState({ selectedWorkdays: 0 });
    }
  };

  handleChangeMode = () => {
    this.setState({
      // eslint-disable-next-line
      reoccurrenceMode: !this.state.reoccurrenceMode,
    });
  };

  disabledDate = (current) =>
    !(
      this.props.isAdmin ||
      // eslint-disable-next-line
      (this.state.selectedType.value == 'L4' &&
        current >=
          dayjs()
            .startOf('day')
            .subtract(7, 'day')) ||
      current >= dayjs().startOf('day')
    );

  generateDayOffTypes = () => [
    {
      name: 'Paid',
      value: 'Paid',
      allTypes: true,
      hasLimits: true,
      allowance: this.dayOff.amount,
      taken: this.dayOff.taken,
      pending: this.dayOff.pending,
      description:
        'A well-deserved break, allowing you to recharge and relax. Enjoy your days off.',
    },
    {
      name: 'Unpaid',
      value: 'Unpaid',
      allTypes: true,
      hasLimits: false,
      description:
        'Take time away from work to attend to personal matters or simply unwind.',
    },
    {
      name: 'L4',
      value: 'L4',
      hasLimits: false,
      description:
        'Sometimes health takes precedence. L4, or medical leave, provides you with time off for medical reasons.',
    },
    {
      name: 'Force Majeure',
      value: 'force_majeure',
      hours: true,
      hasLimits: true,
      allowance: this.dayOff.force_majeure_allowance,
      taken: this.dayOff.force_majeure_taken,
      pending: this.dayOff.force_majeure_pending,
      description: `Max 16 hours yearly, 50% paid, does not need to be approved by employer. Force Majeure leave offers time off when factors beyond control, like natural disasters or emergencies, impact your ability to work.`,
      translate: 'siła wyższa',
    },
    {
      name: 'On Demand',
      value: 'on_demand',
      hasLimits: true,
      allowance: this.dayOff.on_demand_allowance,
      taken: this.dayOff.on_demand_taken,
      pending: this.dayOff.on_demand_pending,
      description:
        'Max 4 days yearly, deducted from the leave pool, 100% paid. On-demand leave permits you to take time for unexpected situations or last-minute personal needs.',
      translate: 'na życzenie',
    },
    {
      name: 'Dependency Leave (Family)',
      value: 'family_carer',
      hasLimits: true,
      allowance: this.dayOff.family_carer_allowance,
      taken: this.dayOff.family_carer_taken,
      pending: this.dayOff.family_carer_pending,
      description:
        'Max 5 days yearly, not paid, does not need to be approved. Family carer leave lets you provide essential care to family members when they need it the most.',
      translate: 'opieka nad członkiem rodziny',
    },
    {
      name: 'Dependency Leave (Child)',
      value: 'child_carer',
      hours: true,
      hasLimits: true,
      allowance: this.dayOff.child_carer_allowance,
      taken: this.dayOff.child_carer_taken,
      pending: this.dayOff.child_carer_pending,
      description:
        "Max 16 hours yearly, 100% paid, has to be approved, you can take, for example, 2 hours for 8 days. Child carer leave allows you to invest time in your child's growth and development.",
      translate: 'opieka nad dzieckiem',
    },
    {
      name: 'Parental',
      value: 'parental',
      hasLimits: true,
      allowance: this.dayOff.parental_allowance,
      taken: this.dayOff.parental_taken,
      pending: this.dayOff.parental_pending,
      description:
        'Celebrate the journey of parenthood. Parental leave grants new parents the time to bond with their newborn, fostering a strong family foundation.',
      translate: 'rodzicielski',
    },
    {
      name: 'Special',
      value: 'special',
      hasLimits: false,
      description:
        'Some occasions call for dedicated time off. Special leave acknowledges important life events like weddings, funerals, or other significant milestones.',
      translate: 'okolicznościowy',
    },
  ];

  async acceptData() {
    try {
      const fieldsValue = await this.formRef.current.validateFields();
      const singleDate = fieldsValue.datePick?.format('YYYY-MM-DD');
      const takenHours = fieldsValue.dateHours;
      const dateStart =
        fieldsValue.dateRange && fieldsValue.dateRange[0].format('YYYY-MM-DD');
      const dateEnd =
        fieldsValue.dateRange && fieldsValue.dateRange[1].format('YYYY-MM-DD');
      const result = {
        type: fieldsValue.dayOffType,
        date_start: dateStart,
        date_end: dateEnd,
        remarks: fieldsValue.remarks,
        id: this.props.me?.data.id,
        reoccurrence: this.state.reoccurrenceMode,
        period: fieldsValue.period,
        repetition: fieldsValue.repetition,
        frequency: fieldsValue.frequency,
      };

      if (this.state.selectedType.hours) {
        // eslint-disable-next-line
        result.date_start = result.date_end = singleDate;
        result.days_no = takenHours;
      }

      await this.formRef.current.resetFields();
      this.setState({ selectedWorkdays: 0 });
      this.props.onOk(result);
      this.setState({ selectedType: this.generateDayOffTypes()[0] });
      // eslint-disable-next-line
    } catch (err) {}
  }

  render() {
    const { user, visible, onCancel } = this.props;
    const { selectedWorkdays, selectedType } = this.state;
    const { RangePicker } = DatePicker;
    const { TextArea } = Input;

    const allDayOffTypes = this.generateDayOffTypes();

    const currentAllowance =
      selectedType.allowance - selectedType.taken - selectedType.pending;

    const afterThisLeave = currentAllowance - selectedWorkdays;

    const b2bDayOffTypes = allDayOffTypes.filter((type) => type.allTypes);

    const contractType = user.contract_type;

    const disabledReoccurrence =
      this.state.selectedDays > 31 ||
      [
        'L4',
        'Force Majeure',
        'On Demand',
        'Dependency Leave (Family)',
        'Dependency Leave (Child)',
        'Parental',
      ].includes(selectedType.name);

    return (
      <Modal
        open={visible}
        onCancel={() => {
          onCancel();
          this.formRef.current.resetFields();
          this.setState({
            selectedType: this.generateDayOffTypes()[0],
            reoccurrenceMode: false,
          });
        }}
        onOk={() => this.acceptData()}
        title="Add Days Off"
      >
        <Form
          ref={this.formRef}
          layout="vertical"
          onValuesChange={(_, allValues) => {
            this.setState({ maxPeriodNumber: getMaxPeriodNumber(allValues) });
            this.handleDateRangeChange(allValues);
          }}
        >
          <Form.Item
            label="Type"
            name="dayOffType"
            initialValue="Paid"
            rules={[{ required: true, message: 'Please select day off type.' }]}
          >
            <Select onSelect={this.onSelectType}>
              {contractType === 'uop'
                ? allDayOffTypes.map((type) => (
                    <Option key={type.value} value={type.value}>
                      {type.name}
                    </Option>
                  ))
                : b2bDayOffTypes.map((type) => (
                    <Option key={type.value} value={type.value}>
                      {type.name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
          {selectedType.description && (
            <p className={styles.description}>
              {selectedType.description}{' '}
              {selectedType.translate && (
                <>
                  (
                  <span style={{ fontStyle: 'italic' }}>
                    pl: {selectedType.translate}
                  </span>
                  )
                </>
              )}
            </p>
          )}
          {selectedType?.hours ? (
            <>
              <Row type="flex">
                <Form.Item
                  style={{ marginRight: '20px' }}
                  label="Date"
                  name="datePick"
                  rules={[{ required: true, message: 'Please select time.' }]}
                >
                  <DatePicker disabledDate={this.disabledDate} />
                </Form.Item>
                <Form.Item
                  label="Hours"
                  name="dateHours"
                  rules={[{ required: true, message: 'Please input amount' }]}
                >
                  <InputNumber
                    min={1}
                    max={currentAllowance >= 8 ? 8 : currentAllowance}
                  />
                </Form.Item>
              </Row>
              <Form.Item name="reoccurrence">
                <Checkbox
                  className={styles.modeCheckbox}
                  checked={this.state.reoccurrenceMode}
                  onChange={this.handleChangeMode}
                  disabled={disabledReoccurrence}
                >
                  Reoccurrence
                </Checkbox>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="From - To"
                name="dateRange"
                rules={[{ required: true, message: 'Please select time.' }]}
              >
                <RangePicker disabledDate={this.disabledDate} />
              </Form.Item>
              <Form.Item name="reoccurrence">
                <Checkbox
                  className={styles.modeCheckbox}
                  checked={this.state.reoccurrenceMode}
                  onChange={this.handleChangeMode}
                  disabled={disabledReoccurrence}
                >
                  Reoccurrence
                </Checkbox>
              </Form.Item>
            </>
          )}
          {this.state.selectedDays > 31 &&
            ![
              'L4',
              'Force Majeure',
              'On Demand',
              'Dependency Leave (Family)',
              'Dependency Leave (Child)',
              'Parental',
            ].includes(selectedType.name) && (
              <p className={styles.error}>
                Cannot set reoccurrence if more than 31 days are selected
              </p>
            )}
          {this.state.reoccurrenceMode &&
            this.state.selectedDays <= 31 &&
            !disabledReoccurrence && (
              <Row gutter={8}>
                <Col>
                  <Form.Item
                    rules={[{ required: true }]}
                    initialValue="week"
                    label="Period"
                    name="period"
                  >
                    <Select
                      options={
                        this.state.selectedDays <= 7
                          ? [
                              {
                                value: 'week',
                                label: 'weekly',
                              },
                              { value: 'month', label: 'monthly' },
                            ]
                          : [{ value: 'month', label: 'monthly' }]
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    initialValue={1}
                    label="Repetition"
                    name="repetition"
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={1} max={this.state.maxPeriodNumber} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    initialValue={1}
                    label={
                      <>
                        <span>Frequency</span>
                        <Tooltip title='The default value is 1. If you change it to, for instance 2, and the time period is set to "week," it means the occurrence is established every two weeks instead of every week.'>
                          <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                        </Tooltip>
                      </>
                    }
                    name="frequency"
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                </Col>
              </Row>
            )}
          <Form.Item
            label="Remarks"
            name="remarks"
            initialValue=""
            rules={[
              { required: true, message: 'Please provide short description.' },
            ]}
          >
            <TextArea rows={3} />
          </Form.Item>
        </Form>
        {selectedType?.hasLimits && (
          <>
            <p>
              Current Allowance:{' '}
              <span
                className={currentAllowance > 0 ? styles.green : styles.red}
              >
                {currentAllowance}{' '}
                {this.state.selectedType.hours ? 'hours' : 'days'}
              </span>
            </p>
            <p>
              After this leave:{' '}
              <span className={afterThisLeave >= 0 ? styles.green : styles.red}>
                {afterThisLeave}{' '}
                {this.state.selectedType.hours ? 'hours' : 'days'}
              </span>
            </p>
          </>
        )}
      </Modal>
    );
  }
}

export default AddDaysOffModal;
