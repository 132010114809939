import archiveConstants from 'constants/archive.constants';

const initialState = { fetching: false };

export default function archive(state = initialState, action) {
  switch (action.type) {
    case archiveConstants.FETCH_ARCHIVE_REQUEST:
      return { ...state, fetching: true };
    case archiveConstants.FETCH_ARCHIVE_SUCCESS:
      return {
        ...state,
        archive: action.fetchedData,
        fetching: false,
      };
    case archiveConstants.FETCH_ARCHIVE_FAILURE:
      return { ...state, errors: action.errors, fetching: false };
    case archiveConstants.ARCHIVE_REQUEST:
      return { ...state, patching: true };
    case archiveConstants.ARCHIVE_SUCCESS:
      return { ...state, patching: false };
    case archiveConstants.ARCHIVE_FAILURE:
      return { ...state, error: action.error, patching: false };
    default:
      return state;
  }
}
