const timesConstants = {
  FETCH_NEXT_TIMES_REQUEST: 'TIMES_FETCH_NEXT_TIMES_REQUEST',
  FETCH_NEXT_TIMES_SUCCESS: 'TIMES_FETCH_NEXT_TIMES_SUCCESS',
  FETCH_TIMES_REQUEST: 'TIMES_FETCH_TIMES_REQUEST',
  FETCH_TIMES_SUCCESS: 'TIMES_FETCH_TIMES_SUCCESS',
  FETCH_TIMES_FAILURE: 'TIMES_FETCH_TIMES_FAILURE',
  DELETE_TIME_REQUEST: 'TIMES_DELETE_TIME_REQUEST',
  DELETE_TIME_SUCCESS: 'TIMES_DELETE_TIME_SUCCESS',
  DELETE_TIME_FAILURE: 'TIMES_DELETE_TIME_FAILURE',
  PATCH_TIME_REQUEST: 'TIMES_PATCH_TIME_REQUEST',
  PATCH_TIME_SUCCESS: 'TIMES_PATCH_TIME_SUCCESS',
  PATCH_TIME_FAILURE: 'TIMES_PATCH_TIME_FAILURE',
  CREATE_TIME_SUCCESS: 'TIMES_CREATE_TIME_SUCCESS',
  CREATE_TIME_REQUEST: 'TIMES_CREATE_TIME_REQUEST',
  CREATE_TIME_FAILURE: 'TIMES_CREATE_TIME_FAILURE',
};

export default timesConstants;
