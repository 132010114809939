import daysOffAllowancesConstants from 'constants/daysOffAllowances.constants';

const initialState = { fetching: false };

export default function daysOffAllowances(state = initialState, action) {
  switch (action.type) {
    case daysOffAllowancesConstants.FETCH_DAYS_OFF_ALLOWANCES_REQUEST:
      return { ...state, fetching: true };
    case daysOffAllowancesConstants.FETCH_DAYS_OFF_ALLOWANCES_SUCCESS:
      return {
        ...state,
        daysOffAllowances: action.fetchedData,
        fetching: false,
      };
    case daysOffAllowancesConstants.FETCH_DAYS_OFF_ALLOWANCES_FAILURE:
      return { ...state, errors: action.errors, fetching: false };
    case daysOffAllowancesConstants.PATCH_DAYS_OFF_ALLOWANCES_REQUEST:
      return { ...state, patching: true };
    case daysOffAllowancesConstants.PATCH_DAYS_OFF_ALLOWANCES_SUCCESS:
      return { ...state, patching: false };
    case daysOffAllowancesConstants.PATCH_DAYS_OFF_ALLOWANCES_FAILURE:
      return { ...state, error: action.error, patching: false };
    default:
      return state;
  }
}
