import groupsConstants from 'constants/groups.constants';

function fetchGroupsRequest(params) {
  return {
    type: groupsConstants.FETCH_GROUPS_REQUEST,
    params,
  };
}

function fetchGroupsSuccess(groups) {
  return {
    type: groupsConstants.FETCH_GROUPS_SUCCESS,
    groups,
  };
}

function fetchGroupsFailure(error) {
  return {
    type: groupsConstants.FETCH_GROUPS_FAILURE,
    error,
  };
}

const groupsActions = {
  fetchGroupsRequest,
  fetchGroupsSuccess,
  fetchGroupsFailure,
};

export default groupsActions;
