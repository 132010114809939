import remoteWorkConstants from 'constants/remoteWork.constants';

function fetchRemoteWorkRequest(params) {
  return {
    type: remoteWorkConstants.FETCH_REMOTE_WORK_REQUEST,
    params,
  };
}

function fetchRemoteWorkSuccess(fetchedData, next) {
  return {
    type: remoteWorkConstants.FETCH_REMOTE_WORK_SUCCESS,
    fetchedData,
    next,
  };
}

function fetchNextRemoteWorkRequest() {
  return {
    type: remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_REQUEST,
  };
}

function fetchNextRemoteWorkSuccess(fetchedData, next) {
  return {
    type: remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_SUCCESS,
    fetchedData,
    next,
  };
}

function createRemoteWorkRequest(data, params, user, office = false) {
  return {
    type: remoteWorkConstants.CREATE_REMOTE_WORK_REQUEST,
    data,
    params,
    user,
    office,
  };
}

function deleteRemoteWorkRequest(id, params) {
  return {
    type: remoteWorkConstants.DELETE_REMOTE_WORK_REQUEST,
    id,
    params,
  };
}

const remoteWorkActions = {
  fetchRemoteWorkRequest,
  fetchRemoteWorkSuccess,
  fetchNextRemoteWorkRequest,
  fetchNextRemoteWorkSuccess,
  createRemoteWorkRequest,
  deleteRemoteWorkRequest,
};

export default remoteWorkActions;
