import React from 'react';
import { Modal, Input, Form } from 'antd';

class AddFreelancerModal extends React.Component {
  // eslint-disable-next-line
  displayName = 'AddFreelancerModal';

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  onCancel() {
    // just clear email input in modal
    this.formRef.current.resetFields();
    this.props.onCancel();
  }

  onOk = async () => {
    try {
      const values = await this.formRef.current.validateFields();
      this.props.onCreate(values);
      // eslint-disable-next-line
    } catch (err) {}
  };

  render() {
    const { visible } = this.props;

    return (
      <Modal
        open={visible}
        onCancel={() => this.onCancel()}
        onOk={() => this.onOk()}
        title="Add Freelancer"
      >
        <Form ref={this.formRef} name="addFreelancer">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter email',
              },
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default AddFreelancerModal;
