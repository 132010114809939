import { Observable } from 'rxjs';
import queryString from 'query-string';
import raportConstants from 'constants/raport.constants';
import raportActions from 'actions/raport.actions';
import { get } from 'helpers/ajax';

const raportEpic = (action$) =>
  Observable.merge(
    action$.ofType(raportConstants.FETCH_RAPORT_REQUEST).mergeMap((action) =>
      get(`/api/v1/monthly/?${queryString.stringify(action.params)}`, {}, true)
        .map((response) =>
          raportActions.fetchRaportSuccess(response.response, action.storeId)
        )
        .catch((error) =>
          Observable.of(raportActions.fetchRaportFailure(error, action.storeId))
        )
    )
  );

export default raportEpic;
