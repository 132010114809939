import daysOffConstants from 'constants/daysOff.constants';

const initialState = { fetching: false, hasMore: false };
export default function daysOff(state = initialState, action) {
  switch (action.type) {
    case daysOffConstants.FETCH_DAYS_OFF_REQUEST:
      return { ...state, fetching: true };

    case daysOffConstants.FETCH_DAYS_OFF_SUCCESS:
      return {
        ...state,
        daysOff: action.fetchedData,
        next: action.next,
        fetching: false,
      };
    case daysOffConstants.FETCH_DAYS_OFF_FAILURE:
      return { ...state, fetching: false };

    case daysOffConstants.FETCH_NEXT_DAYS_OFF_REQUEST:
      return { ...state, fetching: true };

    case daysOffConstants.FETCH_NEXT_DAYS_OFF_SUCCESS:
      // eslint-disable-next-line
      const newState = {
        ...state,
        daysOff: state.daysOff.slice().concat(action.fetchedData),
        next: action.next,
        fetching: false,
      };

      return newState;

    case daysOffConstants.FETCH_NEXT_DAYS_OFF_FAILURE:
      return { ...state, fetching: false };

    case daysOffConstants.FETCH_HOLIDAYS_SUCCESS:
      return { ...state, holidays: action.fetchedData };

    default: {
      return state;
    }
  }
}
