import { ArrowLeftOutlined, MailFilled, MailOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import notificationsActions from 'actions/notifications.actions';
import { Col, Empty, Row, Tooltip } from 'antd';
import { format } from 'date-fns';
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectNotification,
  selectNotificationSender,
} from 'selectors/notifications.selectors';
import ArchiveIcon from './ArchiveIcon';
import styles from './NotificationMessage.less';

/* eslint-disable camelcase */
const NotificationMessage = ({ match, onArchiveNotifications, isFetching }) => {
  const dispatch = useDispatch();
  const { id } = match.params;
  const notification = useSelector(selectNotification(id));
  const sender = useSelector(selectNotificationSender(notification?.sender_id));

  const changeReadStatus = (read) => {
    dispatch(notificationsActions.patchNotificationsRequest([id], read));
  };

  return (
    <div className={styles.notificationMessage}>
      {!isFetching && notification ? (
        <>
          <Row className={styles.backRow}>
            <Link to="/messages-center">
              <ArrowLeftOutlined />
            </Link>
          </Row>
          <Row>
            <Col sm={20}>
              <h2 className={styles.title}>{notification?.title}</h2>
            </Col>
            <Col sm={4} className={styles.actionButtons}>
              {notification?.viewed_at ? (
                <Tooltip title="Mark as unread">
                  <MailOutlined onClick={() => changeReadStatus(false)} />
                </Tooltip>
              ) : (
                <Tooltip
                  onClick={() => changeReadStatus(true)}
                  title="Mark as read"
                >
                  <MailFilled />
                </Tooltip>
              )}
              <Tooltip title="Archive">
                <Icon
                  component={ArchiveIcon}
                  onClick={() => onArchiveNotifications([+id])}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row className={styles.messageInfo}>
            <p>From: {sender || notification?.sender_id}</p>
            <p>
              Sent on:{' '}
              {notification?.created_at &&
                format(new Date(notification?.created_at), 'dd-MM-yyyy')}
            </p>
          </Row>
          <Row className={styles.messageContent}>
            <Markdown>{notification?.message}</Markdown>
          </Row>
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default NotificationMessage;
