import React from 'react';
import { Modal, Input, Form } from 'antd';
import { TwitterPicker } from 'react-color';

const AddProjectModal =
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        entry: {},
      };
    }

    UNSAFE_componentWillReceiveProps(props) {
      const { entry } = props;
      this.setState({
        entry,
      });
    }

    render() {
      const { visible, onCancel, onEdit, form } = this.props;
      const { entry } = this.state;
      return (
        <Modal
          open={visible}
          title="Edit Project"
          okText="Save"
          onCancel={onCancel}
          onOk={onEdit}
        >
          <Form
            ref={form}
            layout="horizontal"
            name="form_in_modal_project_edit"
          >
            <Form.Item
              label="Name"
              initialValue={entry.name}
              name="name"
              rules={[
                {
                  required: true,
                  min: 3,
                  message: 'At least 3 characters!',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="color"
              label="Color"
              initialValue={{ hex: entry.color }}
            >
              <TwitterPicker
                triangle="hide"
                onChange={this.handleColorChange}
              />
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  };

AddProjectModal.displayName = 'AddProjectModal';

export default AddProjectModal;
