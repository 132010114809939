import { createSelector } from 'reselect';

export const selectUsers = (state) => state.users;
export const selectTeams = (state) => state.teams;

export const selectOptionUsers = createSelector(selectUsers, (users) =>
  users.users?.users?.results
    .map((user) => ({
      label: user.name,
      value: user.id,
    }))
    .sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    })
);

export const selectOptionGroups = createSelector(selectTeams, (teams) =>
  teams.data?.results?.map((team) => ({
    label: team.name,
    value: team.group_id,
  }))
);

export const selectOptionContract = createSelector(selectUsers, (users) => {
  const uniqueContractTypes = new Set();
  const options = [];
  // eslint-disable-next-line
  users.users?.users?.results.forEach((user) => {
    const contractType = user.contract_type;
    if (!uniqueContractTypes.has(contractType)) {
      uniqueContractTypes.add(contractType);
      options.push({
        label: contractType,
        value: contractType,
      });
    }
  });

  return options;
});
