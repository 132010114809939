import { AutoComplete, Button, Form, Input, InputNumber } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import './AddTimeForm.css';

import clientsActions from 'actions/clients.actions';
import projectsActions from 'actions/projects.actions';
import ProjectPicker from 'components/ProjectPicker';

const AddTimeForm =
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        projects: [],
        clients: [],
        descriptionDisabled: false,
        project: undefined,
      };
    }

    componentDidMount() {
      this.updateProjects();
      this.updateClients();
    }

    UNSAFE_componentWillReceiveProps(props) {
      const { projects, clients } = props;
      if (projects.fetched) {
        this.setState({
          projects: [...projects.projects.results],
        });
      }
      if (clients.fetched) {
        this.setState({
          clients: [...clients.clients.results],
        });
      }
    }

    handleAutocompleteSelect = (value) => {
      this.updateDescriptionDisabled(value);
      this.props.form.current.setFieldsValue({
        description: value,
      });
    };
    // eslint-disable-next-line
    handleAutocompleteFilterOption = (inputValue, option) => {
      return (
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      );
    };

    handleAutocompleteOnChange = (value) => {
      this.updateDescriptionDisabled(value);
    };

    updateDescriptionDisabled = (value) => {
      const descriptionDisabled = this.props.autoCompleteSuggestions.some(
        (item) => item.value === value
      );
      if (!descriptionDisabled && this.state.descriptionDisabled) {
        this.props.form.current.setFieldsValue({
          description: '',
        });
      }
      this.setState({ descriptionDisabled });
    };

    resetFields = () => {
      // Hack for a stupid bug in antd that nobody bothers fixing
      // you'd probably expect onChange to be fired when field value is
      // changed, but for some reason it doesn't on resetFields/setFieldsValue
      // https://github.com/ant-design/ant-design/issues/6636
      this.props.form.current.resetFields();
      this.updateDescriptionDisabled();
    };

    setFieldsValue = (values) => {
      // Hack for a stupid bug in antd that nobody bothers fixing
      // you'd probably expect onChange to be fired when field value is
      // changed, but for some reason it doesn't on resetFields/setFieldsValue
      // https://github.com/ant-design/ant-design/issues/6636
      this.props.form.current.setFieldsValue(values);
      this.updateDescriptionDisabled(values.task);
    };

    handleProjectPickerChange = (selectedValue) => {
      this.setState({ project: selectedValue });
      // eslint-disable-next-line
      this.props.form.current?.setFieldsValue({
        project: selectedValue,
      });
    };

    updateClients(input) {
      const { dispatch } = this.props;
      dispatch(
        clientsActions.fetchClientsRequest(
          {
            search: input,
          },
          'timesPage'
        )
      );
    }

    updateProjects(input) {
      const { dispatch } = this.props;
      dispatch(
        projectsActions.fetchProjectsRequest({
          search: input,
          // eslint-disable-next-line
          client__active: 'True',
          active: 'True',
        })
      );
    }

    render() {
      const { onCreate, lastProjects, autoCompleteSuggestions } = this.props;
      const { projects, clients, descriptionDisabled } = this.state;

      const lastProjectsSelect = [];
      if (lastProjects) {
        // eslint-disable-next-line
        for (const project of lastProjects) {
          lastProjectsSelect.push(
            // eslint-disable-next-line
            <a
              className="times-last-project-name"
              key={project.id}
              onClick={() => {
                this.setState({ project: project.id });
                // eslint-disable-next-line
                this.props.form.current?.setFieldsValue({
                  project: project.id,
                });
              }}
            >
              {project.name}
            </a>
          );
        }
      }

      return (
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.metaKey) {
              onCreate();
            }
          }}
          onFinish={() => this.setState({ descriptionDisabled: false })}
          ref={this.props.form}
          layout="vertical"
          name="form"
        >
          <Form.Item
            label="Project"
            name="project"
            rules={[
              {
                required: true,
                message: 'Select something!',
              },
            ]}
            shouldUpdate={(prevValues, curValues) => {
              return prevValues?.project !== curValues?.project;
            }}
          >
            <ProjectPicker
              onChange={this.handleProjectPickerChange}
              projects={projects}
              clients={clients}
              value={this.state.project}
            />
          </Form.Item>
          <div className="times-last-times">{lastProjectsSelect}</div>
          <Form.Item
            label="Hours"
            style={{ display: 'inline-block', marginRight: '10px' }}
            name="hours"
            rules={[
              {
                required: true,
                type: 'number',
                message: 'Enter time!',
              },
            ]}
          >
            <InputNumber step={0.01} min={0.01} max={23} />
          </Form.Item>
          <Form.Item
            label="Task ID"
            style={{ display: 'inline-block', width: '178px' }}
            name="task"
          >
            <AutoComplete
              options={autoCompleteSuggestions}
              onSelect={this.handleAutocompleteSelect}
              onChange={this.handleAutocompleteOnChange}
              filterOption={this.handleAutocompleteFilterOption}
              type="text"
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                min: 2,
                message: 'At least 2 characters!',
                whitespace: true,
              },
            ]}
          >
            <Input.TextArea disabled={descriptionDisabled} />
          </Form.Item>
          <Button type="primary" onClick={onCreate}>
            Add
          </Button>
        </Form>
      );
    }
  };

AddTimeForm.displayName = 'AddTimeForm';

function mapStateToProps(state) {
  const projects = state.projects.timesPage;
  const clients = state.clients.timesPage;
  return {
    projects,
    clients,
  };
}

export default connect(mapStateToProps)(AddTimeForm);
