import timesConstants from 'constants/times.constants';

function fetchNextTimesRequest(params, storeId) {
  return {
    type: timesConstants.FETCH_NEXT_TIMES_REQUEST,
    params,
    storeId,
  };
}

function fetchNextTimesSuccess(times, storeId) {
  return {
    type: timesConstants.FETCH_NEXT_TIMES_SUCCESS,
    times,
    storeId,
  };
}

function fetchTimesRequest(params, storeId, fetchAll = true) {
  return {
    type: timesConstants.FETCH_TIMES_REQUEST,
    params,
    storeId,
    fetchAll,
  };
}

function fetchTimesSuccess(times, storeId) {
  return {
    type: timesConstants.FETCH_TIMES_SUCCESS,
    times,
    storeId,
  };
}

function fetchTimesFailure(error, storeId) {
  return {
    type: timesConstants.FETCH_TIMES_FAILURE,
    error,
    storeId,
  };
}

function deleteTimeRequest(id, params, storeId = 'timesPage') {
  return {
    type: timesConstants.DELETE_TIME_REQUEST,
    id,
    params,
    storeId,
  };
}

function deleteTimeSuccess(id) {
  return {
    type: timesConstants.DELETE_TIME_SUCCESS,
    id,
  };
}

function deleteTimeFailure(error) {
  return {
    type: timesConstants.DELETE_TIME_FAILURE,
    error,
  };
}

function patchTimeRequest(id, data, params, storeId = 'timesPage') {
  return {
    type: timesConstants.PATCH_TIME_REQUEST,
    id,
    data,
    params,
    storeId,
  };
}

function patchTimeSuccess(id) {
  return {
    type: timesConstants.PATCH_TIME_SUCCESS,
    id,
  };
}

function patchTimeFailure(error) {
  return {
    type: timesConstants.PATCH_TIME_FAILURE,
    error,
  };
}

function createTimeRequest(data, params, storeId = 'timesPage') {
  return {
    type: timesConstants.CREATE_TIME_REQUEST,
    data,
    params,
    storeId,
  };
}

function createTimeSuccess(data) {
  return {
    type: timesConstants.CREATE_TIME_SUCCESS,
    data,
  };
}

function createTimeFailure(error) {
  return {
    type: timesConstants.CREATE_TIME_FAILURE,
    error,
  };
}

const timesActions = {
  fetchNextTimesRequest,
  fetchNextTimesSuccess,
  fetchTimesRequest,
  fetchTimesSuccess,
  fetchTimesFailure,
  deleteTimeRequest,
  deleteTimeSuccess,
  deleteTimeFailure,
  patchTimeRequest,
  patchTimeSuccess,
  patchTimeFailure,
  createTimeRequest,
  createTimeSuccess,
  createTimeFailure,
};

export default timesActions;
