import presencesConstants from 'constants/presences.constants';

function fetchPresencesRequest(params, storeId) {
  return {
    type: presencesConstants.FETCH_PRESENCES_REQUEST,
    params,
    storeId,
  };
}

function fetchPresencesSuccess(presences, storeId) {
  return {
    type: presencesConstants.FETCH_PRESENCES_SUCCESS,
    presences,
    storeId,
  };
}

function fetchPresencesFailure(error, storeId) {
  return {
    type: presencesConstants.FETCH_PRESENCES_FAILURE,
    error,
    storeId,
  };
}

function sendPresenceTick(payload) {
  return {
    type: presencesConstants.SEND_PRESENCE_TICK,
    payload,
  };
}

function sendPresenceTickSuccess(data) {
  return {
    type: presencesConstants.SEND_PRESENCE_TICK_SUCCESS,
    data,
  };
}

function sendPresenceTickError(error) {
  return {
    type: presencesConstants.SEND_PRESENCE_TICK_FAILURE,
    error,
  };
}

const presencesActions = {
  fetchPresencesRequest,
  fetchPresencesSuccess,
  fetchPresencesFailure,
  sendPresenceTick,
  sendPresenceTickSuccess,
  sendPresenceTickError,
};

export default presencesActions;
