import React from 'react';
import { Modal, Input, Form } from 'antd';
import { TwitterPicker } from 'react-color';
import { randomColor } from 'randomcolor';

const AddClientModal =
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {};

      this.colors = randomColor({ count: 17 });
    }

    render() {
      const { visible, onCancel, onCreate } = this.props;
      return (
        <Modal
          open={visible}
          title="Add Client"
          okText="Add"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="horizontal" name="form_in_modal" ref={this.props.form}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  min: 3,
                  message: 'At least 3 characters!',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Color"
              name="color"
              initialValue={{ hex: '#0693E3' }}
            >
              <TwitterPicker colors={this.colors} triangle="hide" />
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  };

AddClientModal.displayName = 'AddClientModal';

export default AddClientModal;
