import { Table } from 'antd';
import dayjs from 'dayjs';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import presencesActions from 'actions/presences.actions';
import Loader from 'components/Loader/Loader';
import DatePicker from 'components/QuickDatePicker';
import UserTooltip from 'components/UserTooltip/UserTooltip';
import { formatDate, formatDateTime } from 'helpers/dateTime';
import UserBadge from '../../components/UserBadge/UserBadge';
import styles from './Presence.less';

class Presence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      presence: [],
    };

    this.handleDate = this.handleDate.bind(this);
  }

  componentDidMount() {
    this.updatePresence();
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(props) {
    const { presence } = props;
    const queryResult = presence.presencePage;
    if (!queryResult.fetched) {
      return false;
    }
    const results = queryResult.presences.results;
    const processedResults = [];
    const employeePresences = {};
    // eslint-disable-next-line
    for (let i = 0; i < results.length; i++) {
      if (results[i]) {
        const userId = results[i].user.id;
        if (!employeePresences[userId]) {
          employeePresences[userId] = {
            employee: results[i].user,
            key: results[i].id,
          };
        }
        if (results[i].first) {
          employeePresences[userId].start = formatDateTime(results[i].ts);
        } else {
          employeePresences[userId].end = formatDateTime(results[i].ts);
        }
        if (employeePresences[userId].start && employeePresences[userId].end) {
          processedResults.push({
            ...employeePresences[userId],
          });
        }
      }
    }
    this.setState({
      presence: processedResults,
    });
  }

  handleDate(date) {
    this.setState({ date }, () => this.updatePresence());
  }

  updatePresence() {
    const { dispatch } = this.props;
    const { date } = this.state;
    dispatch(
      presencesActions.fetchPresencesRequest(
        {
          date: formatDate(date),
        },
        'presencePage'
      )
    );
  }

  render() {
    const columns = [
      {
        title: 'Employee',
        dataIndex: 'employee',
        key: 'employee',
        render: (user) => (
          <UserTooltip user={user}>
            <Link to={user.id ? `/users/${user.id}` : '/monthly'}>
              <span className={styles.userName}>
                <UserBadge user={user} />
                {user.name}
              </span>
            </Link>
          </UserTooltip>
        ),
      },
      {
        title: 'Start',
        dataIndex: 'start',
        key: 'start',
        width: 150,
      },
      {
        title: 'End',
        dataIndex: 'end',
        key: 'end',
        width: 150,
      },
    ];
    const { presence } = this.state;

    return (
      <Loader loading={presence.lenght <= 0}>
        <Helmet title="Presence" />
        <div className="table-operations">
          <div className="page-filters">
            <DatePicker
              onChange={this.handleDate}
              defaultValue={dayjs(new Date())}
              allowClear={false}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={presence}
          pagination={false}
          style={{ overflow: 'auto' }}
        />
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  const presence = state.presences;
  return {
    presence,
  };
}

export default connect(mapStateToProps)(Presence);
