const buildKeyAndChildren = (days) => {
  // eslint-disable-next-line
  return days?.map((day) => {
    if (!day.items) {
      return { ...day, key: day.id };
    }
    if (day.items?.length > 1) {
      return {
        ...day,
        key: `${day.user?.id}-${day.items[0].id}`,
        children: day.items?.map((item) => ({
          ...item,
          key: item.id,
          user: day.user,
        })),
      };
    }
    if (day.items?.length === 1) {
      return { ...day, key: day.items[0]?.id, ...day.items[0] };
    }
  });
};

export default buildKeyAndChildren;
