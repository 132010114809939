const notificationsConstants = {
  START_FETCH_NOTIFICATIONS: 'START_FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',
  CANCEL_FETCH_NOTIFICATIONS: 'CANCEL_FETCH_NOTIFICATIONS',
  PATCH_NOTIFICATIONS_REQUEST: 'PATCH_NOTIFICATIONS_REQUEST',
  PATCH_NOTIFICATIONS_SUCCESS: 'PATCH_NOTIFICATIONS_SUCCESS',
  PATCH_NOTIFICATIONS_FAILURE: 'PATCH_NOTIFICATIONS_FAILURE',
  ARCHIVE_NOTIFICATIONS_REQUEST: 'ARCHIVE_NOTIFICATIONS_REQUEST',
  ARCHIVE_NOTIFICATIONS_SUCCESS: 'ARCHIVE_NOTIFICATIONS_SUCCESS',
  ARCHIVE_NOTIFICATIONS_FAILURE: 'ARCHIVE_NOTIFICATIONS_FAILURE',
  CREATE_NOTIFICATIONS_REQUEST: 'CREATE_NOTIFICATIONS_REQUEST',
  CREATE_NOTIFICATIONS_SUCCESS: 'CREATE_NOTIFICATIONS_SUCCESS',
  CREATE_NOTIFICATIONS_FAILURE: 'CREATE_NOTIFICATIONS_FAILURE',
};

export default notificationsConstants;
