// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dHzM_5ZsDNGp4sSh0npD {
  overflow: auto;
  background: #fafafa;
}
.darkMode .dHzM_5ZsDNGp4sSh0npD {
  background: #1d1d1d;
}
.darkMode .bqE5UzFItg_lsCByoPyI {
  color: rgba(255, 255, 255, 0.85);
}
.FdFBusA5JsUqtC0YUoZB {
  margin-left: 6px !important;
  line-height: 1.5 !important;
}
.uvBND0UQCM8QWi5_jcnp {
  text-align: left;
  display: inline-block;
  margin-left: 5px;
  margin-top: 10px;
}
.uvBND0UQCM8QWi5_jcnp h3 {
  margin-bottom: 0;
}
.bqE5UzFItg_lsCByoPyI {
  font-size: 1.1em;
  transform: translateY(5%);
  display: inline-block;
  font-weight: 500;
}
.Kf_yNIr_khJ5Nbjg70kw {
  color: #ff000f;
}
.dp5TdhPthFeQf1lRmB2f {
  margin-right: 5px;
}
.KOwGSoh0ArpsmkQ1vhwE {
  display: none;
}
@media screen and (max-width: 425px) {
  .I1SIqvaI7N8AbAwKcJYX {
    margin-top: 16px;
  }
  .KOwGSoh0ArpsmkQ1vhwE {
    display: table-cell;
  }
  .qjhr3POLvZVhyzf97IlZ {
    display: none;
  }
  .times-view .action-buttons.table-operations.action-overlay {
    display: none;
  }
  .times-view .action-overlay-area:hover td {
    background: unset !important;
    background-color: unset !important;
  }
  .times-view .action-overlay-area:hover td * {
    opacity: 1 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Times/Times.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;AACF;AAEA;EAEI,mBAAA;AADJ;AADA;EAKI,gCAAA;AADJ;AAKA;EACE,2BAAA;EACA,2BAAA;AAHF;AAMA;EACE,gBAAA;EACA,qBAAA;EACA,gBAAA;EACA,gBAAA;AAJF;AAMA;EACE,gBAAA;AAJF;AAMA;EACE,gBAAA;EACA,yBAAA;EACA,qBAAA;EACA,gBAAA;AAJF;AAOA;EACE,cAAA;AALF;AAQA;EACE,iBAAA;AANF;AASA;EACE,aAAA;AAPF;AAUA;EACE;IACE,gBAAA;EARF;EAUA;IACE,mBAAA;EARF;EAUA;IACE,aAAA;EARF;EAYA;IACE,aAAA;EAVF;EAYA;IACE,4BAAA;IACA,kCAAA;EAVF;EAYA;IACE,qBAAA;EAVF;AACF","sourcesContent":[":local(.timesTable) {\n  overflow: auto;\n  background: #fafafa;\n}\n\n:global(.darkMode) {\n  .timesTable {\n    background: #1d1d1d;\n  }\n  .timesUserName {\n    color: rgba(255, 255, 255, 0.85);\n  }\n}\n\n:local(.timeButton) {\n  margin-left: 6px !important;\n  line-height: 1.5 !important;\n}\n\n:local(.timesHeader) {\n  text-align: left;\n  display: inline-block;\n  margin-left: 5px;\n  margin-top: 10px;\n}\n:local(.timesHeader h3) {\n  margin-bottom: 0;\n}\n:local(.timesUserName) {\n  font-size: 1.1em;\n  transform: translateY(5%);\n  display: inline-block;\n  font-weight: 500;\n}\n\n:local(.timeInscribedWrongDay) {\n  color: #ff000f;\n}\n\n:local(.userAvatar) {\n  margin-right: 5px;\n}\n\n.actionUIMobile {\n  display: none;\n}\n\n@media screen and (max-width: 425px) {\n  .addTimeFormWrapper {\n    margin-top: 16px;\n  }\n  .actionUIMobile {\n    display: table-cell;\n  }\n  .actionUIDesktop {\n    display: none;\n  }\n\n  // disable default hovering overlay\n  :global(.times-view .action-buttons.table-operations.action-overlay) {\n    display: none;\n  }\n  :global(.times-view .action-overlay-area:hover td) {\n    background: unset !important;\n    background-color: unset !important;\n  }\n  :global(.times-view .action-overlay-area:hover td *) {\n    opacity: 1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timesTable": `dHzM_5ZsDNGp4sSh0npD`,
	"timesUserName": `bqE5UzFItg_lsCByoPyI`,
	"timeButton": `FdFBusA5JsUqtC0YUoZB`,
	"timesHeader": `uvBND0UQCM8QWi5_jcnp`,
	"timeInscribedWrongDay": `Kf_yNIr_khJ5Nbjg70kw`,
	"userAvatar": `dp5TdhPthFeQf1lRmB2f`,
	"actionUIMobile": `KOwGSoh0ArpsmkQ1vhwE`,
	"addTimeFormWrapper": `I1SIqvaI7N8AbAwKcJYX`,
	"actionUIDesktop": `qjhr3POLvZVhyzf97IlZ`
};
export default ___CSS_LOADER_EXPORT___;
