// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.izjCch8uBmrtSllsT3D1 {
  margin-left: 256px;
  transition: margin 0.2s;
}
.izjCch8uBmrtSllsT3D1.F_aOPA8bqm3BB9TasQ13 {
  margin-left: 80px;
}
.o7LqLRvvcR4PxHtW06rb {
  padding: 0;
  position: fixed;
  z-index: 9;
  left: 256px;
  width: calc(100% - 256px);
  transition: all 0.2s;
}
.o7LqLRvvcR4PxHtW06rb.F_aOPA8bqm3BB9TasQ13 {
  left: 80px;
  width: calc(100% - 80px);
}
.tESALyDFaAjnwQT7_6B9 {
  margin: 24px 24px 0;
  height: 100%;
  margin-top: calc(64px + 24px);
}
@media screen and (max-width: 425px) {
  .izjCch8uBmrtSllsT3D1 {
    margin-left: 0;
  }
  .izjCch8uBmrtSllsT3D1.F_aOPA8bqm3BB9TasQ13 {
    margin-left: 0;
  }
  .tESALyDFaAjnwQT7_6B9 {
    margin: 10px 10px 0;
    margin-top: calc(64px + 24px);
  }
  .o7LqLRvvcR4PxHtW06rb.F_aOPA8bqm3BB9TasQ13 {
    left: 0;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/layout/Layout.less"],"names":[],"mappings":"AAIA;EACE,kBAAA;EAIA,uBAAA;AANF;AAGE;EACE,iBAAA;AADJ;AAMA;EACE,UAAA;EACA,eAAA;EACA,UAAA;EAEA,WAAA;EACA,yBAAA;EAKA,oBAAA;AATF;AAKE;EACE,UAAA;EACA,wBAAA;AAHJ;AAQA;EACE,mBAAA;EACA,YAAA;EACA,6BAAA;AANF;AASA;EACE;IACE,cAAA;EAPF;EAQE;IACE,cAAA;EANJ;EASA;IACE,mBAAA;IACA,6BAAA;EAPF;EAUE;IACE,OAAA;IACA,WAAA;EARJ;AACF","sourcesContent":["@sider-width-expanded: 256px;\n@sider-width-collapsed: 80px;\n@header-height: 64px;\n\n.contentLayout {\n  margin-left: @sider-width-expanded;\n  &.menuCollapsed {\n    margin-left: @sider-width-collapsed;\n  }\n  transition: margin 0.2s;\n}\n\n.mainHeader {\n  padding: 0;\n  position: fixed;\n  z-index: 9;\n\n  left: @sider-width-expanded;\n  width: calc(100% - @sider-width-expanded);\n  &.menuCollapsed {\n    left: @sider-width-collapsed;\n    width: calc(100% - @sider-width-collapsed);\n  }\n  transition: all 0.2s;\n}\n\n.mainContent {\n  margin: 24px 24px 0;\n  height: 100%;\n  margin-top: calc(@header-height + 24px);\n}\n\n@media screen and (max-width: 425px) {\n  .contentLayout {\n    margin-left: 0;\n    &.menuCollapsed {\n      margin-left: 0;\n    }\n  }\n  .mainContent {\n    margin: 10px 10px 0;\n    margin-top: calc(@header-height + 24px);\n  }\n  .mainHeader {\n    &.menuCollapsed {\n      left: 0;\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentLayout": `izjCch8uBmrtSllsT3D1`,
	"menuCollapsed": `F_aOPA8bqm3BB9TasQ13`,
	"mainHeader": `o7LqLRvvcR4PxHtW06rb`,
	"mainContent": `tESALyDFaAjnwQT7_6B9`
};
export default ___CSS_LOADER_EXPORT___;
