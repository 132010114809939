import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'helpers/ajax';
import quoteConstants from 'constants/quote.constants';

function* fetchQuoteRequest() {
  try {
    const ajax = get(`/api/v1/get_quote/`, {});
    let resp = yield call([ajax, ajax.toPromise]);
    resp = resp.response;
    yield put({
      type: quoteConstants.FETCH_QUOTE_SUCCESS,
      fetchedData: resp,
    });
  } catch (err) {
    yield put({ type: quoteConstants.FETCH_QUOTE_ERROR });
  }
}

function* quoteOfTheDaySaga() {
  yield takeLatest(quoteConstants.FETCH_QUOTE_REQUEST, fetchQuoteRequest);
}

export default quoteOfTheDaySaga;
