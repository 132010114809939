import dayjs from 'dayjs';
import publicLinksConstants from 'constants/publicLinks.constants';

const initialState = {
  fetching: false,
  link: {
    fetching: false,
  },
};
export default function publicLinks(state = initialState, action) {
  switch (action.type) {
    case publicLinksConstants.FETCH_PUBLIC_LINKS_REQUEST:
      return { ...state, fetching: true, fetched: false };
    case publicLinksConstants.FETCH_PUBLIC_LINKS_SUCCESS:
      return {
        ...state,
        fetched: true,
        fetching: false,
        results: action.fetchedData,
      };
    case publicLinksConstants.GET_PUBLIC_LINK_REQUEST:
      return {
        ...state,
        link: {
          fetching: true,
          fetched: false,
        },
      };
    case publicLinksConstants.GET_PUBLIC_LINK_FAILURE:
      return {
        ...state,
        link: {
          fetching: false,
          fetched: false,
          error: action.error,
        },
      };
    case publicLinksConstants.GET_PUBLIC_LINK_SUCCESS:
      return {
        ...state,
        link: {
          fetched: true,
          fetching: false,
          fetchedAt: dayjs(),
          ...action.fetchedData,
        },
      };
    default: {
      return state;
    }
  }
}
