import meConstants from 'constants/me.constants';

const meStorage = localStorage.getItem('me');
const initialState = meStorage
  ? {
      fetched: true,
      data: JSON.parse(meStorage),
    }
  : {};

export default function me(state = initialState, action) {
  switch (action.type) {
    case meConstants.RETRIEVE_ME_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case meConstants.RETRIEVE_ME_SUCCESS:
      return {
        data: action.data,
        fetched: true,
      };

    case meConstants.RETRIEVE_ME_FAILURE:
      return {};

    default:
      return state;
  }
}
