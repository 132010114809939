import { call, put, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import publicLinksConstants from 'constants/publicLinks.constants';
import { get, patch } from 'helpers/ajax';

function* fetchPublicLinksRequest(action) {
  try {
    const params = queryString.stringify(action.params);
    const ajax = get(`/api/v1/public_links/manage?${params}`, {}, true);
    const resp = yield call([ajax, ajax.toPromise]);
    yield put({
      type: publicLinksConstants.FETCH_PUBLIC_LINKS_SUCCESS,
      fetchedData: resp,
    });
  } catch (err) {
    yield put({
      type: fetchPublicLinksRequest.FETCH_PUBLIC_LINKS_FAILURE,
      error: err,
    });
  }
}

function* patchPublicLinkRequest(action) {
  try {
    const params = action.params;
    const id = action.id;
    const ajax = patch(`/api/v1/public_links/manage/${id}`, params, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: publicLinksConstants.PATCH_PUBLIC_LINK_SUCCESS,
    });
    yield put({
      type: publicLinksConstants.FETCH_PUBLIC_LINKS_REQUEST,
    });
  } catch (err) {
    yield put({
      type: publicLinksConstants.PATCH_PUBLIC_LINK_FAILURE,
      error: err,
    });
  }
}

function* getPublicLinkRequest(action) {
  try {
    const id = action.id;
    const ajax = get(`/api/v1/public_links/report?id=${id}`, {}, true);
    const resp = yield call([ajax, ajax.toPromise]);
    yield put({
      type: publicLinksConstants.GET_PUBLIC_LINK_SUCCESS,
      fetchedData: resp.response,
    });
  } catch (err) {
    yield put({
      type: publicLinksConstants.GET_PUBLIC_LINK_FAILURE,
      error: err,
    });
  }
}

function* publicLinksSaga() {
  yield takeLatest(
    publicLinksConstants.FETCH_PUBLIC_LINKS_REQUEST,
    fetchPublicLinksRequest
  );
  yield takeLatest(
    publicLinksConstants.PATCH_PUBLIC_LINK_REQUEST,
    patchPublicLinkRequest
  );
  yield takeLatest(
    publicLinksConstants.GET_PUBLIC_LINK_REQUEST,
    getPublicLinkRequest
  );
}

export default publicLinksSaga;
