import { all } from 'redux-saga/effects';
import timesSaga from './times.saga';
import daysOffSaga from './daysOff.saga';
import daysOffAllowancesSaga from './daysOffAllowances.saga';
import publicLinksSaga from './publicLinks.saga';
import userSaga from './user.saga';
import freelancersSaga from './freelancers.saga';
import remoteWorkSaga from './remoteWork.saga';
import quoteSaga from './quote.saga';
import archiveSaga from './archive.saga';
import notificationsSaga from './notifications.saga';
import loginSaga from './login.saga';

export default function* rootSaga() {
  yield all([
    timesSaga(),
    daysOffSaga(),
    daysOffAllowancesSaga(),
    publicLinksSaga(),
    userSaga(),
    freelancersSaga(),
    remoteWorkSaga(),
    quoteSaga(),
    archiveSaga(),
    notificationsSaga(),
    loginSaga(),
  ]);
}
