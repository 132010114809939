// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oetpUG1PL18WGFqp23_b {
  line-height: 1.5 !important;
  margin-left: 4px;
  margin-right: 4px;
}
.aP2Uc0ScUmGqY_wqNSb5 {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/QuickDatePicker/index.less"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEA;EACI,aAAA;AAAJ","sourcesContent":[":local(.dateButton) {\n    line-height: 1.5 !important;\n    margin-left: 4px;\n    margin-right: 4px;\n}\n\n.quickDatePicker {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateButton": `oetpUG1PL18WGFqp23_b`,
	"quickDatePicker": `aP2Uc0ScUmGqY_wqNSb5`
};
export default ___CSS_LOADER_EXPORT___;
