import { call, put, takeLatest } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import freelancersConstants from 'constants/freelancers.constants';
import { get, post, remove } from 'helpers/ajax';
import freelancersActions from '../actions/freelancers.actions';

function* fetchFreelancersRequest() {
  try {
    const ajax = get('/api/v1/freelancers', {}, true);
    const resp = yield call([ajax, ajax.toPromise]);
    yield put({
      type: freelancersConstants.FETCH_FREELANCERS_SUCCESS,
      fetchedData: resp.response.results,
    });
  } catch {
    yield put({ type: freelancersConstants.FETCH_FREELANCERS_FAILURE });
  }
}

function* createFreelancerRequest(action) {
  try {
    const data = action.data;
    const ajax = post('/api/v1/freelancers', data, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: freelancersConstants.CREATE_FREELANCER_SUCCESS,
    });
    yield put({
      type: freelancersConstants.FETCH_FREELANCERS_REQUEST,
    });
  } catch (err) {
    yield put(freelancersActions.createFreelancerFailure(err));
  }
}

function* createFreelancerFailure(action) {
  NotificationManager.error(action.error.response.email);
  yield;
}

function* deleteFreelancerRequest(action) {
  try {
    const id = action.id;
    const ajax = remove(`/api/v1/freelancers/${id}`);
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: freelancersConstants.DELETE_FREELANCER_SUCCESS,
    });
    yield put({
      type: freelancersConstants.FETCH_FREELANCERS_REQUEST,
    });
  } catch {
    yield put({ type: freelancersConstants.DELETE_FREELANCER_FAILURE });
  }
}

function* freelancersSaga() {
  yield takeLatest(
    freelancersConstants.FETCH_FREELANCERS_REQUEST,
    fetchFreelancersRequest
  );
  yield takeLatest(
    freelancersConstants.CREATE_FREELANCER_REQUEST,
    createFreelancerRequest
  );
  yield takeLatest(
    freelancersConstants.DELETE_FREELANCER_REQUEST,
    deleteFreelancerRequest
  );
  yield takeLatest(
    freelancersConstants.CREATE_FREELANCER_FAILURE,
    createFreelancerFailure
  );
}

export default freelancersSaga;
