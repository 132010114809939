import React, { useState } from 'react';
import { Alert, Button, Row, ConfigProvider, theme } from 'antd';
import styles from './Login.less';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import GoogleLogo from './assets/GoogleLogo';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AuthLayout from 'layout/AuthLayout';
import LoginFreelancer from './components/LoginFreelancer';
import authActions from 'actions/auth.actions';

function LoginPage() {
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const onFailure = (err) => {
    setError(err);
  };

  const onSuccess = (res) => {
    dispatch(
      authActions.loginRequest({
        code: res.code,
      }),
      'authentication'
    );
  };
  // eslint-disable-next-line
  const renderMessage = (content) => (
    <Alert
      style={{ marginBottom: 24 }}
      message={content.error || content}
      type="error"
      showIcon
    />
  );

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => onSuccess(tokenResponse),
    onError: (errorResponse) => onFailure(errorResponse),
    scope:
      'email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar',
    flow: 'auth-code',
    hint: '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',
  });

  return (
    <AuthLayout>
      <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
        <div className={styles.main}>
          {error && renderMessage(error)}
          <Switch>
            <Route path="/login/freelancer">
              <LoginFreelancer />
            </Route>
            <Route path="/login">
              <Link className={styles.link} to="/login/freelancer">
                <Button>Freelancer Login</Button>
              </Link>
              <Row type="flex" justify="center">
                <Button
                  className={styles.login}
                  icon={<Icon component={GoogleLogo} />}
                  onClick={() => login()}
                >
                  Login via Google
                </Button>
              </Row>
            </Route>
          </Switch>
        </div>
      </ConfigProvider>
    </AuthLayout>
  );
}

export default LoginPage;
