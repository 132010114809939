import React from 'react';
import { Helmet } from 'react-helmet';
import { CopyrightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import GlobalFooter from 'components/GlobalFooter';
import styles from './AuthLayout.less';
import logo from 'assets/logo-wide-black.png';

const copyright = (
  <>
    Copyright <CopyrightOutlined /> {new Date().getFullYear()} Ulam Labs
  </>
);

class AuthLayout extends React.PureComponent {
  // @TODO title
  // getPageTitle() {
  //   const { routerData, location } = this.props;
  //   const { pathname } = location;
  //   let title = 'Ant Design Pro';
  //   if (routerData[pathname] && routerData[pathname].name) {
  //     title = `${routerData[pathname].name} - Ant Design Pro`;
  //   }
  //   return title;
  // }

  render() {
    const { children } = this.props;
    return (
      <div className={styles.container}>
        <Helmet titleTemplate="%s - Ulam Labs Intranet" title="Login Page" />
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <Link to="/">
                <img alt="logo" className={styles.logo} src={logo} />
              </Link>
            </div>
            <div className={styles.desc}>Intranet</div>
          </div>
          {children}
        </div>
        <GlobalFooter copyright={copyright} />
      </div>
    );
  }
}

export default AuthLayout;
