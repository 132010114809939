import rolesConstants from 'constants/roles.constants';

/* eslint-disable */
export default function roles(state = {}, action) {
  switch (action.type) {
    case rolesConstants.FETCH_ROLES_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        params: action.params,
      });

    case rolesConstants.FETCH_ROLES_SUCCESS:
      return Object.assign({}, state, {
        roles: action.roles,
        fetched: true,
      });

    case rolesConstants.FETCH_ROLES_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        roles: {},
      });

    case rolesConstants.CREATE_ROLE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      });

    case rolesConstants.CREATE_ROLE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
      });

    case rolesConstants.CREATE_ROLE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
      });

    case rolesConstants.DELETE_ROLE_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      });

    case rolesConstants.DELETE_ROLE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      });

    case rolesConstants.DELETE_ROLE_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
      });

    default:
      return state;
  }
}
