import { Observable } from 'rxjs';
import queryString from 'query-string';
import clientsConstants from 'constants/clients.constants';
import clientsActions from 'actions/clients.actions';
import { get, remove, patch, post } from 'helpers/ajax';

const clientsEpic = (action$, store$) =>
  Observable.merge(
    action$.ofType(clientsConstants.DELETE_CLIENT_REQUEST).mergeMap((action) =>
      remove(`/api/v1/clients/${action.id}`, {}, true)
        .map(() =>
          clientsActions.deleteClientSuccess(action.id, action.storeId)
        )
        .catch((error) =>
          Observable.of(
            clientsActions.deleteClientFailure(error, action.storeId)
          )
        )
    ),
    action$
      .ofType(clientsConstants.DELETE_CLIENT_SUCCESS)
      .map((action) =>
        clientsActions.fetchClientsRequest(
          store$.value.clients[action.storeId].params,
          action.storeId
        )
      ),
    action$.ofType(clientsConstants.FETCH_CLIENTS_REQUEST).mergeMap((action) =>
      get(`/api/v1/clients?${queryString.stringify(action.params)}`, {}, true)
        .map((response) =>
          clientsActions.fetchClientsSuccess(response.response, action.storeId)
        )
        .catch((error) =>
          Observable.of(
            clientsActions.fetchClientsFailure(error, action.storeId)
          )
        )
    ),
    action$.ofType(clientsConstants.PATCH_CLIENT_REQUEST).mergeMap((action) =>
      patch(`/api/v1/clients/${action.id}`, action.data, {}, true)
        .map(() => clientsActions.patchClientSuccess(action.id, action.storeId))
        .catch((error) =>
          Observable.of(
            clientsActions.patchClientFailure(error, action.storeId)
          )
        )
    ),
    action$
      .ofType(clientsConstants.PATCH_CLIENT_SUCCESS)
      .map((action) =>
        clientsActions.fetchClientsRequest(
          store$.value.clients[action.storeId].params,
          action.storeId
        )
      ),
    action$.ofType(clientsConstants.CREATE_CLIENT_REQUEST).mergeMap((action) =>
      post('/api/v1/clients', action.data, {}, true)
        .map((response) =>
          clientsActions.createClientSuccess(response.response, action.storeId)
        )
        .catch((error) =>
          Observable.of(clientsActions.createClientFailure(error))
        )
    ),
    action$
      .ofType(clientsConstants.CREATE_CLIENT_SUCCESS)
      .map((action) =>
        clientsActions.fetchClientsRequest(
          store$.value.clients[action.storeId].params,
          action.storeId
        )
      )
  );

export default clientsEpic;
