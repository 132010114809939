import dayjs from 'dayjs';
import usersConstants from 'constants/users.constants';

/* eslint-disable prefer-object-spread */
export default function users(state = {}, action) {
  switch (action.type) {
    case usersConstants.FETCH_USERS_REQUEST: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        params: action.params,
        fetching: true,
      };
      return Object.assign({}, state, newState);
    }

    case usersConstants.FETCH_USERS_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        users: action.users,
        fetched: true,
        fetchedAt: dayjs(),
      };
      return Object.assign({}, state, newState);
    }

    case usersConstants.FETCH_USERS_FAILURE: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        users: action.users,
        fetching: false,
      };
      return Object.assign({}, state, newState);
    }

    default:
      return state;
  }
}
