import { CloseOutlined } from '@ant-design/icons';
import { format, formatDistanceToNow, isToday, isYesterday } from 'date-fns';
import Markdown from 'react-markdown';
import DynamicIcon from '../DynamicIcon/DynamicIcon';
import styles from './Notification.less';

const displayTime = (dateStr) => {
  const date = new Date(dateStr);
  const now = new Date();

  const timeDiff = now - date;
  const tenMins = 10 * 60 * 1000;

  if (timeDiff <= tenMins) {
    return `${formatDistanceToNow(date)} ago`;
  }
  if (isToday(date)) {
    return `Today, ${format(date, 'HH:mm')}`;
  }
  if (isYesterday(date)) {
    return `Yesterday, ${format(date, 'HH:mm')}`;
  }
  const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  return `${daysAgo} days ago`;
};

const notificationConfig = (
  data,
  closeNotification,
  clickNotification,
  sender
) => ({
  message: (
    <div className={styles.header}>
      <div>
        <DynamicIcon iconName={data.icon} />
        <strong>{sender || data.sender_id}</strong>
      </div>
      <p>{displayTime(data.created_at)}</p>
    </div>
  ),
  description: (
    <div>
      <strong>{data.title}</strong>
      <Markdown className={styles.description}>{data?.message}</Markdown>
    </div>
  ),
  onClick: clickNotification,
  /* eslint-disable */
  closeIcon: (
    <div onClick={closeNotification}>
      <CloseOutlined />
    </div>
  ),
  duration: 20,
  className: styles.notification,
  placement: 'bottomRight',
  key: data.notification_id,
});

export default notificationConfig;
