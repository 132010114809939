import accessConstants from 'constants/access.constants';

function retrieveAccessRequest(id) {
  return {
    type: accessConstants.RETRIEVE_ACCESS_REQUEST,
    id,
  };
}

function retrieveAccessSuccess(data) {
  return {
    type: accessConstants.RETRIEVE_ACCESS_SUCCESS,
    data,
  };
}

function retrieveAccessFailure(error) {
  return {
    type: accessConstants.RETRIEVE_ACCESS_FAILURE,
    error,
  };
}

function patchAccessRequest(id, data) {
  return {
    type: accessConstants.PATCH_ACCESS_REQUEST,
    id,
    data,
  };
}

function patchAccessSuccess(id) {
  return {
    type: accessConstants.PATCH_ACCESS_SUCCESS,
    id,
  };
}

function patchAccessFailure(error) {
  return {
    type: accessConstants.PATCH_ACCESS_FAILURE,
    error,
  };
}

const accessActions = {
  retrieveAccessRequest,
  retrieveAccessSuccess,
  retrieveAccessFailure,
  patchAccessRequest,
  patchAccessSuccess,
  patchAccessFailure,
};

export default accessActions;
