import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import styles from '../UserProfile.less';

const UserWorkStats = ({ user }) => {
  const {
    profile: {
      daily_hours,
      work_stats: {
        this_week_remote_stats,
        this_month_remote_stats,
        this_quarter_remote_stats,
        this_month_hours_stats,
        this_quarter_hours_stats,
        last_quarter_hours_stats,
        last_quarter_remote_stats,
      } = {},
      day_off,
    },
  } = user;
  /* eslint-disable camelcase */

  const workDaysWeek = this_week_remote_stats?.work_days;
  const workDaysMonth = this_month_remote_stats?.work_days;
  const workDaysQuarter = this_quarter_remote_stats?.work_days;

  const remoteDaysWeek = this_week_remote_stats?.remote_work_days;
  const remoteDaysMonth = this_month_remote_stats?.remote_work_days;
  const remoteDaysQuarter = this_quarter_remote_stats?.remote_work_days;

  const thisMonthHours = this_month_hours_stats;
  const thisQuarterHours = this_quarter_hours_stats;
  const lastQuarterHours = last_quarter_hours_stats;
  const lastQuarterRemote = last_quarter_remote_stats;

  const showMissingHours = daily_hours > 0;

  const currentMonth = dayjs().month() + 1;
  const firstMonthsOfQuarter = [1, 4, 7, 10];

  const isFirstMonthOfQuarter = firstMonthsOfQuarter.some(
    (month) => month === currentMonth
  );

  const quarterHoursStats = isFirstMonthOfQuarter ? (
    <>
      <h3>Last quarter:</h3>
      <p>
        {lastQuarterHours?.sum.toFixed(2)}
        {showMissingHours && ` / ${lastQuarterHours?.expected.toFixed()}`}{' '}
        logged
      </p>
      {lastQuarterHours?.diff !== 0 && (
        <p className={lastQuarterHours?.diff > 0 ? styles.green : styles.red}>
          {`${Math.abs(lastQuarterHours?.diff.toFixed(2))} hours 
          ${lastQuarterHours?.diff > 0 ? 'overtime' : 'missing'}`}
        </p>
      )}
    </>
  ) : (
    <>
      <h3>This quarter:</h3>
      <p>
        {lastQuarterHours?.sum.toFixed(2)}
        {showMissingHours && ` / ${lastQuarterHours?.expected.toFixed()}`}{' '}
        logged
      </p>
      {showMissingHours && thisQuarterHours?.diff !== 0 && (
        <p className={thisQuarterHours?.diff > 0 ? styles.green : styles.red}>
          {`${Math.abs(thisQuarterHours?.diff).toFixed(2)} hours 
          ${thisQuarterHours?.diff > 0 ? 'overtime' : 'missing'}`}
        </p>
      )}
    </>
  );

  const quarterRemoteStats = isFirstMonthOfQuarter ? (
    <>
      <h3>Last quarter:</h3>
      <p>
        {lastQuarterRemote.remote_work_days}/{lastQuarterRemote.work_days} days
        remote (
        {(
          (lastQuarterRemote.remote_work_days /
            (lastQuarterRemote.work_days === 0
              ? 1
              : lastQuarterRemote.work_days)) *
          100
        ).toFixed()}
        %)
      </p>
    </>
  ) : (
    <>
      <h3>This quarter:</h3>
      <p>
        {remoteDaysQuarter}/{workDaysQuarter} days remote (
        {(
          (remoteDaysQuarter / (workDaysQuarter === 0 ? 1 : workDaysQuarter)) *
          100
        ).toFixed()}
        %)
      </p>
    </>
  );

  return (
    <div className={styles.workStats}>
      <Row gutter={[16, 16]} type="flex">
        {!user.profile.is_freelancer && (
          <>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className={styles.statsBlock}>
                <h2>Days Off</h2>
                <h3>Vacation Days Left:</h3>
                <p>
                  <span className={styles.bold}>
                    {day_off?.amount - day_off?.taken}
                  </span>
                  {day_off?.overdue > 0
                    ? ` (including ${day_off?.overdue} from previous year)`
                    : ''}
                </p>
                {day_off?.overdue > 0 && (
                  <>
                    <h3>
                      Days from previous year must be used by the end of
                      September
                    </h3>
                  </>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className={styles.statsBlock}>
                <h2>Remote Work</h2>
                <h3>This week:</h3>
                <p>
                  {remoteDaysWeek}/{workDaysWeek} days remote (
                  {(
                    (remoteDaysWeek / (workDaysWeek === 0 ? 1 : workDaysWeek)) *
                    100
                  ).toFixed()}
                  %)
                </p>
                <h3>This month:</h3>
                <p
                  className={
                    user.profile.contract_type === 'uop' &&
                    workDaysMonth - remoteDaysMonth < 5
                      ? styles.office
                      : ''
                  }
                >
                  {remoteDaysMonth}/{workDaysMonth} days remote (
                  {(
                    (remoteDaysMonth /
                      (workDaysMonth === 0 ? 1 : workDaysMonth)) *
                    100
                  ).toFixed()}
                  %)
                </p>
                {quarterRemoteStats}
              </div>
            </Col>
          </>
        )}

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className={styles.statsBlock}>
            <h2>Hours</h2>
            <h3>This month:</h3>
            <p>
              {thisMonthHours?.sum.toFixed(2)}
              {showMissingHours &&
                ` / ${thisMonthHours?.expected.toFixed()}`}{' '}
              logged
            </p>
            {showMissingHours && thisMonthHours?.diff !== 0 && (
              <p
                className={thisMonthHours?.diff > 0 ? styles.green : styles.red}
              >
                {`${Math.abs(thisMonthHours?.diff).toFixed(2)} hours 
                ${thisMonthHours?.diff > 0 ? 'overtime' : 'missing'}`}
              </p>
            )}
            {quarterHoursStats}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserWorkStats;
