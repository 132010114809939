export function formatDate(date) {
  const d = new Date(date);

  let month = `${d.getMonth() + 1}`;

  let day = `${d.getDate()}`;

  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function formatDateTime(date) {
  const d = new Date(date);

  let month = `${d.getMonth() + 1}`;

  let day = `${d.getDate()}`;

  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  const dateStr = [year, month, day].join('-');
  let hours = d.getHours();
  if (hours < 10) {
    hours = `0${hours}`;
  }
  let minutes = d.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  const timeStr = [hours, minutes].join(':');
  return `${dateStr} ${timeStr}`;
}

export function formatTime(time) {
  if (!time) return time;
  return time.toFixed(2);
}
