import remoteWorkActions from 'actions/remoteWork.actions';
import usersActions from 'actions/users.actions';
import { Button, Col, Modal, Row, Select, Table, Tag, Tooltip } from 'antd';
import AddOfficeWorkModal from 'components/AddOfficeWork/AddOfficeWorkModal';
import AddRemoteWorkModal from 'components/AddRemoteWork/AddRemoteWorkModal';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Loader from 'components/Loader/Loader';
import UserBadge from 'components/UserBadge/UserBadge';
import UserTooltip from 'components/UserTooltip/UserTooltip';
import buildKeyAndChildren from 'helpers/buildKeyAndChildren';
import multiselectConversion from 'helpers/multiselectConversion';
import { selectIsAdmin } from 'selectors/permissions.selectors';
import styles from './RemoteWork.less';

const RemoteWork = () => {
  const [usersFilter, setUsersFilter] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [officeVisible, setOfficeVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const dispatch = useDispatch();

  const {
    remoteWork: remoteWorks,
    next: nextRemoteWork,
    fetching: fetchingRemoteWork,
  } = useSelector((state) => state.remoteWork);
  const user = useSelector((state) => state.me);
  const fetchedUsers = useSelector((state) => state.users);
  const history = useHistory();
  const location = useLocation();

  const isAdmin = useSelector(selectIsAdmin);

  const fetchUsers = (params = {}) => {
    dispatch(usersActions.fetchUsersRequest({ ...params }, 'remoteWorkPage'));
  };

  const fetchRemoteWork = (params = {}) => {
    dispatch(remoteWorkActions.fetchRemoteWorkRequest({ ...params }));
  };

  const fetchNextRemoteWork = () => {
    dispatch(remoteWorkActions.fetchNextRemoteWorkRequest());
  };

  const deleteRemoteWork = (remoteWork, params = {}) => {
    dispatch(
      remoteWorkActions.deleteRemoteWorkRequest(remoteWork.id, { ...params })
    );
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (fetchedUsers.remoteWorkPage && fetchedUsers.remoteWorkPage.fetched)
      setUsers(fetchedUsers.remoteWorkPage.users.results);
  }, [fetchedUsers]);

  useEffect(() => {
    fetchRemoteWork({ user: usersFilter });
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('user');
    usersFilter.forEach((userFilter) => {
      queryParams.append('user', userFilter);
    });
    history.push({
      search: queryParams.toString(),
    });
  }, [usersFilter.toString()]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userParams = params.getAll('user');
    setUsersFilter(userParams);
  }, []);

  const remoteWorkWithKeyAndChildren = buildKeyAndChildren(remoteWorks);

  const showDeleteModal = (remoteWork) => {
    const {
      structuredDays,
      allowedDays,
      showSelectedDaysInfo,
      selectedPast,
      isNotMe,
      noAllowedDays,
    } = multiselectConversion(
      remoteWork,
      remoteWorkWithKeyAndChildren,
      isAdmin,
      user.data
    );

    const { confirm } = Modal;

    if (noAllowedDays && !isAdmin) {
      confirm({
        title: 'You are not allowed to delete selected days!',
      });
      return;
    }

    confirm({
      title: 'Delete Remote Work',
      content: (
        <>
          <p>Are you sure?</p>
          {showSelectedDaysInfo && (
            <>
              <p>You have selected remote work of these users:</p>
              {structuredDays.map((remoteData) => (
                <p key={remoteData.id}>
                  {remoteData.name}: {remoteData.days.length}
                </p>
              ))}
              {!isAdmin && isNotMe && (
                <p className={styles.info}>
                  Only yours day off will be deleted!
                </p>
              )}
              {selectedPast && !isAdmin && (
                <p className={styles.danger}>
                  Selected past remote work will not be removed!
                </p>
              )}
            </>
          )}
        </>
      ),
      okText: 'Delete',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteRemoteWork(
          allowedDays.length === 1
            ? allowedDays[0]
            : { id: allowedDays.map((allowedDay) => allowedDay.id) }
        );
      },
    });
  };

  const { Option } = Select;

  const rowSelection = {
    onChange: (rowKeys) => {
      setSelectedRowKeys(rowKeys);
    },
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'user',
      key: 'user',
      render: (userData) => {
        if (!userData?.id) {
          return;
        }

        return (
          <UserTooltip user={userData}>
            <Link to={userData?.id ? `/users/${userData?.id}` : '/monthly'}>
              <span className={styles.userName}>
                <UserBadge user={userData} />
                {userData?.name}
              </span>
            </Link>
          </UserTooltip>
        );
      },
    },
    { title: 'From', dataIndex: 'date_start', key: 'dataIndex' },
    { title: 'To', dataIndex: 'date_end', key: 'date_end' },
    { title: 'Days', dataIndex: 'days_no', key: 'days_no' },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      className: 'remarks-table-column',
      render: (text) => {
        return (
          <Tooltip title={text} placement="right">
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      dataIndex: 'is_late',
      key: 'is_late',
      render: (isLate, record) => {
        const showIsLate =
          (isAdmin || record.user?.id === user.data?.id) && isLate;
        if (!showIsLate) {
          return null;
        }
        return <Tag color="gold">Late</Tag>;
      },
    },
    {
      title: 'Action',
      className: 'action-column--visible',
      align: 'center',
      key: 'action',
      render: (text, record) => {
        if (record.children) {
          return;
        }
        let deleteButton = null;
        const startDate = new Date(record.date_start);
        if (
          (startDate >=
            dayjs()
              .startOf('day')
              .toDate() &&
            record.user?.id === user.data?.id) ||
          isAdmin
        ) {
          deleteButton = (
            <Button danger onClick={() => showDeleteModal(record)}>
              Delete
            </Button>
          );
        }
        return <div className="action-buttons">{deleteButton}</div>;
      },
    },
  ];

  return (
    <Loader loading={!remoteWorks}>
      <Helmet title="Remote Work" />
      <Row type="flex" gutter={16} justify="space-between">
        <Col md={6}>
          <Select
            placeholder="Users"
            mode="tags"
            className={['group-select', styles.userSelect].join(' ')}
            onChange={setUsersFilter}
            value={usersFilter}
            filterOption={(input, option) => {
              const fuse = new Fuse(users, {
                keys: ['name'],
                threshold: 0.3, // Adjust this value for desired fuzziness
              });
              const results = fuse.search(input);
              return results
                .map((res) => res.item?.name)
                .includes(option.props.children);
            }}
          >
            {users
              .sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
              .map((value) => {
                return (
                  <Option value={String(value?.id)} key={value?.id}>
                    {value?.name}
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col>
          <Row>
            <Button
              style={{ marginRight: '7px' }}
              onClick={() => setOfficeVisible(true)}
            >
              Add Office Work
            </Button>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              Add Remote Work
            </Button>
          </Row>
        </Col>
      </Row>
      <Row>
        <Button
          onClick={() => showDeleteModal(selectedRowKeys)}
          danger
          disabled={selectedRowKeys.length === 0}
        >
          Delete Selected
        </Button>
      </Row>
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchNextRemoteWork}
        hasMore={nextRemoteWork !== null && !fetchingRemoteWork}
      >
        <Table
          className="remote-work-table"
          dataSource={remoteWorkWithKeyAndChildren}
          rowKey="key"
          pagination={false}
          style={{ overflow: 'auto' }}
          columns={columns}
          expandable={{ expandRowByClick: true }}
          rowSelection={{
            hideSelectAll: true,
            type: 'checkbox',
            checkStrictly: false,
            ...rowSelection,
          }}
        />
      </InfiniteScroll>
      <AddRemoteWorkModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={(data) => {
          dispatch(remoteWorkActions.createRemoteWorkRequest({ ...data }, {}));
          setModalVisible(false);
        }}
      />
      <AddOfficeWorkModal
        visible={officeVisible}
        onCancel={() => setOfficeVisible(false)}
        onOk={(data) => {
          dispatch(
            remoteWorkActions.createRemoteWorkRequest(data, {}, undefined, true)
          );
          setOfficeVisible(false);
        }}
      />
    </Loader>
  );
};

RemoteWork.displayName = 'RemoteWork';

export default RemoteWork;
