const projectsConstants = {
  FETCH_PROJECTS_REQUEST: 'PROJECTS_FETCH_PROJECTS_REQUEST',
  FETCH_PROJECTS_SUCCESS: 'PROJECTS_FETCH_PROJECTS_SUCCESS',
  FETCH_PROJECTS_FAILURE: 'PROJECTS_FETCH_PROJECTS_FAILURE',
  DELETE_PROJECT_REQUEST: 'PROJECTS_DELETE_PROJECT_REQUEST',
  DELETE_PROJECT_SUCCESS: 'PROJECTS_DELETE_PROJECT_SUCCESS',
  DELETE_PROJECT_FAILURE: 'PROJECTS_DELETE_PROJECT_FAILURE',
  PATCH_PROJECT_REQUEST: 'PROJECTS_PATCH_PROJECT_REQUEST',
  PATCH_PROJECT_SUCCESS: 'PROJECTS_PATCH_PROJECT_SUCCESS',
  PATCH_PROJECT_FAILURE: 'PROJECTS_PATCH_PROJECT_FAILURE',
  CREATE_PROJECT_SUCCESS: 'PROJECTS_CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_REQUEST: 'PROJECTS_CREATE_PROJECT_REQUEST',
  CREATE_PROJECT_FAILURE: 'PROJECTS_CREATE_PROJECT_FAILURE',
};

export default projectsConstants;
