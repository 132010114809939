import presencesConstants from 'constants/presences.constants';

export function presenceTick(state = {}, action) {
  switch (action.type) {
    case presencesConstants.SEND_PRESENCE_TICK_SUCCESS: {
      return {
        data: action.data,
        fetched: true,
      };
    }

    case presencesConstants.SEND_PRESENCE_TICK_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    default:
      return state;
  }
}

export function presences(state = { authorized: false }, action) {
  switch (action.type) {
    case presencesConstants.FETCH_PRESENCES_REQUEST: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        fetching: true,
      };
      return newState;
    }

    case presencesConstants.FETCH_PRESENCES_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        presences: action.presences,
        fetched: true,
        fetching: false,
      };
      return newState;
    }

    case presencesConstants.FETCH_PRESENCES_FAILURE: {
      const newState = {
        ...state,
      };
      newState[action.storeId] = {
        ...newState[action.storeId],
        fetching: false,
        error: action.error,
      };
      return newState;
    }

    default:
      return state;
  }
}
