import MDEditor from '@uiw/react-md-editor';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import DynamicIcon from 'components/DynamicIcon/DynamicIcon';
import Fuse from 'fuse.js';
import userTheme from 'helpers/userTheme';
import { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import {
  selectOptionContract,
  selectOptionGroups,
  selectOptionUsers,
} from 'selectors/target.selectors';
import styles from './MessageCreation.less';

const { Option } = Select;

const iconMap = [
  'ulam',
  'CameraOutlined',
  'AimOutlined',
  'AlertOutlined',
  'ApiOutlined',
  'AudioOutlined',
  'BankOutlined',
  'BellOutlined',
  'CalendarOutlined',
  'CarOutlined',
  'CloudOutlined',
  'CoffeeOutlined',
  'CommentOutlined',
  'ContactsOutlined',
  'CreditCardOutlined',
  'CrownOutlined',
  'CustomerServiceOutlined',
  'DashboardOutlined',
  'DatabaseOutlined',
  'DesktopOutlined',
  'DisconnectOutlined',
  'DislikeOutlined',
  'DollarOutlined',
  'ExperimentOutlined',
  'EyeOutlined',
  'FireOutlined',
  'FlagOutlined',
  'FolderOutlined',
  'FrownOutlined',
  'GiftOutlined',
  'GlobalOutlined',
  'HeartOutlined',
  'HistoryOutlined',
  'HomeOutlined',
  'HourglassOutlined',
  'IdcardOutlined',
  'KeyOutlined',
  'LaptopOutlined',
  'LikeOutlined',
  'LinkOutlined',
  'MailOutlined',
  'NotificationOutlined',
  'PoweroffOutlined',
  'RestOutlined',
  'RocketOutlined',
  'SettingOutlined',
  'SmileOutlined',
  'SoundOutlined',
  'StarOutlined',
  'ThunderboltOutlined',
  'TrophyOutlined',
  'ToolOutlined',
  'UserOutlined',
  'WifiOutlined',
];

const MessageCreation = ({
  form,
  open,
  handleOk,
  handleCancel,
  markValue,
  setMarkValue,
}) => {
  const [options, setOptions] = useState(undefined);
  const optionUsers = useSelector(selectOptionUsers);
  const optionGroups = useSelector(selectOptionGroups);
  const optionContract = useSelector(selectOptionContract);
  const me = useSelector((state) => state.me);
  const isDarkMode = userTheme(me.data?.theme) === 'dark_mode';

  const getValue = () => {
    if (options !== undefined) {
      return options;
    }
    return optionUsers;
  };

  const handleChangeTarget = (value) => {
    if (value === 'all') {
      setOptions([]);
      form.setFieldValue('target', []);
    }
    if (value === 'group') {
      setOptions(optionGroups);
    }
    if (value === 'user') {
      setOptions(optionUsers);
    }
    if (value === 'contract_type') {
      setOptions(optionContract);
    }
  };

  const iconOptions = iconMap.map((icon) => {
    return (
      <Option key={icon} value={icon}>
        <DynamicIcon iconName={icon} />
      </Option>
    );
  });

  const fuseOptions = {
    keys: ['label'],
    threshold: 0.4,
  };
  const fuse = new Fuse(getValue(), fuseOptions);

  const sendOptions = [
    { label: 'Intranet', value: 'intranet' },
    { label: 'Email', value: 'email' },
    { label: 'Slack', value: 'slack' },
  ];

  return (
    <Modal
      destroyOnClose
      title="Compose Message"
      open={open}
      onOk={handleOk}
      width=""
      className={styles.messageModal}
      onCancel={() => {
        handleCancel();
        setMarkValue('');
      }}
      afterOpenChange={() => setOptions(optionUsers)}
    >
      <Form form={form} name="messageForm">
        <Row justify="space-between">
          <Col sm={5}>
            <Form.Item name="icon" label="Icon" initialValue="ulam">
              <Select
                className={styles.iconSelect}
                popupMatchSelectWidth={false}
                popupClassName={styles.iconDropdown}
              >
                {iconOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={18}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                { required: true, message: 'Title is required' },
                { max: 128, message: 'Character limit exceeded' },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="message"
          label="Message"
          rules={[
            { required: true, message: 'Message is required' },
            { max: 512, message: 'Character limit exceeded' },
          ]}
        >
          <div data-color-mode={isDarkMode ? 'dark' : 'light'}>
            <MDEditor
              height="50vh"
              textareaProps={{
                placeholder: 'Please enter Markdown text',
              }}
              value={markValue}
              onChange={setMarkValue}
            />
          </div>
        </Form.Item>
        <Row justify="space-between">
          <Col xs={8} sm={8}>
            <Form.Item
              name="target_type"
              label="Target"
              rules={[{ required: true, message: 'Target is required' }]}
              initialValue="user"
            >
              <Select onChange={handleChangeTarget}>
                <Option value="user">User</Option>
                <Option value="group">Group</Option>
                <Option value="contract_type">Contract Type</Option>
                <Option value="all">All</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={15}>
            <Form.Item
              name="target"
              rules={[
                {
                  required: getValue()?.length > 0,
                  message: 'Target is required',
                },
              ]}
            >
              <Select
                mode="multiple"
                disabled={getValue()?.length === 0}
                options={getValue()}
                filterOption={(input, option) => {
                  const item = option.label;
                  const result = fuse.search(input.toLowerCase());

                  return result.some((r) => r.item.label === item);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Send via:"
          name="send_via"
          rules={[{ required: true, message: 'Send via is required' }]}
          initialValue={['intranet', 'email', 'slack']}
        >
          <Select
            mode="multiple"
            placeholder="Please select"
            options={sendOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MessageCreation;
