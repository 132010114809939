import daysOffAllowancesConstants from 'constants/daysOffAllowances.constants';

function fetchDaysOffAllowances(params) {
  return {
    type: daysOffAllowancesConstants.FETCH_DAYS_OFF_ALLOWANCES_REQUEST,
    params,
  };
}

function fetchDaysOffAllowancesSuccess(response) {
  return {
    type: daysOffAllowancesConstants.FETCH_DAYS_OFF_ALLOWANCES_SUCCESS,
    fetchedData: response.results,
  };
}

function fetchDaysOffAllowancesFailure(error) {
  return {
    type: daysOffAllowancesConstants.FETCH_DAYS_OFF_ALLOWANCES_FAILURE,
    error,
  };
}

function patchDaysOffAllowances(id, amount) {
  return {
    type: daysOffAllowancesConstants.PATCH_DAYS_OFF_ALLOWANCES_REQUEST,
    id,
    amount,
  };
}

function patchDaysOffAllowancesSuccess() {
  return {
    type: daysOffAllowancesConstants.PATCH_DAYS_OFF_ALLOWANCES_SUCCESS,
  };
}

function patchDaysOffAllowancesFailure(error) {
  return {
    type: daysOffAllowancesConstants.PATCH_DAYS_OFF_ALLOWANCES_FAILURE,
    error,
  };
}

export default {
  fetchDaysOffAllowances,
  fetchDaysOffAllowancesSuccess,
  fetchDaysOffAllowancesFailure,
  patchDaysOffAllowances,
  patchDaysOffAllowancesSuccess,
  patchDaysOffAllowancesFailure,
};
