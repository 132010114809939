// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZF494V24tGPxuucSkhWx {
  color: #6d6d6d;
  font-size: 12px;
  font-weight: 400;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 0;
  line-height: 20px;
  border-radius: 5px 7px;
  -webkit-box-shadow: inset -3px -3px 19px -17px #42445a;
  -moz-box-shadow: inset -3px -3px 19px -17px #42445a;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  margin-bottom: 10px;
}
.darkMode .ZF494V24tGPxuucSkhWx {
  background-color: #2e2e2e;
  color: rgba(255, 255, 255, 0.65);
}
`, "",{"version":3,"sources":["webpack://./src/components/AddOfficeWork/AddOfficeWorkModal.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,SAAA;EACA,iBAAA;EACA,sBAAA;EACA,sDAAA;EACA,mDAAA;EACA,+CAAA;EACA,eAAA;EACA,mBAAA;AACF;AACA;EAEI,yBAAA;EACA,gCAAA;AAAJ","sourcesContent":[".description {\n  color: #6d6d6d;\n  font-size: 12px;\n  font-weight: 400;\n  background-color: #f5f5f5;\n  padding: 10px;\n  margin: 0;\n  line-height: 20px;\n  border-radius: 5px 7px;\n  -webkit-box-shadow: inset -3px -3px 19px -17px rgba(66, 68, 90, 1);\n  -moz-box-shadow: inset -3px -3px 19px -17px rgba(66, 68, 90, 1);\n  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);\n  margin-top: 5px;\n  margin-bottom: 10px;\n}\n:global(.darkMode) {\n  .description {\n    background-color: #2e2e2e;\n    color: rgba(255, 255, 255, 0.65);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `ZF494V24tGPxuucSkhWx`
};
export default ___CSS_LOADER_EXPORT___;
