const remoteWorkConstants = {
  FETCH_REMOTE_WORK_REQUEST: 'REMOTE_WORK_FETCH_REMOTE_WORK_REQUEST',
  FETCH_REMOTE_WORK_SUCCESS: 'REMOTE_WORK_FETCH_REMOTE_WORK_SUCCESS',
  FETCH_REMOTE_WORK_FAILURE: 'REMOTE_WORK_FETCH_REMOTE_WORK_FAILURE',

  FETCH_NEXT_REMOTE_WORK_REQUEST: 'REMOTE_WORK_FETCH_NEXT_REMOTE_WORK_REQUEST',
  FETCH_NEXT_REMOTE_WORK_SUCCESS: 'REMOTE_WORK_FETCH_NEXT_REMOTE_WORK_SUCCESS',
  FETCH_NEXT_REMOTE_WORK_FAILURE: 'REMOTE_WORK_FETCH_NEXT_REMOTE_WORK_FAILURE',

  CREATE_REMOTE_WORK_REQUEST: 'REMOTE_WORK_CREATE_REMOTE_WORK_REQUEST',
  CREATE_REMOTE_WORK_SUCCESS: 'REMOTE_WORK_CREATE_REMOTE_WORK_SUCCESS',
  CREATE_REMOTE_WORK_FAILURE: 'REMOTE_WORK_CREATE_REMOTE_WORK_FAILURE',

  DELETE_REMOTE_WORK_REQUEST: 'REMOTE_WORK_DELETE_REMOTE_WORK_REQUEST',
  DELETE_REMOTE_WORK_FAILURE: 'REMOTE_WORK_DELETE_REMOTE_WORK_FAILURE',
};

export default remoteWorkConstants;
