const accessConstants = {
  RETRIEVE_ACCESS_REQUEST: 'ACCESS_RETRIEVE_ACCESS_REQUEST',
  RETRIEVE_ACCESS_SUCCESS: 'ACCESS_RETRIEVE_ACCESS_SUCCESS',
  RETRIEVE_ACCESS_FAILURE: 'ACCESS_RETRIEVE_ACCESS_FAILURE',
  PATCH_ACCESS_REQUEST: 'ACCESS_PATCH_ACCESS_REQUEST',
  PATCH_ACCESS_SUCCESS: 'ACCESS_PATCH_ACCESS_SUCCESS',
  PATCH_ACCESS_FAILURE: 'ACCESS_PATCH_ACCESS_FAILURE',
};

export default accessConstants;
