import { Observable } from 'rxjs/Rx';
import { push } from 'connected-react-router';
import authConstants from 'constants/auth.constants';
import authActions from 'actions/auth.actions';
import alertsActions from 'actions/alerts.actions';
import { post } from 'helpers/ajax';

const authEpic = (action$) =>
  Observable.merge(
    action$.ofType(authConstants.LOGIN_REQUEST).mergeMap((action) =>
      post('/api/v1/auth/social/google-oauth2/', action.payload, {})
        .map((response) => authActions.loginSuccess(response.response.token))
        .catch((error) => Observable.of(authActions.loginFailure(error)))
    ),
    action$
      .ofType(authConstants.LOGIN_SUCCESS)
      .do((action) =>
        localStorage.setItem(
          'user',
          JSON.stringify({
            token: action.token,
          })
        )
      )
      .mapTo(push('/')),
    action$
      .ofType(authConstants.LOGIN_FAILURE)
      .map(() =>
        alertsActions.addAlert(
          'Could not log in due to unidentified issues.',
          'danger'
        )
      ),
    action$
      .ofType(authConstants.LOGOUT)
      .map(() => localStorage.removeItem('user')) // eslint-disable-next-line
      .map(() => {
        window.location = '/login';
      })
  );

export default authEpic;
