import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import gravatar from 'gravatar';

class UserGravatar extends React.Component {
  /* eslint-disable */
  displayName = 'UserGravatar';

  static propTypes = {
    email: PropTypes.string.isRequired,
    className: PropTypes.string,
  };
  /* eslint-enable */

  getAvatarUrl() {
    const { email } = this.props;

    const defaultAvatar = 'https://api.adorable.io/avatars/100/';
    return gravatar.url(email, {
      size: 100,
      default: defaultAvatar + email,
      protocol: 'https',
      d: '404',
    });
  }

  render() {
    const { size = '', className = '' } = this.props;

    return (
      <Avatar
        icon={<UserOutlined />}
        size={size}
        className={className}
        src={this.getAvatarUrl()}
      />
    );
  }
}

export default UserGravatar;
