import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table, Tooltip } from 'antd';
import { EditableRow, EditableCell } from 'components/EditableTable';
import styles from '../DaysOff.less';
import UserTooltip from 'components/UserTooltip/UserTooltip';
import UserBadge from '../../../components/UserBadge/UserBadge';
import Loader from 'components/Loader/Loader';

const DaysOffAllowancesReport = ({
  initialData,
  editable,
  saveCallback,
  isAdmin,
  me,
}) => {
  const dataWithTotals = (data) => {
    const groupedAllowances = _.groupBy(data, (record) => record.user.id);
    const withTotals = Object.entries(groupedAllowances).map(
      ([userId, userAllowances]) => {
        if (userAllowances.length < 2) return userAllowances;
        const totals = {
          user: { id: userId },
          year: 'Totals',
          amount: userAllowances.reduce((a, b) => a + b.amount, 0),
          taken: userAllowances.reduce((a, b) => a + b.taken, 0),
        };
        return userAllowances.concat(totals);
      }
    );
    return [].concat(...withTotals);
  };

  const [allowances, setAllowances] = useState(dataWithTotals(initialData));

  const updatedTotals = (data, userId) => {
    const userAllowances = data.filter(
      (record) => record.user.id === userId && record.year !== 'Totals'
    );
    if (userAllowances.length < 2) return data;
    const index = data.findIndex((record) => record.user.id === userId);
    const totals = {
      user: { id: userId },
      year: 'Totals',
      amount: userAllowances.reduce((a, b) => a + parseInt(b.amount, 10), 0),
      taken: userAllowances.reduce((a, b) => a + parseInt(b.taken, 10), 0),
    };
    const newData = [...data];
    newData[index + userAllowances.length] = totals;
    return newData;
  };

  const handleSave = (row) => {
    const newData = [...allowances];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    // eslint-disable-next-line
    row.amount = parseInt(row.amount, 10);
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setAllowances(updatedTotals(newData, row.user.id));
    saveCallback(row);
  };

  useEffect(() => setAllowances(dataWithTotals(initialData)), [initialData]);

  const { Column } = Table;

  const userIndex = 0;
  const userCounts = _.countBy(allowances, (record) => record.user.id);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Loader loading={!initialData}>
      <Table
        size="middle"
        bordered
        components={editable ? components : undefined}
        pagination={false}
        scroll={{ x: true }}
        dataSource={allowances.sort((a, b) =>
          a.user.name.localeCompare(b.user.name)
        )}
        rowClassName={(record) =>
          `editable-row ${record.year}-row ${
            record.user.is_active ? '' : 'inactive-user-row'
          }`
        }
        rowKey={(record) => {
          return record.year === 'Totals'
            ? `${record.user.id}-${record.year}`
            : record.id;
        }}
      >
        <Column
          title="User"
          align="left"
          className="user-column"
          key="userCol"
          onCell={(record, rowIndex) => {
            return {
              props: {
                rowSpan:
                  rowIndex === userIndex ? userCounts[record.user.id] : 0,
              },
            };
          }}
          render={(text, record) => {
            return (
              <UserTooltip user={record.user}>
                <Link to={`/users/${record.user.id}`}>
                  <UserBadge user={record.user} /> {record.user.name}
                </Link>
              </UserTooltip>
            );
          }}
        />
        <Column title="Year" dataIndex="year" />
        {editable ? (
          <Column
            title="Allowance"
            dataIndex="amount"
            inputType="integer"
            onCell={(record) => ({
              record,
              title: 'Allowance',
              editable: record.year !== 'Totals',
              dataIndex: 'amount',
              inputType: 'integer',
              // eslint-disable-next-line
              title: 'Allowance',
              handleSave,
            })}
          />
        ) : (
          <Column title="Allowance" dataIndex="amount" />
        )}
        <Column title="Planned & Taken" dataIndex="taken" />
        <Column
          title="Balance"
          render={(text, row) => row.amount - row.taken}
        />
        <Column
          title="Next vacation"
          render={(text, row) => (
            <span>
              {row.start_next ? `${row.start_next} - ${row.end_next}` : ''}
            </span>
          )}
        />
        <Column
          title="Comment"
          render={(text, row) => (
            <span>
              {(isAdmin || me.data.id === row.user.id) && row.remarks_next}
            </span>
          )}
        />
        <Column
          title="Overdue"
          render={(text, row) => {
            const isOverdue = row.overdue > 0;
            return (
              <Tooltip
                title={
                  isOverdue && (
                    <p className={styles.overdue}>
                      Must be used by the end of September
                    </p>
                  )
                }
                className={isOverdue ? styles.overdue : ''}
              >
                {row.overdue}
              </Tooltip>
            );
          }}
        />
      </Table>
    </Loader>
  );
};

DaysOffAllowancesReport.propTypes = {
  // eslint-disable-next-line
  initialData: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  saveCallback: PropTypes.func,
};

export default DaysOffAllowancesReport;
