import { call, put, takeLatest, select } from 'redux-saga/effects';
import queryString from 'query-string';
import { Modal, message } from 'antd';
import daysOffConstants from 'constants/daysOff.constants';
import { get, post, remove, getUrl, patch } from 'helpers/ajax';
import meActions from 'actions/me.actions';
import daysOffActions from '../actions/daysOff.actions';
import userActions from '../actions/user.actions';

function* fetchDaysOffRequest(action) {
  try {
    const params = queryString.stringify({ ...action.params, limit: 40 });
    const fetchAll = action.fetchAll;
    let ajax = get(`/api/v1/days_off?${params}`, {}, true);
    let resp = yield call([ajax, ajax.toPromise]);
    const results = resp.response.results;

    while (resp.response.next && fetchAll) {
      ajax = getUrl(resp.response.next, {}, true);
      resp = yield call([ajax, ajax.toPromise]);
      results.push(...resp.response.results);
    }

    yield put({
      type: daysOffConstants.FETCH_DAYS_OFF_SUCCESS,
      fetchedData: resp.response.results,
      next: resp.response.next,
    });
  } catch (err) {
    yield put({ type: daysOffConstants.FETCH_DAYS_OFF_FAILURE, error: err });
  }
}

function* fetchNextDaysOffRequest() {
  try {
    const next = yield select((state) => state.daysOff.next);
    if (next) {
      const ajax = getUrl(next, {}, true);
      const resp = yield call([ajax, ajax.toPromise]);

      yield put({
        type: daysOffConstants.FETCH_NEXT_DAYS_OFF_SUCCESS,
        fetchedData: resp.response.results,
        next: resp.response.next,
      });
    }
  } catch (err) {
    yield put({
      type: daysOffConstants.FETCH_NEXT_DAYS_OFF_FAILURE,
      error: err,
    });
  }
}

function* createDaysOffRequest(action) {
  try {
    const params = action.params;
    const data = action.data;
    const ajax = post(
      `/api/v1/days_off${data.reoccurrence ? '/add_periodic' : ''}`,
      data,
      {},
      true
    );
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: daysOffConstants.CREATE_DAYS_OFF_SUCCESS,
    });
    yield put({
      type: daysOffConstants.FETCH_DAYS_OFF_REQUEST,
      params,
    });
  } catch (err) {
    yield put({ type: daysOffConstants.CREATE_DAYS_OFF_FAILURE, error: err });
  }
}

function* deleteDaysOffRequest(action) {
  try {
    const id = action.id;
    const params = action.params;
    const ajax = Array.isArray(id)
      ? patch(
          `/api/v1/days_off/archive_selected`,
          // eslint-disable-next-line
          { days_off: id },
          { 'Content-Type': 'application/json' },
          true
        )
      : remove(`/api/v1/days_off/${id}`, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: daysOffConstants.FETCH_DAYS_OFF_REQUEST,
      params,
    });
    yield put(meActions.retrieveMeRequest());
  } catch (err) {
    yield put({ type: daysOffConstants.DELETE_DAYS_OFF_FAILURE });
  }
}

function* fetchHolidayRequest(action) {
  try {
    const params = queryString.stringify(action.params);
    const ajax = get(`/api/v1/days_off/holidays?${params}`, {}, true);
    const resp = yield call([ajax, ajax.toPromise]);
    yield put({
      type: daysOffConstants.FETCH_HOLIDAYS_SUCCESS,
      fetchedData: resp.response,
    });
  } catch (err) {
    yield put({ type: daysOffConstants.FETCH_HOLIDAYS_ERROR });
  }
}

function* checkAndCreateDaysOff(action) {
  try {
    const { data, params } = action;
    const ajax = post('/api/v1/days_off/check', data, {}, true);
    const resp = yield call([ajax, ajax.toPromise]);
    const numberOfDays = resp.response.days_no;

    if (resp.response.is_late) {
      let modalDaysLength = 'less';
      let modalDaysBefore = window.__NOTICE_DAYS_BEFORE_SHORT_DAYS_OFF__;

      if (numberOfDays >= 5) {
        modalDaysLength = 'more';
        modalDaysBefore = window.__NOTICE_DAYS_BEFORE_LONG_DAYS_OFF__;
      }

      yield new Promise((resolve, reject) => {
        Modal.confirm({
          title: 'Are you sure?',
          content: `Usually days-off that are ${modalDaysLength} than 5 days long should be submitted ${modalDaysBefore} days before.`,
          okText: 'Submit',
          onOk() {
            resolve();
          },
          onCancel() {
            reject(new Error('Confirmation canceled'));
          },
        });
      });
      yield put(daysOffActions.createDaysOffRequest(data, params));
      yield put(
        userActions.retrieveUserRequest(
          data.user_id !== undefined && data.user_id !== null
            ? data.user_id
            : data.id
        )
      );
      yield put(meActions.retrieveMeRequest());
    } else {
      yield put(daysOffActions.createDaysOffRequest(data, params));
      yield put(
        userActions.retrieveUserRequest(
          data.user_id !== undefined && data.user_id !== null
            ? data.user_id
            : data.id
        )
      );
      yield put(meActions.retrieveMeRequest());
    }
  } catch (err) {
    yield put({ type: daysOffConstants.CREATE_DAYS_OFF_FAILURE, error: err });
    Object.entries(err.response).forEach((response) => message.error(response));
  }
}

function* daysOffSaga() {
  yield takeLatest(
    daysOffConstants.FETCH_DAYS_OFF_REQUEST,
    fetchDaysOffRequest
  );
  yield takeLatest(
    daysOffConstants.FETCH_NEXT_DAYS_OFF_REQUEST,
    fetchNextDaysOffRequest
  );
  yield takeLatest(
    daysOffConstants.CREATE_DAYS_OFF_REQUEST,
    createDaysOffRequest
  );
  yield takeLatest(
    daysOffConstants.DELETE_DAYS_OFF_REQUEST,
    deleteDaysOffRequest
  );
  yield takeLatest(
    daysOffConstants.FETCH_HOLIDAYS_REQUEST,
    fetchHolidayRequest
  );
  yield takeLatest(
    daysOffConstants.CHECK_AND_CREATE_DAYS_OFF,
    checkAndCreateDaysOff
  );
}

export default daysOffSaga;
