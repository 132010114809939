import React from 'react';
import { Modal, Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { formatDate } from 'helpers/dateTime';
import publicLinksActions from 'actions/publicLinks.actions';
import clientsActions from 'actions/clients.actions';

/* eslint-disable camelcase */
const ManagePublicLinksModal =
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        publicLinks: [],
        clients: [],
      };

      this.archiveLink = this.archiveLink.bind(this);
      this.unarchiveLink = this.unarchiveLink.bind(this);
    }

    /* eslint-disable react/no-did-update-set-state */
    componentDidUpdate(prevProps) {
      const { publicLinks, clients } = this.props;
      const clientsQuery = clients.managePublicLinks;
      const clientsPrevQuery = prevProps.clients.managePublicLinks;
      if (!prevProps.open && this.props.open) {
        this.updateLinks();
        this.updateClients();
      }
      if (clientsQuery && clientsQuery.fetched && !clientsPrevQuery.fetched) {
        this.setState({
          clients: clientsQuery.clients.results,
        });
      }
      if (
        publicLinks &&
        !prevProps.publicLinks.fetched &&
        publicLinks.fetched
      ) {
        this.setState({
          publicLinks: publicLinks.results.response,
        });
      }
    }

    // eslint-disable-next-line
    getClientName(id) {
      const { clients } = this.state;
      const clientsDict = {};
      clients.forEach((value) => {
        clientsDict[value.id] = value.name;
      });
      if (id in clientsDict) {
        return clientsDict[id];
      }
    }

    updateLinks() {
      const { dispatch } = this.props;
      dispatch(publicLinksActions.fetchPublicLinks());
    }

    updateClients() {
      const { dispatch } = this.props;
      dispatch(clientsActions.fetchClientsRequest({}, 'managePublicLinks'));
    }

    archiveLink(event) {
      const { dispatch } = this.props;
      dispatch(
        publicLinksActions.patchPublicLink(event, {
          archived_date: new Date().toISOString(),
          is_archived: true,
        })
      );
    }

    unarchiveLink(event) {
      const { dispatch } = this.props;
      dispatch(
        publicLinksActions.patchPublicLink(event, {
          is_archived: false,
        })
      );
    }

    render() {
      const { publicLinks } = this.state;
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const columns = [
        {
          title: 'Client',
          dataIndex: 'client',
          render: (client) => {
            const name = this.getClientName(client);
            return name;
          },
        },
        {
          title: 'Link',
          dataIndex: 'id',
          key: 'id',
          render: (link) => {
            return (
              <Link to={`/public/reports/${link}`}>
                {baseUrl}/public/reports/{link}
              </Link>
            );
          },
        },
        {
          title: 'Last visit',
          dataIndex: 'used_at',
          key: 'used_at',
          render: (date) => {
            return formatDate(date);
          },
        },
        {
          title: 'Total visits',
          dataIndex: 'views',
          key: 'views',
        },
        {
          title: 'Action',
          render: (obj) => {
            if (obj.is_archived) {
              return (
                <Button onClick={() => this.unarchiveLink(obj.id)}>
                  Unarchive
                </Button>
              );
            }
            return (
              <Button onClick={() => this.archiveLink(obj.id)}>Archive</Button>
            );
          },
        },
      ];
      return (
        <Modal
          title="Manage Public Links"
          className="public-links-modal"
          // eslint-disable-next-line
          {...this.props}
        >
          <Table
            columns={columns}
            dataSource={publicLinks}
            pagination={false}
            rowKey="id"
            scroll={{ x: 'auto' }}
          />
        </Modal>
      );
    }
  };

ManagePublicLinksModal.displayName = 'ManagePublicLinksModal';

function mapStateToProps(state) {
  const { publicLinks, clients } = state;
  return {
    publicLinks,
    clients,
  };
}

export default connect(mapStateToProps)(ManagePublicLinksModal);
