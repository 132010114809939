import alertsConstants from 'constants/alerts.constants';

function addAlert(text, style) {
  return {
    type: alertsConstants.ADD_ALERT,
    text,
    style,
  };
}

function removeAlert(id) {
  return {
    type: alertsConstants.REMOVE_ALERT,
    id,
  };
}

const alertsActions = {
  addAlert,
  removeAlert,
};

export default alertsActions;
