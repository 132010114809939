import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Select, Row, Col, Checkbox } from 'antd';
import dayjs from 'dayjs';
import '../DaysOff.css';
import daysOffAllowancesActions from 'actions/daysOffAllowances.actions';
import DaysOffAllowancesReport from '../components/DaysOffAllowancesReport';
import { selectIsAdmin } from 'selectors/permissions.selectors';
import { get } from 'helpers/ajax';

const DaysOffAllowances = ({ usersFilter, onInactiveFilterChange }) => {
  const dispatch = useDispatch();

  const { daysOffAllowances, error } = useSelector(
    (state) => state.daysOffAllowances
  );
  const isAdmin = useSelector(selectIsAdmin);
  const me = useSelector((state) => state.me);

  const [yearFilter, setYearFilter] = useState([dayjs().year()]);

  const [inactiveFilter, setInactiveFilter] = useState(false);
  const inactiveFilterChange = (event) => {
    setInactiveFilter(event.target.checked);
    onInactiveFilterChange(event.target.checked);
  };

  const fetchDaysOffAllowances = (params = {}) => {
    dispatch(daysOffAllowancesActions.fetchDaysOffAllowances(params));
  };

  const patchDaysOffAllowances = (id, amount) => {
    dispatch(daysOffAllowancesActions.patchDaysOffAllowances(id, amount));
  };

  useEffect(() => {
    fetchDaysOffAllowances({
      user: usersFilter,
      year: yearFilter,
      active: inactiveFilter ? undefined : true,
      ordering: 'user,-year',
    });
  }, [usersFilter, yearFilter, inactiveFilter]);

  const saveCallback = (row) => {
    patchDaysOffAllowances(row.id, row.amount);
  };

  const renderOptions = () => {
    const { Option } = Select;
    const currentYear = dayjs().year();
    const options = [];
    // eslint-disable-next-line
    for (let i = 2018; i <= currentYear; i++) {
      options.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    }
    return options;
  };

  const alert = error ? (
    <Alert
      type="error"
      message={`HTTP ${error.status}`}
      description={error.response.detail}
      style={{ 'margin-bottom': '10px' }}
      showIcon
    />
  ) : null;

  const getFile = async () => {
    const resp = await get(`/api/v1/get_token`, {}, true).toPromise();
    const token = resp.response.token;
    const params = new URLSearchParams({ year: yearFilter, jwt: token });
    const url = `${
      window.__API__
    }/api/v1/csv/days_off_allowance?${params.toString()}`;

    window.location.replace(url);
  };

  return (
    <div>
      <Row gutter={16} className="days-off-controls">
        <Col md={6}>
          <Select
            className="group-select"
            placeholder="Year"
            onChange={(newFilter) => setYearFilter(newFilter)}
            defaultValue={yearFilter}
          >
            {renderOptions()}
          </Select>
        </Col>
        <Col md={6}>
          {me.data.is_superuser ? (
            <Checkbox checked={inactiveFilter} onChange={inactiveFilterChange}>
              Show inactive employees
            </Checkbox>
          ) : null}
        </Col>
        {isAdmin && (
          <Col md={6}>
            {/* eslint-disable */}
            <Link onClick={getFile} to="#">
              CSV
            </Link>
          </Col>
        )}
      </Row>
      {alert}
      <DaysOffAllowancesReport
        initialData={daysOffAllowances}
        editable={me.data.is_superuser}
        saveCallback={saveCallback}
        isAdmin={isAdmin}
        me={me}
      />
    </div>
  );
};

DaysOffAllowances.propTypes = {
  usersFilter: PropTypes.arrayOf(PropTypes.string),
  onInactiveFilterChange: PropTypes.func,
};

export default DaysOffAllowances;
