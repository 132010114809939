import daysOffConstants from 'constants/daysOff.constants';

function fetchDaysOffRequest(params, fetchAll = true) {
  return {
    type: daysOffConstants.FETCH_DAYS_OFF_REQUEST,
    params,
    fetchAll,
  };
}

function fetchDaysOffSuccess(fetchedData) {
  return {
    type: daysOffConstants.FETCH_DAYS_OFF_SUCCESS,
    fetchedData,
  };
}

function fetchNextDaysOffRequest(params) {
  return {
    type: daysOffConstants.FETCH_NEXT_DAYS_OFF_REQUEST,
    params,
  };
}

function fetchNextDaysOffSuccess(fetchedData, next) {
  return {
    type: daysOffConstants.FETCH_NEXT_DAYS_OFF_SUCCESS,
    fetchedData,
    next,
  };
}

function createDaysOffRequest(data, params) {
  return {
    type: daysOffConstants.CREATE_DAYS_OFF_REQUEST,
    data,
    params,
  };
}

function deleteDaysOffRequest(id, params) {
  return {
    type: daysOffConstants.DELETE_DAYS_OFF_REQUEST,
    id,
    params,
  };
}

function fetchHolidaysRequest(params) {
  return {
    type: daysOffConstants.FETCH_HOLIDAYS_REQUEST,
    params,
  };
}

function checkAndCreateDaysOffAction(data, params = {}) {
  return {
    type: daysOffConstants.CHECK_AND_CREATE_DAYS_OFF,
    data,
    params,
  };
}

const daysOffActions = {
  fetchDaysOffRequest,
  fetchDaysOffSuccess,
  fetchNextDaysOffRequest,
  fetchNextDaysOffSuccess,
  createDaysOffRequest,
  deleteDaysOffRequest,
  fetchHolidaysRequest,
  checkAndCreateDaysOffAction,
};

export default daysOffActions;
