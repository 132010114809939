// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HkiIUntzmDRFUhZRufPS {
  height: 100%;
  padding: 40px;
  border-left: 1px solid #f0f0f0;
}
.HkiIUntzmDRFUhZRufPS h2 {
  margin: 0;
  font-size: 28px;
  line-height: 32px;
  text-wrap: wrap;
}
.NmUPtMhFE5bm3rpFKfVk {
  text-wrap: wrap;
  word-break: break-all;
}
.TMLHDh_J3dVXQten4y_t {
  display: flex;
  font-size: 18px;
  gap: 10px;
  height: 100%;
  margin-top: 10px;
  justify-content: flex-end;
}
.TMLHDh_J3dVXQten4y_t span:hover {
  color: #2081ff;
  cursor: pointer;
}
.HZIc66D00PrDbX1jhDSo {
  flex-direction: column;
  margin-top: 20px;
}
.HZIc66D00PrDbX1jhDSo p {
  margin: 0;
}
.VdkU6mh5OVoGQTndLMC4 {
  margin-top: 10px;
  margin-bottom: 30px;
  display: none;
}
.VdkU6mh5OVoGQTndLMC4 span {
  color: rgba(0, 0, 0, 0.88);
  font-size: 24px;
}
.pPmXrGzbsv5vvTKU_39g {
  margin-top: 20px;
  word-break: break-all;
  flex-direction: column;
}
.pPmXrGzbsv5vvTKU_39g p {
  font-weight: 500;
  font-size: 16px;
}
.darkMode .VdkU6mh5OVoGQTndLMC4 span {
  color: rgba(255, 255, 255, 0.85);
}
.darkMode .HkiIUntzmDRFUhZRufPS {
  border-left: 1px solid #303030;
}
@media (max-width: 992px) {
  .VdkU6mh5OVoGQTndLMC4 {
    display: block;
    margin-top: 0;
    margin-bottom: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/MessagesCenter/components/NotificationMessage.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,8BAAA;AACF;AAJA;EAKI,SAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAEJ;AAEA;EACE,eAAA;EACA,qBAAA;AAAF;AAGA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;AADF;AALA;EAQI,cAAA;EACA,eAAA;AAAJ;AAIA;EACE,sBAAA;EACA,gBAAA;AAFF;AAAA;EAII,SAAA;AADJ;AAKA;EACE,gBAAA;EACA,mBAAA;EACA,aAAA;AAHF;AAAA;EAKI,0BAAA;EACA,eAAA;AAFJ;AAMA;EACE,gBAAA;EACA,qBAAA;EACA,sBAAA;AAJF;AACA;EAKI,gBAAA;EACA,eAAA;AAHJ;AAOA;EAGM,gCAAA;AAPN;AAIA;EAQI,8BAAA;AATJ;AAaA;EACE;IACE,cAAA;IACA,aAAA;IACA,mBAAA;EAXF;AACF","sourcesContent":[".notificationMessage {\n  height: 100%;\n  padding: 40px;\n  border-left: 1px solid #f0f0f0;\n  h2 {\n    margin: 0;\n    font-size: 28px;\n    line-height: 32px;\n    text-wrap: wrap;\n  }\n}\n\n.title {\n  text-wrap: wrap;\n  word-break: break-all;\n}\n\n.actionButtons {\n  display: flex;\n  font-size: 18px;\n  gap: 10px;\n  height: 100%;\n  margin-top: 10px;\n  justify-content: flex-end;\n  span:hover {\n    color: rgb(32, 129, 255);\n    cursor: pointer;\n  }\n}\n\n.messageInfo {\n  flex-direction: column;\n  margin-top: 20px;\n  p {\n    margin: 0;\n  }\n}\n\n.backRow {\n  margin-top: 10px;\n  margin-bottom: 30px;\n  display: none;\n  span {\n    color: rgba(0, 0, 0, 0.88);\n    font-size: 24px;\n  }\n}\n\n.messageContent {\n  margin-top: 20px;\n  word-break: break-all;\n  flex-direction: column;\n  p {\n    font-weight: 500;\n    font-size: 16px;\n  }\n}\n\n:global(.darkMode) {\n  .backRow {\n    span {\n      color: rgba(255, 255, 255, 0.85);\n    }\n  }\n\n  .notificationMessage {\n    border-left: 1px solid #303030;\n  }\n}\n\n@media (max-width: 992px) {\n  .backRow {\n    display: block;\n    margin-top: 0;\n    margin-bottom: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationMessage": `HkiIUntzmDRFUhZRufPS`,
	"title": `NmUPtMhFE5bm3rpFKfVk`,
	"actionButtons": `TMLHDh_J3dVXQten4y_t`,
	"messageInfo": `HZIc66D00PrDbX1jhDSo`,
	"backRow": `VdkU6mh5OVoGQTndLMC4`,
	"messageContent": `pPmXrGzbsv5vvTKU_39g`
};
export default ___CSS_LOADER_EXPORT___;
