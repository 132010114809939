// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tDRBWwSnk6i1hi6meT3i {
  color: rgba(0, 0, 0, 0.65);
}
.EEMQU9K5jc9lVsMSy4aT {
  color: #bbbb01;
}
.EM4UvKpC8QtvxUJIUTVy {
  color: #b30000;
}
.darkMode .tDRBWwSnk6i1hi6meT3i {
  color: rgba(255, 255, 255, 0.85);
}
`, "",{"version":3,"sources":["webpack://./src/components/DaysOffReport/DaysOffReport.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AAEA;EACE,cAAA;AAAF;AAGA;EACE,cAAA;AADF;AAIA;EAEI,gCAAA;AAHJ","sourcesContent":[".userName {\n  color: rgba(0, 0, 0, 0.65);\n}\n\n.info {\n  color: rgb(187, 187, 1);\n}\n\n.danger {\n  color: rgb(179, 0, 0);\n}\n\n:global(.darkMode) {\n  .userName {\n    color: rgba(255, 255, 255, 0.85);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userName": `tDRBWwSnk6i1hi6meT3i`,
	"info": `EEMQU9K5jc9lVsMSy4aT`,
	"danger": `EM4UvKpC8QtvxUJIUTVy`
};
export default ___CSS_LOADER_EXPORT___;
