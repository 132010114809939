import { Observable } from 'rxjs';
import accessConstants from 'constants/access.constants';
import accessActions from 'actions/access.actions';
import { get, put } from 'helpers/ajax';

const accessEpic = (action$) =>
  Observable.merge(
    action$.ofType(accessConstants.RETRIEVE_ACCESS_REQUEST).mergeMap((action) =>
      get(`/api/v1/accessprofiles/${action.id}`, {}, true)
        .map((response) =>
          accessActions.retrieveAccessSuccess(response.response)
        )
        .catch((error) =>
          Observable.of(accessActions.retrieveAccessFailure(error))
        )
    ),
    action$.ofType(accessConstants.PATCH_ACCESS_REQUEST).mergeMap((action) =>
      put(
        `/api/v1/accessprofiles/${action.id}`,
        action.data,
        { 'Content-Type': 'application/json' },
        true
      )
        .map(() => accessActions.patchAccessSuccess(action.id))
        .catch((error) =>
          Observable.of(accessActions.patchAccessFailure(error))
        )
    ),
    action$
      .ofType(accessConstants.PATCH_ACCESS_SUCCESS)
      .map((action) => accessActions.retrieveAccessRequest(action.id))
  );

export default accessEpic;
