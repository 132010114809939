import { Observable } from 'rxjs';
import queryString from 'query-string';
import presencesConstants from 'constants/presences.constants';
import presencesActions from 'actions/presences.actions';
import { get, post } from 'helpers/ajax';

const presencesEpic = (action$) =>
  Observable.merge(
    action$
      .ofType(presencesConstants.FETCH_PRESENCES_REQUEST)
      .mergeMap((action) =>
        get(
          `/api/v1/presences?${queryString.stringify(action.params)}`,
          {},
          true
        )
          .map((response) =>
            presencesActions.fetchPresencesSuccess(
              response.response,
              action.storeId
            )
          )
          .catch((error) =>
            Observable.of(
              presencesActions.fetchPresencesFailure(error, action.storeId)
            )
          )
      ),
    action$.ofType(presencesConstants.SEND_PRESENCE_TICK).mergeMap((action) =>
      post('/api/v1/presences', action.payload, {}, true)
        .map((response) =>
          presencesActions.sendPresenceTickSuccess(response.response)
        )
        .catch((error) =>
          Observable.of(presencesActions.sendPresenceTickError(error))
        )
    )
  );

export default presencesEpic;
