import dayjs from 'dayjs';
import React, { Component } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DatePicker, Button } from 'antd';

import styles from './index.less';

export default class QuickDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onChange: (value) => props.onChange(value),
      value: props.value || props.defaultValue,
    };

    this.handleLeftClick = this.handleLeftClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { onChange, value } = props;
    if (onChange) {
      this.setState({
        onChange,
      });
    }
    if (value) {
      this.setState({
        value,
      });
    }
  }

  handleChange(value) {
    const { onChange } = this.state;
    onChange(value);
    this.setState({
      value,
    });
  }

  handleLeftClick() {
    const { value } = this.state;
    const newDate = dayjs(value).subtract(1, 'days');
    this.setState({
      value: newDate,
    });
    this.handleChange(newDate);
  }

  handleRightClick() {
    const { value } = this.state;
    const newDate = dayjs(value).add(1, 'days');
    this.setState({
      value: newDate,
    });
    this.handleChange(newDate);
  }

  render() {
    const { value } = this.state;
    return (
      <span className={styles.quickDatePicker}>
        <Button
          icon={<LeftOutlined />}
          className={styles.dateButton}
          onClick={this.handleLeftClick}
        />
        <DatePicker
          // eslint-disable-next-line
          {...this.props}
          value={value}
          onChange={(date) => this.handleChange(date)}
        />
        <Button
          icon={<RightOutlined />}
          className={styles.dateButton}
          onClick={this.handleRightClick}
        />
      </span>
    );
  }
}
