import { call, put, takeLatest, select } from 'redux-saga/effects';
import queryString from 'query-string';
import remoteWorkConstants from 'constants/remoteWork.constants';
import { get, post, remove, getUrl, patch } from 'helpers/ajax';
import userActions from '../actions/user.actions';

function* fetchRemoteWorkRequest(action) {
  try {
    const params = queryString.stringify(action.params);
    const ajax = get(`/api/v1/remote_work?${params}`, {}, true);
    const resp = yield call([ajax, ajax.toPromise]);
    yield put({
      type: remoteWorkConstants.FETCH_REMOTE_WORK_SUCCESS,
      fetchedData: resp.response.results,
      next: resp.response.next,
    });
  } catch (err) {
    yield put({
      type: remoteWorkConstants.FETCH_REMOTE_WORK_FAILURE,
      error: err,
    });
  }
}

function* fetchNextRemoteWorkRequest() {
  try {
    const next = yield select((state) => state.remoteWork.next);
    if (next) {
      const ajax = getUrl(next, {}, true);
      const resp = yield call([ajax, ajax.toPromise]);

      yield put({
        type: remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_SUCCESS,
        fetchedData: resp.response.results,
        next: resp.response.next,
      });
    }
  } catch (err) {
    yield put({
      type: remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_FAILURE,
      error: err,
    });
  }
}

function* createRemoteWorkRequest(action) {
  try {
    const { data, office, params } = action;
    const mode = office ? '/office_work' : '/remote_work';
    const reoccurrence = data.reoccurrence ? '/add_periodic' : '';
    const ajax = post(`/api/v1${mode}${reoccurrence}`, data, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: remoteWorkConstants.CREATE_REMOTE_WORK_SUCCESS,
    });
    yield put({
      type: remoteWorkConstants.FETCH_REMOTE_WORK_REQUEST,
      params,
    });
    yield put(
      userActions.retrieveUserRequest(
        data.user_id !== undefined && data.user_id !== null
          ? data.user_id
          : action.user.data.id
      )
    );
  } catch (err) {
    yield put({
      type: remoteWorkConstants.CREATE_REMOTE_WORK_FAILURE,
      error: err,
    });
  }
}

function* deleteRemoteWorkRequest(action) {
  try {
    const id = action.id;
    const params = action.params;
    const ajax =
      id.length > 1
        ? patch(
            `/api/v1/remote_work/archive_selected`,
            // eslint-disable-next-line
            { remote_works: id },
            { 'Content-Type': 'application/json' },
            true
          )
        : remove(`/api/v1/remote_work/${id}`, {}, true);
    yield call([ajax, ajax.toPromise]);
    yield put({
      type: remoteWorkConstants.FETCH_REMOTE_WORK_REQUEST,
      params,
    });
  } catch (err) {
    yield put({ type: remoteWorkConstants.DELETE_REMOTE_WORK_FAILURE });
  }
}

function* remoteWorkSaga() {
  yield takeLatest(
    remoteWorkConstants.FETCH_REMOTE_WORK_REQUEST,
    fetchRemoteWorkRequest
  );
  yield takeLatest(
    remoteWorkConstants.FETCH_NEXT_REMOTE_WORK_REQUEST,
    fetchNextRemoteWorkRequest
  );
  yield takeLatest(
    remoteWorkConstants.CREATE_REMOTE_WORK_REQUEST,
    createRemoteWorkRequest
  );
  yield takeLatest(
    remoteWorkConstants.DELETE_REMOTE_WORK_REQUEST,
    deleteRemoteWorkRequest
  );
}

export default remoteWorkSaga;
