import teamsConstants from 'constants/teams.constants';

function fetchTeamsRequest() {
  return {
    type: teamsConstants.FETCH_TEAMS_REQUEST,
  };
}

function fetchTeamsSuccess(teams) {
  return {
    type: teamsConstants.FETCH_TEAMS_SUCCESS,
    teams,
  };
}

function fetchTeamsFailure(error) {
  return {
    type: teamsConstants.FETCH_TEAMS_FAILURE,
    error,
  };
}

const teamsActions = {
  fetchTeamsRequest,
  fetchTeamsSuccess,
  fetchTeamsFailure,
};

export default teamsActions;
